/**
 * Enums for provider roles.
 */

const ProviderRole = Object.freeze({
	CareNavigator       			: 'care_navigator',
	Therapist          				: 'therapist',
	MedicationManager   			: 'medication_manager',
	Coach              				: 'coach',
	CareAdvocate       				: 'care_advocate',
	CareConsultant     				: 'care_consultant',
	EmployeeConsultant 				: 'employee_consultant',
	AssociateProvider  				: 'associate_provider',
	PeerRecoverySpecialist : 'peer_recovery_specialist',
	SpecialtyCareNavigator : 'specialty_care_navigator',
});

/**
 * Enums for Medication Manager specific roles.
 */

const MedicationManagerRole = Object.freeze({
	Psychiatrist                 : 'Psychiatrist',
	Internist                    : 'Internist',
	FamilyPractitioner           : 'Family Practitioner',
	PsychiatricNursePractitioner : 'Psychiatric Nurse Practitioner',
	Physician                    : 'Physician',
});

const isMedicationManagerRole = (roles) => {
	for (const role of roles){
		if (Object.values(MedicationManagerRole).includes(role)) return true;
		return false;
	}
};

/**
 *
 * @param {string} providerRole enums/ProviderRole, eg ProviderRole.CareNavigator
 * @returns {boolean}
 */
const isStateLicensedProvider = (providerRole) => [
	ProviderRole.Therapist,
	ProviderRole.MedicationManager,
].includes(providerRole);

/**
 *
 * This is to help distinguish between a provider with a single role of Care Consultant
 * vs a MultiRole provider (e.g. [Care Consultant, Employee Consultant])
 * Since some MultiRole Providers share the same appt types
 * @param {string | string[]} providerRole - Single role string or an array of roles, e.g., ProviderRole.CareNavigator or [ProviderRole.CareConsultant]
 * @returns {boolean}
 */
const isCareConsultantRole = (providerRole) => {
	if (Array.isArray(providerRole)) {
		return providerRole.length === 1 && providerRole[0] === getProviderRoleForTracking(ProviderRole.CareConsultant);
	}
	return providerRole === ProviderRole.CareConsultant;
};

/**
 * This is a layer of abstraction to ensure that the name of these provider roles
 * that is sent to immutable tracking systems is never changed.
 * The values below should not be modified without lots of communication and consideration.
 * @param {string}
 * @returns {string}
 */
const getProviderRoleForTracking = (providerRoleEnum) => ({
	[ProviderRole.CareNavigator]          : 'Care Navigator',
	[ProviderRole.Therapist]              : 'Therapist',
	[ProviderRole.MedicationManager]      : 'Medication Manager',
	[ProviderRole.Coach]                  : 'Coach',
	[ProviderRole.CareAdvocate]           : 'Care Advocate',
	[ProviderRole.CareConsultant]         : 'Care Consultant',
	[ProviderRole.PeerRecoverySpecialist] : 'Peer Recovery Specialist',
	[ProviderRole.SpecialtyCareNavigator] : 'Specialty Care Navigator',
})[providerRoleEnum];

module.exports = {
	ProviderRole,
	MedicationManagerRole,
	isMedicationManagerRole,
	isStateLicensedProvider,
	getProviderRoleForTracking,
	isCareConsultantRole,
};
