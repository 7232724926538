import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Text } from "@chakra-ui/react";
import Router from "next/router";

import routes from "routes";
import { Link } from "@springcare/sh-component-library";
import { CareProvider } from "shared/contexts";
import { ScheduleModalOpenOptions } from "shared/hooks";
import envUtils from "utils/environment";
import { TRACK_EVENT } from "utils/mixpanel";

import styles from "./styles.module.scss";

import useChatBotHandler from "utils/chatbot/useChatBotHandler";

interface ConnectTextProps {
  careNavigator: CareProvider;
  isGlobalTherapy: boolean;
  needHelpText: string;
  isCoach: boolean;
  hideCostText?: boolean;
  modalControls: { isOpen: boolean; onClose: () => void; onOpen: () => void };
  onScheduleModalOpen: (props: ScheduleModalOpenOptions) => void;
  providerType?: boolean;
  openHelpFlyout: () => void;
}

const ConnectWithCareNavigation = ({ text, onClickCareNavigatorButton }) => {
  return <Link onClick={onClickCareNavigatorButton}>{text}</Link>;
};

const RealTimeChatButton = ({ text, onClickChatButton }) => {
  return <Link onClick={onClickChatButton}>{text}</Link>;
};

const FindATimeButton = ({ text, handleFindATimeButtonClick }) => {
  return <Link onClick={handleFindATimeButtonClick}>{text}</Link>;
};

const careTeamEmail = envUtils.careTeamEmail;

const ConnectText: FC<React.PropsWithChildren<ConnectTextProps>> = ({
  careNavigator,
  isGlobalTherapy,
  needHelpText,
  isCoach,
  hideCostText = false,
  providerType,
  openHelpFlyout,
}) => {
  const { t } = useTranslation("benefits");
  const { handleOpenChat } = useChatBotHandler();

  const onClickChatButton = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      "Connect with your Care Navigator",
      {
        location: providerType,
      },
    );

    handleOpenChat();
  };

  const onClickCareNavigatorButton = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      "Connect with your Care Navigator",
      {
        location: providerType,
      },
    );

    Router.push(routes.CareNavigation.as);
  };

  const handleFindATimeButtonClick = () => {
    Router.push(routes.CareNavigation.as);
  };

  if (careNavigator && !isCoach) {
    const i18nKey = isGlobalTherapy
      ? needHelpText
      : "coveredCareContent.careNavigatorHelpNew";
    return (
      <>
        <Box pb="30">
          <Trans
            ns="benefits"
            i18nKey={i18nKey}
            values={{ careTeamEmail }}
            components={[
              <Link
                key="0"
                onClick={openHelpFlyout}
              >{`${careTeamEmail}`}</Link>,
              <RealTimeChatButton
                key="1"
                text={t("coveredCareContent.realTimeChat")}
                onClickChatButton={onClickChatButton}
              />,
              <FindATimeButton
                key="2"
                text={t("coveredCareContent.findATime")}
                handleFindATimeButtonClick={handleFindATimeButtonClick}
              />,
            ]}
          />
        </Box>
        {!isGlobalTherapy && !hideCostText && (
          <Trans
            ns="benefits"
            i18nKey="coveredCareContent.careNavigatorNoCost"
            components={[
              <Text
                as="span"
                size="body-medium-strong"
                key="coveredCareContent.careNavigatorNoCost"
              >
                {t("coveredCareContent.careNavigatorNoCost")}
              </Text>,
            ]}
          />
        )}
      </>
    );
  }

  return (
    <Box className={styles.connectTextWrapper} pb="v-20">
      <Trans
        ns="benefits"
        i18nKey={
          needHelpText ? needHelpText : "coveredCareContent.needHelpText"
        }
        components={[
          <ConnectWithCareNavigation
            key={t("coveredCareContent.connectWithCareNavigator")}
            text={t("coveredCareContent.connectWithCareNavigator")}
            onClickCareNavigatorButton={onClickCareNavigatorButton}
          />,
        ]}
      />
    </Box>
  );
};

export default ConnectText;
