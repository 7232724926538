import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";

import { getProviderRoleForTracking, ProviderRole } from "@spring/constants";

import { CareProvider } from "shared/contexts";
import { getProviderRole } from "utils/memberHelpers";

interface TelemetryProps {
  springDocId: string;
  location?: string;
  headerText?: string;
  to?: string;
}

export interface ScheduleModalOpenOptions {
  kind: string;
  provider: CareProvider;
  providerRole?: ProviderRole;
  buttonText: string;
  action: string;
  telemetryProps?: TelemetryProps;
  dataCy?: string;
  appointmentId?: string;
  providerOrder?: string;
  queryRequestId?: string;
  pageNumber?: string;
  previousReasonForScheduling?: string;
  mpLocation?: string;
  defaultTab?: number;
  scheduleModalWithPropsV2?: object;
  getButtonText?: (kind: string) => string;
  isMultiRole?: boolean;
  selectedKind?: string;
  setSelectedKind?: (kind: string) => void;
  multiRoleSupportedAppointmentKinds?: string[];
  customRoleName?: string;
  showMultiRoleTypeSelector?: boolean;
}

const emptyProps: ScheduleModalOpenOptions = {
  kind: "",
  provider: null,
  buttonText: "",
  action: "",
};

export const useScheduleModalWithProps = (defaultProps = emptyProps) => {
  const [modalProps, setModalProps] =
    useState<ScheduleModalOpenOptions>(defaultProps);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenModal = (newModalProps: ScheduleModalOpenOptions): void => {
    if (!isOpen) {
      // Prop (if present) comes in as an enum; otherwise convert the provider's role to an enum
      const providerRoleForTracking =
        newModalProps.providerRole ||
        getProviderRole(newModalProps.provider?.roles?.[0]);

      setModalProps({
        ...modalProps,
        ...newModalProps,
        providerRole: getProviderRoleForTracking(providerRoleForTracking), // Turn back into a string for tracking
      });

      onOpen();
    }
  };

  const handleResetModalProps = () => {
    setModalProps(defaultProps || emptyProps);
  };

  return {
    isOpen,
    onOpen: handleOpenModal,
    onClose,
    modalProps,
    handleResetModalProps,
  };
};
