const VIDEO_PROFILE_PROVIDER_IDS = [
  "f4c4c466-4ec4-4fbf-89be-b2fa06fabc28",
  "c0dd2ca9-14a1-444d-ab2e-93a9beca2f54",
  "cf74b9f4-2f5b-4e11-80ab-53f0bd9df85e",
  "e2e64b72-f99b-489b-9f1d-fb58b996abb6",
  "4eba3210-7d27-4554-b5d8-18c4aebb3e2c",
  "58296bcc-feb7-41e7-8a50-472b02b412f8",
  "fd8460eb-8dbf-454a-a61c-fd51d53945f2",
  "6ead0145-9a44-46b7-8d47-bbeed6fabb80",
  "cc19c69f-da88-40bc-91cd-2aed02399c11",
  "74b504c5-6afd-4cb3-9e0c-6c5b0e64d36b",
  "822b68bf-3181-4034-b4f9-89d5f61f29dd",
  "bd3c1686-b183-4e2a-a3fa-6a3c949c117c",
  "6a7f9d97-a409-4ab6-a1fd-5a837378ff1c",
  "37e7fcda-942b-4ea7-b164-e9c12bdfce8b",
  "38a93f97-4825-4d57-9d45-7018c674cb14",
  "aff576d8-72f5-4c98-83ee-95fdea61473b",
  "bf3672d4-668b-423d-9a73-ec23a55699ff",
  "75b4f29c-cb81-4b16-8513-5f546806dc32",
  "92e6eed3-5606-4add-893a-111bf9225fbc",
  "62a3be1a-58bd-4350-8b9c-14eb1f2df4a4",
  "fe4c5986-6c3d-4ffd-be02-b85ecb8217bd",
  "ba719d7b-2e37-400d-afad-30632b2cb24d",
  "a1d730e0-c983-4437-a632-6b30cd936749",
  "35c79f21-7b20-42d8-881a-db51cd691f5f",
  "7b85af9c-755f-486f-a6a8-b3f375db613e",
  "811e223d-218b-4814-90c5-fd5e75ce9bae",
  "1b1ae740-be52-4afa-a27f-f8a44b7a6f43",
  "2bef3281-9554-4a63-a078-3b0e42f36d10",
  "6d3661f6-e8dd-432a-991f-939dc9402ab0",
  "b32493fc-0d3f-4431-8cea-917208fd0e7c",
  "f11aeaf3-6cbc-4e00-b34b-d3a7857b3d5f",
  "a699fafb-a704-450e-abda-5fa9e2cf7a7f",
  "d8551ef9-5202-4788-86d4-9d94c2bed3e3",
  "aaca95b8-1d71-4fa8-a930-59b95eb1db19",
  "7fea9a76-6902-4fbd-90b8-fa65ce52fd27",
  "b389d33e-4699-491f-b4bf-894f0cf5de55",
  "06628b42-b99d-4c19-8faf-617f68f956b2",
  "d7d1d1e9-b2f7-4234-8ecf-bc84ade9a874",
  "b71e7055-ee91-4f78-9ff9-a7f6cf82c7e1",
  "241dfa39-ac97-4da2-9848-349f09a68bf1",
  "8858e181-39d2-4722-81c7-7260280aacee",
  "a524daf4-c42e-4d55-9e99-ded7ee402595",
  "313d1f5d-cda4-462e-b5ac-c62e8c0be76d",
  "9dfa32f3-dd31-47de-8b48-a3af2af50256",
  "7f24dbf4-b996-4a83-8bab-da623faf6606",
  "c3906716-ff6a-44ba-b8ee-480dddbbef23",
  "7ee22e6e-8b0d-416e-bd1a-4d0d8f540638",
  "a888f030-e67c-4dee-99bb-d7e5b0607f65",
  "61083611-9cc1-4ed0-a9d3-86889a64cf5a",
  "2d28f1d3-8a68-4798-bccd-d92d5443d681",
  "f37dbbd1-702b-41ec-8b37-11480da14eda",
  "cfc05223-32e8-4893-ab55-de1ca8a19db8",
  "63744a3f-c97d-46bf-b436-815789f2c7d3",
];

export const PROVIDER_PROFILE_VIDEOS_MAP = {
  dev: [...VIDEO_PROFILE_PROVIDER_IDS, "3e5b190d-5b93-4a04-92e9-5e568de68ebf"],
  "spring-features": [
    ...VIDEO_PROFILE_PROVIDER_IDS,
    "3e5b190d-5b93-4a04-92e9-5e568de68ebf",
  ],
  staging: VIDEO_PROFILE_PROVIDER_IDS,
  demo: [...VIDEO_PROFILE_PROVIDER_IDS, "3e5b190d-5b93-4a04-92e9-5e568de68ebf"],
  "pre-production": VIDEO_PROFILE_PROVIDER_IDS,
  prod: VIDEO_PROFILE_PROVIDER_IDS,
  production: VIDEO_PROFILE_PROVIDER_IDS,
};

export const PROVIDER_PROFILE_VIDEOS_BASE_URL_MAP = {
  dev: "https://provider-profile-videos.dev.springtest.us/",
  "spring-features": "https://provider-profile-videos.dev.springtest.us/",
  staging: "https://provider-profile-videos.dev.springtest.us/",
  demo: "https://provider-profile-videos.demo.springhealth.com/",
  "pre-production":
    "https://provider-profile-videos.pre-production.springhealth.com/",
  prod: "https://provider-profile-videos.springhealth.com/",
  production: "https://provider-profile-videos.springhealth.com/",
};
