import { FC } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "@spring/smeargle/actions";

import { Grid, Col } from "@spring/smeargle";
import {
  AppointmentAction,
  AppointmentKind,
  AppointmentMedium,
  modalIds,
  isTherapy,
  isCareNavigation,
  isCoaching,
  isCareConsultantRole,
} from "@spring/constants";
import { Heading, Spinner } from "@chakra-ui/react";
import { getAppointmentSlotStartTimeAndMedium } from "utils/schedulingHelpers";

import {
  AppointmentDetailsCard,
  CancellationCopy,
} from "components/templates/CareVisitDetails/components";
import {
  curriedGetAppointmentKind,
  curriedGetAppointmentType,
  curriedGetCancellationHeadline,
} from "components/templates/CareVisitDetails/utils";
import { useAllUpcomingAppointmentSlots } from "hooks";
import { CareProviderScheduleModal } from "components/modals";
import { getSchedulableProviderRole } from "utils/providers/";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "components";
import { useCustomerCustomRoleName } from "hooks/useCustomerCustomRoleName";
import { camelCase } from "lodash";
interface MemberTherapyDetails {
  therapyCoveredCount: number | null;
  therapyUsedCount: number | null;
  therapyRemainingCount: number | null;
  therapistEmail: string | null;
  isGlobalMemberWithNoVisitsRemaining: boolean;
}

interface MemberCoachingDetails {
  coachingCoveredCount: number | null;
  coachingUsedCount: number | null;
  coachingRemainingCount: number | null;
  coachEmail: string | null;
  isGlobalMemberWithNoVisitsRemaining: boolean;
}
interface CancellationDetailsProps {
  kind: (typeof AppointmentKind)[keyof AppointmentKind];
  // provider is typed as any object as it's complex and there's no existing type for it
  provider: { [key: string]: any };
  time: string | number;
  medium: (typeof AppointmentMedium)[keyof AppointmentMedium];
  isCancelled: boolean;
  isAMinor: boolean;
  memberFirstName: string;
  memberHasInPersonSupport?: boolean;
  memberCountry: string;
  isWithin24Hours: boolean;
  appointmentLocation?: string;
  customerId: string;
  memberCoachingDetails: MemberCoachingDetails;
  memberTherapyDetails: MemberTherapyDetails;
  isMultiRole?: boolean;
}

export const CancellationDetails: FC<
  React.PropsWithChildren<CancellationDetailsProps>
> = ({
  kind,
  provider,
  time,
  medium,
  isAMinor,
  memberFirstName,
  memberHasInPersonSupport,
  memberCountry,
  isWithin24Hours,
  appointmentLocation,
  customerId,
  memberCoachingDetails,
  memberTherapyDetails,
  isMultiRole,
}) => {
  const { t } = useTranslation("careVisits");
  const customRoleName = camelCase(useCustomerCustomRoleName());
  const isCareConsultant = isCareConsultantRole(provider?.roles);
  const { data: upcomingAppointmentSlots, loading } =
    useAllUpcomingAppointmentSlots(
      provider,
      kind,
      1,
      isCareNavigation(kind),
      isCoaching(kind),
    );
  const dispatch = useDispatch();
  const wrappedDispatchOpenModal = () => {
    if (isTherapy(kind)) {
      const { initialStartTime } = getAppointmentSlotStartTimeAndMedium(
        upcomingAppointmentSlots,
        provider,
        memberHasInPersonSupport,
        memberCountry,
      );
      return dispatch(
        openModal(modalIds.careProviderScheduleModal, {
          ...provider,
          kind,
          medium,
          initialStartTime,
          providerRole: getSchedulableProviderRole(provider),
          mpLocation: "Appointment Cancelled Page",
          action: AppointmentAction.Create,
          buttonText: t("careProvider.confirmAppointment"),
        }),
      );
    }
    const { initialStartTime } = getAppointmentSlotStartTimeAndMedium(
      upcomingAppointmentSlots,
      provider,
    );
    return dispatch(
      openModal(modalIds.careProviderScheduleModal, {
        ...provider,
        kind,
        medium,
        initialStartTime,
        providerRole: getSchedulableProviderRole(provider),
        mpLocation: "Appointment Cancelled Page",
        action: AppointmentAction.Create,
        buttonText: t("careProvider.confirmAppointment"),
        appointmentLocation,
        customRoleName: isMultiRole ? customRoleName : null,
        isMultiRole: isMultiRole,
        showMultiRoleTypeSelector: false,
      }),
    );
  };
  const getCancellationHeadline = curriedGetCancellationHeadline(t);
  const appointmentKindText = curriedGetAppointmentKind(t, customerId);
  const appointmentTypeText = curriedGetAppointmentType(t);

  const therapyData: [string, number, string, number] = [
    "therapyUsed",
    memberTherapyDetails.therapyUsedCount,
    "therapyCovered",
    memberTherapyDetails.therapyCoveredCount,
  ];
  const therapyEmailDetails: [string, string] = [
    "careTeamCard.zeroVisitsLeft.therapistEmail",
    memberTherapyDetails.therapistEmail,
  ];
  const coachingData: [string, number, string, number] = [
    "coachingUsed",
    memberCoachingDetails.coachingUsedCount,
    "coachingCovered",
    memberCoachingDetails.coachingCoveredCount,
  ];
  const coachEmailDetails: [string, string] = [
    "careTeamCard.zeroCoachingVisitsLeft.coachEmail",
    memberCoachingDetails.coachEmail,
  ];

  type NoGlobalMemberWithVisitsRemainingCareVerbiageType = (
    zeroVisitsLeftSessionUsed: string,
    continueSessionKey: string,
    therapistOrCoachEmailDetails: [string, string],
    therapyOrCoachingData: [string, number, string, number],
  ) => React.ReactElement;
  const noGlobalMemberWithVisitsRemainingCareVerbiage: NoGlobalMemberWithVisitsRemainingCareVerbiageType =
    (
      zeroVisitsLeftSessionUsed,
      continueSessionKey,
      therapistOrCoachEmailDetails,
      therapyOrCoachingData,
    ) => (
      <>
        <p>
          {t(zeroVisitsLeftSessionUsed, {
            [`${therapyOrCoachingData[0]}Count`]: therapyOrCoachingData[1],
            [`${therapyOrCoachingData[2]}Count`]: therapyOrCoachingData[3],
          })}
        </p>
        <p style={{ marginTop: "16px" }}>{t(continueSessionKey)}</p>
        <p style={{ marginTop: "16px" }}>
          <Trans
            ns="careVisits"
            i18nKey={therapistOrCoachEmailDetails[0]}
            components={[
              <Link
                key={"0"}
                to={`mailto: ${therapistOrCoachEmailDetails[1]}`}
              />,
            ]}
            values={{ email: therapistOrCoachEmailDetails[1] }}
          />
        </p>
      </>
    );
  const globalMemberWithCoachingNoVisitsRemainingVerbiage =
    noGlobalMemberWithVisitsRemainingCareVerbiage(
      "careTeamCard.zeroCoachingVisitsLeft.sessionsUsed",
      "careTeamCard.zeroCoachingVisitsLeft.continueCoaching",
      coachEmailDetails,
      coachingData,
    );
  const globalMemberWithNoVisitsRemainingVerbiage =
    noGlobalMemberWithVisitsRemainingCareVerbiage(
      "careTeamCard.zeroVisitsLeft.sessionsUsed",
      "careTeamCard.zeroVisitsLeft.continueTherapy",
      therapyEmailDetails,
      therapyData,
    );
  const disallowSchedule =
    memberTherapyDetails.isGlobalMemberWithNoVisitsRemaining ||
    memberCoachingDetails.isGlobalMemberWithNoVisitsRemaining;
  const noGlobalVisitsRemainingVerbiage = isTherapy(kind)
    ? globalMemberWithNoVisitsRemainingVerbiage
    : isCoaching(kind)
      ? globalMemberWithCoachingNoVisitsRemainingVerbiage
      : undefined;
  return (
    <>
      <div>
        <Heading
          data-cy="appointment-details-headline"
          as="h1"
          size="heading-large"
          color="#292929"
          margin={"24px 0px"}
        >
          {getCancellationHeadline(
            medium,
            isAMinor,
            memberFirstName,
            appointmentKindText(kind, isCareConsultant),
            appointmentTypeText(medium),
          )}
        </Heading>
      </div>
      <Grid>
        <Col md={5} lg={4}>
          <div>
            <AppointmentDetailsCard
              kind={kind}
              time={time}
              provider={provider}
              isCancelled
              customerId={customerId}
              isMultiRole={isMultiRole}
            />
          </div>
        </Col>
        <Col md={1} />

        <Col md={6} lg={7}>
          {loading ? (
            <Spinner speed="1s" size="xl" />
          ) : disallowSchedule ? (
            noGlobalVisitsRemainingVerbiage
          ) : (
            <CancellationCopy
              customerId={customerId}
              openScheduleModalCallback={wrappedDispatchOpenModal}
              kind={kind}
              medium={medium}
              provider={provider}
              isWithin24Hours={isWithin24Hours}
              customRoleName={customRoleName}
            />
          )}
        </Col>
      </Grid>
      <CareProviderScheduleModal />
    </>
  );
};
