import { connect } from "react-redux";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  FormLabel,
  FormControl,
  Stack,
  Text,
} from "design-system/components";
import {
  Afternoon,
  Celebration,
  ChevronDown,
  ChevronUp,
  Evening,
  Flexible,
  Morning,
  Virtual,
  InPerson,
} from "design-system/assets";
import { Input } from "@springcare/sh-component-library";
import { useState } from "react";
import { toTagOptionNames } from "@spring/immutability";
import {
  CareProviderTagKind,
  ProviderTags,
  days,
  ProviderRole,
} from "@spring/constants";

import { createProviderRequestAvailabilityZendeskTicket } from "operations/mutations/careProvider";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";

import { addNotification } from "@spring/smeargle/actions";
import { getFirstError } from "utils/apollo/errorHandler";

import routes from "routes";
import { trackRequestProviderFormSubmitted } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import { useQuery } from "@apollo/client";
import {
  getCareProviderTags,
  getCareTeam,
} from "operations/queries/careProvider";
import { filterTagsByProviderType } from "utils/displayHelpers";

import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { Link } from "components/atoms";
import Meowth from "@spring/meowth";
import { get, isEmpty } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import style from "./styles.module.scss";

const FLEXIBLE = "I'm flexible";
const FLEXIBLE_VALUE = "I'm Flexible";
const VIRTUAL = "Virtual";
const IN_PERSON = "In-person";

const ButtonCheckbox = ({ label, Icon, value, onCheck, ...props }) => {
  const [isChecked, setIsChecked] = useState(false);
  const isSelected = isChecked && !(props.disabled === true);

  return (
    <Button
      {...props}
      onClick={() => {
        if (onCheck) {
          onCheck(!isSelected, value ? value : label);
        }
        setIsChecked(!isSelected);
      }}
      py={18}
      fontSize={16}
      fontWeight={500}
      borderRadius={16}
      leftIcon={
        Icon && (
          <Icon
            boxSize={24}
            color={isSelected ? "tertiary.25" : "tertiary.800"}
          />
        )
      }
      aria-label={label}
      aria-pressed={isSelected}
      bg={isSelected ? "tertiary.700" : "tertiary.50"}
      color={isSelected ? "tertiary.25" : "tertiary.800"}
      borderColor={isSelected ? "tertiary.900" : "transparent"}
      _hover={{
        bg: isSelected ? "tertiary.700" : "tertiary.50",
        color: isSelected ? "tertiary.25" : "tertiary.800",
        borderColor: isSelected ? "tertiary.900" : "transparent",
      }}
      _focus={{
        bg: isSelected ? "tertiary.700" : "tertiary.50",
        color: isSelected ? "tertiary.25" : "tertiary.800",
        boxShadow: "0 0 0 3px var(--chakra-colors-info-400)",
      }}
      _focusVisible={{
        boxShadow: "0 0 0 3px var(--chakra-colors-info-400)",
      }}
      variant="outline"
    >
      {label}
    </Button>
  );
};

const ButtonRadio = ({ label, Icon, value, onCheck, isChecked, ...props }) => {
  return (
    <Button
      {...props}
      onClick={() => {
        if (onCheck) {
          onCheck(!isChecked, value ? value : label);
        }
      }}
      py={18}
      fontSize={16}
      fontWeight={500}
      borderRadius={16}
      leftIcon={
        Icon && (
          <Icon
            boxSize={24}
            color={isChecked ? "tertiary.25" : "tertiary.800"}
          />
        )
      }
      aria-label={label}
      aria-pressed={isChecked}
      bg={isChecked ? "tertiary.700" : "tertiary.50"}
      color={isChecked ? "tertiary.25" : "tertiary.800"}
      borderColor={isChecked ? "tertiary.900" : "transparent"}
      _hover={{
        bg: isChecked ? "tertiary.700" : "tertiary.50",
        color: isChecked ? "tertiary.25" : "tertiary.800",
        borderColor: isChecked ? "tertiary.900" : "transparent",
      }}
      _focus={{
        bg: isChecked ? "tertiary.700" : "tertiary.50",
        color: isChecked ? "tertiary.25" : "tertiary.800",
        boxShadow: "0 0 0 3px var(--chakra-colors-info-400)",
      }}
      _focusVisible={{
        boxShadow: "0 0 0 3px var(--chakra-colors-info-400)",
      }}
      variant="outline"
    >
      {label}
    </Button>
  );
};

const CustomAccordionItem = ({
  title,
  options,
  onCheck,
  checkedCountMessage,
}) => {
  const [checkedCount, setCheckedCount] = useState(0);

  return (
    <AccordionItem
      bg="tertiary.50"
      borderRadius="16px"
      border="none"
      py={10}
      mb={8}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            _focusVisible={{}}
            _focus={{}}
            _active={{}}
            _hover={{}}
            className={style.accordionButton}
            px={16}
          >
            <Box as="span" flex="1" display="flex" gap="8px">
              <Heading
                as="h3"
                fontSize={16}
                fontWeight={700}
                color="tertiary.800"
              >
                {title}
              </Heading>
              {checkedCountMessage && checkedCount !== 0 && (
                <Text fontSize={16} fontWeight={400} color="tertiary.800">
                  {checkedCountMessage.replace("%COUNT%", checkedCount)}
                </Text>
              )}
            </Box>
            {isExpanded ? (
              <ChevronUp boxSize={24} />
            ) : (
              <ChevronDown boxSize={24} />
            )}
          </AccordionButton>
          <AccordionPanel pb={4} maxH={400} overflowY="auto">
            <Stack pl={12} mt={1} spacing={20}>
              {options?.map((option) => (
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCheckedCount(checkedCount + 1);
                    } else {
                      setCheckedCount(checkedCount - 1);
                    }
                    if (onCheck) {
                      onCheck(e.target.checked, option.value, title);
                    }
                  }}
                  key={option.value}
                  variant="tertiary"
                  color="tertiary.600"
                  fontSize={16}
                  fontWeight={400}
                >
                  {option.label}
                </Checkbox>
              ))}
            </Stack>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

const getTrueKeys = (obj) => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj).filter((key) => obj[key] === true);
};

const getIfFlexible = (arr) => {
  if (arr.indexOf(FLEXIBLE) !== -1) {
    return [FLEXIBLE_VALUE];
  }
  return arr;
};

const PostRequestAvailability = (props) => (
  <Box bg="tertiary.25" py={29} borderRadius="lg">
    <Box maxW="fit-content">
      <Box px={43} mb={26}>
        <Celebration width={80} />
      </Box>
      <Box px={49}>
        <Heading color="#3E302E" as="h2" fontSize={29} fontWeight={700}>
          {props.t("findProvider.supportRequestForm.successTitle")}
        </Heading>
        <Text color="#3E302E" mt={13} fontSize={16} fontWeight={700}>
          {props.t("findProvider.supportRequestForm.successSubtitle")}
        </Text>
      </Box>
    </Box>
  </Box>
);

PostRequestAvailability.propTypes = {
  t: PropTypes.func,
};

const FindProviderCard = (props) => {
  const { t } = useTranslation("careProvider");
  const therapistProviderType = "therapist";
  const showInPersonMedMan = useFeatureFlag(
    FLAGS.IN_PERSON_MED_MANAGER_RELEASE,
  );
  const showNewBrowseForCoach = useFeatureFlag(FLAGS.COACH_NEW_BROWSE_RELEASE);
  const careNavigatorId = get(
    "careTeam.user.member.care_team.care_navigator.id",
    props,
  );
  const careNavigatorStandalonePage = routes[
    "ScheduleAppointmentProviderDetail"
  ].as.replace(":id", careNavigatorId);
  const { providerType } = props;

  const [selectedTime, setSelectedTime] = useState({});
  const [selectedDay, setSelectedDay] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [additionalNotes, setAdditionalNotes] = useState("");
  const { queryRequestId, customRoleName } = useProviderBrowseContext();
  const isTherapistRole = providerType === ProviderRole.Therapist;

  const isMedManagerRole = providerType === ProviderRole.MedicationManager;
  const isMultiRole = isEmpty(providerType);
  const showInPersonMedicationManager =
    showInPersonMedMan && providerType === ProviderRole.MedicationManager;

  const showCoachRole =
    showNewBrowseForCoach && providerType === ProviderRole.Coach;

  let tagMap = {
    condition: CareProviderTagKind.Condition,
    specialty: CareProviderTagKind.Specialty,
    language: CareProviderTagKind.Language,
    gender: CareProviderTagKind.Gender,
    ethnicity: CareProviderTagKind.Ethnicity,
  };

  if (
    isTherapistRole ||
    isMedManagerRole ||
    showInPersonMedicationManager ||
    showCoachRole
  ) {
    tagMap = {
      condition: "conditions",
      specialty: "specialties",
      language: "languages",
      gender: "genders",
      ethnicity: "ethnicities",
    };
  }

  const { data: careProviderTagData } = useQuery(getCareProviderTags, {
    variables: {
      locale: "en",
    },
  });

  const filteredProviderTags = filterTagsByProviderType(
    careProviderTagData,
    providerType,
  );
  const optionsDict = filteredProviderTags
    ? filteredProviderTags.reduce((map, obj) => {
        map[obj.id] = obj.name;
        return map;
      }, {})
    : {};

  const getOptions = (tagData, kind) => {
    if (
      isTherapistRole ||
      isMedManagerRole ||
      showInPersonMedicationManager ||
      showCoachRole ||
      isMultiRole
    ) {
      // Shallow copy
      const tagDataCopy = { ...tagData };
      const tagsCopy =
        tagDataCopy[kind]?.length > 0 ? [...tagDataCopy[kind]] : [];
      const sortData = tagsCopy?.sort((a, b) => a.localeCompare(b));
      return sortData?.map((tag) => ({ label: tag, value: tag }));
    }
    return toTagOptionNames(tagData, kind, "");
  };

  const handleSubmit = async () => {
    const preferredTime = getIfFlexible(getTrueKeys(selectedTime));
    const preferredDay = getIfFlexible(getTrueKeys(selectedDay));
    const preferredLocation = getTrueKeys(selectedLocation);
    const conditions = getTrueKeys(selectedFilters["Condition"]);
    const specialties = getTrueKeys(selectedFilters["Specialty"]);
    const ethnicities = getTrueKeys(selectedFilters["Ethnicity"]);
    const genders = getTrueKeys(selectedFilters["Gender"]);
    const languages = getTrueKeys(selectedFilters["Language"]);
    const providerRole = getProviderTypeName();

    const formValues = {
      preferred_time: preferredTime,
      preferred_day: preferredDay,
      conditions: conditions,
      specialties: specialties,
      ethnicities: ethnicities,
      genders: genders,
      languages: languages,
      additional_note: additionalNotes,
      provider_role: providerRole,
      preferred_location: preferredLocation,
    };

    trackRequestProviderFormSubmitted({ ...formValues }, queryRequestId);

    try {
      const res =
        await props.createProviderRequestAvailabilityZendeskTicket(formValues);

      if (
        res.data.createProviderRequestAvailabilityZendeskTicket.success === true
      ) {
        sessionStorage.setItem(
          `availability_requested_${props.providerType}`,
          res.data.createProviderRequestAvailabilityZendeskTicket.success,
        );
        props.addNotification(
          t("findProvider.notificationMessage.success"),
          "success",
        );
      }
    } catch (e) {
      props.addNotification(getFirstError(e), "error");
    }
  };

  const handleLocationCheck = (isChecked, label) => {
    setSelectedLocation({
      [label]: isChecked,
    });
  };

  const handleTimeCheck = (isChecked, label) => {
    setSelectedTime({
      ...selectedTime,
      [label]: isChecked,
    });
  };

  const handleDayCheck = (isChecked, label) => {
    setSelectedDay({
      ...selectedDay,
      [label]: isChecked,
    });
  };

  const handleFiltersCheck = (label) => (isChecked, optionId) => {
    const option =
      isTherapistRole || isMedManagerRole || showNewBrowseForCoach
        ? optionId
        : optionsDict[optionId];

    setSelectedFilters({
      ...selectedFilters,
      [label]: { ...selectedFilters[label], [option]: isChecked },
    });
  };

  const handleAdditionalQuestion = (e) => {
    setAdditionalNotes(e.target.value);
  };

  const getProviderTypeName = () => {
    if (providerType === "medication_manager") {
      return "Medication Manager";
    }

    if (isMultiRole) {
      return customRoleName;
    }
    return providerType.charAt(0).toUpperCase() + providerType.slice(1);
  };

  const isFormSubmitted =
    sessionStorage.getItem(`availability_requested_${props.providerType}`) ===
    "true";

  if (isFormSubmitted) {
    return <PostRequestAvailability t={t} />;
  }

  const isGlobalTherapist =
    props.showGlobalExperience && providerType === therapistProviderType;
  const subtitleKey = `findProvider.supportRequestForm.${isGlobalTherapist ? "globalSubtitle" : "subtitle"}`;

  return (
    <Box bg="tertiary.25" py={29} px={49} borderRadius="lg">
      <Box maxW="fit-content">
        <Heading color="#3E302E" as="h2" fontSize={29} fontWeight={700}>
          {t("findProvider.supportRequestForm.title", {
            providerType: getProviderTypeName()?.toLowerCase(),
          })}
        </Heading>
        <Text
          fontSize={16}
          fontWeight={400}
          mt={8}
          data-testid="support-request-verbiage"
        >
          <Trans
            ns={"careProvider"}
            i18nKey={subtitleKey}
            components={[<Link key={"0"} to={careNavigatorStandalonePage} />]}
          />
        </Text>
        <Box mt={18}>
          <FormControl as="fieldset">
            <FormLabel
              as="legend"
              color={"#3E302E"}
              fontSize={16}
              fontWeight={700}
            >
              {t("findProvider.supportRequestForm.time.title")}
            </FormLabel>

            <Flex mt={18} gap="2" wrap="wrap">
              <ButtonCheckbox
                _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                disabled={selectedTime[FLEXIBLE]}
                onCheck={handleTimeCheck}
                label={t("findProvider.supportRequestForm.time.morning")}
                value="Morning"
                Icon={Morning}
              />
              <ButtonCheckbox
                _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                disabled={selectedTime[FLEXIBLE]}
                onCheck={handleTimeCheck}
                label={t("findProvider.supportRequestForm.time.afternoon")}
                value="Afternoon"
                Icon={Afternoon}
              />
              <ButtonCheckbox
                _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                disabled={selectedTime[FLEXIBLE]}
                onCheck={handleTimeCheck}
                label={t("findProvider.supportRequestForm.time.evening")}
                value="Evening"
                Icon={Evening}
              />
              <ButtonCheckbox
                _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                onCheck={handleTimeCheck}
                label={t("findProvider.supportRequestForm.time.flexible")}
                value={FLEXIBLE}
                Icon={Flexible}
              />
            </Flex>
          </FormControl>
        </Box>

        <Box mt={18}>
          <FormControl as="fieldset">
            <FormLabel
              as="legend"
              mt={31}
              color={"#3E302E"}
              fontSize={16}
              fontWeight={700}
            >
              {t("findProvider.supportRequestForm.days.title")}
            </FormLabel>

            <Flex mt={18} gap="2" wrap="wrap">
              {days.map((day) => (
                <ButtonCheckbox
                  _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                  key={day.label}
                  onCheck={handleDayCheck}
                  disabled={selectedDay[FLEXIBLE]}
                  value={day.label}
                  label={t(
                    `findProvider.supportRequestForm.days.${day.label.toLowerCase()}`,
                  )}
                />
              ))}
              <ButtonCheckbox
                _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                onCheck={handleDayCheck}
                value={FLEXIBLE}
                label={t("findProvider.supportRequestForm.time.flexible")}
              />
            </Flex>
          </FormControl>
        </Box>

        <Box mt={18}>
          <FormControl as="fieldset">
            <FormLabel
              as="legend"
              color={"#3E302E"}
              fontSize={16}
              fontWeight={700}
            >
              {t("findProvider.supportRequestForm.location.title")}
            </FormLabel>

            <Flex mt={18} gap="2" wrap="wrap">
              <ButtonRadio
                _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                onCheck={handleLocationCheck}
                label={t("findProvider.supportRequestForm.location.virtual")}
                value="Virtual"
                Icon={Virtual}
                isChecked={selectedLocation[VIRTUAL]}
              />
              {providerType !== ProviderRole.Coach && (
                <ButtonRadio
                  _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                  onCheck={handleLocationCheck}
                  label={t("findProvider.supportRequestForm.location.inPerson")}
                  value="In-person"
                  Icon={InPerson}
                  isChecked={selectedLocation[IN_PERSON]}
                />
              )}
              <ButtonRadio
                _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                onCheck={handleLocationCheck}
                label={t(
                  "findProvider.supportRequestForm.location.noPreference",
                )}
                value={FLEXIBLE}
                isChecked={selectedLocation[FLEXIBLE]}
              />
            </Flex>
          </FormControl>
        </Box>

        <Text color="#3E302E" mt={31} fontSize={16} fontWeight={700}>
          {t("findProvider.supportRequestForm.preferences")}
        </Text>

        <Accordion mt={14} allowToggle>
          {providerType !== "coach" && (
            <CustomAccordionItem
              onCheck={handleFiltersCheck(ProviderTags.Conditions)}
              title={t("multiSelectParent.conditions.label")}
              options={getOptions(props.filteredProviderTags, tagMap.condition)}
              checkedCountMessage={t(
                "findProvider.supportRequestForm.preferencesSelectedCount",
                { selectedPreferencesCount: "%COUNT%" },
              )}
            />
          )}
          <CustomAccordionItem
            onCheck={handleFiltersCheck(ProviderTags.Specialties)}
            title={t("multiSelectParent.specialties.label")}
            options={getOptions(props.filteredProviderTags, tagMap.specialty)}
            checkedCountMessage={t(
              "findProvider.supportRequestForm.preferencesSelectedCount",
              { selectedPreferencesCount: "%COUNT%" },
            )}
          />
          <CustomAccordionItem
            onCheck={handleFiltersCheck(ProviderTags.Genders)}
            title={t("multiSelectParent.genders.label")}
            options={getOptions(props.filteredProviderTags, tagMap.gender)}
            checkedCountMessage={t(
              "findProvider.supportRequestForm.preferencesSelectedCount",
              { selectedPreferencesCount: "%COUNT%" },
            )}
          />
          <CustomAccordionItem
            onCheck={handleFiltersCheck(ProviderTags.Ethnicities)}
            title={t("multiSelectParent.ethnicities.label")}
            options={getOptions(props.filteredProviderTags, tagMap.ethnicity)}
            checkedCountMessage={t(
              "findProvider.supportRequestForm.preferencesSelectedCount",
              { selectedPreferencesCount: "%COUNT%" },
            )}
          />
          <CustomAccordionItem
            onCheck={handleFiltersCheck(ProviderTags.Languages)}
            title={t("multiSelectParent.languages.label")}
            options={getOptions(props.filteredProviderTags, tagMap.language)}
            checkedCountMessage={t(
              "findProvider.supportRequestForm.preferencesSelectedCount",
              { selectedPreferencesCount: "%COUNT%" },
            )}
          />
        </Accordion>

        <FormLabel htmlFor="additionalInfo">
          <Text color="#3E302E" mt={31} fontSize={18} fontWeight={700}>
            {t("findProvider.supportRequestForm.additionalQuestion")}
          </Text>
        </FormLabel>

        <Input
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          onChange={handleAdditionalQuestion}
          mt={10}
          height="44px"
          borderColor="platform.300"
          backgroundColor="tertiary.25"
          _placeholder={{
            color: "tertiary.500",
            fontSize: "18px",
            fontWeight: 500,
          }}
          aria-label={t("findProvider.supportRequestForm.additionalQuestion")}
          id="additionalInfo"
        />
        <Text color={"content.secondary"}>
          {t("findProvider.supportRequestForm.additionalQuestionPlaceholder")}
        </Text>

        <Button
          className={style.submitButton}
          _focus={{}}
          _focusVisible={{}}
          onClick={handleSubmit}
          mt={50}
          colorScheme="primary"
          variant="solid"
          w={"100%"}
          py={25}
        >
          {t("findProvider.supportRequestForm.submitText")}
        </Button>
      </Box>
    </Box>
  );
};

FindProviderCard.propTypes = {
  providerType: PropTypes.string,
  filteredProviderTags: PropTypes.object,
};

export { FindProviderCard };

const mapStateToProps = (state) => ({
  showGlobalExperience: state.global.showGlobalExperience,
});

export default compose(
  connect(mapStateToProps, { addNotification }),
  graphql(createProviderRequestAvailabilityZendeskTicket, {
    props: ({ mutate }) => ({
      createProviderRequestAvailabilityZendeskTicket: (input) =>
        mutate({
          variables: { input },
        }),
    }),
  }),
  graphql(getCareTeam, {
    options: Meowth.apolloOptionsUserId,
    name: "careTeam",
  }),
)(FindProviderCard);
