/**
 * Enums for provider statuses.
 */

const ProviderStatus = Object.freeze({
  Pending: "Pending",
  Submitted: "Submitted",
  Approved: "Approved",
  Disabled: "Disabled",
  Rejected: "Rejected",
  NoNewPatients: "No New Patients",
  GeneralHold: "General Hold",
  QualityHold: "Quality Hold",
});

module.exports = ProviderStatus;
