// NOTE: This is a 2-view modal, one view for welcoming 1st time livekit users,
// and the other starts Device Permissions flow.
import { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { Button, ModalSheet } from "@springcare/sh-component-library";
import { Box, Flex, Text } from "@chakra-ui/react";
import { VCircleCheckIcon } from "@springcare/verdant-icons-react";

import useFirstLivekitSession from "components/templates/SHSessionRoom/hooks/useFirstLivekitSession";

import {
  handleWelcomeModalContinueClick,
  trackRequestDevicePermissionsModalAllowed,
} from "components/templates/SHSessionRoom/telemetry/analytics";

import { useTranslation } from "hooks/react-i18next";

const WelcomeModal = ({ startMedia, isOpen, onClose }) => {
  const isFirstSession = useFirstLivekitSession();

  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "preJoin",
  });
  const [hasClickedContinue, setHasClickedContinue] = useState(false);
  const [hasClickedAllow, setHasClickedAllow] = useState(false);

  const WelcomeView = () => {
    const TIPS_LIST = [
      t("welcomeModal.welcomeView.firstTip"),
      t("welcomeModal.welcomeView.secondTip"),
      t("welcomeModal.welcomeView.thirdTip"),
    ];

    const handleContinueClick = () => {
      handleWelcomeModalContinueClick();
      setHasClickedContinue(true);
    };

    return (
      <>
        <Box mb="v-16">
          <Text fontWeight="medium">
            {t("welcomeModal.welcomeView.firstSubHeader")}
          </Text>
        </Box>

        <Box mb="v-16">
          <Box>
            {TIPS_LIST.map((tip, index) => (
              <Flex align="center" mb="v-12" key={index}>
                <VCircleCheckIcon color="#068262" width={24} height={24} />
                <Text fontWeight="medium" ml="v-8">
                  {tip}
                </Text>
              </Flex>
            ))}
          </Box>
        </Box>
        <Box>
          <Text fontWeight="medium">
            {t("welcomeModal.welcomeView.secondSubHeader")}
          </Text>
        </Box>
        <Box textAlign="right">
          <Button onClick={handleContinueClick}>Continue</Button>
        </Box>
      </>
    );
  };

  const RequestPermissionsView = () => {
    const handleAllowBtnClick = async () => {
      trackRequestDevicePermissionsModalAllowed();
      setHasClickedAllow(true);
      onClose();
      await startMedia();
    };

    return (
      <>
        <Box mb="v-16">
          <Text fontWeight="medium">
            {t("welcomeModal.permissionsView.firstSubHeader")}
          </Text>
        </Box>
        <Box mb="v-16">
          <Text fontWeight="medium">
            {t("welcomeModal.permissionsView.secondSubHeader")}
          </Text>
        </Box>
        <Box textAlign="right">
          <Button
            onClick={handleAllowBtnClick}
            isLoading={hasClickedAllow}
            tabIndex={1}
            aria-label={"request-device-permissions"}
          >
            {t("welcomeModal.permissionsView.allowAccessBtn")}
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Box>
      {/* @ts-ignore */}
      <ModalSheet
        isOpen={isOpen}
        placement={isMobileOnly ? "bottom" : "center"}
        size="sm"
        title={t("welcomeModal.header")}
        showCloseButton={false}
      >
        {isFirstSession && !hasClickedContinue && <WelcomeView />}

        {(!isFirstSession || hasClickedContinue) && <RequestPermissionsView />}
      </ModalSheet>
    </Box>
  );
};

export default WelcomeModal;
