import { VStack, Text, Box, useMediaQuery, useTheme } from "@chakra-ui/react";
import React, { useContext } from "react";
import Welcome from "./Welcome";
import { VCelebrationDuotoneSpot } from "@springcare/verdant-icons-react";
import { Carousel } from "components/molecules";
import { Heading } from "design-system/components";
import Card from "components/templates/YourAssessmentResults/ResultsSections/Card";
import styles from "components/templates/YourAssessmentResults/styles.module.scss";
import { AssessmentResultsContext } from "components/templates/YourAssessmentResults/YourAssessmentResults";
import ProviderCarousel from "./ProviderCarousel";
import DownloadCTA from "components/templates/YourAssessmentResults/DownloadCTA";
import FooterCTA from "components/templates/YourAssessmentResults/FooterCTA";

const NEW_FOCUS_CARD = "newFocusCard";

const NewAssessmentResultsPage = ({
  t,
  firstName = "",
  getMainContentStyles,
  selectedAssessment,
  assignRiskScore,
  setIsSpecializedCNSupported,
  openSpecializedCNScheduleFlyout,
}) => {
  const values = useContext(AssessmentResultsContext);
  const { cards, Router } = values;

  const { breakpoints } = useTheme();
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [isTablet] = useMediaQuery("(max-width: 540px)");
  const [isLgScreen] = useMediaQuery(
    `(min-width: 768px) and (max-width: 1250px)`,
  );
  const [isXLgScreen] = useMediaQuery(
    `(min-width: 1250px) and (max-width: 1400px)`,
  );

  const hideSliderButtons = isMobile || cards.length <= 3;

  const CarouselHeading = () => (
    <Heading size={isMobile ? "heading-small" : "heading-medium"} as="h1">
      {t("focus.newHeader")}
    </Heading>
  );

  const CarouselSubheading = () => (
    <Text size="body-medium" mt={8} mb={16}>
      {t("focus.subHeader")}
    </Text>
  );

  return (
    <VStack
      pt={isDesktop ? 0 : isTablet || isMobile ? 75 : 0}
      alignItems={"flex-start"}
    >
      <Welcome icon={VCelebrationDuotoneSpot} displayInline={true}>
        {`${t("welcome.newHeader")} ${firstName}`}
      </Welcome>

      <Box overflow="hidden" width="100%">
        <main
          id="main-content"
          tabIndex={-1}
          className={styles["results-page"]}
          style={{
            ...getMainContentStyles(isXLgScreen, isLgScreen, isDesktop),
            marginTop: isDesktop && "48px",
          }}
        >
          <Carousel
            gap={0}
            CarouselHeading={CarouselHeading}
            CarouselSubHeading={CarouselSubheading}
            hideSliderButtons={hideSliderButtons}
            itemWidth={isMobile ? null : "400px"}
            sliderFullWidth={true}
            sliderVariant="compact"
          >
            {cards.map((card) => (
              <Box pr={16} key={card.label} w="100%">
                <Card data={card} type={NEW_FOCUS_CARD} />
              </Box>
            ))}
          </Carousel>
        </main>
        <Box pb="120px" backgroundColor="background-subtle">
          <Box
            mx={"24px"}
            maxW="1200px"
            className={isDesktop ? styles["results-page"] : ""}
            style={
              isDesktop
                ? getMainContentStyles(isXLgScreen, isLgScreen, isDesktop)
                : {}
            }
          >
            <ProviderCarousel isMobile={isMobile} isDesktop={isDesktop} t={t} />
            {!isDesktop && (
              <DownloadCTA
                selectedAssessment={selectedAssessment}
                t={t}
                Router={Router}
              />
            )}
          </Box>
        </Box>
        <FooterCTA
          styles={styles}
          t={t}
          Router={Router}
          assessmentRiskScore={assignRiskScore(selectedAssessment?.risk)}
          assignRiskScore={assignRiskScore}
          setIsSpecializedCNSupported={setIsSpecializedCNSupported}
          openCNFlyout={openSpecializedCNScheduleFlyout}
        />
      </Box>
    </VStack>
  );
};

export default NewAssessmentResultsPage;
