import React, { useState, useEffect } from "react";
import getRecommendedTherapistsAvatar from "operations/queries/careProvider/getRecommendedTherapistsAvatar";
import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import { Box, Flex, Heading, Text, Image } from "@chakra-ui/react";
import { Carousel } from "components/molecules";
import { Badge, Card } from "@springcare/sh-component-library";
import { PlantLeaves, Star } from "design-system/index";
import routes from "routes";

const MAX_THERAPISTS = 3;

const ProviderCarousel = ({ isMobile, isDesktop, t }) => {
  const [recommendedTherapistCards, setRecommendedTherapistCards] = useState(
    [],
  );

  const QUERY_VERSION = "browse_recs_v1";
  const apolloOptionsUserId = Meowth.apolloOptionsUserId();
  apolloOptionsUserId.variables.version = QUERY_VERSION;
  const ProviderRole = "THERAPIST";

  const {
    data: recommendedTherapistData,
    loading: recommendedTherapistsLoading,
  } = useQuery(getRecommendedTherapistsAvatar, {
    ...apolloOptionsUserId,
  });

  const recommendedTherapistsList =
    recommendedTherapistData?.user?.member?.recommended_therapists;
  const recommendedTherapistsCount = recommendedTherapistsList?.length;

  useEffect(() => {
    if (recommendedTherapistsList) {
      const topMatchTherapists =
        recommendedTherapistsCount > 2
          ? recommendedTherapistsList.slice(0, MAX_THERAPISTS)
          : recommendedTherapistsList;

      const therapistCards = topMatchTherapists.map((therapist) => ({
        type: ProviderRole,
        provider: therapist,
      }));
      const browseTherapistCard = {
        type: "BROWSE_THERAPIST",
        provider: { therapist: { id: "default" } },
      };

      setRecommendedTherapistCards([...therapistCards, browseTherapistCard]);
    }
  }, [recommendedTherapistData, recommendedTherapistsLoading]);

  const handleBrowseTherapists = (type, providerId) => {
    const url =
      type === "BROWSE_THERAPIST"
        ? routes.TherapistsBrowse.as
        : routes["ScheduleAppointmentProviderDetail"].as.replace(
            ":id",
            providerId,
          );

    window.open(url, "_blank");
  };

  const ProviderCarouselHeading = () => (
    <Heading
      size={isMobile ? "heading-small" : "heading-medium"}
      as={isMobile ? "h2" : "h1"}
      mb={8}
    >
      {t("NewProviderCarousel.heading")}
    </Heading>
  );

  const ProviderCarouselSubheading = () => (
    <Text size="body-medium" mt={8} mb={16}>
      {t("NewProviderCarousel.subheading")}
    </Text>
  );

  const ViewMoreTherapists = () => (
    <Text size="body-medium-strong" color="primary-base">
      {t("NewProviderCarousel.moreTherapist")}
    </Text>
  );

  const getTherapistLicense = (licenses) => {
    return (
      licenses &&
      licenses.length > 0 &&
      (licenses.length > 3
        ? `${licenses.slice(0, 3).join(", ")}...`
        : licenses.join(", "))
    );
  };

  const getShortenedBio = (bio, isMobile) => {
    const maxLength = isMobile ? 60 : 90;
    return bio.length > maxLength ? `${bio.slice(0, maxLength)}...` : bio;
  };

  const ProviderCard = ({ provider }) => {
    return (
      <>
        <Badge
          icon={Star}
          variant="medium-emphasis"
          bg="#E1F0EC"
          color="positive-on-subtle"
        >
          {t("NewProviderCarousel.topTherapistMatches")}
        </Badge>
        <Flex
          direction="row"
          mt={16}
          w={"100%"}
          alignItems="center"
          justifyContent="space-between"
          gap={4}
        >
          <Box
            w={{ base: 56, sm: 56, md: 80, lg: 80 }}
            h={{ base: 56, sm: 56, md: 80, lg: 80 }}
            flexShrink={0}
          >
            <Image
              src={provider.therapist.avatar || "/static/DefaultAvatar.png"}
              alt={provider.therapist.name || "Default Avatar"}
              borderRadius="full"
              w={"100%"}
              h={"100%"}
              objectFit="cover"
            />
          </Box>
          <Box flex="1" ps={16}>
            {isMobile ? (
              <Text size="body-medium-strong">{provider?.therapist.name}</Text>
            ) : (
              <Heading size="heading-small">{provider?.therapist.name}</Heading>
            )}
            <Flex direction="row" alignItems="center" wrap="wrap">
              <PlantLeaves color="content-secondary" />
              <Text size="body-small" color="content-secondary" ms={8}>
                {provider.therapist.roles[0]}
              </Text>
              <Text variant="body_v1" as="span" ms={8}>
                {"•"}
              </Text>
              <Text size="body-small" color="content-secondary" ms={8}>
                {getTherapistLicense(provider.therapist.licenses)}
              </Text>
            </Flex>
          </Box>
        </Flex>

        <Text mt={16} color="content-secondary" h={isMobile ? 60 : 40}>
          {getShortenedBio(provider.therapist.bio, isMobile)}
        </Text>
      </>
    );
  };

  return (
    <Box
      bg={"background-subtle"}
      py={isDesktop ? 40 : 24}
      mt={isDesktop && 40}
      role="region"
      aria-label={"Recommended Therapists"}
      data-testid="recommended-therapists-carousel"
    >
      {!recommendedTherapistsLoading && (
        <Carousel
          gap={0}
          CarouselHeading={ProviderCarouselHeading}
          CarouselSubHeading={ProviderCarouselSubheading}
          sliderVariant="compact"
          itemVariant="wide"
          itemWidth={isMobile ? null : "470px"}
          sliderFullWidth={true}
          hideSliderButtons={isMobile || recommendedTherapistCards.length <= 3}
          aria-label={t("NewProviderCarousel.carouselDescription")}
          aria-live="polite"
        >
          {recommendedTherapistCards.map(({ type, provider }) => (
            <Card
              key={provider.therapist.id}
              variant="high-emphasis"
              w={type === ProviderRole && "100%"}
              h={type !== ProviderRole && "100%"}
              justifyContent={type !== ProviderRole && "center"}
              me={16}
              p={24}
              onClick={() =>
                handleBrowseTherapists(type, provider.therapist.id)
              }
              role="button"
              tabIndex={0}
              aria-label={
                type !== ProviderRole
                  ? t("NewProviderCarousel.moreTherapist")
                  : `${provider?.therapist.name}, ${provider?.therapist.roles[0]}`
              }
            >
              {type !== ProviderRole ? (
                <ViewMoreTherapists />
              ) : (
                <ProviderCard provider={provider} />
              )}
            </Card>
          ))}
        </Carousel>
      )}
    </Box>
  );
};

export default ProviderCarousel;
