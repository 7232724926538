/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from "prop-types";
import RiskLevelArrow from "components/templates/YourAssessmentResults/icons/Misc/risk-level-arrow";
import { Heading } from "design-system/components";

const RiskIndicator = ({ styles, scoreLabel, kindLabel, riskLevelDesc, t }) => {
  function setOffsetByScoreLabel() {
    let offset = scoreLabel.split("/");
    offset = (parseInt(offset[0]) / parseInt(offset[1])) * 100;
    return `${parseInt(offset).toString()}%`;
  }

  return (
    <div>
      <div
        className={styles.riskIndicatorContainer}
        style={{ insetInlineStart: setOffsetByScoreLabel() }}
      >
        <b>
          <Heading fontSize={16} as="h3">
            {t("detailFlyout.riskIndicator.result")}
          </Heading>
        </b>
        <RiskLevelArrow title={riskLevelDesc} kind={kindLabel} />
      </div>
    </div>
  );
};

const RiskLevelBar = ({
  styles,
  scoreLabel,
  kindLabel,
  riskLevelDesc,
  t,
  newRiskLevelBar,
}) => {
  return (
    <>
      <RiskIndicator
        styles={styles}
        scoreLabel={scoreLabel}
        kindLabel={kindLabel}
        riskLevelDesc={riskLevelDesc}
        t={t}
      />
      <div aria-hidden className={styles.parentContainer}>
        <div
          className={
            newRiskLevelBar
              ? styles.newRiskBarContainer
              : styles.riskBarContainer
          }
        >
          <span className={styles.riskBarChild} />
          <span className={styles.riskBarChild} />
          <span className={styles.riskBarChild} />
        </div>
        <div className={styles.riskBarLabelContainer}>
          <p>{t("detailFlyout.riskLevelBar.lowRisk")}</p>
          <p>{t("detailFlyout.riskLevelBar.mediumRisk")}</p>
          <p>{t("detailFlyout.riskLevelBar.highRisk")}</p>
        </div>
      </div>
    </>
  );
};

RiskIndicator.propTypes = {
  scoreLabel: PropTypes.string,
  kindLabel: PropTypes.string,
  styles: PropTypes.object,
  riskLevelDesc: PropTypes.string,
  t: PropTypes.func,
};

RiskLevelBar.propTypes = {
  scoreLabel: PropTypes.string,
  kindLabel: PropTypes.string,
  styles: PropTypes.object,
  riskLevelDesc: PropTypes.string,
  t: PropTypes.func,
  newRiskLevelBar: PropTypes.bool,
};

export default RiskLevelBar;
