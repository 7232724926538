import { useEffect, useState, useMemo } from "react";
import { VRecordIcon } from "@springcare/verdant-icons-react";
import { Flex, Icon, Text, Box } from "@chakra-ui/react";
import {
  useParticipantMetadata,
  useSessionRecording,
} from "context/SessionRoomContext";
import { ParticipantType } from "components/templates/SHSessionRoom/types";
import {
  useIsRecording,
  useRemoteParticipants,
} from "@livekit/components-react";
import { usePostStopRecording } from "components/templates/SHSessionRoom/hooks/usePostStopRecording";
import { TemporaryStopIcon } from "components/templates/SHSessionRoom/components/temporaryStopIcon";
import { useTranslation } from "react-i18next";

const RecordingIcon = ({
  isRecording,
  isProvider,
  appointmentId,
}: {
  isRecording: boolean;
  isProvider: boolean;
  appointmentId: string;
}) => {
  const { requestingConsent, sessionConsents, requestConsent } =
    useSessionRecording();
  const stopRecording = usePostStopRecording();
  const showsStopRecording = isProvider && isRecording;
  const canRequestConsent =
    isProvider &&
    !isRecording &&
    !requestingConsent &&
    sessionConsents?.care_provider_consent &&
    !sessionConsents?.member_consent;

  const onClick = () => {
    if (canRequestConsent) {
      requestConsent();
    }
  };

  return (
    <Flex>
      <Flex
        opacity={isRecording || canRequestConsent ? "100%" : "50%"}
        bg={"white"}
        h={"48px"}
        w={showsStopRecording ? "88px" : "48px"}
        align="center"
        justify="space-around"
        borderRadius={"lg"}
        as="button"
        cursor={canRequestConsent ? "cursor" : undefined}
        onClick={onClick}
      >
        <Icon
          as={VRecordIcon}
          color={isRecording ? "red.500" : "black"}
          height={18}
          width={18}
        />
        {showsStopRecording && (
          <Box
            as="button"
            w={18}
            h={18}
            padding={0}
            _focus={{ outline: "none !important" }}
            _active={{ outline: "none !important" }}
            onClick={() => {
              stopRecording({
                appointment_id: appointmentId,
              });
            }}
          >
            <Icon as={TemporaryStopIcon} color="black" height={18} width={18} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export const RecordingBadge = () => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "recordingButton",
  });
  const { sessionConsents, requestingConsent } = useSessionRecording();
  const { participantType, appointmentId } = useParticipantMetadata();
  const recording = useIsRecording();
  const [recordingCopy, setRecordingCopy] = useState("");

  const remoteParticipants = useRemoteParticipants();

  const hasOver2AdmittedParticipants = useMemo(
    () =>
      remoteParticipants.filter((p) => p.permissions?.canPublish).length > 1,
    [remoteParticipants],
  );

  useEffect(() => {
    // Member view
    if (participantType == ParticipantType.Member) {
      if (recording) {
        setRecordingCopy(t("recordingMember"));
      } else {
        setRecordingCopy(t("notRecording"));
      }
    }
    // Provider view
    if (participantType == ParticipantType.Provider) {
      if (recording) {
        setRecordingCopy(t("recordingProvider"));
      } else {
        if (hasOver2AdmittedParticipants) {
          setRecordingCopy(t("stoppedTooManyAttendees"));
        } else if (sessionConsents && sessionConsents.care_provider_consent) {
          if (!sessionConsents.member_consent) {
            if (requestingConsent) {
              setRecordingCopy(t("consentRequested"));
            } else {
              setRecordingCopy(t("clientDeclined"));
            }
          } else {
            setRecordingCopy(t("startingRecording"));
          }
        } else {
          setRecordingCopy(t("notRecording"));
        }
      }
    }
  }, [
    recording,
    sessionConsents,
    participantType,
    hasOver2AdmittedParticipants,
    requestingConsent,
  ]);

  return (
    <Flex alignContent="center" alignItems="center">
      <RecordingIcon
        isRecording={recording}
        isProvider={participantType === ParticipantType.Provider}
        appointmentId={appointmentId}
      />
      <Text pl={8} color={"white"}>
        {recordingCopy}
      </Text>
    </Flex>
  );
};
