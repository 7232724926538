import { useDataChannel, useLocalParticipant } from "@livekit/components-react";
import { useSessionRecording } from "context/SessionRoomContext";
import { useCallback, useEffect } from "react";
import { useOnConsentResponse } from "components/templates/SHSessionRoom/hooks/useOnConsentResponse";

export type RecordingConsentsMessagePayload =
  | {
      action: "request-consent";
      value?: undefined;
    }
  | {
      action: "consent-response";
      value: boolean;
    };

export const useRecordingConsentsMessages = () => {
  const {
    onRequestConsent,
    onConsentResponse,
    setRequestingConsent,
    setRequestConsent,
    setRespondWithConsent,
  } = useSessionRecording();
  const { localParticipant } = useLocalParticipant();
  useOnConsentResponse();
  const messageHandler = useCallback<Parameters<typeof useDataChannel>[0]>(
    (msg) => {
      if (msg.from.identity !== localParticipant.identity) {
        const parsedPayload = JSON.parse(
          String.fromCharCode.apply(null, msg.payload),
        ) as RecordingConsentsMessagePayload;

        switch (parsedPayload.action) {
          case "request-consent":
            onRequestConsent();
            break;
          case "consent-response":
            onConsentResponse(parsedPayload.value);
            break;
          default:
            break;
        }
      }
    },
    [localParticipant, onRequestConsent, onConsentResponse],
  );
  const { send } = useDataChannel("recording-consents", messageHandler);
  const prepareAndSend = (payload: RecordingConsentsMessagePayload) =>
    send(new TextEncoder().encode(JSON.stringify(payload)), { reliable: true });

  useEffect(() => {
    setRequestConsent(() => () => {
      setRequestingConsent(true);
      prepareAndSend({ action: "request-consent" });
    });
    setRespondWithConsent(() => (value: boolean) => {
      prepareAndSend({ action: "consent-response", value });
    });
  }, [send]);
};
