import { Button } from "@springcare/sh-component-library";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  useDisclosure,
  AlertDialogBody,
} from "@chakra-ui/react";
import { useSessionRecording } from "context/SessionRoomContext";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export const RecordingConsentRequestedModal = () => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "confirmRecordingConsent",
  });
  const declineRef = useRef(null);
  const confirmationDisclosure = useDisclosure();
  const { respondWithConsent, setOnRequestConsent } = useSessionRecording();

  useEffect(() => {
    setOnRequestConsent(() => confirmationDisclosure.onOpen);
  }, []);

  const onDecline = () => {
    confirmationDisclosure.onClose();
    respondWithConsent(false);
  };
  const onAccept = () => {
    confirmationDisclosure.onClose();
    respondWithConsent(true);
  };

  return (
    <AlertDialog
      onClose={confirmationDisclosure.onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      leastDestructiveRef={declineRef}
      isOpen={confirmationDisclosure.isOpen}
    >
      <AlertDialogContent>
        <AlertDialogHeader
          textStyle="heading-small"
          px="v-24"
          pt="v-24"
          pb="v-16"
        >
          {t("header")}
        </AlertDialogHeader>

        <AlertDialogBody textStyle="body-medium-regular">
          {t("body")}
        </AlertDialogBody>

        <AlertDialogFooter px="v-24" pb="v-24" pt="v-16">
          <Button
            variant="medium-emphasis"
            onClick={onDecline}
            ref={declineRef}
          >
            {t("decline")}
          </Button>
          <Button
            variant="high-emphasis"
            onClick={onAccept}
            ml={3}
            _focus={{
              color: "white",
            }}
          >
            {t("agree")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
