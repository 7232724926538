import { useEffect } from "react";
import { Flex, Icon, useMediaQuery } from "@chakra-ui/react";
import { VCircleCheckIcon } from "@springcare/verdant-icons-react";
import { Link } from "@springcare/sh-component-library";
import { useSelector } from "react-redux";
import classnames from "classnames";

import styles from "./CostEstimateDisclaimer.module.scss";
import { useTranslation } from "hooks/react-i18next";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";
import useHighmarkExperience from "hooks/useHighmarkExperience";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { RootState } from "components/molecules/InNetworkStatusDisclaimer/InNetworkStatusDisclaimer";

export const CostEstimateDisclaimerTypes = {
  FULL: "full",
  HALF: "half",
  CARD: "card",
};

interface CostEstimateDisclaimerProps {
  variant: string;
  isModal?: boolean;
}

const MIXPANEL_COMPONENT_NAME = "Payer cost estimate banner";

const CostEstimateDisclaimer = ({
  variant,
  isModal,
}: CostEstimateDisclaimerProps) => {
  const isCard = variant === CostEstimateDisclaimerTypes.CARD;
  const isHalf = variant === CostEstimateDisclaimerTypes.HALF;
  const isFull = variant === CostEstimateDisclaimerTypes.FULL;
  const { t } = useTranslation("benefits");
  const [isMobileOrTablet] = useMediaQuery("(max-width: 900px)");

  const {
    showHighmarkBanners,
    showMainHighmarkBanner,
    isLoadingExperienceData,
  } = useHighmarkExperience();

  const shouldShowCoverageTab = useFeatureFlag(FLAGS.ENABLE_COST_TRANSPARENCY);
  const { has_access_to_cost_estimation: hasAccessToCostEstimation } =
    useSelector(
      (state: RootState) => state?.auth?.userIdentifiers?.user?.member || {},
    );

  const linkPath =
    shouldShowCoverageTab && hasAccessToCostEstimation
      ? routes["Billing--Coverage"].as
      : routes.MembersInsurance.as;

  const getIconStyles = () => {
    if (isCard || isMobileOrTablet || isModal) {
      return styles.cardIcon;
    }
    if (isHalf) {
      return styles.halfIcon;
    }
    return styles.bannerIcon;
  };

  const handleClick = () => {
    TRACK_EVENT.LINK_CLICKED(
      window.location.pathname,
      linkPath,
      MIXPANEL_COMPONENT_NAME,
    );
  };

  useEffect(() => {
    if (showHighmarkBanners) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        MIXPANEL_COMPONENT_NAME,
      );
    }
  }, [showHighmarkBanners]);

  if (
    !showHighmarkBanners ||
    (!showMainHighmarkBanner && isFull) ||
    isLoadingExperienceData
  ) {
    return null;
  }
  const isCardCTA = isCard || isMobileOrTablet || isModal;

  return (
    <div className={styles.disclaimerContainer}>
      <Flex
        className={classnames(styles.container, {
          [styles.full]: isFull,
          [styles.half]: isHalf && !isModal,
          [styles.card]: isCard || isModal,
        })}
      >
        <div className={getIconStyles()}>
          <Icon
            as={VCircleCheckIcon}
            width="v-md"
            height="v-md"
            color="info-on-subtle"
            aria-hidden="true"
          />
        </div>
        <div>{t("costEstimateDisclaimerBody")}</div>
        <Link
          onClick={handleClick}
          href={linkPath}
          color="info-on-subtle"
          _hover={{ color: "info-on-subtle" }}
          _active={{ color: "info-on-subtle" }}
          _focus={{ color: "info-on-subtle" }}
          mt={isCardCTA ? "v-16" : "v-none"}
          mb={isCardCTA ? "v-16" : "v-none"}
          ms={isCardCTA ? "v-none" : "v-16"}
          me={isCardCTA ? "v-none" : "auto"}
        >
          {t("costEstimateDisclaimerCTA")}
        </Link>
      </Flex>
    </div>
  );
};

export default CostEstimateDisclaimer;
