// TODO: remove this eslint-disable when ready
/* eslint-disable no-unused-vars */
import { useTranslation } from "hooks/react-i18next";
import {
  Box,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from "design-system/components";
import { AppointmentSlot } from "./AppointmentSlot";
import { Virtual, InPerson } from "design-system/assets";
import { ProviderOfficeLocation } from "./ProviderOfficeLocation";
import {
  AppointmentMedium,
  AppointmentKind,
  ProviderRole,
  getAppointmentKindByProviderRole,
} from "@spring/constants";
import { RequestSupport } from "./RequestSupport";
import { trackMediumTabClicked } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const DesktopSlots = ({
  videoAppointments,
  inPersonAppointments,
  tabIndex,
  handleTabsChange,
  handleSelectedSlot,
  provider,
  trackingCategory,
  providerIndex,
  pageNumber,
  queryRequestId,
  seeMoreTimes,
  showGlobalExperience,
}) => {
  const { t } = useTranslation(["careProvider"]);
  const { providerType, memberInfo, isMultiRole, selectedKind } =
    useProviderBrowseContext();
  const inPersonMedManagerFlag = useFeatureFlag(
    FLAGS.IN_PERSON_MED_MANAGER_RELEASE,
  );

  const inPersonSupportedTherapist =
    memberInfo?.user?.member?.cohort?.in_person_supported;
  const inPersonSupportedMedicationManager =
    memberInfo?.user?.member?.cohort?.in_person_med_management_supported;
  const memberInPersonSupported =
    (providerType === ProviderRole.MedicationManager &&
      inPersonSupportedMedicationManager &&
      inPersonMedManagerFlag) ||
    ((providerType === ProviderRole.Therapist || isMultiRole) &&
      inPersonSupportedTherapist);

  const noVideoAppointments =
    !videoAppointments || videoAppointments.length === 0;
  const providerTooFar = !inPersonAppointments;
  const noInPersonAppointments =
    inPersonAppointments?.length === 0 ||
    (!inPersonAppointments &&
      providerType === ProviderRole.MedicationManager &&
      inPersonSupportedMedicationManager &&
      inPersonMedManagerFlag &&
      provider?.in_person_supported);
  const providerVirtualSupported = provider.virtual_supported;
  const appointmentKind = isMultiRole
    ? selectedKind
    : getAppointmentKindByProviderRole(providerType);

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Tabs
        isFitted
        size="md"
        index={tabIndex}
        onChange={handleTabsChange}
        isManual
      >
        <TabList mb={20}>
          {providerVirtualSupported && (
            <Tab
              color="platform.base"
              maxW={"50%"}
              borderBottom="2px solid"
              borderColor="#E2E8F0"
              _hover={{ cursor: "pointer" }}
              _selected={{ color: "primary.base", borderColor: "primary.base" }}
              onClick={() =>
                trackMediumTabClicked({
                  provider,
                  appointmentMedium: "Video",
                  appointmentKind,
                })
              }
            >
              <Text variant="bodyBold_v1">
                <Virtual boxSize={22} color="currentColor" mr={2} />
                {t("virtual")}
              </Text>
            </Tab>
          )}
          {provider?.in_person_supported &&
            memberInPersonSupported &&
            !providerTooFar && (
              <Tab
                color="platform.base"
                borderBottom="2px solid"
                maxW={"50%"}
                borderColor="#E2E8F0"
                _hover={{ cursor: "pointer" }}
                _selected={{
                  color: "primary.base",
                  borderColor: "primary.base",
                }}
                onClick={() =>
                  trackMediumTabClicked({
                    provider,
                    appointmentMedium: "In Person",
                    appointmentKind,
                  })
                }
              >
                <Text variant="bodyBold_v1">
                  <InPerson boxSize={20} color="currentColor" mr={2} />
                  {t("inPerson")}
                </Text>
              </Tab>
            )}
        </TabList>
        <TabPanels mb={0}>
          {providerVirtualSupported && (
            <TabPanel p={0}>
              <Box display="flex" flexDirection="column">
                {videoAppointments?.map((time, index) => (
                  <AppointmentSlot
                    key={time}
                    index={index}
                    time={time}
                    provider={provider}
                    providerIndex={providerIndex}
                    pageNumber={pageNumber}
                    queryRequestId={queryRequestId}
                    appointmentMedium={AppointmentMedium.Video}
                    trackingCategory={trackingCategory}
                    onClick={() =>
                      handleSelectedSlot(index, time, AppointmentMedium.Video)
                    }
                  />
                ))}
                {noVideoAppointments ? (
                  <RequestSupport
                    {...{
                      provider,
                      providerIndex,
                      pageNumber,
                      medium: AppointmentMedium.Video,
                      kind: AppointmentKind.Therapy,
                      queryRequestId: queryRequestId,
                    }}
                  />
                ) : (
                  <Box
                    mt={10}
                    display="flex"
                    justifyContent="center"
                    _hover={{ cursor: "pointer" }}
                  >
                    <Text
                      as="button"
                      variant="bodyBold_v1"
                      color="primary.base"
                      textDecoration="underline"
                      data-cy="see-more-times-button"
                      onClick={(event) => {
                        event.stopPropagation();
                        seeMoreTimes();
                      }}
                    >
                      {t("seeMoreTimes")}
                    </Text>
                  </Box>
                )}
              </Box>
            </TabPanel>
          )}
          <TabPanel p={0}>
            {provider?.in_person_supported &&
              memberInPersonSupported &&
              !providerTooFar && (
                <ProviderOfficeLocation
                  {...{ provider, showGlobalExperience }}
                />
              )}
            <Box display="flex" flexDirection="column">
              {inPersonAppointments?.map((time, index) => (
                <AppointmentSlot
                  key={time}
                  index={index}
                  time={time}
                  provider={provider}
                  providerIndex={providerIndex}
                  pageNumber={pageNumber}
                  queryRequestId={queryRequestId}
                  appointmentMedium={AppointmentMedium.InPerson}
                  trackingCategory={trackingCategory}
                  onClick={() =>
                    handleSelectedSlot(index, time, AppointmentMedium.InPerson)
                  }
                />
              ))}
              {noInPersonAppointments ? (
                <RequestSupport
                  {...{
                    provider,
                    providerIndex,
                    pageNumber,
                    medium: AppointmentMedium.InPerson,
                    kind: AppointmentKind.Therapy,
                    queryRequestId: queryRequestId,
                  }}
                />
              ) : (
                <Box
                  mt={10}
                  display="flex"
                  justifyContent="center"
                  _hover={{ cursor: "pointer" }}
                >
                  <Text
                    as="button"
                    variant="bodyBold_v1"
                    color="primary.base"
                    textDecoration="underline"
                    onClick={(event) => {
                      event.stopPropagation();
                      seeMoreTimes();
                    }}
                  >
                    {t("seeMoreTimes")}
                  </Text>
                </Box>
              )}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default DesktopSlots;
