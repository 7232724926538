import { useMemberInsurance } from "hooks";
import { useAllUpcomingAppointmentSlotsV2 } from "shared/hooks";
import { getAppointments } from "operations/queries/appointment";
import { useQuery } from "@apollo/client";
import { getMemberInfo } from "operations/queries/member";
import Meowth from "@spring/meowth";
import { getAppointmentSlotStartTimeAndMedium } from "utils/schedulingHelpers";
import { getOr } from "lodash/fp";
import { getCustomerSite } from "operations/queries/customerSite";
import { ProviderRole, getProviderRoleForTracking } from "@spring/constants";
import { MemberInsuranceData } from "components/templates/CostEstimation/types";

export const useScheduleModalData = ({ providerRole, kind, provider }) => {
  const {
    data: upcomingAppointmentSlots,
    loading: upcomingAppointmentSlotsLoading,
  } = useAllUpcomingAppointmentSlotsV2(
    provider,
    kind,
    1,
    providerRole === "Care Navigator",
  );
  const { data: cnAppointmentData, loading: cnAppointmentLoading } = useQuery(
    getAppointments,
    {
      skip: providerRole !== "Care Navigator",
      variables: {
        limit: 1,
        attended: "KEPT",
        kind: [
          // Check if they have talked to a CN before
          "Initial Care Navigator Call",
          "Follow Up With Care Navigator",
        ],
      },
    },
  );
  const { data: mmAppointmentData, loading: mmAppointmentLoading } = useQuery(
    getAppointments,
    {
      skip: providerRole !== "Medication Manager",
      variables: {
        limit: 1,
        attended: "KEPT",
        booking_user_id: Meowth.getUserId(),
        kind: [
          "Initial Physician Appointment",
          "Follow Up Physician Appointment",
        ],
      },
    },
  );
  const { data: memberInfo, loading: memberLoading } = useQuery(
    getMemberInfo,
    Meowth.apolloOptionsUserId(),
  );
  const member = getOr(memberInfo, "user.member", memberInfo); // check if nested member, else use top level object

  const customerSiteId = member?.customer_site_id;
  const isACareAdvocate =
    providerRole === getProviderRoleForTracking(ProviderRole.CareAdvocate);
  const shouldLoadCustomerSite =
    isACareAdvocate && customerSiteId && !memberLoading;
  const {
    data: { customer_site: customerSite } = {},
    loading: isCustomerSiteLoading,
  } = useQuery(getCustomerSite, {
    variables: {
      id: customerSiteId,
    },
    skip: !shouldLoadCustomerSite, // Don't load if we don't need it
  });

  const {
    data: wrappedMemberInsuranceData,
    loading: isMemberInsuranceLoading,
  }: { data: MemberInsuranceData; loading: boolean } = useMemberInsurance();

  const getApptStartAndMedium = () => {
    let initialStartTime = "";
    let medium = "";
    if (upcomingAppointmentSlots && provider && member) {
      ({ initialStartTime, medium } = getAppointmentSlotStartTimeAndMedium(
        upcomingAppointmentSlots,
        provider,
        member?.cohort?.in_person_supported,
        member?.postal_address?.country,
      ));
    }
    return { initialStartTime, medium };
  };

  return {
    cnAppointmentData: cnAppointmentData?.appointments?.data || [],
    mmAppointmentData: mmAppointmentData?.appointments?.data || [],
    member,
    wrappedMemberInsuranceData,
    customerSite,
    ...getApptStartAndMedium(),
    upcomingAppointmentSlotsLoading,
    isLoading:
      cnAppointmentLoading ||
      mmAppointmentLoading ||
      memberLoading ||
      isMemberInsuranceLoading ||
      isCustomerSiteLoading,
  };
};
