import { Box, Checkbox, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { camelCase } from "lodash";
import { AppointmentKind } from "@spring/constants";

const MultiRoleAppointmentTypeSelector = ({
  handleAppointmentTypeChange,
  selectedKind,
  multiRoleSupportedAppointmentKinds,
  isModal,
}) => {
  const { t } = useTranslation(["careProvider"], {
    keyPrefix: "scheduleModal.appointmentTypes",
  });

  return (
    <>
      <Heading variant="sm_v1" mt={48}>
        {t("heading")}
      </Heading>
      <Box mt={16} mb={41}>
        <Stack spacing={0} direction={isModal ? "column" : "row"} gap={16}>
          {multiRoleSupportedAppointmentKinds?.map((kind) => {
            // TODO: this is currently hard coded because the returned supported_appointment_kinds is valid
            // Refactor BE to return the correct kinds for that customer if they are in the multi role flow
            if (kind === AppointmentKind.TrainingAndAwarenessEventConsultation)
              return null;

            return (
              <Flex
                key={kind}
                bg="background-subtle"
                border="1px"
                borderColor="border-subtle"
                borderRadius={8}
                justify="space-between"
                align="center"
                py={20}
                px={16}
                h={76}
                w="100%"
                onClick={() => handleAppointmentTypeChange(kind)}
                cursor="pointer"
              >
                <Stack>
                  <Text>{t(`${camelCase(kind)}`)}</Text>
                  {kind === AppointmentKind.ManagerConsultationAndSupport && (
                    <Text color="content-secondary" size="body-small-regular">
                      {t(`${"managerConsultationAndSupportSubtext"}`)}
                    </Text>
                  )}
                </Stack>
                <Checkbox
                  size="lg"
                  variant="circle"
                  border="1px"
                  borderColor="border-interactive"
                  borderRadius="50%"
                  isChecked={selectedKind === kind}
                />
              </Flex>
            );
          })}
        </Stack>
      </Box>
    </>
  );
};

export default MultiRoleAppointmentTypeSelector;
