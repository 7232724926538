import React, { useEffect, useRef, useState } from "react";
import Router from "next/router";
import routes from "routes";
import {
  Button,
  Input,
  Badge,
  useCustomToast,
  VerdantVariantsType,
} from "@springcare/sh-component-library";
import {
  Heading,
  Text,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { uploadDocument } from "operations/mutations/insurance";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { InfoIcon } from "design-system/assets";
import { updateMemberInsurance } from "operations/mutations/member";
import { getMemberInsurance } from "operations/queries/member";
import { DocumentUploadButton } from "components/templates/CostEstimation/components/shared/DocumentUploadButton";
import { CostEstimateInsuranceTypeEnum } from "constants/insurance";
import { MemberInsuranceData } from "components/templates/CostEstimation/types";
import { TRACK_EVENT } from "utils/mixpanel";
import { datadogAddAction } from "lib/datadog-setup";
import { useInViewportOnce } from "hooks";

type RequestCostEstimateFormData = {
  primaryInsuranceCarrier: string;
  planName: string;
  groupId: string;
  insuranceMemberId: string;
  frontOfCard: File;
  backOfCard: File;
  finishLater?: boolean;
};

type InsuranceFormProps = {
  memberInsuranceInfo: MemberInsuranceData;
  changeDisplay?: () => void;
  isSaveInsuranceOperation?: boolean;
};

type InputStylingProps = {
  borderRadius?: string;
  borderWidth?: string;
  border?: string;
  variant: VerdantVariantsType;
};

export const InsuranceForm = ({
  memberInsuranceInfo,
  changeDisplay,
}: InsuranceFormProps) => {
  const { t } = useTranslation("costEstimate");
  const [insurancePolicyInfo, setInsurancePolicyInfo] = useState(
    memberInsuranceInfo?.user?.member?.insurance_policy,
  );
  /*
    Need to add the isSaveInsurance otherwise the form is disabled if the member is actually self paying
    even if the member might want to add their insurance in
  */
  const [frontOfCardUrlObjectKey, setFrontOfCardUrlObjectKey] = useState(null);
  const [frontOfCardFileIsLoading, setFrontOfCardFileIsLoading] =
    useState(false);
  const [backOfCardUrlObjectKey, setBackOfCardUrlObjectKey] = useState(null);
  const [backOfCardFileIsLoading, setBackOfCardFileIsLoading] = useState(false);
  const [showFileUploadDisclaimer, setShowFileUploadDisclaimer] =
    useState(false);
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);

  const initialFormValues = {
    primaryInsuranceCarrier: insurancePolicyInfo?.carrier_name,
    planName: insurancePolicyInfo?.plan_name,
    groupId: insurancePolicyInfo?.insurance_group_id,
    insuranceMemberId: insurancePolicyInfo?.insurance_member_id,
  };

  useEffect(() => {
    if (inViewport && !trackedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Verify Insurance",
      );
      setTrackedOnce(true);
    }
  }, [inViewport, trackedOnce, memberInsuranceInfo]);

  const errorToast = useCustomToast({
    type: "error",
    message: t("modal.form.errors.somethingWentWrong"),
    layout: "fit-content",
    duration: 5000,
  });

  const successToast = useCustomToast({
    type: "success",
    message: t("modal.form.success.message"),
    buttonText: t("modal.form.success.actionButtonText"),
    buttonAction: () =>
      Router.push(routes.MemberSettings.to, routes.MemberSettings.as),
    layout: "fit-content",
    action: "on",
  });

  const [uploadDocumentMutation] = useMutation(uploadDocument, {
    onError: (err) => errorToast(),
  });

  const [updateInsurance] = useMutation(updateMemberInsurance, {
    onError: (err) => {
      useCustomToast({
        type: "error",
        message: t("modal.form.errors.insuranceUpdate"),
        layout: "fit-content",
        duration: 5000,
      });
    },
    onCompleted: (data) => {
      // reset object keys and update insurance policy info with saved data
      setInsurancePolicyInfo(
        data.updateMemberInsurancePolicy.member_insurance_policy,
      );
      setFrontOfCardUrlObjectKey(null);
      setBackOfCardUrlObjectKey(null);
      successToast();
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm<RequestCostEstimateFormData>({
    defaultValues: initialFormValues,
  });

  const getEmptyFields = (data: Record<string, any>): string[] => {
    return Object.entries(data).reduce((accumulator, [key, value]) => {
      if (["frontOfCard", "backOfCard"].includes(key)) {
        if (
          value instanceof File ||
          (value instanceof FileList && value.length > 0)
        ) {
          return accumulator;
        }

        const frontOfCardId =
          key === "frontOfCard" && value !== null
            ? insurancePolicyInfo?.insurance_card_front_id
            : null;
        if (!frontOfCardId && key === "frontOfCard") {
          return [...accumulator, key];
        }

        const backOfCardId =
          key === "backOfCard" && value !== null
            ? insurancePolicyInfo?.insurance_card_back_id
            : null;
        if (!backOfCardId && key === "backOfCard") {
          return [...accumulator, key];
        }
      } else if (!value) {
        return [...accumulator, key];
      }

      return accumulator;
    }, []);
  };

  const onError = (_errors) => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Submit cost estimate",
      {
        page_version: "Add insurance info",
        is_submitted: String(false),
        missing_fields: getEmptyFields(getValues()),
      },
    );
  };

  const onSubmit = async (data: RequestCostEstimateFormData) => {
    const {
      primaryInsuranceCarrier,
      planName,
      groupId,
      insuranceMemberId,
      frontOfCard,
      backOfCard,
    } = data;
    let frontOfCardId =
      frontOfCard !== null
        ? insurancePolicyInfo?.insurance_card_front_id
        : null;
    let backOfCardId =
      backOfCard !== null ? insurancePolicyInfo?.insurance_card_back_id : null;

    try {
      if (!frontOfCard?.name || !backOfCard?.name) {
        datadogAddAction("file_name_missing_error", {
          member_id: memberInsuranceInfo?.user?.member?.id,
          front_of_card: frontOfCard,
          back_of_card: backOfCard,
        });
      }
      if (frontOfCard?.name && frontOfCardUrlObjectKey) {
        const frontOfCardResponse = await uploadDocumentMutation({
          variables: {
            input: {
              id: memberInsuranceInfo.user.id,
              object_key: frontOfCardUrlObjectKey,
              file_name: frontOfCard.name,
              member_id: memberInsuranceInfo.user.member.id,
            },
          },
        });
        frontOfCardId = frontOfCardResponse.data.upload_document.document_id;
      }

      if (backOfCard?.name && backOfCardUrlObjectKey) {
        const backOfCardResponse = await uploadDocumentMutation({
          variables: {
            input: {
              id: memberInsuranceInfo.user.id,
              object_key: backOfCardUrlObjectKey,
              file_name: backOfCard.name,
              member_id: memberInsuranceInfo.user.member.id,
            },
          },
        });
        backOfCardId = backOfCardResponse.data.upload_document.document_id;
      }

      const formDataObject = {
        member_id: memberInsuranceInfo.user.member.id,
        carrier_name: primaryInsuranceCarrier,
        plan_name: planName,
        insurance_group_id: groupId,
        insurance_member_id: insuranceMemberId,
        insurance_card_front_id: frontOfCardId,
        insurance_card_back_id: backOfCardId,
        insurance_type: CostEstimateInsuranceTypeEnum.insurance,
      };

      if (
        getEmptyFields(getValues()).length === Object.keys(getValues()).length
      ) {
        return changeDisplay();
      }

      TRACK_EVENT.BUTTON_CLICKED(
        window.location.pathname,
        "Fill Out Insurance",
        {
          location: "Verify Insurance",
          page_version: "Add Insurance Info",
          is_submitted: "true",
          missing_fields: getEmptyFields(getValues()),
        },
      );

      await updateInsurance({
        variables: {
          ...formDataObject,
        },
        refetchQueries: [getMemberInsurance],
      });
      changeDisplay();
    } catch (err) {
      errorToast();
    }
  };

  const inputStyling: InputStylingProps = {
    border: "hidden",
    variant: "medium-emphasis",
  };

  return (
    <form
      id="requestCostEstimateForm"
      aria-label={t("a11y.costEstimateFormLabel")}
      ref={ref}
    >
      <SimpleGrid columns={[1, 1, 2]} columnGap={3} rowGap={3} pb={16}>
        <GridItem colSpan={1}>
          <FormControl isInvalid={Boolean(errors.primaryInsuranceCarrier)}>
            <FormLabel fontWeight="normal" htmlFor="primaryInsuranceCarrier">
              {t("modal.form.primaryInsuranceCarrier")}
            </FormLabel>
            <Input
              paddingLeft={16}
              data-cy="primary-insurance-carrier"
              type="text"
              height={50}
              name="primaryInsuranceCarrier"
              id="primaryInsuranceCarrier"
              {...inputStyling}
              {...register("primaryInsuranceCarrier")}
            />
            <ErrorMessage
              errors={errors}
              name="primaryInsuranceCarrier"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl isInvalid={Boolean(errors.planName)}>
            <FormLabel fontWeight="normal" htmlFor="planName">
              {t("modal.form.planName")}
            </FormLabel>
            <Input
              paddingLeft={16}
              data-cy="plan-name"
              type="text"
              height={50}
              name="planName"
              id="planName"
              {...inputStyling}
              {...register("planName")}
            />
            <ErrorMessage
              errors={errors}
              name="planName"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={Boolean(errors.groupId)}>
            <FormLabel fontWeight="normal" htmlFor="groupId">
              {t("modal.form.groupId")}
            </FormLabel>
            <Input
              paddingLeft={16}
              data-cy="group-id"
              type="text"
              height={50}
              name="groupId"
              id="groupId"
              {...inputStyling}
              {...register("groupId")}
            />
            <ErrorMessage
              errors={errors}
              name="groupId"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={Boolean(errors.insuranceMemberId)}>
            <FormLabel fontWeight="normal" htmlFor="insuranceMemberId">
              {t("modal.form.memberId")}
            </FormLabel>
            <Input
              paddingLeft={16}
              data-cy="insurance-member-id"
              type="text"
              height={50}
              name="insuranceMemberId"
              id="insuranceMemberId"
              {...inputStyling}
              {...register("insuranceMemberId")}
            />
            <ErrorMessage
              errors={errors}
              name="insuranceMemberId"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={[1, 1, 2]}>
          <Heading
            size="heading-small"
            as="h3"
            fontSize={16}
            marginTop={"1.5em"}
          >
            {t("modal.form.insuranceCardUploadHeading")}{" "}
            <Text as="span" style={{ fontWeight: 400 }}>
              {t("modal.form.insuranceCardUploadHeadingFileTypes")}
            </Text>
          </Heading>
          <Text marginBottom={8}>
            {t("modal.form.insuranceCardUploadSubHeading")}
          </Text>
        </GridItem>

        <GridItem colSpan={1}>
          <DocumentUploadButton
            userId={memberInsuranceInfo?.user.id}
            data-cy="front-of-card"
            label={t("modal.form.frontOfCardLabel")}
            buttonId="frontOfCardButton"
            inputId="frontOfCard"
            register={register}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
            documentId={insurancePolicyInfo?.insurance_card_front_id}
            deactivateFormSubmitButton={setFrontOfCardFileIsLoading}
            setDocumentUrlObjectKey={setFrontOfCardUrlObjectKey}
            isDisabled={false}
            setShowFileUploadDisclaimer={setShowFileUploadDisclaimer}
            required={false}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <DocumentUploadButton
            userId={memberInsuranceInfo?.user.id}
            data-cy="back-of-card"
            label={t("modal.form.backOfCardLabel")}
            buttonId="backOfCardButton"
            inputId="backOfCard"
            register={register}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
            documentId={insurancePolicyInfo?.insurance_card_back_id}
            deactivateFormSubmitButton={setBackOfCardFileIsLoading}
            setDocumentUrlObjectKey={setBackOfCardUrlObjectKey}
            isDisabled={false}
            setShowFileUploadDisclaimer={setShowFileUploadDisclaimer}
            required={false}
          />
        </GridItem>
        {showFileUploadDisclaimer && (
          <GridItem colSpan={[1, 1, 2]} mt={2}>
            <Badge icon={InfoIcon} fontWeight={400} fontSize={16} pl={8} pr={8}>
              <Text py={2} whiteSpace="pre-wrap">
                {t("modal.form.fileUploadDisclaimer")}
              </Text>
            </Badge>
          </GridItem>
        )}

        <GridItem colSpan={[1, 1, 2]} marginTop={16}>
          <Button
            width="100%"
            type="button"
            form="requestCostEstimateForm"
            onClick={handleSubmit(
              (data) => onSubmit(data),
              (errors) => onError(errors),
            )}
            disabled={
              isSubmitting ||
              frontOfCardFileIsLoading ||
              backOfCardFileIsLoading
            }
          >
            {t("modal.saveDetailsCtaText")}
          </Button>
        </GridItem>
      </SimpleGrid>
    </form>
  );
};
