import { useQuery } from "@apollo/client";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import getMemberConnectedBenefits from "operations/queries/connectedBenefits/getMemberConnectedBenefits";

export const useMemberHasConnectedBenefits = (memberId: string) => {
  const connectedBenefitsEnabled = useFeatureFlag(
    FLAGS.SHOW_CONNECTED_BENEFITS,
  );

  const { data, loading: isMemberBenefitsDataLoading } = useQuery(
    getMemberConnectedBenefits,
    {
      variables: {
        member_id: memberId,
        recommended_only: false,
      },
      skip: !memberId || !connectedBenefitsEnabled,
    },
  );

  return {
    connectedBenefits: data?.member_connected_benefits?.benefits,
    loading: isMemberBenefitsDataLoading,
  };
};
