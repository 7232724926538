import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import {
  DrawerTabTypes,
  ParticipantType,
  SessionViewStatus,
  SessionRecordingConsent,
} from "components/templates/SHSessionRoom/types";
import { useChat, useLocalParticipant } from "@livekit/components-react";
import { useDisclosure, UseDisclosureReturn } from "@chakra-ui/react";

import { isUserLoggedIn } from "lib/isUserLoggedIn";

import { logActiveDeviceChanged } from "components/templates/SHSessionRoom/telemetry/datadog";
import { DisconnectReason } from "livekit-client";

type SessionViewStatusType = {
  sessionViewStatus: SessionViewStatus;
  setSessionViewStatus: Dispatch<SetStateAction<SessionViewStatus>>;
};

type SessionUserActiveManager = {
  wakeLock: boolean;
  setWakeLock: Dispatch<SetStateAction<boolean>>;
  isLoggedIn: boolean;
};

// NOTE: This applies to isConnected+shouldConnect props for <LiveKitRoom>
type SessionConnectionStatusType = {
  shouldConnect: boolean;
  setShouldConnect: Dispatch<SetStateAction<boolean>>;
  isConnected: boolean;
  setIsConnected: Dispatch<SetStateAction<boolean>>;
  skipSetStatus: boolean;
  setSkipSetStatus: Dispatch<SetStateAction<boolean>>;
  likelyFirewallError: boolean;
  setLikelyFirewallError: Dispatch<SetStateAction<boolean>>;
  handleDisconnect: (reason?: DisconnectReason) => void;
};

// NOTE: This applies to user media devices state+logic
type SessionDevicesType = {
  stream: MediaStream | null;
  isCameraEnabled: boolean;
  isMicrophoneEnabled: boolean;
  devices: {
    audioinput: MediaDeviceInfo[];
    videoinput: MediaDeviceInfo[];
    audiooutput: MediaDeviceInfo[];
  };
  selectedVideoDevice: { deviceId: string; label: string };
  selectedAudioInputDevice: { deviceId: string; label: string };
  selectedAudioOutputDevice: { deviceId: string; label: string };
  getDevices: () => Promise<void>;
  // Used to simplify UI logic for ALL permissions denied
  devicePermissionsDenied: boolean;
  setDevicePermissionsDenied: Dispatch<SetStateAction<boolean>>;
};

type SessionRoomDrawerType = {
  isSessionRoomDrawerOpen: boolean;
  setIsSessionRoomDrawerOpen: Dispatch<SetStateAction<boolean>>;
  onSessionRoomDrawerClose: () => void;
  activeDrawerTab: string;
  setActiveDrawerTab: Dispatch<SetStateAction<string>>;
  drawerWidth: number;
  setDrawerWidth: Dispatch<SetStateAction<number>>;
};

type SessionParticipantMetadata = {
  participantType: ParticipantType;
  setParticipantType: Dispatch<SetStateAction<ParticipantType>>;
  localParticipantName: string;
  setLocalParticipantName: Dispatch<SetStateAction<string>>;
  remoteParticipantName: string;
  setRemoteParticipantName: Dispatch<SetStateAction<string>>;
  memberName: string;
  setMemberName: Dispatch<SetStateAction<string>>;
  providerName: string;
  setProviderName: Dispatch<SetStateAction<string>>;
  appointmentId: string;
  setAppointmentId: Dispatch<SetStateAction<string>>;
  participantId: string;
  setParticipantId: Dispatch<SetStateAction<string>>;
  isNoiseCancelingPreferenceEnabled: boolean;
  setIsNoiseCancelingPreferenceEnabled: Dispatch<SetStateAction<boolean>>;
  isBlurBackgroundPreferenceEnabled: boolean;
  setIsBlurBackgroundPreferenceEnabled: Dispatch<SetStateAction<boolean>>;
};

type SessionChatMessageMetadataType = {
  numberOfUnreadChatMessages: number;
  setNumberOfUnreadChatMessages: Dispatch<SetStateAction<number>>;
  lastTimeLocalParticipantOpenedChat: number;
  setLastTimeLocalParticipantOpenedChat: Dispatch<SetStateAction<number>>;
};

type SessionScreensharingMetadataType = {
  isRemoteParticipantSharingScreen: boolean;
  setIsRemoteParticipantSharingScreen: Dispatch<SetStateAction<boolean>>;
};

type SessionAuthType = {
  authDisclosure: UseDisclosureReturn;
  authenticated: boolean;
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
  afterAuthAction: () => void;
  setAfterAuthAction: Dispatch<SetStateAction<() => void>>;
  quitAuthAction: () => void;
  setQuitAuthAction: Dispatch<SetStateAction<() => void>>;
};

type SessionVerificationsType = {
  sessionVerified: boolean;
  setSessionVerified: Dispatch<SetStateAction<boolean>>;
};

type SessionRecordingsType = {
  sessionConsents: SessionRecordingConsent;
  setSessionConsents: Dispatch<SetStateAction<SessionRecordingConsent>>;
  recording: boolean;
  setRecording: Dispatch<SetStateAction<boolean>>;
  recordingEligible: boolean;
  setRecordingEligible: Dispatch<SetStateAction<boolean>>;
  requestingConsent: boolean;
  requestConsent: () => void;
  setRequestConsent: Dispatch<SetStateAction<() => void>>;
  respondWithConsent: (consent: boolean) => void;
  setRespondWithConsent: Dispatch<SetStateAction<(consent: boolean) => void>>;
  setRequestingConsent: Dispatch<SetStateAction<boolean>>;
  onRequestConsent: () => void;
  setOnRequestConsent: Dispatch<SetStateAction<() => void>>;
  onConsentResponse: (consent: boolean) => void;
  setOnConsentResponse: Dispatch<SetStateAction<(consent: boolean) => void>>;
};

interface SessionRoomContextType
  extends SessionViewStatusType,
    SessionUserActiveManager,
    SessionRoomDrawerType,
    SessionConnectionStatusType,
    SessionParticipantMetadata,
    SessionChatMessageMetadataType,
    SessionScreensharingMetadataType,
    SessionAuthType,
    SessionVerificationsType,
    SessionRecordingsType {
  sessionDevices: SessionDevicesType;
  consentRequestDisclosure: UseDisclosureReturn;
  setSessionDevices: Dispatch<SetStateAction<SessionDevicesType>>;
  getDevices: () => Promise<void>;
  devicePermissionsDenied: boolean;
  setDevicePermissionsDenied: Dispatch<SetStateAction<boolean>>;
}

// Create the context
const SessionRoomContext = createContext<SessionRoomContextType | undefined>(
  undefined,
);

// Create the context provider component
export const SessionRoomProvider = ({ children }: { children: ReactNode }) => {
  const [sessionViewStatus, setSessionViewStatus] = useState(
    SessionViewStatus.NotStarted,
  );

  // state+logic for logged-in user active management
  const [wakeLock, setWakeLock] = useState(null);
  const isLoggedIn = isUserLoggedIn();
  // state+logic for livekit connect/disconnect
  const [shouldConnect, setShouldConnect] = useState(false);
  const [isConnected, setIsConnected] = useState(true);
  const [skipSetStatus, setSkipSetStatus] = useState(false);
  const [likelyFirewallError, setLikelyFirewallError] = useState(false);

  const handleDisconnect = (reason: DisconnectReason) => {
    if (reason === DisconnectReason.JOIN_FAILURE) {
      setLikelyFirewallError(true);
      setSessionViewStatus(SessionViewStatus.LikelyFirewallError);
      return;
    }

    setShouldConnect(false);
    setIsConnected(false);
    // skipSetStatus is needed to avoid a collision when leaving the page
    // when handleDisconnect is called, that by default will set a different view
    // we don't want the view to change if we have to leave the page so we use skipSetStatus
    if (!skipSetStatus) {
      setSessionViewStatus(SessionViewStatus.Ended);
    }
  };

  // state+logic for device selection
  const [devicePermissionsDenied, setDevicePermissionsDenied] = useState(false);
  const [sessionDevices, setSessionDevices] = useState<SessionDevicesType>({
    stream: null,
    isCameraEnabled: false,
    isMicrophoneEnabled: false,
    devices: { audioinput: [], videoinput: [], audiooutput: [] },
    selectedVideoDevice: { deviceId: undefined, label: undefined },
    selectedAudioInputDevice: { deviceId: undefined, label: undefined },
    selectedAudioOutputDevice: { deviceId: undefined, label: undefined },
    getDevices: () => Promise.resolve(),
    devicePermissionsDenied,
    setDevicePermissionsDenied,
  });

  const getDevices = async () => {
    try {
      const devicesInfo = await navigator.mediaDevices.enumerateDevices();

      const audioinput = devicesInfo
        .filter((device) => device.kind === "audioinput")
        .map((device) => ({
          ...device,
          label: device.label,
          deviceId: device.deviceId,
        }));
      const videoinput = devicesInfo
        .filter((device) => device.kind === "videoinput")
        .map((device) => ({
          ...device,
          label: device.label,
          deviceId: device.deviceId,
        }));

      const audiooutput = devicesInfo
        .filter((device) => device.kind === "audiooutput")
        .map((device) => ({
          ...device,
          label: device.label,
          deviceId: device.deviceId,
        }));

      setSessionDevices((prevState) => {
        // Prioritize devices with "communications" in their name for audio input,
        // as they are likely more relevant for video call purposes
        const selectedAudioInputDevice =
          audioinput.find((device) =>
            device.deviceId.includes("communications"),
          ) ||
          audioinput[0] ||
          prevState.selectedAudioInputDevice;

        return {
          ...prevState,
          devices: { audioinput, videoinput, audiooutput },
          selectedAudioInputDevice: {
            deviceId: selectedAudioInputDevice.deviceId,
            label: selectedAudioInputDevice.label,
          },
          selectedVideoDevice:
            videoinput.length > 0
              ? {
                  deviceId: videoinput[0].deviceId,
                  label: videoinput[0].label,
                }
              : prevState.selectedVideoDevice,
          selectedAudioOutputDevice:
            audiooutput.length > 0
              ? {
                  deviceId: audiooutput[0].deviceId,
                  label: audiooutput[0].label,
                }
              : prevState.selectedAudioOutputDevice,
        };
      });

      const logDeviceObj = {
        devices: { audioinput, videoinput, audiooutput },
        selectedAudioInputDevice: audioinput[0],
        selectedVideoDevice: videoinput[0],
        selectedAudioOutputDevice: audiooutput[0],
      };
      logActiveDeviceChanged(
        ["audiooutput", "audioinput", "videoinput"],
        logDeviceObj,
        sessionViewStatus,
        "SessionRoomContext - getDevices()",
      );
    } catch (error) {
      // TODO: handle the error appropriately
    }
  };
  // state+logic for session room drawer
  const [isSessionRoomDrawerOpen, setIsSessionRoomDrawerOpen] = useState(false);
  const onSessionRoomDrawerClose = () => {
    setIsSessionRoomDrawerOpen(false);
    setLastTimeLocalParticipantOpenedChat(new Date().getTime());
  };
  const [drawerWidth, setDrawerWidth] = useState(376);
  const [activeDrawerTab, setActiveDrawerTab] = useState("participants");
  const [participantType, setParticipantType] = useState<ParticipantType>(
    ParticipantType.Unknown,
  );
  const [localParticipantName, setLocalParticipantName] = useState(undefined);
  const [
    isNoiseCancelingPreferenceEnabled,
    setIsNoiseCancelingPreferenceEnabled,
  ] = useState(true);
  const [
    isBlurBackgroundPreferenceEnabled,
    setIsBlurBackgroundPreferenceEnabled,
  ] = useState(false);
  const [remoteParticipantName, setRemoteParticipantName] = useState(undefined);
  const [memberName, setMemberName] = useState(undefined);
  const [providerName, setProviderName] = useState(undefined);

  const [appointmentId, setAppointmentId] = useState("");
  const [participantId, setParticipantId] = useState("");
  const [numberOfUnreadChatMessages, setNumberOfUnreadChatMessages] =
    useState(0);
  const [
    lastTimeLocalParticipantOpenedChat,
    setLastTimeLocalParticipantOpenedChat,
  ] = useState(0);
  const [
    isRemoteParticipantSharingScreen,
    setIsRemoteParticipantSharingScreen,
  ] = useState(false);

  const consentRequestDisclosure = useDisclosure();

  const [streamAvailable, setStreamAvailable] = useState(false);

  const [authenticated, setAuthenticated] = useState(false);
  const [afterAuthAction, setAfterAuthAction] = useState(() => () => {});
  const [quitAuthAction, setQuitAuthAction] = useState(() => () => {});

  const [sessionVerified, setSessionVerified] = useState(false);
  const [sessionConsents, setSessionConsents] =
    useState<SessionRecordingConsent>(null);
  const [recording, setRecording] = useState(false);
  const [recordingEligible, setRecordingEligible] = useState(false);
  const [requestingConsent, setRequestingConsent] = useState(false);
  const [requestConsent, setRequestConsent] = useState(() => () => {});
  const [respondWithConsent, setRespondWithConsent] = useState<
    (consent: boolean) => void
  >(() => () => {});
  const [onRequestConsent, setOnRequestConsent] = useState(() => () => {});
  const [onConsentResponse, setOnConsentResponse] = useState<
    (consent: boolean) => void
  >(() => () => {});

  useEffect(() => {
    const handleDeviceChange = () => {
      getDevices();
    };

    if (sessionDevices.stream && !streamAvailable) {
      navigator.mediaDevices.addEventListener(
        "devicechange",
        handleDeviceChange,
      );
      getDevices();
      setStreamAvailable(true);
    }
  }, [sessionDevices.stream]);

  const sessionRoomContext: SessionRoomContextType = {
    sessionViewStatus,
    setSessionViewStatus,
    wakeLock,
    setWakeLock,
    isLoggedIn,
    shouldConnect,
    setShouldConnect,
    isConnected,
    setIsConnected,
    skipSetStatus,
    setSkipSetStatus,
    likelyFirewallError,
    setLikelyFirewallError,
    handleDisconnect,
    sessionDevices,
    setSessionDevices,
    getDevices,
    consentRequestDisclosure,
    devicePermissionsDenied,
    setDevicePermissionsDenied,
    isSessionRoomDrawerOpen,
    setIsSessionRoomDrawerOpen,
    onSessionRoomDrawerClose,
    activeDrawerTab,
    setActiveDrawerTab,
    drawerWidth,
    setDrawerWidth,
    participantType,
    setParticipantType,
    localParticipantName,
    setLocalParticipantName,
    remoteParticipantName,
    setRemoteParticipantName,
    memberName,
    setMemberName,
    providerName,
    setProviderName,
    appointmentId,
    setAppointmentId,
    participantId,
    setParticipantId,
    numberOfUnreadChatMessages,
    setNumberOfUnreadChatMessages,
    lastTimeLocalParticipantOpenedChat,
    setLastTimeLocalParticipantOpenedChat,
    isRemoteParticipantSharingScreen,
    setIsRemoteParticipantSharingScreen,
    authDisclosure: useDisclosure(),
    authenticated,
    setAuthenticated,
    afterAuthAction,
    setAfterAuthAction,
    quitAuthAction,
    setQuitAuthAction,
    isNoiseCancelingPreferenceEnabled,
    setIsNoiseCancelingPreferenceEnabled,
    isBlurBackgroundPreferenceEnabled,
    setIsBlurBackgroundPreferenceEnabled,
    sessionVerified,
    setSessionVerified,
    sessionConsents,
    setSessionConsents,
    recording,
    setRecording,
    recordingEligible,
    setRecordingEligible,
    requestingConsent,
    requestConsent,
    setRequestConsent,
    respondWithConsent,
    setRespondWithConsent,
    setRequestingConsent,
    onRequestConsent,
    setOnRequestConsent,
    onConsentResponse,
    setOnConsentResponse,
  };

  return (
    <SessionRoomContext.Provider value={sessionRoomContext}>
      {children}
    </SessionRoomContext.Provider>
  );
};

export const useSessionRoom = (): SessionRoomContextType => {
  const context = useContext(SessionRoomContext);
  if (context === undefined) {
    throw new Error("useSessionRoom must be used within a SessionRoomProvider");
  }
  return context;
};

// Custom hook for Session View Status
export const useSessionViewStatus = (): SessionViewStatusType => {
  const { sessionViewStatus, setSessionViewStatus } = useSessionRoom();
  return { sessionViewStatus, setSessionViewStatus };
};

export const useSessionUserActive = (): SessionUserActiveManager => {
  const { wakeLock, setWakeLock, isLoggedIn } = useSessionRoom();
  return { wakeLock, setWakeLock, isLoggedIn };
};

// Custom hook for Session Connect Status
export const useSessionConnectStatus = (): SessionConnectionStatusType => {
  const {
    shouldConnect,
    setShouldConnect,
    isConnected,
    setIsConnected,
    skipSetStatus,
    setSkipSetStatus,
    likelyFirewallError,
    setLikelyFirewallError,
    handleDisconnect,
  } = useSessionRoom();
  return {
    shouldConnect,
    setShouldConnect,
    isConnected,
    setIsConnected,
    skipSetStatus,
    setSkipSetStatus,
    likelyFirewallError,
    setLikelyFirewallError,
    handleDisconnect,
  };
};

// Custom hook for Session Info
export const useParticipantMetadata = (): SessionParticipantMetadata => {
  const {
    participantType,
    setParticipantType,
    localParticipantName,
    setLocalParticipantName,
    remoteParticipantName,
    setRemoteParticipantName,
    memberName,
    setMemberName,
    providerName,
    setProviderName,
    appointmentId,
    setAppointmentId,
    participantId,
    setParticipantId,
    isNoiseCancelingPreferenceEnabled,
    setIsNoiseCancelingPreferenceEnabled,
    isBlurBackgroundPreferenceEnabled,
    setIsBlurBackgroundPreferenceEnabled,
  } = useSessionRoom();

  return {
    participantType,
    setParticipantType,
    localParticipantName,
    setLocalParticipantName,
    remoteParticipantName,
    setRemoteParticipantName,
    memberName,
    setMemberName,
    providerName,
    setProviderName,
    participantId,
    appointmentId,
    setAppointmentId,
    setParticipantId,
    isNoiseCancelingPreferenceEnabled,
    setIsNoiseCancelingPreferenceEnabled,
    isBlurBackgroundPreferenceEnabled,
    setIsBlurBackgroundPreferenceEnabled,
  };
};

// Custom hook for number of unread chat messages
export const useSessionChatMessages = () => {
  const {
    numberOfUnreadChatMessages,
    setNumberOfUnreadChatMessages,
    lastTimeLocalParticipantOpenedChat,
    setLastTimeLocalParticipantOpenedChat,
  } = useSessionRoom();

  return {
    numberOfUnreadChatMessages,
    setNumberOfUnreadChatMessages,
    lastTimeLocalParticipantOpenedChat,
    setLastTimeLocalParticipantOpenedChat,
  };
};

export const useUpdateChatMessages = () => {
  const {
    numberOfUnreadChatMessages,
    setNumberOfUnreadChatMessages,
    lastTimeLocalParticipantOpenedChat,
    isSessionRoomDrawerOpen,
    activeDrawerTab,
  } = useSessionRoom();

  const { localParticipant } = useLocalParticipant();
  const { localParticipantName, remoteParticipantName } =
    useParticipantMetadata();
  const { send, chatMessages, isSending } = useChat();

  useEffect(() => {
    chatMessages.forEach((chatMessage) => {
      const isMessageFromRemoteParticipant =
        chatMessage?.from?.sid !== localParticipant?.sid;
      if (chatMessage?.from) {
        chatMessage.from.name = isMessageFromRemoteParticipant
          ? remoteParticipantName
          : localParticipantName;
      }
    });
    if (
      !isSessionRoomDrawerOpen ||
      (isSessionRoomDrawerOpen && activeDrawerTab !== DrawerTabTypes.Chat)
    ) {
      chatMessages.forEach((chatMessage) => {
        const isMessageNew =
          chatMessage?.timestamp > lastTimeLocalParticipantOpenedChat;
        const isMessageFromRemoteParticipant =
          chatMessage?.from?.sid !== localParticipant?.sid;

        if (isMessageNew && isMessageFromRemoteParticipant) {
          setNumberOfUnreadChatMessages(numberOfUnreadChatMessages + 1);
        }
      });
    }
  }, [chatMessages]);

  return { send, chatMessages, isSending };
};

export const useSessionRoomConsentDisclosure = () => {
  const { consentRequestDisclosure } = useSessionRoom();
  return consentRequestDisclosure;
};

// Custom hook for getting details about whether or not a remote participant is sharing their screen
export const useSessionScreenSharingMetadata = () => {
  const {
    isRemoteParticipantSharingScreen,
    setIsRemoteParticipantSharingScreen,
  } = useSessionRoom();
  return {
    isRemoteParticipantSharingScreen,
    setIsRemoteParticipantSharingScreen,
  };
};

// Custom hook for Session Devices state+logic
export const useSessionDevices = (): {
  sessionDevices: SessionDevicesType;
  setSessionDevices: Dispatch<SetStateAction<SessionDevicesType>>;
  getDevices: () => Promise<void>;
  devicePermissionsDenied: boolean;
  setDevicePermissionsDenied: Dispatch<SetStateAction<boolean>>;
} => {
  const {
    sessionDevices,
    setSessionDevices,
    getDevices,
    devicePermissionsDenied,
    setDevicePermissionsDenied,
  } = useSessionRoom();
  return {
    sessionDevices,
    setSessionDevices,
    getDevices,
    devicePermissionsDenied,
    setDevicePermissionsDenied,
  };
};

// Custom hook for Session Room Drawer state+logic
export const useSessionRoomDrawer = (): SessionRoomDrawerType => {
  const {
    isSessionRoomDrawerOpen,
    setIsSessionRoomDrawerOpen,
    onSessionRoomDrawerClose,
    activeDrawerTab,
    setActiveDrawerTab,
    drawerWidth,
    setDrawerWidth,
  } = useSessionRoom();
  return {
    isSessionRoomDrawerOpen,
    setIsSessionRoomDrawerOpen,
    onSessionRoomDrawerClose,
    activeDrawerTab,
    setActiveDrawerTab,
    drawerWidth,
    setDrawerWidth,
  };
};

// Custom hook for authentication
export const useSessionAuthentication = () => {
  const {
    authDisclosure,
    authenticated,
    setAuthenticated,
    afterAuthAction,
    setAfterAuthAction,
    quitAuthAction,
    setQuitAuthAction,
  } = useSessionRoom();

  const authenticate = () => {
    authDisclosure.onClose();
    setAuthenticated(true);
    afterAuthAction();
  };

  const quitAuthentication = () => {
    authDisclosure.onClose();
    quitAuthAction();
  };

  return {
    authenticate,
    quitAuthentication,
    authDisclosure,
    authenticated,
    setAuthenticated,
    afterAuthAction,
    setAfterAuthAction,
    quitAuthAction,
    setQuitAuthAction,
  };
};

// Custom hook for verification state
export const useSessionVerification = () => {
  const { sessionVerified, setSessionVerified } = useSessionRoom();

  return {
    sessionVerified,
    setSessionVerified,
  };
};

// Custom hook for session recording state
export const useSessionRecording = () => {
  const {
    sessionConsents,
    setSessionConsents,
    recording,
    setRecording,
    recordingEligible,
    setRecordingEligible,
    onRequestConsent,
    setOnRequestConsent,
    onConsentResponse,
    setOnConsentResponse,
    requestingConsent,
    setRequestingConsent,
    requestConsent,
    setRequestConsent,
    respondWithConsent,
    setRespondWithConsent,
  } = useSessionRoom();

  return {
    recording,
    setRecording,
    sessionConsents,
    setSessionConsents,
    recordingEligible,
    setRecordingEligible,
    onRequestConsent,
    setOnRequestConsent,
    onConsentResponse,
    setOnConsentResponse,
    requestingConsent,
    setRequestingConsent,
    requestConsent,
    setRequestConsent,
    respondWithConsent,
    setRespondWithConsent,
  };
};
