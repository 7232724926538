import {
  Heading,
  VStack,
  useMediaQuery,
  useTheme,
  HStack,
  Flex,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

const WelcomeContent = ({ icon: Icon, isDesktop, children, displayInline }) => (
  <Flex
    role="banner"
    flexDirection={isDesktop || displayInline ? "row" : "column"}
    alignItems={isDesktop || displayInline ? "center" : "flex-start"}
    gap={isDesktop ? 4 : 0}
    w="100%"
    h="100%"
  >
    <Icon w={80} h={80} aria-label="celebration confetti icon" role="img" />
    <Heading
      size={isDesktop ? "heading-large" : "heading-medium"}
      as="h1"
      ps={displayInline ? 16 : isDesktop ? 24 : 0}
      pt={isDesktop || displayInline ? 0 : 16}
      role={!isDesktop ? "heading" : undefined}
    >
      {children}
    </Heading>
  </Flex>
);

const Welcome = ({ icon, children, displayInline = false }) => {
  const { breakpoints } = useTheme();
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [isLgScreen] = useMediaQuery(
    `(min-width: 768px) and (max-width: 1250px)`,
  );

  const [isXLgScreen] = useMediaQuery(
    `(min-width: 1250px) and (max-width: 1400px)`,
  );

  return isDesktop ? (
    <HStack
      alignItems={"flex-start"}
      spacing={4}
      w={isXLgScreen ? "1200px" : "100%"}
      h="100%"
      pt={40}
      pb={!displayInline && 80}
      ps={isXLgScreen ? 0 : isLgScreen ? 24 : 107}
      justifyContent={isXLgScreen && "flex-start"}
      mx={isXLgScreen && "auto"}
    >
      <WelcomeContent
        icon={icon}
        isDesktop={isDesktop}
        displayInline={displayInline}
      >
        {children}
      </WelcomeContent>
    </HStack>
  ) : (
    <VStack
      spacing={4}
      alignItems={"flex-start"}
      w="100%"
      h="100%"
      px={24}
      pt={!(displayInline && !isDesktop) && 17}
      pb={!(displayInline && !isDesktop) && 8}
    >
      <WelcomeContent
        icon={icon}
        isDesktop={isDesktop}
        displayInline={displayInline}
      >
        {children}
      </WelcomeContent>
    </VStack>
  );
};

Welcome.propTypes = {
  icon: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  displayInline: PropTypes.bool,
};

WelcomeContent.propTypes = {
  icon: PropTypes.elementType.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  displayInline: PropTypes.bool,
};

export default Welcome;
