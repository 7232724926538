import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useSelector } from "react-redux";
import { RootState } from "components/templates/CostEstimation/types";

export const useInsuranceValidationExperience = () => {
  const isInsuranceValidationEnabled = useFeatureFlag(
    FLAGS.INSURANCE_VALIDATION,
  );
  const {
    eligible_for_insurance_validation: isEligibleForInsuranceValidation,
  } = useSelector(
    (state: RootState) => state?.auth?.userIdentifiers?.user?.member || {},
  );

  return (
    Boolean(isInsuranceValidationEnabled) &&
    Boolean(isEligibleForInsuranceValidation)
  );
};
