import React, { useEffect, useRef, useState } from "react";
import { ActionChip } from "@springcare/sh-component-library";
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { VPlayIcon } from "@springcare/verdant-icons-react";

import { useTranslation } from "hooks/react-i18next";
import { useInViewportOnce } from "hooks";
import { TRACK_EVENT } from "utils/mixpanel";

import dynamic from "next/dynamic";

const DynamicVideoPlayer = dynamic(
  () => import("./VideoPlayer").then((m) => m.default),
  {
    ssr: false,
    loading: () => (
      <Flex justifyContent="center" alignItems="center" p="v-32">
        <Spinner speed="1s" size="xl" />
      </Flex>
    ),
  },
);

interface ProfileVideoPlayButtonProps {
  captionsUrl: string;
  name: string;
  videoUrl: string;
}

const ProfileVideoPlayButton = ({
  captionsUrl,
  name,
  videoUrl,
}: ProfileVideoPlayButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation("careProvider");

  // Extract the provider ID from the video URL for tracking
  const providerIdWithExtension = videoUrl.split("/").pop();
  const providerId = providerIdWithExtension.replace(".mp4", "");

  const handleVideoOpen = (e) => {
    e.stopPropagation();
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Profile Video Play Button",
      {
        provider_id: providerId,
      },
    );
    onOpen();
  };

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);

  useEffect(() => {
    if (inViewport && !trackedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Profile Video Play Button",
        {
          provider_id: providerId,
        },
      );
      setTrackedOnce(true);
    }
  }, [inViewport, trackedOnce]);

  const handleVideoDismiss = ({ watchedTime, isComplete }) => {
    TRACK_EVENT.VIDEO_ENDED(
      window.location.pathname,
      "Profile Video Player Dismissed",
      {
        provider_id: providerId,
        watched_time_seconds: watchedTime,
        video_completed: String(isComplete),
      },
    );
  };

  return (
    <>
      <ActionChip
        icon={VPlayIcon}
        variant="medium-emphasis"
        colorScheme="positive"
        position="absolute"
        top="100%"
        left="50%"
        backgroundColor="border-subtle"
        fontSize="v-14"
        transform="translate(-50%, -50%)"
        onClick={handleVideoOpen}
        ref={ref}
        aria-label={t("profileVideoPlayer.actionChipAriaLabel", {
          // @ts-ignore
          providerName: name,
        })}
        data-testid="profile-video-play-button"
      >
        {t("profileVideoPlayer.actionChipText")}
      </ActionChip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
        isCentered
        aria-label="Video player"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            sx={{
              _focus: {
                boxShadow: "none", // Removes the focus box shadow
              },
            }}
          />
          <Box p={16} pt="48px">
            <Heading
              as="h2"
              fontSize={21}
              fontWeight={600}
              variant="md_v1"
              mb="v-12"
            >
              {
                // @ts-ignore
                t("profileVideoPlayer.modalHeading", { providerName: name })
              }
            </Heading>
            <DynamicVideoPlayer
              captionsUrl={captionsUrl}
              onDismiss={handleVideoDismiss}
              videoUrl={videoUrl}
            />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileVideoPlayButton;
