import { trackButtonClicked } from "./analytics";
import { minorAllowedStates } from "@spring/constants";
import { getIterableCampaignInfo } from "utils/localStorage";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";

export function displayBackBtn(currentView) {
  if (
    currentView === "ENHANCED_VERIFICATION" ||
    currentView === "PRIMARY_OR_DEPENDENT" ||
    currentView === "LOGIN_VIA_SSO_PROVIDER" ||
    currentView === "SIGNUP_VIA_SSO_PROVIDER"
  ) {
    return true;
  }
  return false;
}

export function doGoBack(currentView, setCurrentView) {
  trackButtonClicked("Back", currentView, "register001", "Header");
  switch (currentView) {
    case "ENHANCED_VERIFICATION":
      setCurrentView("EMAIL_VERIFICATION");
      break;
    case "PRIMARY_OR_DEPENDENT":
      setCurrentView("ENHANCED_VERIFICATION");
      break;
    case "LOGIN":
      setCurrentView("EMAIL_VERIFICATION");
      break;
    case "LOGIN_VIA_SSO_PROVIDER":
      TRACK_EVENT.BUTTON_CLICKED(routes.SignIn.as, "back", {
        page_version: "Sign in with SSO",
      });
      setCurrentView("LOGIN");
      break;
    case "SIGNUP_VIA_SSO_PROVIDER":
      setCurrentView("EMAIL_VERIFICATION");
      break;
    default:
      break;
  }
}

export function formatProvidedDateOfBirth(date_of_birth) {
  let parts = date_of_birth.split("-");
  let year = parts[0];
  let month = parts[1];
  let day = parts[2];
  return month + "/" + day + "/" + year;
}

export const formatDefaultDateOfBirth = (dateOfBirth) => {
  if (!dateOfBirth || typeof dateOfBirth !== "string") return "";

  const datePart = dateOfBirth.split(" ")[0];

  if (datePart.indexOf("-") > -1) {
    // it's already in the correct format
    return datePart;
  } else if (datePart.indexOf("/") > -1) {
    // it's in the MM/DD/YYYY format, so convert to YYYY-MM-DD
    const [month, day, year] = datePart.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  return ""; // unknown date format
};

// format day, month, year into 'DD/MM/YYYY'
export function formatExistingDateOfBirth(dateString) {
  if (!dateString || typeof dateString !== "string") {
    return "";
  }

  const dateObj = new Date(dateString);

  if (isNaN(dateObj)) {
    return "";
  }

  const day = dateObj.getUTCDate().toString().padStart(2, "0");
  const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getUTCFullYear();

  return `${month}/${day}/${year}`;
}

export function getMinAgeAllowedToSignUpOnTheirOwn(
  stateName,
  isDependentOrNoEmail,
) {
  const isPrimaryMember = !isDependentOrNoEmail;

  if (isPrimaryMember) {
    if (minorAllowedStates.STATES_ALLOWED_FOR_13.includes(stateName)) {
      return 13;
    }
    if (minorAllowedStates.STATES_ALLOWED_FOR_14.includes(stateName)) {
      return 14;
    }
    if (minorAllowedStates.STATES_ALLOWED_FOR_EMPLOYEE_15.includes(stateName)) {
      return 15;
    }
    if (minorAllowedStates.STATES_ALLOWED_FOR_16.includes(stateName)) {
      return 16;
    }
    if (minorAllowedStates.STATES_ALLOWED_FOR_EMPLOYEE_16.includes(stateName)) {
      return 16;
    }
    return 18;
  }

  if (minorAllowedStates.STATES_ALLOWED_FOR_13.includes(stateName)) {
    return 13;
  }
  if (minorAllowedStates.STATES_ALLOWED_FOR_14.includes(stateName)) {
    return 14;
  }
  if (minorAllowedStates.STATES_ALLOWED_FOR_16.includes(stateName)) {
    return 16;
  }

  return 18;
}

export async function createDependentCL(
  values,
  isDependentObj,
  doCreateCoveredLife,
  trackErrorMsgViewed,
  currentView,
) {
  const { first_name, last_name, date_of_birth, country, email } = values;
  const { dependent, primary_covered_life_id } = isDependentObj;

  const payload = {
    first_name,
    last_name,
    date_of_birth,
    primary_covered_life_id,
    dependent,
    country,
    corporate_email: email,
  };

  try {
    const res = await doCreateCoveredLife({
      variables: { input: { ...payload, campaign: getIterableCampaignInfo() } },
    });
    if (res.data.createCoveredLife.success) {
      const { invite_token, customer_id, cohort_id, country } =
        res.data.createCoveredLife;
      return {
        first_name,
        last_name,
        invite_token,
        customer_id,
        cohort_id,
        country,
      };
    }
  } catch (_e) {
    trackErrorMsgViewed(currentView);
    return null;
  }
}

const constructionList = ["Construction", "Energy, Utilities, & Waste"];
const healthcareList = [
  "Healthcare Services",
  "Hospitals & Physicians Clinics",
];
const biotechList = [
  "Pharmaceuticals",
  "Chemicals & Related Products",
  "Cleaning Products",
  "Medical Devices & Equipment",
  "Cosmetics, Beauty Supply & Personal Care Products",
  "Health & Nutrition Products",
];
const deskOfficeList = [
  "Benefits",
  "Business Services",
  "Customer Software & IT Services",
  "Consumer Services",
  "Education",
  "Government",
  "Holding Companies and Conglomerates",
  "Hospitality",
  "Insurance",
  "Media & Internet",
  "Minerals & Mining",
  "Real Estate",
  "Software",
  "Telecommunications",
  "Law Firms & Legal Services",
  "Legal",
];

export function setImageryByIndustry(primaryIndustry, subIndustry) {
  if (constructionList.includes(primaryIndustry))
    return "/static/primary_industry/outdoors.png";

  if (deskOfficeList.includes(primaryIndustry))
    return "/static/primary_industry/desk_office.png";

  if (healthcareList.includes(primaryIndustry))
    return "/static/primary_industry/healthcare.png";

  switch (primaryIndustry) {
    case "Agriculture":
      return "/static/primary_industry/agriculture.png";
    case "Transportation":
      return "/static/primary_industry/transportation.png";
    case "Retail":
      return "/static/primary_industry/retail.png";
    case "Manufacturing":
      return biotechList.includes(subIndustry)
        ? "/static/primary_industry/biotech.png"
        : "/static/primary_industry/manufacturing.png";
    case "Finance":
      return "/static/primary_industry/finance.png";
    default:
      return "/static/primary_industry/agnostic.png";
  }
}
