import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { Flex, Box, useMediaQuery } from "@chakra-ui/react";
import { useRouter } from "next/router";

import {
  RequestCostEstimate,
  LoadingDisplay,
  InitialDisplay,
  SelfPayDisplay,
  EstimateRequestStatus,
} from "./components";
import { smeargleMaxTabletSize } from "constants/smeargle";
import {
  COST_ESTIMATE_DISPLAY_STATES,
  ValueOfCostEstimateDisplayStates,
} from "./constants";
import { useMemberInsurance } from "hooks";
import useHighmarkExperience from "hooks/useHighmarkExperience";
import { TRACK_EVENT } from "utils/mixpanel";
import { BackCta } from "./components/features";
import routes from "routes";
import { MemberInsuranceData } from "./types";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const CostEstimationTemplate = () => {
  const isInsuranceValidationEnabled = useFeatureFlag(
    FLAGS.INSURANCE_VALIDATION,
  );

  const {
    data: wrappedMemberInsuranceData,
    loading: isMemberInsuranceLoading,
  }: { data: MemberInsuranceData; loading: boolean } = useMemberInsurance();

  const { showHighmarkInsuranceForm, isLoadingExperienceData } =
    useHighmarkExperience();
  const router = useRouter();

  const [displayState, setDisplayState] =
    useState<ValueOfCostEstimateDisplayStates>(
      COST_ESTIMATE_DISPLAY_STATES.LOADING,
    );
  const [isUnderSmeargleTabletSize] = useMediaQuery(
    `(max-width: ${smeargleMaxTabletSize}px)`,
  );
  const widthValueToUse = isUnderSmeargleTabletSize ? "100%" : "724px";
  const trackingProperties = { page_version: "Request cost estimate" };

  useEffect(() => {
    // if the member already has validated insurance policy and is eligible for insurance validation
    // then we route them to their cost estimate on the Coverage tab in Billing and payments
    const hasValidatedPolicy = Boolean(
      wrappedMemberInsuranceData?.user?.member?.validated_insurance_policy,
    );
    const isEligibleForInsuranceValidation =
      wrappedMemberInsuranceData?.user?.member
        ?.eligible_for_insurance_validation;
    if (
      isInsuranceValidationEnabled &&
      !isMemberInsuranceLoading &&
      hasValidatedPolicy &&
      isEligibleForInsuranceValidation
    ) {
      router.push(routes["Billing--Coverage"].as);
    } else {
      const costEstimateRequestedDate =
        wrappedMemberInsuranceData?.user.member.insurance_policy
          ?.cost_estimate_requested_date;
      const requestDateLuxonObj =
        costEstimateRequestedDate &&
        DateTime.fromISO(costEstimateRequestedDate);
      const isMoreThan30Days =
        requestDateLuxonObj &&
        DateTime.now().diff(requestDateLuxonObj, "days").as("days") > 30;
      // if cost estimate is more than 30 dates OR if there's no cost estimate, then return intial display
      if (
        !isMemberInsuranceLoading &&
        !isLoadingExperienceData &&
        !showHighmarkInsuranceForm &&
        (isMoreThan30Days || !costEstimateRequestedDate)
      ) {
        setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
      } else if (costEstimateRequestedDate) {
        setDisplayState(COST_ESTIMATE_DISPLAY_STATES.REQUEST_STATUS);
      } else if (isMemberInsuranceLoading || isLoadingExperienceData) {
        setDisplayState(COST_ESTIMATE_DISPLAY_STATES.LOADING);
      } else if (showHighmarkInsuranceForm) {
        setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM);
      }
    }
  }, [
    isMemberInsuranceLoading,
    wrappedMemberInsuranceData,
    isLoadingExperienceData,
    showHighmarkInsuranceForm,
  ]);

  const changeDisplay = (displayState: ValueOfCostEstimateDisplayStates) =>
    setDisplayState(displayState);

  const isNotOnFinalScreen =
    displayState !== COST_ESTIMATE_DISPLAY_STATES.REQUEST_STATUS;

  return (
    <Box
      as="main"
      width="100%"
      minHeight={["100%", "768px", "768px"]}
      padding={[8, 8, "initial"]}
    >
      <Flex
        width="100%"
        height={32}
        mb={isNotOnFinalScreen ? 16 : 0}
        justifyContent="center"
        alignItems="center"
      >
        <BackCta
          widthValueToUse={widthValueToUse}
          updateDisplay={(newDisplay) => setDisplayState(newDisplay)}
          displayState={displayState}
        />
      </Flex>
      {/* Conditional rendering of displays depending on state below */}
      {displayState === COST_ESTIMATE_DISPLAY_STATES.LOADING && (
        <LoadingDisplay isMemberInsuranceLoading={isMemberInsuranceLoading} />
      )}
      {displayState === COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY && (
        <InitialDisplay
          trackingProperties={trackingProperties}
          changeToInsuranceFormDisplayCallback={() => {
            setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM);
            TRACK_EVENT.BUTTON_CLICKED(
              window.location.pathname,
              "Add insurance info",
              trackingProperties,
            );
          }}
          changeToSelfPayDisplayCallback={() => {
            setDisplayState(COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY);
            TRACK_EVENT.BUTTON_CLICKED(
              window.location.pathname,
              "Self pay",
              trackingProperties,
            );
          }}
        />
      )}
      {displayState === COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM && (
        <RequestCostEstimate
          memberInsuranceInfo={wrappedMemberInsuranceData}
          changeDisplay={changeDisplay}
          displayState={displayState}
          isHighmark={showHighmarkInsuranceForm}
        />
      )}
      {displayState === COST_ESTIMATE_DISPLAY_STATES.REQUEST_STATUS && (
        <EstimateRequestStatus
          memberInsuranceInfo={wrappedMemberInsuranceData}
        />
      )}
      {displayState === COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY && (
        <SelfPayDisplay
          memberId={wrappedMemberInsuranceData?.user.member.id}
          handleDisplayChange={() =>
            setDisplayState(COST_ESTIMATE_DISPLAY_STATES.REQUEST_STATUS)
          }
        />
      )}
    </Box>
  );
};
