import { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useMemberInfo } from "hooks";
import { debounce } from "lodash";
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Select,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";

import {
  Button,
  ComboboxInput,
  Input,
  Link,
  useCustomToast,
} from "@springcare/sh-component-library";
import { InsuranceMemberIdInput } from "components/templates/CostEstimation/components/shared/InsuranceMemberIdInput";

import { DocumentUploadButton } from "components/templates/CostEstimation/components/shared/DocumentUploadButton";
import {
  useAddS3DocumentToMember,
  useInsuranceEligibilityCheck,
  useUpdateMemberInsurance,
} from "components/templates/CostEstimation/hooks";
import { CostEstimateInsuranceTypeEnum } from "constants/insurance";
import { useApolloClient } from "@apollo/client";
import {
  InsuranceValidationFormProps,
  RequestCostEstimateFormData,
} from "components/templates/CostEstimation/types";
import { getMemberInfo, getMemberInsurance } from "operations/queries/member";
import routes from "routes";

const FAILED_ATTEMPT_THRESHOLD = 2;

export const InsuranceValidationForm = ({
  memberInsuranceInfo,
  renderInputProps,
  disableHeading = false,
  subHeading,
  submitButtonProps,
  submitButtonText,
  onValidatedInsuranceInfoSave = () => {},
  onNonValidatedInsuranceInfoSave = () => {},
  onSelfPayInfoSave = () => {},
  hideSelfPayOption = false,
}: InsuranceValidationFormProps) => {
  const { t } = useTranslation("insurance");
  subHeading = subHeading || t("form.subHeading_InsuranceValidation");

  const ref = useRef();
  const [frontOfCardUrlObjectKey, setFrontOfCardUrlObjectKey] = useState(null);
  const [frontOfCardFileIsLoading, setFrontOfCardFileIsLoading] =
    useState(false);
  const [backOfCardUrlObjectKey, setBackOfCardUrlObjectKey] = useState(null);
  const [backOfCardFileIsLoading, setBackOfCardFileIsLoading] = useState(false);
  const [, setShowFileUploadDisclaimer] = useState(false);
  const [isSelfPaying, setIsSelfPaying] = useState(
    !hideSelfPayOption &&
      memberInsuranceInfo?.user?.member?.payment_preference === "OUT_OF_POCKET",
  );

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    clearErrors,
    trigger,
    control,
  } = useForm<RequestCostEstimateFormData>({
    defaultValues: {
      primaryInsuranceCarrier: null,
      planName: null,
      groupId: null,
      insuranceMemberId: null,
      frontOfCard: null,
      backOfCard: null,
    },
    mode: "onBlur",
    shouldUnregister: true,
  });
  const primaryInsuranceCarrier = watch("primaryInsuranceCarrier");

  const { data: memberInfoData, loading } = useMemberInfo();
  const payerOptions = memberInfoData?.user?.member?.insurance_payers?.map(
    (payer) => {
      const formattedLabel =
        payer.name.charAt(0).toUpperCase() + payer.name.slice(1);
      return { value: payer.id, label: formattedLabel };
    },
  );

  const insuranceMemberIdValue = getValues("insuranceMemberId");
  const validatedInsurancePolicyPresent = Boolean(
    memberInsuranceInfo?.user?.member?.validated_insurance_policy,
  );
  const {
    data: eligibilityData,
    loading: isCheckingEligibility,
    refetch: refetchEligibilityCheck,
  } = useInsuranceEligibilityCheck(
    memberInfoData?.user?.member?.id,
    insuranceMemberIdValue,
    primaryInsuranceCarrier,
    validatedInsurancePolicyPresent,
    false,
  );

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const planNameValue = getValues("planName");
  const groupIdValue = getValues("groupId");
  const frontOfCard = getValues("frontOfCard");
  const backOfCard = getValues("backOfCard");

  const isInsuranceValidated =
    validatedInsurancePolicyPresent ||
    (Boolean(eligibilityData) &&
      eligibilityData?.insurance_eligibility_check.success);
  const isManualEntry = showAdditionalFields && !isInsuranceValidated;
  const isIndeterminate =
    !isCheckingEligibility &&
    !isInsuranceValidated &&
    Boolean(eligibilityData) &&
    failedAttempts < FAILED_ATTEMPT_THRESHOLD;

  const isManualEntryFieldsFilled =
    !!frontOfCard && !!backOfCard && !!groupIdValue && !!planNameValue;

  const isSubmitDisabled =
    (!eligibilityData ||
      isCheckingEligibility ||
      !planNameValue ||
      !groupIdValue) &&
    !isSelfPaying;

  const comboboxValue = payerOptions?.filter(
    ({ value }) => value === primaryInsuranceCarrier,
  );

  useEffect(() => {
    if (validatedInsurancePolicyPresent) {
      setValue(
        "primaryInsuranceCarrier",
        memberInsuranceInfo?.user?.member?.validated_insurance_policy
          ?.insurance_payer_id,
      );
      setValue(
        "insuranceMemberId",
        memberInsuranceInfo?.user?.member?.validated_insurance_policy
          ?.insurance_member_id,
      );
      setValue(
        "groupId",
        memberInsuranceInfo?.user?.member?.validated_insurance_policy
          ?.insurance_group_id,
      );
      setValue(
        "planName",
        memberInsuranceInfo?.user?.member?.validated_insurance_policy
          ?.plan_name,
      );
      setShowAdditionalFields(true);
    }
  }, [validatedInsurancePolicyPresent]);

  useEffect(() => {
    if (eligibilityData) {
      const isEligible = eligibilityData?.insurance_eligibility_check.success;

      // use the data returned to populate additional fields
      if (isEligible) {
        setValue(
          "planName",
          eligibilityData?.insurance_eligibility_check?.eligibility
            ?.plan_name || null,
        );
        setValue(
          "groupId",
          eligibilityData?.insurance_eligibility_check?.eligibility
            ?.insurance_group_id || null,
        );
        trigger(["planName", "groupId"]);
      } else {
        setFailedAttempts(failedAttempts + 1);
      }

      setShowAdditionalFields(true);
    }
  }, [eligibilityData]);

  const onError = (_errors) => {
    // eslint-disable-next-line no-console
    console.log("onError", _errors);
  };

  const [uploadDocumentMutation] = useAddS3DocumentToMember({
    onError: () => {
      useCustomToast({
        type: "error",
        message: t("modal.form.errors.somethingWentWrong"),
        layout: "fit-content",
        duration: 5000,
      });
    },
  });

  const [updateInsurance] = useUpdateMemberInsurance({
    onError: () => {
      // TODO handle error case in next commit
      useCustomToast({
        type: "error",
        message: t("modal.form.errors.insuranceUpdate"),
        layout: "fit-content",
        duration: 5000,
      });
    },
    onCompleted: (data) => {
      onNonValidatedInsuranceInfoSave(data);
    },
    refetchQueries: [getMemberInsurance, getMemberInfo],
  });

  const client = useApolloClient();
  const onSubmit = async () => {
    // TODO telemetry for button click
    if (isSelfPaying) {
      onSelfPayInfoSave();
      return;
    }

    // non-validated insurance submit path
    if (isManualEntry) {
      const formValues = getValues();

      const documentUploadPromises = await Promise.all([
        uploadDocumentMutation({
          variables: {
            input: {
              id: memberInsuranceInfo.user.id,
              object_key: frontOfCardUrlObjectKey,
              file_name: formValues.frontOfCard?.name,
              member_id: memberInsuranceInfo.user.member.id,
            },
          },
        }),
        uploadDocumentMutation({
          variables: {
            input: {
              id: memberInsuranceInfo.user.id,
              object_key: backOfCardUrlObjectKey,
              file_name: formValues.backOfCard?.name,
              member_id: memberInsuranceInfo.user.member.id,
            },
          },
        }),
      ]);

      const insurancePolicyPayload = {
        member_id: memberInfoData?.user?.member?.id,
        insurance_payer_id: primaryInsuranceCarrier,
        plan_name: formValues.planName,
        insurance_group_id: formValues.groupId,
        insurance_member_id: formValues.insuranceMemberId,
        insurance_card_front_id:
          documentUploadPromises[0].data.upload_document.document_id,
        insurance_card_back_id:
          documentUploadPromises[1].data.upload_document.document_id,
        trigger_cost_estimate_zendesk_ticket: true,
        insurance_type: CostEstimateInsuranceTypeEnum.insurance, // TODO update this when we have self-pay
      };

      // TODO updateMemberMutation when we have self-pay option to
      // set payment_preference as "OUT_OF_POCKET" or "INSURANCE"

      await updateInsurance({
        variables: insurancePolicyPayload,
        refetchQueries: [getMemberInsurance, getMemberInfo],
      });
    } else {
      const { data: eligibilityCreateCoverageData } =
        await refetchEligibilityCheck({
          on_success_create_coverage: true,
        });

      if (eligibilityCreateCoverageData?.insurance_eligibility_check.success) {
        await client.refetchQueries({
          include: ["getMemberInsurance", "getMemberInfo", "getUserInfo"],
        });

        onValidatedInsuranceInfoSave();
      }
    }
  };

  const resetAdditionalFormFields = () => {
    setValue("planName", null); // reset the other field values
    setValue("groupId", null);
    setValue("frontOfCard", null);
    setValue("backOfCard", null);
    setFrontOfCardUrlObjectKey(null);
    setBackOfCardUrlObjectKey(null);
  };

  const debounceValidate = useCallback(
    debounce((value) => {
      resetAdditionalFormFields();
      setValue("insuranceMemberId", value, { shouldValidate: true });
    }, 980),
    [],
  );

  const handleMemberIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowAdditionalFields(false);
    clearErrors(["planName", "groupId"]);
    debounceValidate(e.target.value); // Debounce the input change
  };

  if (!loading && !payerOptions?.length) {
    return <Box>No insurance payers found</Box>;
  }

  if (loading) {
    return <Spinner data-testid={"loading"} />;
  }

  function getDisabledSelect() {
    return (
      <FormControl isRequired={false} isInvalid={false} isDisabled={true}>
        <FormLabel fontWeight="normal" htmlFor="primaryInsuranceCarrier">
          {t("form.primaryInsuranceCarrier")}
        </FormLabel>
        <Select
          data-cy="primary-insurance-carrier"
          height={48}
          name="primaryInsuranceCarrierDisabled"
          id="primaryInsuranceCarrierDisabled"
          {...renderInputProps}
          disabled={true}
          placeholder={t("form.selectPlaceholder")}
          backgroundColor={"accent-subtle"}
        />
      </FormControl>
    );
  }

  function renderPrimaryInsuranceCarrier() {
    if (isSelfPaying) {
      return getDisabledSelect();
    }
    return (
      <FormControl
        isRequired={!isSelfPaying}
        isInvalid={!isSelfPaying && Boolean(errors.primaryInsuranceCarrier)}
        isDisabled={isSelfPaying}
      >
        <FormLabel fontWeight="normal" htmlFor="primaryInsuranceCarrier">
          {t("form.primaryInsuranceCarrier")}
        </FormLabel>
        <Controller
          control={control}
          name="primaryInsuranceCarrier"
          render={({ field: { onChange, value } }) => (
            // TODO: remove external disablement once ComboboxInput in SH Component library starts accepting isDisabled prop
            <div
              style={{
                pointerEvents: validatedInsurancePolicyPresent
                  ? "none"
                  : "auto",
                opacity: validatedInsurancePolicyPresent ? 0.5 : 1,
              }}
            >
              <ComboboxInput
                label={""}
                items={payerOptions}
                name="primaryInsuranceCarrier"
                onSelectedItemChange={({ selectedItem }) => {
                  onChange(selectedItem.value);
                }}
                value={comboboxValue[0]}
                selectedItem={comboboxValue}
              />
            </div>
          )}
        />
        <ErrorMessage
          errors={errors}
          name="primaryInsuranceCarrier"
          render={({ message }) => (
            <FormErrorMessage>{message}</FormErrorMessage>
          )}
        />
      </FormControl>
    );
  }

  return (
    <Flex direction="column" gap="v-16">
      <Flex gap="v-8" direction="column">
        {!disableHeading && (
          <Text size="body-medium-strong">
            {t("form.heading_InsuranceValidation")}
          </Text>
        )}
        <Text size="body-medium-regular">{subHeading}</Text>
      </Flex>

      <form
        id="requestCostEstimateForm"
        aria-label={t("a11y.costEstimateFormLabel")}
        ref={ref}
      >
        <SimpleGrid columns={[1, 1, 2]} columnGap={3} rowGap={16}>
          <GridItem colSpan={1}>{renderPrimaryInsuranceCarrier()}</GridItem>
          {!!primaryInsuranceCarrier && !isSelfPaying && (
            <GridItem colSpan={1}>
              <InsuranceMemberIdInput
                name="insuranceMemberId"
                label={t("form.memberId")}
                placeholder={t("form.memberIdPlaceholder_InsuranceValidation")}
                register={register}
                errors={errors}
                isCheckingEligibility={isCheckingEligibility}
                isIndeterminate={isIndeterminate}
                isInsuranceValidated={isInsuranceValidated}
                handleMemberIdChange={handleMemberIdChange}
                isDisabled={validatedInsurancePolicyPresent}
              />
            </GridItem>
          )}

          {showAdditionalFields &&
            !isCheckingEligibility &&
            !!primaryInsuranceCarrier &&
            !isSelfPaying && (
              <>
                <GridItem colSpan={1}>
                  <FormControl
                    isRequired
                    isInvalid={Boolean(errors.planName)}
                    isDisabled={isInsuranceValidated}
                  >
                    <FormLabel fontWeight="normal" htmlFor="planName">
                      {t("form.planName")}
                    </FormLabel>
                    <Input
                      data-cy="plan-name"
                      type="text"
                      height={48}
                      name="planName"
                      id="planName"
                      isDisabled={isInsuranceValidated}
                      {...renderInputProps}
                      {...register("planName", {
                        required: t("form.errors.requiredField"),
                      })}
                      color={isInsuranceValidated && "content-disabled"}
                      onChange={(e) => {
                        setValue("planName", e.target.value);
                        trigger(["planName"]);
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="planName"
                      render={({ message }) => (
                        <FormErrorMessage>{message}</FormErrorMessage>
                      )}
                    />
                  </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl
                    isRequired
                    isInvalid={Boolean(errors.groupId)}
                    isDisabled={isInsuranceValidated}
                  >
                    <FormLabel fontWeight="normal" htmlFor="groupId">
                      {t("form.groupId")}
                    </FormLabel>
                    <Input
                      data-cy="group-id"
                      type="text"
                      height={48}
                      name="groupId"
                      id="groupId"
                      isDisabled={isInsuranceValidated}
                      {...renderInputProps}
                      {...register("groupId", {
                        required: t("form.errors.requiredField"),
                      })}
                      color={isInsuranceValidated && "content-disabled"}
                      onChange={(e) => {
                        setValue("groupId", e.target.value);
                        trigger(["groupId"]);
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="groupId"
                      render={({ message }) => (
                        <FormErrorMessage>{message}</FormErrorMessage>
                      )}
                    />
                  </FormControl>
                </GridItem>

                {!isInsuranceValidated && showAdditionalFields && (
                  <>
                    <GridItem colSpan={[1, 1, 2]}>
                      <Flex direction="column" mt={"v-16"}>
                        <Flex>
                          <Text size="body-medium-strong" me={"v-4"}>
                            {t("form.insuranceCardUploadHeading")}{" "}
                          </Text>
                          <Text size="body-medium-regular">
                            {t("form.insuranceCardUploadHeadingFileTypes")}
                          </Text>
                        </Flex>
                        <Text size="body-medium-regular">
                          {t("form.insuranceCardUploadSubHeading")}
                        </Text>
                      </Flex>
                    </GridItem>

                    <GridItem colSpan={1}>
                      <DocumentUploadButton
                        userId={memberInsuranceInfo?.user.id}
                        data-cy="front-of-card"
                        label={t("form.frontOfCardLabel")}
                        buttonId="frontOfCardButton"
                        inputId="frontOfCard"
                        documentId=""
                        register={register}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                        errors={errors}
                        deactivateFormSubmitButton={setFrontOfCardFileIsLoading}
                        setDocumentUrlObjectKey={setFrontOfCardUrlObjectKey}
                        isDisabled={false}
                        setShowFileUploadDisclaimer={
                          setShowFileUploadDisclaimer
                        }
                        trigger={!backOfCardFileIsLoading && trigger}
                      />
                    </GridItem>

                    <GridItem colSpan={1}>
                      <DocumentUploadButton
                        userId={memberInsuranceInfo?.user.id}
                        data-cy="back-of-card"
                        label={t("form.backOfCardLabel")}
                        buttonId="backOfCardButton"
                        inputId="backOfCard"
                        documentId=""
                        register={register}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                        errors={errors}
                        deactivateFormSubmitButton={setBackOfCardFileIsLoading}
                        setDocumentUrlObjectKey={setBackOfCardUrlObjectKey}
                        isDisabled={false}
                        setShowFileUploadDisclaimer={
                          setShowFileUploadDisclaimer
                        }
                        trigger={!frontOfCardFileIsLoading && trigger}
                      />
                    </GridItem>
                  </>
                )}
              </>
            )}
          {!hideSelfPayOption && (
            <>
              <GridItem colSpan={[1, 1, 2]} mb={"v-16"}>
                <Flex width={"100%"} alignItems={"center"}>
                  <Box
                    width={"50%"}
                    height={"2px"}
                    backgroundColor={
                      "var(--Border-Bold, rgba(1, 18, 25, 0.67))"
                    }
                  />
                  <Text size="body-medium-strong" mt={"v-4"} px={"v-8"}>
                    {t("form.or")}
                  </Text>
                  <Box
                    width={"50%"}
                    height={"2px"}
                    backgroundColor={
                      "var(--Border-Bold, rgba(1, 18, 25, 0.67))"
                    }
                  />
                </Flex>
              </GridItem>

              <GridItem colSpan={[1, 1, 2]}>
                <Flex gap="v-8" direction="column">
                  <Text size="body-medium-strong">
                    {t("form.selfPayHeading")}
                  </Text>
                  <Text size="body-medium-regular">
                    {t("form.selfPaySubHeading")}
                  </Text>
                </Flex>
                <Box
                  backgroundColor={"accent-on-bold"}
                  borderRadius={"v-lg"}
                  mt={"v-16"}
                  py={"v-16"}
                  px={"v-24"}
                >
                  <FormControl>
                    <Checkbox
                      onChange={() => {
                        setIsSelfPaying(!isSelfPaying);
                        setShowAdditionalFields(false);
                      }}
                      isChecked={isSelfPaying}
                      disabled={validatedInsurancePolicyPresent}
                    >
                      <Text size={"body-medium-strong"}>
                        {t("form.selfPayAcknowledgementText")}
                      </Text>
                    </Checkbox>
                  </FormControl>
                  <Trans
                    t={t}
                    i18nKey="form.selfPayDescriptionText"
                    components={[
                      <Link
                        colorScheme="base"
                        key={"0"}
                        href={routes["Billing--WalletTab"].as}
                      />,
                    ]}
                  />
                </Box>
              </GridItem>
            </>
          )}

          <GridItem colSpan={[1, 1, 2]}>
            <Button
              width="100%"
              marginTop={showAdditionalFields ? "v-8" : "v-32"}
              type="button"
              id="requestCostEstimateSubmitButton"
              form="requestCostEstimateForm"
              onClick={handleSubmit(onSubmit, (errors) => onError(errors))}
              {...submitButtonProps}
              disabled={
                isSubmitDisabled ||
                (isManualEntry && !isManualEntryFieldsFilled)
              }
              mb={10}
            >
              {submitButtonText ||
                (isIndeterminate || isManualEntry || isSelfPaying
                  ? t("title")
                  : t("form.viewCostEstimateButton_InsuranceValidation"))}
            </Button>
          </GridItem>
        </SimpleGrid>
      </form>
    </Flex>
  );
};
