import { TRACK_EVENT } from "utils/mixpanel";

export const trackPageVersionViewed = (pageVersion: string) => {
  TRACK_EVENT.PAGE_VERSION_VIEWED(window.location.pathname, pageVersion);
};

export const handleWelcomeModalContinueClick = () => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Welcome Modal Continue",
  );
};

export const trackRequestDevicePermissionsModalAllowed = () => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Browser Device Permission Requested - Allowed",
  );
};

export const trackBrowserPermissionsModalOpened = () => {
  TRACK_EVENT.MODAL_OPENED(
    window.location.pathname,
    "Browser Device Permission Requested by Browser",
  );
};

export const trackBrowserPermissionsSet = (
  status: boolean,
  device: ["camera", "microphone"],
) => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Browser Device Permission Set by Browser",
    {
      granted: status,
      device,
    },
  );
};

export const trackDeviceToggleState = (
  device: string,
  active: boolean,
  source: string,
) => {
  const status = active === true ? true : "false"; // false needs to be string otherwise not counted - an MP quirk

  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    `${device.charAt(0).toUpperCase() + device.slice(1)} Toggle`,
    {
      active: status,
      source,
    },
  );
};

export const trackDeviceMenuOpened = (
  deviceType: string | string[],
  source: string,
) => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Device Menu", {
    deviceType,
    source,
  });
};

export const trackDeviceMenuSelection = (
  deviceType: string,
  source: string,
  name: string,
) => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Device Item", {
    deviceType,
    source,
    name,
  });
};

export const trackFilterToggleState = (filterType: string, active: boolean) => {
  const status = active === true ? true : "false";

  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, `${filterType} Toggle`, {
    active: status,
  });
};

export const trackHelpInstructionsClicked = (type) => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, type);
};

export const trackContactSupportClicked = (action: string) => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, `Need Help - ${action}`);
};

export const trackStartSessionClick = () => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Start Session");
};

export const trackSessionDrawerToggle = (
  action: string,
  active: boolean,
  isChangingActiveTab: boolean,
) => {
  const status = active === true ? true : "false";
  if (action !== "screensharing") {
    if (isChangingActiveTab) {
      TRACK_EVENT.BUTTON_CLICKED(
        window.location.pathname,
        `${action.charAt(0).toUpperCase() + action.slice(1)} Drawer Switched to via Toggle Button`,
        {
          active: status,
        },
      );
    } else {
      TRACK_EVENT.BUTTON_CLICKED(
        window.location.pathname,
        `${action.charAt(0).toUpperCase() + action.slice(1)} Drawer Toggle`,
        {
          open: status,
        },
      );
    }
  } else {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      `${action.charAt(0).toUpperCase() + action.slice(1)} Toggle`,
      {
        active: status,
      },
    );
  }
};

export const trackSessionDrawerTabToggle = (tabName: string) => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    `${tabName.charAt(0).toUpperCase() + tabName.slice(1)} Drawer Tab`,
  );
};

export const trackChatMessageSent = () => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Video Call Message Sent",
  );
};

export const trackAdmitParticipantClick = () => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Admit Participant");
};

export const trackExitMenuOpened = (active: boolean) => {
  const status = active === true ? true : "false";
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Exit Menu", {
    active: status,
  });
};

export const trackExitSessionClick = (type: string) => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    type === "leave" ? "Leave Session" : "End Session for All",
  );
};

export const trackExitSessionVerificationClick = () => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "End Session - Verification",
  );
};

export const trackProviderFeedbackSent = (
  rating: number,
  comment: string,
  appointmentId: string,
) => {
  TRACK_EVENT.FORM_SUBMITTED(
    window.location.pathname,
    "Livekit Session Provider Feedback",
    {
      rating,
      comment,
      appointmentId,
    },
  );
};
