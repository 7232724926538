import { formatMemberExactAge } from "utils/displayHelpers";
import routes from "routes";
import { cloneDeep, isEqual } from "lodash/fp";
import { datadogAddError } from "lib/datadog-setup";
import { normalizeToISO2 } from "utils/global";
import { truncateString } from "@spring/immutability";

// prepareQueryFilters.js
// This function is used to prepare the query filters for the API call
// Our query filters are derived from selectedFilters in Context
// There is dependency between possible_filters and selectedFilters
// In order to render the correct UI, we need values from possible_filters
// But we have to format the values before we send them to the API
// Hence this function
export function prepareQueryFilters(selectedFilters, providerType) {
  function removeEmptyArrays(obj) {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (Array.isArray(newObj[key]) && newObj[key].length === 0) {
        delete newObj[key];
      }
    }
    return newObj;
  }

  function replaceSpacesDashesAndCapitalize(str) {
    return str.replace(/( |-)/g, "_").toUpperCase();
  }

  // deep-clone selectedFilters - spreading it is a shallow copy and causes bugs
  let queryFilters = JSON.parse(JSON.stringify(selectedFilters));

  // TODO: Maybe add this to Context - problem that causes is with auto-rendering elements
  // queryFilters.filters.licenses = { countries: ['US'], states: ['NY'] };

  queryFilters = Object.entries(queryFilters?.filters).reduce(
    (acc, [key, value]: [string, Array<string>]) => {
      let transformedValue;

      if (key === "mediums") {
        transformedValue = value?.map(replaceSpacesDashesAndCapitalize);
        // Replace 'VIRTUAL' with 'VIDEO' in session_type
        if (key === "mediums") {
          transformedValue = transformedValue?.map((item) =>
            item === "VIRTUAL" ? "VIDEO" : item,
          );
        }
      } else {
        transformedValue = value;
      }

      acc[key] = transformedValue;
      return acc;
    },
    {},
  );

  // search care providers here with the selected filters
  queryFilters = removeEmptyArrays(queryFilters);
  // TODO: this should probably always return an object even if
  // queryFilters is empty
  if (queryFilters) {
    return {
      roles: Array.isArray(providerType) ? [] : providerType.toUpperCase(),
      filters: queryFilters,
    };
  }
}

export const autoFilterProviderListByFilterTag = (
  filterTagName,
  queriedFilters,
  setQueriedFilters,
  setSelectedFilters,
  filterType = "specialties",
) => {
  // creating a deep copy of selectedFilters so we dont mutate original obj
  const typeOfVariable = typeof filterTagName;
  const copyOfQueriedFilters = cloneDeep(queriedFilters);
  {
    typeOfVariable === "string"
      ? copyOfQueriedFilters.filters[filterType].push(filterTagName)
      : copyOfQueriedFilters.filters[filterType].push(...filterTagName);
  }
  setQueriedFilters(cloneDeep(copyOfQueriedFilters));
  setSelectedFilters(cloneDeep(copyOfQueriedFilters));
};

export function minorFilterTag(memberInfo, t): string {
  const membersAge = formatMemberExactAge(
    memberInfo?.user?.member?.date_of_birth,
  );

  let filterTag = "";

  if (memberInfo?.user?.member?.minor) {
    filterTag = t("minorFixedTag.childrenTag");
  }

  if (membersAge >= 13 && memberInfo?.user?.member?.minor) {
    filterTag = t("minorFixedTag.adolescentsTag");
  }

  return filterTag;
}

export function mapCategoryToSingular(category, t): string {
  switch (category) {
    case t("filterCategory.plural.specialties"):
      return t("filterCategory.singular.specialty");
    case t("filterCategory.plural.conditions"):
      return t("filterCategory.singular.condition");
    case t("filterCategory.plural.genders"):
      return t("filterCategory.singular.gender");
    case t("filterCategory.plural.ethnicities"):
      return t("filterCategory.singular.ethnicity");
    case t("filterCategory.plural.languages"):
      return t("filterCategory.singular.language");
    case t("filterCategory.plural.mediums"):
      return t("filterCategory.singular.sessionType");
    case t("filterCategory.plural.daysOfWeek"):
      return t("filterCategory.singular.daysOfWeeks");
    case t("filterCategory.plural.timesOfDay"):
      return t("filterCategory.singular.timeOfDay");
    default:
      return category;
  }
}

export const providerProfileClick = (
  providerId: string,
  queryRequestId: string,
  therapistCategory = "",
  isCouplesFilterSelected = false,
  isMultiRole?: boolean,
  customRoleName?: string,
  multiRoleSupportedAppointmentKinds: string[] = [],
) => {
  const providerPageRoute = routes.ScheduleAppointmentProviderDetail.as.replace(
    ":id",
    providerId,
  );

  const queryParams = new URLSearchParams({
    browseTherapist: "true",
    therapistCategory,
    queryRequestId,
  });

  if (isCouplesFilterSelected) {
    queryParams.append("couplesFilter", "true");
  }
  if (isMultiRole) {
    queryParams.append("isMultiRole", "true");
    queryParams.append("showMultiRoleTypeSelector", "true");
  }
  if (customRoleName) {
    queryParams.append("customRoleName", customRoleName);
  }
  if (multiRoleSupportedAppointmentKinds.length > 0) {
    queryParams.append(
      "multiRoleSupportedAppointmentKinds",
      JSON.stringify(multiRoleSupportedAppointmentKinds),
    );
  }

  return () =>
    window.open(`${providerPageRoute}?${queryParams.toString()}`, "_blank");
};

// Used for breaking down the filters into a single array
export function getQueriedFilters(filters: object) {
  const result = [];

  for (const key in filters) {
    for (const value of filters[key]) {
      if (value) {
        result.push({ type: key, filterLabel: value });
      }
    }
  }

  return result;
}

export function dedupeProviders(
  searchCareProviders,
  brecsProviderList,
  providerList,
  setProviderList,
  isCombinedList = false,
  isInitialLoad = false,
  showFakeBadges = false,
) {
  if (Array.isArray(searchCareProviders) && Array.isArray(brecsProviderList)) {
    const _brecsProviderList = brecsProviderList?.map((item) => ({
      // @ts-ignore
      ...item.therapist,
      // @ts-ignore
      group_name: item.group_name,
      isBrecsRecommended: true,
    }));

    const brecsProviderIds = new Set(
      _brecsProviderList?.map((provider) => provider.id),
    );

    const filteredProviderList = searchCareProviders?.filter(
      (provider) => !brecsProviderIds.has(provider.id),
    );

    const finalList = isCombinedList
      ? [..._brecsProviderList, ...filteredProviderList]
      : filteredProviderList;

    // Check if the filtered list is different from the current providerList, prevents infinite loop
    if (!isEqual(finalList, providerList)) {
      setProviderList(finalList);
    }

    if (isInitialLoad && showFakeBadges) {
      setFakeBadges(filteredProviderList, _brecsProviderList);
    }

    return searchCareProviders?.length > 0
      ? searchCareProviders?.length - filteredProviderList?.length
      : 0;
  }
}

export enum BrecsVariants {
  Control = "control",
  CombinedList = "combinedList",
  MoreTherapistsButton = "moreTherapistsButton",
}

export const isBrecsVariant = (variant: string | boolean): boolean => {
  if (
    typeof variant === "string" &&
    (variant === BrecsVariants.CombinedList ||
      variant === BrecsVariants.MoreTherapistsButton)
  ) {
    return true;
  }
  return false;
};

export const BOOKS_FAST_PROVIDERS = "fakeBadgeBooksFast";
export const MEMBER_FAVORITE_PROVIDERS = "fakeBadgeMemberFavorite";

export const setFakeBadges = (providers, brecsProviders) => {
  try {
    const recommendedIds = brecsProviders?.map((provider) => provider?.id);
    const browseIds = providers?.map((provider) => provider?.id);

    if (browseIds?.length >= 3 && !localStorage.getItem(BOOKS_FAST_PROVIDERS)) {
      setIdsToLocalStorage(browseIds, BOOKS_FAST_PROVIDERS, 3);
    }

    if (
      recommendedIds?.length >= 3 &&
      !localStorage.getItem(MEMBER_FAVORITE_PROVIDERS)
    ) {
      setIdsToLocalStorage(
        recommendedIds,
        MEMBER_FAVORITE_PROVIDERS,
        recommendedIds?.length,
      );
    }
  } catch (error) {
    datadogAddError(`Error setting fake badges: ${error}`);
  }
};

export const includesBadgeId = (id) => {
  const booksFastIds = JSON.parse(localStorage.getItem(BOOKS_FAST_PROVIDERS));
  const memberFavoriteIds = JSON.parse(
    localStorage.getItem(MEMBER_FAVORITE_PROVIDERS),
  );

  return booksFastIds?.includes(id) || memberFavoriteIds?.includes(id);
};

export const setIdsToLocalStorage = (providerIDs, storageKey, maxIndex) => {
  const randomIndexes = getTwoRandomNumbers(maxIndex);
  const firstId = providerIDs[randomIndexes.first];
  const secondId = providerIDs[randomIndexes.second];
  if (!includesBadgeId(firstId) && !includesBadgeId(secondId)) {
    localStorage.setItem(storageKey, JSON.stringify([firstId, secondId]));
  }
};

export const getTwoRandomNumbers = (max) => {
  const firstRandom = Math.floor(Math.random() * max);
  let secondRandom = Math.floor(Math.random() * max);
  while (firstRandom === secondRandom) {
    secondRandom = Math.floor(Math.random() * max);
  }

  return {
    first: firstRandom,
    second: secondRandom,
  };
};

export const getManagerExperienceCoachingFilters = (t) => {
  return [
    t("filterTags.managerExperience.executiveFunctioning"),
    t("filterTags.managerExperience.careerGrowthPath"),
    t("filterTags.managerExperience.leadershipSupport"),
    t("filterTags.managerExperience.workLifeBalance"),
    t("filterTags.managerExperience.productivePerformance"),
    t("filterTags.managerExperience.professionalDevelopment"),
  ];
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return "";
  }
  const basePhone = phoneNumber?.substring(phoneNumber?.length - 10);
  const match =
    basePhone?.length === 10 && basePhone?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match && match?.length === 4) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return "";
};

export const providerBooksFast = (provider) => {
  const booksFastIds = JSON.parse(localStorage.getItem(BOOKS_FAST_PROVIDERS));
  return booksFastIds?.includes(provider?.id);
};

export const providerMemberFavorite = (provider) => {
  const memberFavoriteIds = JSON.parse(
    localStorage.getItem(MEMBER_FAVORITE_PROVIDERS),
  );
  return memberFavoriteIds?.includes(provider?.id);
};

export const getFakeBadgesTracking = (provider) => {
  const badges = [];
  if (providerBooksFast(provider)) {
    badges.push("books_fast");
  } else if (providerMemberFavorite(provider)) {
    badges.push("member_favorite");
  }

  return badges;
};

export const getMemberAddressCopy = (postalAddress) => {
  const { street_address_1, city, state, country } = postalAddress;
  const countryISO2 = normalizeToISO2(country);

  return [
    truncateString(street_address_1, 15),
    truncateString(city, 15),
    state,
    countryISO2,
  ]
    .filter(Boolean)
    .join(", ");
};
