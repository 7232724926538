import {
  Box,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from "design-system/components";

import { SHLogoLink } from "design-system/components/custom/headers/SHLogoLink";
import { useMediaQuery } from "@chakra-ui/react";

import { useTranslation } from "hooks/react-i18next";

import { VAiIcon, VVisitsIcon } from "@springcare/verdant-icons-react";

import { MomentsIcon } from "@spring/smeargle";

import { useRegisterContext } from "context/RegisterContext";
import { setImageryByIndustry } from "components/templates/RegisterPage/utils";

const PromoExperiment = ({
  variant = undefined,
  isWarmSignup,
  isIndustryImagery,
}) => {
  const { t } = useTranslation("limitedLangRegister");

  const { coveredLife } = useRegisterContext();
  const primaryIndustry = coveredLife?.primary_industry;
  const subIndustry = coveredLife?.sub_industry;

  const remainingCovered = coveredLife?.total_visits_covered || 0;
  const sessionsCopy =
    remainingCovered > 0
      ? t("promo.PERSON_IMAGERY_EXPERIMENT.itemOneWithSessions", {
          remainingCovered,
        })
      : t("promo.PERSON_IMAGERY_EXPERIMENT.itemOne");

  let imageSrc =
    variant == "variant_b"
      ? "/static/woman_promo.png"
      : "/static/man_promo.png";

  const coveredLifeName = coveredLife?.first_name ?? null;

  const headingCopy = coveredLifeName
    ? t("promo.itemOne.titleWarm", { coveredLifeName })
    : t("promo.PERSON_IMAGERY_EXPERIMENT.title");

  if (isIndustryImagery && isWarmSignup) {
    imageSrc = setImageryByIndustry(primaryIndustry, subIndustry);
  }

  const [isSmallerBreakpoint] = useMediaQuery("(max-width: 1100px)");
  const [isMediumBreakpoint] = useMediaQuery("(max-width: 1250px)");

  return (
    <Box padding="24px">
      <Flex
        align="center"
        display={"flex"}
        justifyContent="center"
        height="stretch"
        maxH={"calc(100vh - 48px)"}
        width="50%"
        position="absolute"
        left="0px"
        top="0px"
        borderRadius={"16px"}
        marginY={"24px"}
      >
        <Box
          borderRadius={"16px"}
          height="100%"
          width={"100%"}
          size="none"
          position="relative"
          zIndex={1}
          justifyContent="center"
          alignItems="center"
          display="flex"
          marginStart={"24px"}
        >
          <>
            <Box
              zIndex={2}
              position={"absolute"}
              top={24}
              left={24}
              filter={
                // apply filter to SHLogo to make logo white
                "invert(100%) sepia(100%) saturate(1%) hue-rotate(231deg) brightness(104%) contrast(102%)"
              }
              height={"30px"}
              width={"30px"}
              justifyContent={"center"}
              display={"flex"}
            >
              <SHLogoLink
                desktopBg={"none"}
                isLoggedIn={false}
                height={"30px"}
                width={"auto"}
                paddingStart={"0px"}
                boxSize={"100%"}
              />
            </Box>

            <Box
              h={"100%"}
              width={"100%"}
              position="relative"
              mixBlendMode={"multiply"}
              borderRadius={"16px"}
              pointerEvents={"none"}
              id="car"
            >
              <Image
                width={"100%"}
                alt={t("promo.PERSON_IMAGERY_EXPERIMENT.title")}
                aria-hidden="true"
                src={imageSrc}
                borderRadius={"16px"}
                objectFit="cover"
                height={"100%"}
                objectPosition="100"
              />
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                borderRadius={"16px"}
                mixBlendMode="multiply"
                background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.00656119) 14.59%, rgba(0, 0, 0, 0.0251828) 26.58%, rgba(0, 0, 0, 0.054272) 36.32%, rgba(0, 0, 0, 0.0922358) 44.14%, rgba(0, 0, 0, 0.137481) 50.37%, rgba(0, 0, 0, 0.188416) 55.36%, rgba(0, 0, 0, 0.243447) 59.44%, rgba(0, 0, 0, 0.30098) 62.96%, rgba(0, 0, 0, 0.359424) 66.24%, rgba(0, 0, 0, 0.417185) 69.63%, rgba(0, 0, 0, 0.472671) 73.46%, rgba(0, 0, 0, 0.524288) 78.08%, rgba(0, 0, 0, 0.570444) 83.82%, rgba(0, 0, 0, 0.609545) 91.01%, rgba(0, 0, 0, 0.64) 100%)"
                pointerEvents="none"
              />
            </Box>

            <VStack
              w={"100%"}
              padding={
                isSmallerBreakpoint
                  ? "24px"
                  : isMediumBreakpoint
                    ? "40px"
                    : "80px"
              }
              alignItems="flex-start"
              position="absolute"
              bottom={0}
            >
              <Heading
                size={"lg"}
                color="background-base"
                textAlign={"start"}
                fontWeight={700}
                fontSize={"38px"}
                as="h1"
                data-cy="welcome-to-spring-health"
              >
                {headingCopy}
              </Heading>
              <Text
                size="body-medium-regular"
                color="background-base"
                textAlign={"start"}
                fontSize={"18px"}
                fontWeight={600}
                marginBottom={"24px"}
                marginTop={"8px"}
              >
                {t("promo.PERSON_IMAGERY_EXPERIMENT.subtitle")}
              </Text>
              <HStack>
                <VAiIcon color="white" aria-label="ai-icon" />
                <Text
                  size="body-medium-regular"
                  color="background-base"
                  textAlign={"start"}
                  fontSize={"16px"}
                  fontWeight={400}
                >
                  {sessionsCopy}
                </Text>
              </HStack>

              <HStack>
                <VVisitsIcon color="white" aria-label="flexible-appointments" />
                <Text
                  size="body-medium-regular"
                  color="background-base"
                  textAlign={"start"}
                  fontSize={"16px"}
                  fontWeight={400}
                >
                  {t("promo.PERSON_IMAGERY_EXPERIMENT.itemTwo")}
                </Text>
              </HStack>

              <HStack>
                <Flex
                  filter={
                    "invert(100%) sepia(100%) saturate(1%) hue-rotate(231deg) brightness(104%) contrast(102%)"
                  }
                >
                  <MomentsIcon
                    color="white"
                    aria-label="self-guided-exercises"
                  />
                </Flex>
                <Text
                  size="body-medium-regular"
                  color="background-base"
                  textAlign={"start"}
                  fontSize={"16px"}
                  fontWeight={400}
                >
                  {t("promo.PERSON_IMAGERY_EXPERIMENT.itemThree")}
                </Text>
              </HStack>
            </VStack>
          </>
        </Box>
      </Flex>
    </Box>
  );
};

export default PromoExperiment;
