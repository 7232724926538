import React, { useEffect, useState, useCallback } from "react";
import uuid from "react-uuid";
import { ModalSheet } from "@springcare/sh-component-library";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { useToast, SHNotification } from "design-system/components";
import CareProviderScheduleContent from "components/molecules/CareProviderScheduleContent/CareProviderScheduleContent";
import { trackProviderProfileModalClosed } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { PaymentInfoUpdateWrapper } from "components/modals";
import {
  COST_ESTIMATE_DISPLAY_STATES,
  ValueOfCostEstimateDisplayStates,
} from "components/templates/CostEstimation/constants";
import { useTranslation } from "hooks/react-i18next";
import { useScheduleModalData } from "./__hooks__/useScheduleModalData";
import { useRouteTeenToInitialAssessment } from "./__hooks__/useRouteTeenToInitialAssessment";
import {
  CareProviderScheduleContentProps,
  CareProviderScheduleModalV2Props,
} from "./types";
import {
  getEventProps,
  lookupModalTitle,
  getMinWidth,
  hasTwoOrFewerVisitsRemaining,
  isCostEstimateDisplayState,
  getModalPlacement,
  getModalSize,
} from "./utils";
import { isGlobalUser } from "utils/global";
import { useModalTracking } from "./__hooks__/useModalTracking";
import { PaymentPreference } from "@spring/constants";
import { datadogAddError } from "lib/datadog-setup";
import { TRACK_EVENT } from "utils/mixpanel";
import { MultiSessionBooking } from "./MultiSessionBooking";

// Clone of CareProviderScheduleModal - MEI-107 will replace remaining uses
// of CareProviderScheduleModal with this component after GA of MXENG-3682
export const CareProviderScheduleModalV2 = ({
  isOpen: isScheduleModalOpen,
  onClose: onScheduleModalClose,
  kind,
  provider,
  providerRole,
  action,
  buttonText,
  dataCy,
  appointmentId,
  providerOrder,
  queryRequestId,
  pageNumber,
  previousReasonForScheduling,
  telemetryProps,
  defaultTab,
  selectedKind,
  setSelectedKind,
  openModal,
  scheduleModalWithPropsV2,
  getButtonText,
  isMultiRole,
  multiRoleSupportedAppointmentKinds,
  customRoleName,
  showMultiRoleTypeSelector,
}: CareProviderScheduleModalV2Props): JSX.Element => {
  const [isDesktop] = useMediaQuery("(min-width: 780px)");
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const therapyTopSpecialtiesFlag = useFeatureFlag(
    FLAGS.THERAPY_TOP_SPECIALTIES,
  );
  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);
  const isGatedBookingEnabled = useFeatureFlag(
    FLAGS.MEMBER_PAYMENT_COLLECTIONS_M3,
  );
  const isInsuranceValidationEnabled = useFeatureFlag(
    FLAGS.INSURANCE_VALIDATION,
  );

  const [currentProvider, setCurrentProvider] = useState(provider);

  const [modalUuid, setModalId] = useState<string>("");
  const [showScheduleContent, setShowScheduleContent] = useState<boolean>(null);
  const [displayState, setDisplayState] =
    useState<ValueOfCostEstimateDisplayStates>(
      COST_ESTIMATE_DISPLAY_STATES.LOADING,
    );
  const [updatedInitialStartTime, setUpdatedInitialStartTime] =
    useState<string>("");
  const [multiSessionAppointments, setMultiSessionAppointments] = useState<
    string[]
  >([]);
  const { t } = useTranslation("costEstimate");
  const {
    cnAppointmentData,
    mmAppointmentData,
    member,
    wrappedMemberInsuranceData,
    medium,
    initialStartTime,
    customerSite,
    upcomingAppointmentSlotsLoading,
    isLoading,
  } = useScheduleModalData({ providerRole, kind, provider: currentProvider });
  const { routeTeenToInitialAssessment } = useRouteTeenToInitialAssessment(
    member,
    kind,
  );

  const isPayerPersona = member?.cohort?.customer?.is_health_plan;
  const payerAndGatedBooking = isPayerPersona && isGatedBookingEnabled;
  const isEligibleForInsuranceValidation =
    wrappedMemberInsuranceData?.user?.member?.eligible_for_insurance_validation;

  const isCCAProviderListLoaded =
    customerSite?.care_providers?.length !== undefined;

  const providerIsLoading =
    isLoading ||
    (provider && !currentProvider) ||
    (!isCCAProviderListLoaded && providerRole === "Care Advocate");

  useEffect(() => {
    setCurrentProvider(provider);
  }, [provider]);

  const handleCCANext = () => {
    setCurrentProvider((currentProvider) => {
      const careProviderIndices =
        customerSite?.care_providers.map((provider) => provider.id) || [];
      const currentIndex = careProviderIndices.indexOf(currentProvider?.id);
      const length = customerSite?.care_providers.length;
      const nextIndex = (currentIndex + 1) % length;
      if (!customerSite || customerSite.care_providers.length === 0) {
        return null;
      }
      return customerSite?.care_providers[nextIndex];
    });
  };

  const errorLoadingProvider =
    !providerIsLoading && isScheduleModalOpen && !currentProvider;

  useModalTracking(
    currentProvider,
    kind,
    medium,
    telemetryProps,
    isScheduleModalOpen,
    providerIsLoading,
    showScheduleContent,
  );

  useEffect(() => {
    if (
      !providerIsLoading &&
      displayState === COST_ESTIMATE_DISPLAY_STATES.LOADING
    ) {
      setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
    }
  }, [providerIsLoading, displayState]);

  const hasTooFewSessions = useCallback(
    () => hasTwoOrFewerVisitsRemaining(member, kind),
    [kind, member],
  );

  const country: string = member?.postal_address?.country;

  const shouldShowPaymentModal = useCallback(() => {
    // if member has validated insurance and is eligible_for_insurance_validation
    // then we do not want to show the payment modal
    // we must check for isEligibleForInsuranceValidation because they may have a valid policy but may no longer
    // be eligible for insurance validation, and not have a non-valid insurance record
    const hasValidatedInsurance = Boolean(
      wrappedMemberInsuranceData?.user?.member?.validated_insurance_policy,
    );
    const hasNonValidatedInsurance = Boolean(
      wrappedMemberInsuranceData?.user?.member?.insurance_policy,
    );
    const hasElectedToSelfPay =
      wrappedMemberInsuranceData?.user?.member?.payment_preference ===
      PaymentPreference.OutOfPocket;

    if (isInsuranceValidationEnabled && isEligibleForInsuranceValidation) {
      // TODO revisit this when gated booking is enabled for all
      // 12/6/2024 - gated booking is currently not turned on for everyone so we need to check this condition;
      const showPaymentPreference =
        !isGatedBookingEnabled &&
        !(
          hasElectedToSelfPay ||
          hasValidatedInsurance ||
          hasNonValidatedInsurance
        );

      if (showPaymentPreference) {
        return true;
      }

      return false;
    }

    const hasInsuranceInfo =
      !!wrappedMemberInsuranceData?.user?.member?.insurance_policy
        ?.carrier_name;

    return (
      hasTooFewSessions() &&
      !hasInsuranceInfo &&
      !hasElectedToSelfPay &&
      shouldShowNewInsuranceModal &&
      !isGlobalUser(country) &&
      !payerAndGatedBooking
    );
  }, [
    shouldShowNewInsuranceModal,
    wrappedMemberInsuranceData,
    country,
    hasTooFewSessions,
  ]);

  useEffect(() => {
    setModalId(uuid());
  }, []);

  useEffect(() => {
    //condition for displaying payment modal for the first time
    if (!providerIsLoading) {
      setShowScheduleContent(!shouldShowPaymentModal());
    }
  }, [
    isScheduleModalOpen,
    shouldShowNewInsuranceModal,
    wrappedMemberInsuranceData,
    providerIsLoading,
    shouldShowPaymentModal,
    country,
  ]);

  const toast = useToast();
  const createToast = (text: string, status: string) => {
    toast({
      duration: null,
      containerStyle: {
        maxWidth: "100%",
        width: "80%",
      },
      render: () => (
        <SHNotification
          notification={{ text: text, status: status }}
          setNotification={() => true}
        />
      ),
    });
  };

  useEffect(() => {
    // If the modal is being opened but there's no provider
    // then show an error toast, log the error, and "close" the modal
    if (errorLoadingProvider) {
      const message = `CareProviderScheduleModalV2: provider is not defined`;

      console.error(message); // eslint-disable-line no-console
      createToast(`Error loading ${providerRole} info for scheduling`, "error");
      datadogAddError(message);
      TRACK_EVENT.ERROR(window.location.pathname, message);

      onScheduleModalClose();
    }
  }, [providerIsLoading, isScheduleModalOpen, currentProvider]);

  const onScheduleModalCloseWithTracking = () => {
    if (isCostEstimateDisplayState(displayState)) {
      trackProviderProfileModalClosed({
        modalUuid,
        type: "Insurance Details Prompt Modal",
        eventProps: getEventProps(
          provider,
          kind,
          medium,
          therapyTopSpecialtiesFlag,
        ),
      });
    } else {
      TRACK_EVENT.MODAL_CLOSED(window.location.pathname, displayState, {
        modal_id: modalUuid,
      });
    }
    setModalId(uuid());
    setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
    onScheduleModalClose();
  };

  const transitionToScheduleModal = () => {
    setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
    setShowScheduleContent(true);
  };

  const handleBackButtonClick = () =>
    setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
  const shouldShowBackButton =
    displayState === COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM ||
    displayState === COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY
      ? "on"
      : "off";

  const onBookAnotherAppointment = (nextApptStartTime) => {
    setUpdatedInitialStartTime(nextApptStartTime);
    transitionToScheduleModal();
  };

  const scheduleContentProps: CareProviderScheduleContentProps = {
    modalUUID: modalUuid,
    careProvider: currentProvider,
    memberInfo: member,
    providerRole,
    appointmentKind: kind,
    cnAppointments: cnAppointmentData,
    mmAppointments: mmAppointmentData,
    action,
    buttonText,
    dataCy,
    appointmentId,
    providerOrder,
    queryRequestId,
    pageNumber,
    previousReasonForScheduling,
    medium,
    initialStartTime: updatedInitialStartTime || initialStartTime,
    customerSite,
    displayState,
    setDisplayState,
    defaultTab,
    handleCCANext,
    currentProvider,
    upcomingAppointmentSlotsLoading,
    selectedKind,
    setSelectedKind,
    openModal,
    onScheduleModalClose,
    scheduleModalWithPropsV2,
    getButtonText,
    isMultiRole,
    multiRoleSupportedAppointmentKinds,
    customRoleName,
    setMultiSessionAppointments,
    routeTeenToInitialAssessment,
    showMultiRoleTypeSelector,
  };

  const isCancellationDisplay =
    displayState === COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY;

  // TODO it is an a11y violation to have a heading without content (ModalHeader needs a title)
  // but for the scheduling view we do not have a title displayed
  const modalTitle =
    !showScheduleContent || isCancellationDisplay
      ? lookupModalTitle(t, member?.auto_cancel_policy_ack_required)[
          COST_ESTIMATE_DISPLAY_STATES[displayState]
        ]
      : "";

  if (errorLoadingProvider) return null; /* Needs to be after all the hooks */
  return (
    <>
      {!providerIsLoading && (
        <ModalSheet
          isOpen={isScheduleModalOpen}
          onClose={onScheduleModalCloseWithTracking}
          placement={getModalPlacement(
            isMobile,
            displayState,
            showScheduleContent,
          )}
          size={getModalSize(displayState, isMobile)}
          title={modalTitle}
          onBack={handleBackButtonClick}
          backButton={shouldShowBackButton}
        >
          {displayState ===
          COST_ESTIMATE_DISPLAY_STATES.MULTI_SESSION_BOOKING ? (
            <MultiSessionBooking
              onBookAnotherAppointment={onBookAnotherAppointment}
              memberId={member.id}
              multiSessionAppointments={multiSessionAppointments}
              routeTeenToInitialAssessment={routeTeenToInitialAssessment}
            />
          ) : showScheduleContent ? (
            <Box
              maxWidth="728px"
              minWidth={getMinWidth(isMobile, displayState, isDesktop)}
              mx="auto"
            >
              <CareProviderScheduleContent {...scheduleContentProps} />
            </Box>
          ) : (
            <PaymentInfoUpdateWrapper
              closeModal={transitionToScheduleModal}
              displayState={displayState}
              setDisplayState={setDisplayState}
              wrappedMemberInsuranceData={wrappedMemberInsuranceData}
              isMemberInsuranceLoading={providerIsLoading}
            />
          )}
        </ModalSheet>
      )}
    </>
  );
};
