import { isCareConsultantRole } from "@spring/constants";
import { getIsWPCCustomer } from "utils/employerServicesHelpers";

export const useIsMultiRole = (
  providerRoles: string | string[],
  customerId: string,
  appointmentKind?: string,
) => {
  const isRelevantAppointmentKind =
    !appointmentKind || // If no appointmentKind, skip this check
    appointmentKind === "MANAGER_CONSULTATION_AND_SUPPORT" ||
    appointmentKind === "INDIVIDUAL_SOLUTIONS_FOCUSED_CONSULTATION";

  const isMultiRole =
    isRelevantAppointmentKind &&
    !isCareConsultantRole(providerRoles) &&
    getIsWPCCustomer(customerId);

  return isMultiRole;
};
