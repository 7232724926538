import {
  BoeingTestID,
  BoeingCustomerID,
  TargetCustomerID,
  DeltaCustomerID,
  DeltaTestID,
  TargetTestID,
} from "constants/customers";
import { BoeingSDOHZendeskForm, TargetSDOHZendeskForm } from "constants/links";
import {
  VRelationshipsDuotoneSpot,
  VOutpatientCareDuotoneSpot,
} from "@springcare/verdant-icons-react";

const ccaCustomerIds = [
  DeltaCustomerID,
  DeltaTestID,
  TargetCustomerID,
  TargetTestID,
];
const wpmhcCustomerIds = [
  BoeingCustomerID,
  BoeingTestID,
  TargetCustomerID,
  TargetTestID,
];
const wpcCustomerIds = [DeltaCustomerID, DeltaTestID];
const customersHaveBadge = [
  TargetCustomerID,
  TargetTestID,
  DeltaCustomerID,
  DeltaTestID,
];
const showParaFiveCustomers = [TargetCustomerID, TargetTestID];
const showAvailabilityCustomers = [BoeingCustomerID, BoeingTestID];

const PROPS_MAP = {
  [BoeingTestID]: {
    formLinkWPMHC: BoeingSDOHZendeskForm,
    heroIllustration: VRelationshipsDuotoneSpot,
    showHeaderIcon: false,
  },
  [BoeingCustomerID]: {
    formLinkWPMHC: BoeingSDOHZendeskForm,
    heroIllustration: VRelationshipsDuotoneSpot,
    showHeaderIcon: false,
  },
  [TargetCustomerID]: {
    formLinkWPMHC: TargetSDOHZendeskForm,
    heroIllustration: VRelationshipsDuotoneSpot,
    showHeaderIcon: true,
  },
  [TargetTestID]: {
    formLinkWPMHC: TargetSDOHZendeskForm,
    heroIllustration: VRelationshipsDuotoneSpot,
    showHeaderIcon: true,
  },
};

export const getFormLinkWPMHC = (customerId) => {
  let formLinkWPMHC;
  if (wpmhcCustomerIds.includes(customerId)) {
    formLinkWPMHC = PROPS_MAP[customerId].formLinkWPMHC;
  } else {
    formLinkWPMHC = null;
  }
  return formLinkWPMHC;
};
//test should have a test case for every customer we are checking to make sure it is returning the correct form

export const getHeroIllustration = (customerId) => {
  let heroIllustration;
  if (wpmhcCustomerIds.includes(customerId)) {
    heroIllustration = PROPS_MAP[customerId].heroIllustration;
  } else {
    heroIllustration = VOutpatientCareDuotoneSpot;
  }
  return heroIllustration;
};

export const shouldShowHeaderIcontus = (customerId) => {
  let headerIconExists;
  if (wpmhcCustomerIds.includes(customerId)) {
    headerIconExists = PROPS_MAP[customerId].showHeaderIcon;
  } else {
    headerIconExists = true;
  }
  return headerIconExists;
};

export const getIsUniqueWPMCHCopy = (customerId) => {
  return wpmhcCustomerIds.includes(customerId);
};

export const getIsUniqueWPCCopy = (customerId) => {
  return wpcCustomerIds.includes(customerId);
};

export const getIsUniqueCCACopy = (customerId) => {
  return ccaCustomerIds.includes(customerId);
};

export const getBadgeExists = (customerId) => {
  return customersHaveBadge.includes(customerId);
};

export const getParaFiveExists = (customerId) => {
  return showParaFiveCustomers.includes(customerId);
};

export const getAvailabilityExists = (customerId) => {
  return showAvailabilityCustomers.includes(customerId);
};

export const getIsWPCCustomer = (customerId) => {
  return wpcCustomerIds.includes(customerId);
};
