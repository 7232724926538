import { useState } from "react";
import { AppointmentKind } from "@spring/constants";
import {
  hasHadMedManagerAppt,
  hasHadSpecialtyCareNavigationAppointment,
} from "utils/providers/";

export const useProviderApptKind = ({
  appointmentKindFromProps,
  careProvider,
  cnAppointments,
  mmAppointments,
  scnAppointments,
  isAMinor,
}) => {
  const determineAppointmentKind = () => {
    const isSpecialtyCareNavigationAppointment =
      appointmentKindFromProps ===
        AppointmentKind.InitialSpecialtyCareNavigation ||
      appointmentKindFromProps ===
        AppointmentKind.FollowUpSpecialtyCareNavigation;

    if (appointmentKindFromProps === AppointmentKind.InitialCareNavigation) {
      if (cnAppointments?.length > 0) {
        return AppointmentKind.FollowUpCareNavigation;
      }
      return AppointmentKind.InitialCareNavigation;
    }

    if (
      appointmentKindFromProps ===
        AppointmentKind.InitialMedicationManagement ||
      appointmentKindFromProps === AppointmentKind.FollowUpMedicationManagement
    ) {
      if (hasHadMedManagerAppt(careProvider, mmAppointments)) {
        return AppointmentKind.FollowUpMedicationManagement;
      }
      return AppointmentKind.InitialMedicationManagement;
    }

    if (appointmentKindFromProps === AppointmentKind.Therapy && isAMinor) {
      return AppointmentKind.MinorTherapy;
    }

    if (isSpecialtyCareNavigationAppointment) {
      return hasHadSpecialtyCareNavigationAppointment(
        careProvider,
        scnAppointments,
      )
        ? AppointmentKind.FollowUpSpecialtyCareNavigation
        : AppointmentKind.InitialSpecialtyCareNavigation;
    }

    return appointmentKindFromProps;
  };

  const [selectedKind, setSelectedKind] = useState(determineAppointmentKind);

  const handleSelectedApptKind = (newKind) => {
    setSelectedKind(newKind || determineAppointmentKind());
  };

  return {
    selectedKind,
    handleSelectedApptKind,
  };
};
