/* eslint-disable react/no-children-prop */
/**
 * NOTE: @param are props
 * NOTE: RHF refers to the react-hook-form library
 * @param {number}   [fcMb]         margin-bottom for <FormControl>
 * @param {required} [required]     Used w/ 'errors' to display <FormErrorMessage> if true, and 'errors' true
 * @param {object}   errors         Passed from RHF - will be passed if input has error
 * @param {string}   name           Important - this the key for the input value that gets Submitted
 * @param {node}     label          Text value or JSX element for <FormLabel>
 * @param {function} register       Important - RHF function that registers the key (i.e 'name' prop)
 * @param {object}   validation     Important - this object sets validation used by RHF, pass false if not needed
 * @param {string}   inputType      String to set HTML input type, use standard HTML input values (i.e text, number etc)
 * @param {string}   autoComplete   String to set HTML autocomplete tag, use standard HTML autocomplete values
 * @param {boolean}  [disabled]     Set input to disabled
 * @param {string}  [placeholder]   Set placeholder to the input
 * @param {string}  textTransform   Set textTransform to the input
 * @param {string}  background      Set background color of input
 * @param {boolean}   spinner       Show spinner or use as normal input without spinner
 * @param {boolean}  showCheck      Set when Check icon is shown
 * @param {boolean}  fontWeight     Sets fontWeight of label
 * @param {boolean}  fontSize       Sets fontSize of label
 * @param {string}     color        Sets color of text shown in input
 * @param {bool}     loading        Bool to set if spinner/check is shown
 * @param {string}   helpText       String for help text displayed
 * @param {string}   helpTextColor  Sets color for helpText
 * @param {string}   ariaLabel      Sets aria-label
 * @param {string}   [dataCy]       Sets data-cy for cypress tests
 * @param {string}   [minYear]      Sets min year for input
 * @param {bool}     [autoFocus]    Sets autoFocus for input (default false)
 */

import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Flex,
  Spinner,
  Text,
} from "design-system/components/index";
import { Input } from "@springcare/sh-component-library";
import { Check } from "design-system/assets";

export const SHInputWithLoadingState = ({
  fcMb,
  errors,
  name,
  label,
  register,
  validation,
  inputType,
  autoComplete,
  disabled,
  placeholder,
  dataCy,
  fontWeight,
  fontSize,
  showCheck,
  spinner,
  background = null,
  color = null,
  textTransform = null,
  helpText,
  loading,
  ariaLabel,
  minYear,
  helpTextColor,
  autoFocus,
  testId,
  required = false,
  inputMode = "text",
  maxLength = null,
}) => {
  return (
    <FormControl mb={fcMb} isInvalid={errors} isRequired={required}>
      {label && (
        <FormLabel
          fontSize={fontSize}
          fontWeight={fontWeight}
          htmlFor={name}
          requiredIndicator={
            required ? (
              <span style={{ marginLeft: "4px" }} title="required field">
                *
              </span>
            ) : null
          }
        >
          {label}
        </FormLabel>
      )}

      {!spinner && (
        <Input
          {...register(name, validation)}
          pl={14}
          id={name}
          type={inputType}
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={disabled}
          data-cy={dataCy}
          data-testid={testId}
          aria-label={ariaLabel}
          min={minYear}
          autoFocus={autoFocus}
          required={required}
          isInvalid={errors}
          background={background}
          color={color}
          fontWeight={fontWeight}
          textTransform={textTransform}
          inputMode={inputMode}
          maxLength={maxLength}
        />
      )}

      {spinner && (
        <InputGroup>
          <Input
            {...register(name, { ...validation })}
            pl={14}
            id={name}
            type={inputType}
            placeholder={placeholder}
            autoComplete={autoComplete}
            disabled={disabled}
            data-cy={dataCy}
            data-testid={testId}
            aria-label={ariaLabel}
            min={minYear}
            autoFocus={autoFocus}
            required={required}
            isInvalid={errors}
            background={background}
            color={color}
            textTransform={textTransform}
            fontWeight={fontWeight}
            inputMode={inputMode}
          />
          <InputRightElement>
            {loading && (
              <Flex
                w={32}
                height={32}
                bg="platform.200"
                borderRadius="6px"
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Spinner color="black" />
              </Flex>
            )}
            {!loading && showCheck && (
              <Flex
                w={32}
                height={32}
                bg="platform.200"
                borderRadius="6px"
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Check color="tertiary.light" />
              </Flex>
            )}
          </InputRightElement>
        </InputGroup>
      )}
      {errors && (
        <FormErrorMessage color="error.base" fontWeight="bold">
          {errors?.message}
        </FormErrorMessage>
      )}
      {helpText && (
        <Text color={helpTextColor} mt={8} fontSize={16} fontWeight={400}>
          {helpText}
        </Text>
      )}
    </FormControl>
  );
};

SHInputWithLoadingState.propTypes = {
  fcMb: PropTypes.number,
  required: PropTypes.bool,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  register: PropTypes.func.isRequired,
  validation: PropTypes.object,
  inputType: PropTypes.string.isRequired,
  autoComplete: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  spinner: PropTypes.bool,
  showCheck: PropTypes.bool,
  loading: PropTypes.bool,
  helpText: PropTypes.string,
  helpTextColor: PropTypes.string,
  ariaLabel: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minYear: PropTypes.string,
  autoFocus: PropTypes.bool,
  testId: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  textTransform: PropTypes.string,
  pl: PropTypes.number,
  inputMode: PropTypes.string,
  maxLength: PropTypes.number,
};
