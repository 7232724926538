import { createContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import { getOr } from "lodash/fp";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { CarePage } from "components/templates/CareVisitsPage/CarePage";
import CareTeamFooter from "components/templates/CareVisitsPage/components/Tabs/components/CareTeamFooter";

import { MemberDashboardPageWrapper } from "components";

import { getUserInfo } from "operations/queries/user";
import { isMinor } from "utils/memberHelpers";
import { formatMemberExactAge } from "utils/displayHelpers";
import { TRACK_EVENT } from "utils/mixpanel";
import { connect } from "react-redux";
import { compose } from "redux";
import { getMemberInfo } from "operations/queries/member";
import { getCareTeam } from "operations/queries/careProvider";
import { getAppointmentsWithProviderDetails } from "operations/queries/appointment";
import {
  isFutureAppointment,
  isPastAppointment,
} from "utils/schedulingHelpers";
import { Box, useDisclosure } from "@chakra-ui/react";
import CareNavigatorAvailabilityModal from "components/modals/CareNavigatorScheduleModal";
import {
  AccountTransitionBanner,
  UpcomingChangesBanner,
} from "modules/MemberDashboard/Home/components/AgingOut/components";
import { useMemberBenefits } from "hooks/useMemberBenefits";
import { isCNScheduling } from "utils/global";
import { returnAllSessionCounts } from "modules/shared/utils";
import { Member } from "modules/shared/graphql-codegen/graphql";
import { RecursivePartial } from "shared/types/recursivePartial";

type MemberInfo = RecursivePartial<Member>;

export const CareContextValues = createContext<{
  pastAppointmentsData: unknown[];
  setPastAppointmentsData?: React.Dispatch<React.SetStateAction<unknown[]>>;
  upcomingAppointmentsData: unknown[];
  setUpcomingAppointmentsData?: React.Dispatch<React.SetStateAction<unknown[]>>;
  isMemberUnderSix: unknown;
  setIsMemberUnderSix?: React.Dispatch<React.SetStateAction<unknown>>;
  isAMinor: boolean;
  setIsAMinor?: React.Dispatch<React.SetStateAction<unknown>>;
  memberCoachingDetails: unknown;
  setMemberCoachingDetails?: React.Dispatch<React.SetStateAction<unknown>>;
  careTeamInfo: unknown;
  setCareTeamInfo?: React.Dispatch<React.SetStateAction<unknown>>;
  therapistEmail?: unknown;
  setTherapistEmail?: React.Dispatch<React.SetStateAction<unknown>>;
  coachEmail?: unknown;
  setCoachEmail?: React.Dispatch<React.SetStateAction<unknown>>;
  showSUDCopy: unknown;
  setShowSUDCopy?: React.Dispatch<React.SetStateAction<unknown>>;
  isFindNewCareTab: unknown;
  setIsFindNewCareTab: React.Dispatch<React.SetStateAction<unknown>>;
  memberTherapyDetails: unknown;
  setMemberTherapyDetails?: React.Dispatch<React.SetStateAction<unknown>>;
  mpCareTeamList?: unknown;
  setCareTeamList: React.Dispatch<React.SetStateAction<unknown>>;
  showGlobalExperience: unknown;
  memberInfo: MemberInfo;
  setMemberInfo?: React.Dispatch<React.SetStateAction<MemberInfo>>;
  hasAppointment?: unknown;
  setHasAppointment: React.Dispatch<React.SetStateAction<unknown>>;
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  determinedInitialTab: boolean;
  setDeterminedInitialTab: React.Dispatch<React.SetStateAction<boolean>>;
  trackedEmptyCareCardOnce: boolean;
  setTrackedEmptyCareCardOnce: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowCoaching: unknown;
  setShouldShowCoaching?: React.Dispatch<React.SetStateAction<unknown>>;
  allowDirectScheduling: unknown;
  setAllowDirectScheduling?: React.Dispatch<React.SetStateAction<unknown>>;
  specializedCNEnabled: unknown;
  setSpecializedCNEnabled?: React.Dispatch<React.SetStateAction<unknown>>;
  isPrivatePractice: unknown;
  setIsPrivatePractice?: React.Dispatch<React.SetStateAction<unknown>>;
  isCoachingEnabled: boolean;
}>({
  pastAppointmentsData: [],
  upcomingAppointmentsData: [],
  isMemberUnderSix: null,
  setIsMemberUnderSix: () => {},
  isAMinor: null,
  setIsAMinor: () => {},
  memberCoachingDetails: {},
  careTeamInfo: {},
  showSUDCopy: null,
  isFindNewCareTab: null,
  setIsFindNewCareTab: (_isFindNewCareTab) => {},
  memberTherapyDetails: {},
  mpCareTeamList: undefined,
  setCareTeamList: () => {},
  showGlobalExperience: undefined,
  memberInfo: undefined,
  hasAppointment: undefined,
  setHasAppointment: () => {},
  currentTab: 0,
  setCurrentTab: (tabIndex: number) => {},
  determinedInitialTab: false,
  setDeterminedInitialTab: () => {},
  trackedEmptyCareCardOnce: false,
  setTrackedEmptyCareCardOnce: () => {},
  shouldShowCoaching: undefined,
  allowDirectScheduling: undefined,
  specializedCNEnabled: undefined,
  isPrivatePractice: undefined,
  setIsPrivatePractice: () => {},
  setMemberInfo: (value: any) => {},
  isCoachingEnabled: false,
});

const CareVisits = ({ showGlobalExperience }) => {
  const router = useRouter();
  const [mpCareTeamList, setCareTeamList] = useState(undefined);
  const [hasAppointment, setHasAppointment] = useState(undefined);
  const [hasTracked, setHasTracked] = useState(false);
  const [pastAppointmentsData, setPastAppointmentsData] = useState([]);
  const [upcomingAppointmentsData, setUpcomingAppointmentsData] = useState([]);
  const [isMemberUnderSix, setIsMemberUnderSix] = useState(undefined);
  const [isAMinor, setIsAMinor] = useState(undefined);
  const [memberCoachingDetails, setMemberCoachingDetails] = useState({});
  const [memberTherapyDetails, setMemberTherapyDetails] = useState({});
  const [isFindNewCareTab, setIsFindNewCareTab] = useState(false);
  const [showSUDCopy, setShowSUDCopy] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [isPrivatePractice, setIsPrivatePractice] = useState(false);
  //for A/A test -  not leveraging flag value here, just need to evaluate it.
  useFeatureFlag(FLAGS.LD_EXPERIMENTATION_POC);

  const [determinedInitialTab, setDeterminedInitialTab] = useState(false);
  const [trackedEmptyCareCardOnce, setTrackedEmptyCareCardOnce] =
    useState(false);

  const {
    isOpen: isCNScheduleModalOpen,
    onOpen: openCNScheduleModal,
    onClose: closeCNScheduleModal,
  } = useDisclosure();

  const { data: userData, loading: userLoading } = useQuery(getUserInfo, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });

  const { data: memberData, loading: memberInfoLoading } = useQuery(
    getMemberInfo,
    {
      ...Meowth.apolloOptionsUserId(),
      skip: Meowth.apolloSkipUserId(),
    },
  );

  const {
    allowDirectScheduling,
    specializedCNEnabled,
    shouldShowCoaching,
    isCoachingEnabled,
  } = useMemberBenefits(memberData?.user?.member?.id);

  const { data: careTeamData, loading: careTeamInfoLoading } = useQuery(
    getCareTeam,
    {
      ...Meowth.apolloOptionsUserId(),
      skip: Meowth.apolloSkipUserId(),
    },
  );

  const { data: appointmentsData, loading: appointmentsLoading } = useQuery(
    getAppointmentsWithProviderDetails,
    {
      skip: Meowth.apolloSkipUserId(),
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: {
        booking_user_id: Meowth.getUserId(),
        sort_by: [
          {
            column: "appointments.start_at",
            direction: "desc",
          },
        ],
      },
    },
  );

  const initialTab = (isCnScheduling, hasAppointments): number => {
    if (router?.query?.resources) {
      return 2;
    }
    if (isCnScheduling || (hasAppointments && !router.query.findNewCare)) {
      return 0;
    }
    return 1;
  };

  const determineInitialTab = () => {
    const hasAppointments = appointmentsData?.appointments?.data?.length;
    const isCnScheduling = isCNScheduling(
      memberData?.user?.member?.cohort?.contract_term?.scheduling_access?.name,
    );
    setCurrentTab(initialTab(isCnScheduling, hasAppointments));
    setDeterminedInitialTab(true);
    return initialTab(isCnScheduling, hasAppointments);
  };

  useEffect(() => {
    if (!determinedInitialTab && !appointmentsLoading && !memberInfoLoading) {
      determineInitialTab();
    }
  }, [appointmentsLoading, memberInfoLoading]);

  const getTrackingName = () => {
    if (determineInitialTab() === 0) {
      return "Your Care Tab";
    }
    if (determineInitialTab() === 1) {
      return "Find New Care Tab";
    }
  };

  useEffect(() => {
    setShowSUDCopy(memberData?.user?.member?.sud_benefit?.enabled);
  }, [memberData?.user?.member?.sud_benefit?.enabled]);

  useEffect(() => {
    setIsPrivatePractice(memberData?.user?.member?.is_private_practice);
  }, [memberData?.user?.member?.is_private_practice]);

  useEffect(() => {
    if (
      !hasTracked &&
      mpCareTeamList !== undefined &&
      hasAppointment !== undefined &&
      !appointmentsLoading
    ) {
      TRACK_EVENT.PAGE_VERSION_VIEWED(
        window.location.pathname,
        getTrackingName(),
        {
          has_upcoming_appointment: hasAppointment,
          //TODO: this is only updated when user scrolls, leaving the tracked data on page load empty
          care_team_members: mpCareTeamList,
        },
      );
      setHasTracked(true);
    }
  }, [mpCareTeamList, hasAppointment, hasTracked, appointmentsLoading]);

  useEffect(() => {
    setIsMemberUnderSix(
      formatMemberExactAge(userData?.user?.date_of_birth) < 6,
    );
    setIsAMinor(isMinor(getOr({}, "user.member", userData)));
  }, [userData]);

  useEffect(() => {
    const therapistEmail = getOr(
      {},
      "user.member.care_team.therapist.email",
      careTeamData,
    );
    const coachEmail = getOr(
      {},
      "user.member.care_team.coach.email",
      careTeamData,
    );
    const {
      coveredCoaching: coachingCoveredCount,
      usedCoaching: coachingUsedCount,
      remainingCoaching: coachingRemainingCount,
      coveredTherapy: therapyCoveredCount,
      usedTherapy: therapyUsedCount,
      remainingTherapy: therapyRemainingCount,
    } = returnAllSessionCounts(memberData?.user?.member);
    setMemberCoachingDetails({
      coachingCoveredCount,
      coachingUsedCount,
      coachingRemainingCount,
      coachEmail,
      isGlobalMemberWithNoCoachingVisitsRemaining:
        showGlobalExperience &&
        coachingRemainingCount === 0 &&
        coachingCoveredCount >= 0,
    });
    setMemberTherapyDetails({
      therapyCoveredCount,
      therapyUsedCount,
      therapyRemainingCount,
      therapistEmail,
      isGlobalMemberWithNoVisitsRemaining:
        showGlobalExperience &&
        therapyRemainingCount === 0 &&
        therapyCoveredCount >= 0,
    });
  }, [memberData, careTeamData, showGlobalExperience]);

  useEffect(() => {
    let pastAppointmentsDataArray = [];
    let upcomingAppointmentsDataArray = [];
    if (!appointmentsLoading && appointmentsData) {
      pastAppointmentsDataArray = appointmentsData.appointments.data.filter(
        (appointment) => {
          return isPastAppointment(appointment);
        },
      );
      setPastAppointmentsData(pastAppointmentsDataArray);

      // upcomingAppointmentsData should be sorted asc.
      upcomingAppointmentsDataArray = appointmentsData.appointments.data
        .filter((appointment) => {
          return isFutureAppointment(appointment);
        })
        .sort(
          (a, b) =>
            new Date(a.start_at).getUTCMilliseconds() -
            new Date(b.start_at).getUTCMilliseconds(),
        );
      setUpcomingAppointmentsData(upcomingAppointmentsDataArray);
    }
  }, [appointmentsLoading, appointmentsData]);

  if (userLoading || memberInfoLoading || careTeamInfoLoading) {
    return null;
  }

  const careContext = {
    pastAppointmentsData,
    upcomingAppointmentsData,
    isAMinor,
    isMemberUnderSix,
    memberCoachingDetails,
    careTeamInfo: careTeamData,
    memberTherapyDetails,
    setCareTeamList,
    showSUDCopy,
    isFindNewCareTab,
    setIsFindNewCareTab,
    showGlobalExperience,
    memberInfo: memberData,
    setHasAppointment,
    currentTab,
    setCurrentTab,
    determinedInitialTab,
    setDeterminedInitialTab,
    trackedEmptyCareCardOnce,
    setTrackedEmptyCareCardOnce,
    openCNScheduleModal,
    isCNScheduleModalOpen,
    shouldShowCoaching,
    allowDirectScheduling,
    specializedCNEnabled,
    isPrivatePractice,
    isCoachingEnabled,
  };

  return (
    <MemberDashboardPageWrapper
      // @ts-ignore: AP these appear to be valid and I need a boundary in typescript refactoring today.
      wide
      darkBg={false}
      float={false}
      bordered={false}
      headerBackground="transparent"
      showCheckIn={false}
      shadedBackground
      showCustomFooter={true}
      removePaddingBottom={true}
      customFooter={
        <CareContextValues.Provider value={careContext}>
          <CareTeamFooter />
        </CareContextValues.Provider>
      }
    >
      {isAMinor && memberData?.user?.member.within_aging_out && (
        <Box mb="v-24" mt={-20}>
          <UpcomingChangesBanner
            agingOutRange={memberData?.user?.member.within_aging_out}
            dateOfBirth={memberData?.user?.member?.date_of_birth || ""}
          />
        </Box>
      )}

      {isAMinor && memberData?.user?.member.recently_transitioned_account && (
        <Box mb="v-24" mt={-20}>
          <AccountTransitionBanner
            accountTransitionRange={
              memberData?.user?.member.recently_transitioned_account
            }
          />
        </Box>
      )}
      <CareContextValues.Provider value={careContext}>
        <CarePage />

        <CareNavigatorAvailabilityModal
          onOpen={openCNScheduleModal}
          isOpen={isCNScheduleModalOpen}
          onClose={closeCNScheduleModal}
          showSpecializedCN={!!specializedCNEnabled}
        />
      </CareContextValues.Provider>
    </MemberDashboardPageWrapper>
  );
};

const mapStateToProps = ({ global: { showGlobalExperience } }) => ({
  showGlobalExperience,
});

export default compose(connect(mapStateToProps, null))(CareVisits);
