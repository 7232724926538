/* eslint-disable react/prop-types */
//TODO: Remove this and fix the eslint errors
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import Router from "next/router";
import { get, getOr } from "lodash/fp";
import {
  Subtitle,
  BorderPage,
  Section,
  HorizontalRule,
  Timeline,
  TimelineStep,
  Button,
  MomentsCarePlanStep,
  TryMomentsModal,
  LoadingCircle,
  TherapistIcon,
  CoachIcon,
} from "@spring/smeargle";
import Meowth from "@spring/meowth";
import { addNotification, openModal } from "@spring/smeargle/actions";
import {
  links,
  modalIds,
  AppointmentKind,
  AppointmentMedium,
  RequestableAppointmentKind,
  getAppointmentMediumForTracking,
  getAppointmentKindForTracking,
  getModalNameForTracking,
} from "@spring/constants";
import { useTranslation } from "react-i18next";
import {
  ChangeCareProviderModal,
  CareProviderScheduleModal,
  ReviewBenefitsModal,
  AvailabilityRequestModal,
} from "components/modals";
import { WhatsInYourPlan } from "components/organisms";
import {
  OffboardingStep,
  BenefitsSnapshot,
  CarePlanStep,
} from "components/molecules";

import styles from "./styles.module.scss";

import routes from "routes";
import { sendDownloadAppEmail } from "operations/mutations/general";
import { latestInitialAssessment } from "operations/selectors/member";
import {
  getMemberCarePlanProgress,
  getMemberInfo,
  getMemberGoals,
} from "operations/queries/member";
import { getAssessmentRiskLevels } from "operations/queries/assessment";
import { skipCarePlanProgressStep } from "operations/mutations/carePlan";
import { requestAppointment } from "operations/mutations/appointment";
import { generateAssessment } from "operations/mutations/assessment";
import {
  useAllUpcomingAppointmentSlots,
  useUpcomingAppointmentSlots,
  useCareTeam,
} from "hooks";
import { getFirstError } from "utils/apollo/errorHandler";

import {
  track,
  TRACK_EVENT,
  EVENT_TYPE,
  makeEventString,
} from "utils/mixpanel";
import { Heading } from "design-system/components";
import { useMemberBenefits } from "hooks/useMemberBenefits";
import { getIterableCampaignInfo } from "utils/localStorage";

const medicationRecommended = (props) => {
  const { showGlobalExperience } = props;
  //for global experience we do not show the medication management
  if (!showGlobalExperience) {
    const medicationRecommended = get(
      "memberInfo.user.member.chart.medication_manager_recommended",
      props,
    );

    return Boolean(medicationRecommended);
  }
  return false;
};

const benefitSnapshotData = (props) => {
  const result = [];
  const specialistVisitsCovered = getOr(
    null,
    "memberInfo.user.member.visits_covered_specialist",
    props,
  );
  const totalVisitsCovered = getOr(
    null,
    "memberInfo.user.member.visits_covered_total",
    props,
  );
  const visits_eligibility_limit_date = getOr(
    {},
    "memberInfo.user.member.visits_eligibility_limit_date",
    props,
  );
  const specialist_visits_remaining = getOr(
    null,
    "memberInfo.user.member.visits_remaining_specialist",
    props,
  );
  const total_remaining_visits = getOr(
    null,
    "memberInfo.user.member.visits_remaining_total",
    props,
  );
  const member = getOr(null, "memberInfo.user.member", props);

  if (member) {
    if (
      total_remaining_visits > 0 &&
      (totalVisitsCovered > 0 || specialistVisitsCovered > 0)
    ) {
      const therapyComputedVals = {
        total_visits_covered: totalVisitsCovered,
        specialist_visits_remaining: specialist_visits_remaining,
        end_date: DateTime.fromISO(visits_eligibility_limit_date).toFormat(
          "DDD",
        ),
        total_remaining_visits: total_remaining_visits,
      };

      result.push({
        translation: {
          ns: "carePlan",
          key: "carePlanSteps.benefitSnapshotData",
        },
        values: therapyComputedVals,
        icon: <TherapistIcon />,
      });
    }

    const { visits_covered_coaching, visits_remaining_coaching } = member;
    const { showCoachingPaidExperience } = useMemberBenefits(member?.id);

    if (showCoachingPaidExperience && visits_remaining_coaching > 0) {
      const coachingComputedVals = {
        coaching_visits_covered: visits_covered_coaching,
        end_date: DateTime.fromISO(visits_eligibility_limit_date).toFormat(
          "DDD",
        ),
        coaching_visits_remaining: visits_remaining_coaching,
      };
      result.push({
        translation: {
          ns: "carePlan",
          key: "carePlanSteps.coachingBenefitSnapshotData",
        },
        values: coachingComputedVals,
        icon: <CoachIcon />,
      });
    }
  }
  return result;
};

const CarePlanSteps = ({
  carePlanProps,
  firstTherapySlots,
  firstCNSlot,
  activeStep,
  setActiveStep,
  setTimeLineHeight,
}) => {
  const { t } = useTranslation(["carePlan", "a11y"]);
  let steps = getOr(
    [],
    "carePlan.care_plan_progress.progress_steps",
    carePlanProps,
  );
  const memberId = get("memberInfo.user.member.id", carePlanProps);
  const { showGlobalExperience } = carePlanProps;
  let timelineSteps = [];

  const provider = (path, props) => {
    const careTeam = getOr({}, "careTeam.user.member.care_team", props);
    const { showGlobalExperience } = props;
    const { care_navigator, medication_manager } = careTeam;
    const therapist = getOr({}, "therapist", props);

    let provider = {};

    if (path.includes("CareNavigation")) {
      //  all members should have a CN
      if (care_navigator) {
        provider = {
          ...care_navigator,
        };
      }
    } else if (path.includes("Therapy")) {
      if (therapist) {
        provider = {
          ...therapist,
        };
      } else {
        provider = {
          genericDescription: t("carePlanSteps.therapy.description"),
          genericImage:
            links.SpringCDN + "/assets/images/members/Therapy+Visit.png",
        };
      }
    } else if (path.includes("Medication") && !showGlobalExperience) {
      if (medication_manager) {
        provider = {
          ...medication_manager,
        };
      } else {
        provider = {
          genericDescription: t("carePlanSteps.medication.description"),
          genericImage:
            links.SpringCDN + "/assets/images/members/Medication+Managers.png",
        };
      }
    } else if (path.includes("Onward")) {
      provider = {
        genericDescription: t("carePlanSteps.onward.description"),
        genericImage: links.SpringCDN + "/assets/images/members/signature.png",
      };
    } else if (path.includes("SPRASS")) {
      provider = {
        genericDescription: t("carePlanSteps.sprass.description"),
        genericImage:
          links.SpringCDN + "/assets/images/members/Therapy+Visit.png",
      };
    }

    return provider;
  };

  const handleClickCTA = async (path, props, activeStepIndex) => {
    const onLoadStepIndex = getActiveStep(props);
    const careTeam = getOr({}, "careTeam.user.member.care_team", props);
    const { showGlobalExperience } = props;
    const { care_navigator, medication_manager, therapist } = careTeam;
    const { firstVirtualSlot } = getOr(null, "therapy", firstTherapySlots);
    const { firstInPersonSlot } = getOr(null, "therapy", firstTherapySlots);
    const memberId = get("memberInfo.user.member.id", props);

    const fallbackUpcomingAppointmentDate = DateTime.local()
      .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
      .toISO();
    if (path.includes("CareNavigation")) {
      if (care_navigator) {
        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          "Schedule Care Navigation",
          {
            to: getModalNameForTracking(modalIds.careProviderScheduleModal),
            location: "Care Plan Care Navigation",
            appointment_type: getAppointmentKindForTracking(
              AppointmentKind.InitialCareNavigation,
            ),
            appointment_medium: getAppointmentMediumForTracking(
              AppointmentMedium.Phone,
            ),
            on_load: activeStepIndex === onLoadStepIndex,
            order: activeStepIndex,
            spring_doc_id: "cnsched001",
          },
        );
        props.openModal(
          modalIds.careProviderScheduleModal,
          Object.assign({}, care_navigator, {
            kind: AppointmentKind.InitialCareNavigation,
            medium: AppointmentMedium.Phone,
            providerRole: "Care Navigator",
            buttonText: t("carePlanSteps.scheduleCareCallButtonText"),
            action: "createAppointment",
            initialStartTime:
              firstCNSlot.length > 0
                ? firstCNSlot[0]
                : fallbackUpcomingAppointmentDate,
          }),
        );
      } else {
        const { to, as } = routes.TherapistsBrowse;
        Router.replace(to, as);
      }
    } else if (path.includes("SPRASS")) {
      props
        .generateAssessment({
          variables: {
            member_id: memberId,
            kind: "SPRASS",
            campaign: getIterableCampaignInfo(),
          },
        })
        .then((res) => {
          const id = get("data.generateAssessment.assessment.id", res);
          let { to, as } = routes.TakeMemberAssessment;

          if (res.data.generateAssessment.success) {
            as = as.replace(":id", id);
            Router.replace(
              {
                pathname: to,
                query: { id },
              },
              as,
            );
          }
        });
    } else if (path.includes("Therapy")) {
      const minor = get("memberInfo.user.member.minor", props);

      if (therapist) {
        if (minor) {
          try {
            const payload = {
              care_provider_id: therapist.id,
              availability_days_of_week: ["Unknown"],
              availability_time_of_day: ["Unknown"],
              medium: AppointmentMedium.Video,
              kind: RequestableAppointmentKind.Therapy,
            };

            const {
              errors,
              data: { requestAppointment },
            } = await props.requestAppointment(payload);

            if (!requestAppointment.success) {
              return props.addNotification(getFirstError(errors), "error");
            }

            return props.addNotification(
              t("notifications.appointmentScheduleEmail"),
              "success",
            );
          } catch (err) {
            return props.addNotification(getFirstError(err), "error");
          }
        } else {
          let initialStartTime;
          let medium;

          const therapyVideoStartTime =
            firstVirtualSlot && firstVirtualSlot.appointment_slots?.available;

          const therapyInPersonStartTime =
            firstInPersonSlot && firstInPersonSlot.appointment_slots?.available;

          if (therapyInPersonStartTime === null && therapyVideoStartTime) {
            initialStartTime = therapyVideoStartTime;
            medium = AppointmentMedium.Video;
          } else if (
            therapyVideoStartTime === null &&
            therapyInPersonStartTime
          ) {
            initialStartTime = therapyInPersonStartTime;
            medium = AppointmentMedium.InPerson;
          } else {
            initialStartTime =
              therapyVideoStartTime > therapyInPersonStartTime
                ? therapyInPersonStartTime
                : therapyVideoStartTime;
            medium =
              therapyVideoStartTime > therapyInPersonStartTime
                ? AppointmentMedium.InPerson
                : AppointmentMedium.Video;
          }

          props.openModal(modalIds.careProviderScheduleModal, {
            ...therapist,
            kind: AppointmentKind.Therapy,
            medium: medium,
            providerRole: "Therapist",
            buttonText: t("carePlanSteps.confirmAppointment"),
            action: "createAppointment",
            initialStartTime: initialStartTime[0],
          });
        }
      } else {
        const { to, as } = routes.TherapistsBrowse;
        Router.replace(to, as);
      }
    } else if (path.includes("Medication") && !showGlobalExperience) {
      if (medication_manager) {
        try {
          const payload = {
            care_provider_id: medication_manager.id,
            availability_days_of_week: ["Unknown"],
            availability_time_of_day: ["Unknown"],
            medium: AppointmentMedium.Video,
            kind: RequestableAppointmentKind.MedicationManagement,
          };

          const {
            errors,
            data: { requestAppointment },
          } = await props.requestAppointment(payload);

          if (!requestAppointment.success) {
            return props.addNotification(getFirstError(errors), "error");
          }

          return props.addNotification(
            t("notifications.appointmentScheduleEmail"),
            "success",
          );
        } catch (err) {
          return props.addNotification(getFirstError(err), "error");
        }
      } else {
        const { to, as } = routes.Medication_ManagersBrowse;
        Router.push(to, as);
      }
    } else if (path.includes("Previsit")) {
      const { to, as } = routes.PrevisitForm;
      Router.push(to, as);
    }
  };

  const actions = (step, props, activeStepIndex) => {
    if (!step.cta_path.includes("Onward")) {
      return [
        {
          text: step.call_to_action,
          onClick: () => {
            track("Care Plan Step", {
              deprecated: true,
              replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
                page: window.location.pathname,
                type: "Schedule Therapy",
                "Care Plan Kind": step.cta_path,
                "Care Plan External Name": step.external_name,
                "Care Plan Internal Name": step.internal_name,
              }),
            });
            const { as } = routes.TherapistsBrowse;
            TRACK_EVENT.BUTTON_CLICKED(
              window.location.pathname,
              "Schedule Therapy",
              {
                spring_doc_id: "therapy003",
                location: "Care Plan Therapy",
                to: as,
              },
            );

            handleClickCTA(step.cta_path, props, activeStepIndex);
          },
        },
      ];
    }

    return [];
  };

  const momentsActions = (step_id, props, setActiveStep) => {
    return [
      {
        text: t("carePlanSteps.moments.selfGuidedBtnText"),
        icon: "arrow-right",
        onClick: () => {
          const { to, as } = routes.MemberMoments;
          Router.push(to, as).then(() => window && window.scrollTo(0, 0));
        },
      },
      {
        text: t("carePlanSteps.moments.moveOnBtnText"),
        reverse: true,
        onClick: () => {
          props
            .skipCarePlanProgressStep({
              variables: { care_plan_progress_step_id: step_id },
            })
            .then((success) => {
              if (success) {
                setActiveStep(getActiveStep(props));
              }
            });
        },
      },
    ];
  };

  const momentsLearnMoreLink = () => {
    return {
      text: t("carePlanSteps.moments.learnMoreLinkText"),
      onClick: () => {
        track("Moments Pathway - Care Plan step - Learn More");
        const tabId = 1;
        const { to, as } = routes.MemberBenefits;

        Router.replace(
          {
            pathname: to,
            query: { tabId },
          },
          as,
        );
      },
    };
  };

  if (steps) {
    // for global we do not show the medication management
    if (showGlobalExperience) {
      steps = steps.filter(
        (step) =>
          !step.cta_path.includes("SPRASS") &&
          !step.cta_path.includes("Medication"),
      );
    }
    timelineSteps = steps.map((step, idx) => {
      let current_step;
      let active = step.completed;
      let completed = step.completed;

      if (step.cta_path.includes("Offboarding")) {
        current_step = <OffboardingStep memberId={memberId} />;
      } else if (step.cta_path.includes("MomentsCarePlanStep")) {
        active = false;
        completed = false;
        current_step = (
          <MomentsCarePlanStep
            index={idx}
            title={step.external_name}
            description={step.description}
            firstActiveStep={activeStep}
            actions={momentsActions(step.id, carePlanProps, setActiveStep)}
            learnMoreLink={momentsLearnMoreLink}
            memberGoals={memberGoals(carePlanProps)}
          />
        );
      } else if (step.cta_path.includes("Therapy")) {
        const copy = step.alt_copy;

        current_step = (
          <CarePlanStep
            index={idx}
            firstActiveStep={activeStep}
            kind={step.cta_path}
            title={step.external_name}
            description={step.description}
            actions={actions(step, carePlanProps, idx)}
            provider={provider(step.cta_path, carePlanProps)}
            footer={copy}
          />
        );
      } else {
        current_step = (
          <CarePlanStep
            index={idx}
            firstActiveStep={activeStep}
            kind={step.cta_path}
            title={step.external_name}
            description={step.description}
            actions={actions(step, carePlanProps, idx)}
            provider={provider(step.cta_path, carePlanProps)}
            footer={step.alt_copy}
          />
        );
      }
      return (
        <TimelineStep
          key={step.id}
          index={idx}
          firstActiveStep={activeStep}
          active={active}
          completed={completed}
          ctaPath={step.cta_path}
          steps={steps}
          timelineName={step.timeline_name}
          checkIconAriaLabel={t("icons.journeyCheckAlt", {
            ns: "a11y",
            care_plan_step: step.timeline_name,
          })}
        >
          {current_step}
        </TimelineStep>
      );
    });
  }

  if (typeof activeStep === "number") {
    return (
      <Timeline
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setTimeLineHeight={setTimeLineHeight}
      >
        {timelineSteps}
      </Timeline>
    );
  }

  return null;
};

CarePlanSteps.propTypes = {
  activeStep: PropTypes.any,
  carePlanProps: PropTypes.shape({
    showGlobalExperience: PropTypes.any,
  }),
  firstCNSlot: PropTypes.shape({
    length: PropTypes.number,
  }),
  firstTherapySlots: PropTypes.any,
  setActiveStep: PropTypes.func,
  setTimeLineHeight: PropTypes.any,
};

const getActiveStep = (props) => {
  const steps = getOr([], "carePlan.care_plan_progress.progress_steps", props);
  let activeStep = steps.length - 1; // safety check and auto-assign activeStep to last step for cases when all steps are completed
  if (steps) {
    for (let i = 0; i < steps.length; i++) {
      if (!steps[i].completed && !steps[i].skipped) {
        activeStep = i; // if step is incomplete, override activeStep
        break;
      }
    }
  }
  return activeStep;
};

const CarePlanComponent = ({
  carePlanProps,
  firstTherapySlots,
  firstCNSlot,
  activeStep,
  setActiveStep,
  setTimeLineHeight,
  timeLineRef,
}) => {
  const steps = getOr(
    [],
    "carePlan.care_plan_progress.progress_steps",
    carePlanProps,
  );
  if (steps.length > 1) {
    return (
      <Section size="md">
        <div className={styles.carePlan}>
          <div className={styles.contentWrapper} ref={timeLineRef}>
            <CarePlanSteps
              firstTherapySlots={firstTherapySlots}
              firstCNSlot={firstCNSlot}
              carePlanProps={carePlanProps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setTimeLineHeight={setTimeLineHeight}
            />
          </div>
        </div>
      </Section>
    );
  }

  return (
    <Section size="xlg">
      <HorizontalRule />
    </Section>
  );
};

CarePlanComponent.propTypes = {
  activeStep: PropTypes.any,
  carePlanProps: PropTypes.any,
  firstCNSlot: PropTypes.any,
  firstTherapySlots: PropTypes.any,
  setActiveStep: PropTypes.any,
  setTimeLineHeight: PropTypes.any,
  timeLineRef: PropTypes.any,
};

const ReviewBenefits = ({ props }) => {
  const { t } = useTranslation("benefitsSnapshot");
  const handleButtonClick = () => {
    if (props.showGlobalExperience) {
      const { to, as } = routes.MemberBenefits;
      Router.replace(to, as);
    } else {
      track("Opened Review Benefits Modal");
      props.openModal(modalIds.reviewBenefitsModal);
    }
  };

  return (
    <div className={styles.buttonWrapper}>
      <Button
        full
        reverse
        onClick={handleButtonClick}
        text={t("carePlan.tellMeMore")}
        icon="arrow-right"
      />
    </div>
  );
};

ReviewBenefits.propTypes = {
  props: PropTypes.shape({
    openModal: PropTypes.func,
    showGlobalExperience: PropTypes.any,
  }),
};

const memberGoals = (props) => {
  return getOr([], "memberGoals.user.member.tracked_goals", props).map(
    (goal) => goal.goal.description,
  );
};

const CarePlan = (props) => {
  const { t } = useTranslation(["carePlan", "moments"]);
  const { data: careTeam } = useCareTeam();
  const [activeStep, setActiveStep] = useState(0);
  const therapist = careTeam?.user.member.care_team.therapist;
  const care_navigator = careTeam?.user.member.care_team.care_navigator;
  const { data: upcomingTherapyAppointmentSlots } =
    useAllUpcomingAppointmentSlots(
      therapist,
      RequestableAppointmentKind.Therapy,
    );
  const { data: upcomingCNAppointmentSlot } = useUpcomingAppointmentSlots(
    care_navigator,
    AppointmentKind.FollowUpCareNavigation,
    AppointmentMedium.Phone,
  );
  const sudSupported = props?.memberInfo?.user?.member?.sud_benefit?.enabled;

  const timeLineRef = useRef();
  const isCheckInTodo = document.getElementById("checkintodo");
  const setTimeLineHeight = () => {
    props.setYourPlanCurveWrapperHeight(650);
    if (timeLineRef.current) {
      setTimeout(() => {
        let marginTop = -90;
        if (timeLineRef.current.offsetHeight > 400) {
          marginTop = marginTop + timeLineRef.current.offsetHeight - 400;
        } else {
          props.setYourPlanCurveWrapperHeight(600);
        }
        if (isCheckInTodo === null) {
          if (marginTop === -90 && /iPad/i.test(navigator.userAgent)) {
            marginTop = marginTop - 245;
          } else {
            marginTop = marginTop - 175;
          }
        }
        if (/iPad/i.test(navigator.userAgent)) {
          if (isCheckInTodo !== null) {
            props.setYourPlanCurveWrapperHeight(700);
          }
          if (marginTop !== -90) {
            if (timeLineRef.current.offsetHeight > 550) {
              marginTop = marginTop + 110;
              if (isCheckInTodo !== null) {
                props.setYourPlanCurveWrapperHeight(815);
              }
            } else {
              marginTop = marginTop + 100;
            }
          } else {
            marginTop = marginTop + 100;
          }
          if (screen.width <= 1200 && screen.width > 1000) {
            marginTop = marginTop + 80;
          }
        } else if (/iPhone|iPod|Android/i.test(navigator.userAgent)) {
          if (timeLineRef.current.offsetHeight > 400) {
            marginTop = marginTop + 40;
          } else {
            if (screen.width > 1400) {
              marginTop = marginTop + 80;
            }
            marginTop = marginTop - 20;
          }
          if (isCheckInTodo === null) {
            marginTop = marginTop - 80;
          }
          if (screen.width <= 1680 && screen.width > 1500) {
            marginTop = marginTop + 50;
          }
          if (
            screen.width <= 1500 &&
            screen.width > 1200 &&
            isCheckInTodo === null
          ) {
            marginTop = marginTop + 100;
          }
        } else {
          if (screen.width <= 1680 && screen.width > 1500) {
            marginTop = marginTop + 50;
          }
          if (screen.width <= 1500) {
            marginTop = marginTop + 100;
          }
        }

        props.setCurveMarginTop(marginTop);
      }, 0);
    }
  };
  useEffect(() => {
    if (props.carePlan.care_plan_progress) {
      setActiveStep(getActiveStep(props));
    }
  }, [props.carePlan.care_plan_progress]);

  useEffect(() => {
    setTimeLineHeight();
  }, [timeLineRef.current]);
  if (
    !props.data.loading &&
    !careTeam?.loading &&
    !get("initialAssessment.loading", props) &&
    !get("carePlan.loading", props) &&
    !get("memberGoals.loading", props)
  ) {
    const careNavigator = get("user.member.care_team.care_navigator", careTeam);
    const therapist = get("user.member.care_team.therapist", careTeam);
    const cohort = get("memberInfo.user.member.cohort", props);
    const coachingSupported = getOr(
      false,
      "memberInfo.user.member.cohort.contract_term.coaching_support",
      props,
    );
    const optimism = get("memberInfo.user.member.optimism", props);
    const riskLevels = get("assessmentRiskLevels", props);
    const userEmail = get("memberInfo.user.member.email", props);
    const member = getOr([], "memberInfo.user.member", props);

    const sendDownloadAppEmailFn = (props) => {
      props.addNotification("Sending email", "info");
      props.sendDownloadAppEmail().then((success) => {
        if (success) {
          props.addNotification(t("notifications.emailSendSuccess"), "success");
        } else {
          props.addNotification(t("notifications.emailSendFailure"), "error");
        }
      });
    };
    // NOTE: could turn this into a big hook that combines both useCareTeam
    // ... and useUpcomingAppointmentSlots to skip out on this awkward prop passing
    const firstTherapySlots = {
      therapy: upcomingTherapyAppointmentSlots,
    };
    const firstCNSlot = upcomingCNAppointmentSlot?.appointment_slots?.available;

    return (
      <>
        <Section>
          <Heading
            as="h1"
            py={10}
            data-cy="start-journey"
            fontSize={48}
            fontWeight={50}
          >
            {t("headline")}
          </Heading>
          <Subtitle noMargin>{t("subtitle")}</Subtitle>
        </Section>
        <CarePlanComponent
          carePlanProps={{ ...props, careTeam }}
          firstTherapySlots={firstTherapySlots}
          firstCNSlot={firstCNSlot}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setTimeLineHeight={setTimeLineHeight}
          timeLineRef={timeLineRef}
        />
        <Section>
          <WhatsInYourPlan
            careNavigator={careNavigator}
            sudSupported={sudSupported}
            therapist={therapist}
            showMedication={medicationRecommended(props)}
            eapUrl={cohort && cohort.cohort_eap_link}
            customerName={cohort && cohort.customer.name}
            coachingSupported={coachingSupported}
            optimism={optimism}
            riskLevels={riskLevels}
            member={member}
          />
          <Section size="xlg">
            <HorizontalRule />
          </Section>

          <BenefitsSnapshot
            benefits={benefitSnapshotData(props)}
            sudSupported={sudSupported}
          />

          <ReviewBenefits props={props} />

          <ReviewBenefitsModal />
          <CareProviderScheduleModal />
          <AvailabilityRequestModal />
          <ChangeCareProviderModal />
          <TryMomentsModal
            title={t("tryMomentsModal.title", { ns: "limitedLangMoments" })}
            subtitle={t("tryMomentsModal.subtitle", {
              ns: "limitedLangMoments",
            })}
            linkText={t("tryMomentsModal.linkText", {
              ns: "limitedLangMoments",
            })}
            userEmail={userEmail}
            url={
              (window &&
                window.location &&
                window.location.protocol +
                  "//" +
                  window.location.host +
                  "/download_spring_app") ||
              "https://client-landing.springhealth.com/download_spring_app"
            }
            sendEmail={() => sendDownloadAppEmailFn(props)}
          />
        </Section>
      </>
    );
  }
  return (
    <BorderPage>
      <Heading py={10} as="h1" fontSize={48} fontWeight={50}>
        {t("headline")}
      </Heading>
      <Subtitle noMargin>{t("subtitle")}</Subtitle>

      <LoadingCircle />

      <Section size="xlg">
        <HorizontalRule />
      </Section>

      <BenefitsSnapshot
        benefits={benefitSnapshotData(props)}
        sudSupported={sudSupported}
      />
    </BorderPage>
  );
};

CarePlan.propTypes = {
  addNotification: PropTypes.func,
  carePlan: PropTypes.shape({
    care_plan_progress: PropTypes.any,
  }),
  data: PropTypes.shape({
    loading: PropTypes.any,
  }),
  sendDownloadAppEmail: PropTypes.func,
  setCurveMarginTop: PropTypes.func,
  setYourPlanCurveWrapperHeight: PropTypes.func,
};

const mapStateToProps = ({ global: { showGlobalExperience } }) => ({
  showGlobalExperience,
});

export default compose(
  graphql(getMemberGoals, {
    name: "memberGoals",
    options: Meowth.apolloOptionsUserId,
  }),
  graphql(getMemberInfo, {
    name: "memberInfo",
    options: Meowth.apolloOptionsUserId,
  }),
  graphql(getMemberCarePlanProgress, {
    options: (ownProps) => ({
      fetchPolicy: "network-only",
      variables: {
        member_id: get("memberInfo.user.member.id", ownProps),
      },
    }),
    name: "carePlan",
    refetchQueries: ["getMemberCarePlanProgress"],
  }),
  graphql(requestAppointment, {
    props: ({ mutate }) => ({
      requestAppointment: (data) =>
        mutate({ variables: { input: { ...data } } }),
    }),
  }),
  graphql(generateAssessment, { name: "generateAssessment" }),
  graphql(getAssessmentRiskLevels, {
    options: (ownProps) => ({
      variables: {
        member_id: get("memberInfo.user.member.id", ownProps),
      },
    }),
    name: "assessmentRiskLevels",
  }),
  graphql(skipCarePlanProgressStep, {
    name: "skipCarePlanProgressStep",
    options: {
      refetchQueries: ["getMemberCarePlanProgress"],
    },
  }),
  graphql(sendDownloadAppEmail, {
    props: ({ mutate }) => ({
      sendDownloadAppEmail: () => mutate(),
    }),
  }),
  latestInitialAssessment(),
  connect(mapStateToProps, { openModal, addNotification }),
)(CarePlan);
