import { get } from "lodash/fp";
import { paramsToObject } from "utils/routes";

async function identify(ldClient, context) {
  try {
    await ldClient.identify(context);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("could not identify in Launch Darkly.", err);
  }
}

export function launchdarklyIdentify(
  userIdentifiers,
  ldClient,
  isAnonUser = false,
) {
  if (!ldClient) return;

  const queryParams = paramsToObject(
    new URLSearchParams(window.location.search).entries(),
  );

  const baseContext = {
    kind: "user",
    anonymous: isAnonUser,
    ...getContextForAnonymousInternalUser(),
  };

  if (Object.keys(queryParams).length === 2 && isAnonUser) {
    const context = {
      ...baseContext,
      customerId: queryParams.customer_id,
      country: queryParams.country,
    };

    identify(ldClient, context);
  }

  if (userIdentifiers?.user?.id) {
    const context = {
      ...baseContext,
      key: get("user.id", userIdentifiers),
      memberId: get("user.member.id", userIdentifiers),
      customerId: get("user.member.cohort.customer.id", userIdentifiers),
      channelPartnerId: get(
        "user.member.cohort.customer.channel_partner.id",
        userIdentifiers,
      ),
      cohortId: get("user.member.cohort.id", userIdentifiers),
      language: get("user.preferred_language", userIdentifiers),
      memberCountry: get("user.member.postal_address.country", userIdentifiers),
      memberState: get("user.member.postal_address.state", userIdentifiers),
      createdAt: get("user.created_at", userIdentifiers),
      sudSupported: get("user.member.sud_benefit.enabled", userIdentifiers),
      censusDependentsOnly: get(
        "user.member.cohort.contract_term.census_dependents_only",
        userIdentifiers,
      ),
      minor: userIdentifiers?.user?.member?.minor,
      dependentEligibilityTier: get(
        "user.member.cohort.customer.dependent_eligibility_tier",
        userIdentifiers,
      ),
    };

    identify(ldClient, context);
  }
}

/**
 * Function to read cookies for anonymous (and primarily internal user) targeting. It returns an
 * object that can be passed to the LaunchDarkly identify function with cookie name as the key
 * and a boolean value as the value.
 *
 * Removal ticket: https://springhealth.atlassian.net/browse/CORE-2102
 */
const getContextForAnonymousInternalUser = () => {
  const COOKIES = ["displaySSOLoginAndRegister"];

  return COOKIES.reduce((acc, cookie) => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${cookie}=`))
      ?.split("=")[1];

    return {
      ...acc,
      [cookie]: cookieValue === "true",
    };
  }, {});
};
