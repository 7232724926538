import { Switch } from "@springcare/sh-component-library";
import { MenuItem, FormControl, FormLabel, Text } from "@chakra-ui/react";

import { useParticipantMetadata } from "context/SessionRoomContext";

import { trackFilterToggleState } from "components/templates/SHSessionRoom/telemetry/analytics";

import { useTranslation } from "hooks/react-i18next";

export const NoiseFilterMenuItem = () => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "noiseFilterMenuItem",
  });

  const {
    isNoiseCancelingPreferenceEnabled,
    setIsNoiseCancelingPreferenceEnabled,
  } = useParticipantMetadata();

  const toggleNoiseFilter = (track) => {
    if (track) {
      trackFilterToggleState(
        "Noise Cancellation",
        !isNoiseCancelingPreferenceEnabled,
      );
    }
    setIsNoiseCancelingPreferenceEnabled((prevState) => !prevState);
  };

  return (
    <MenuItem
      onClick={() => toggleNoiseFilter(false)}
      _focus={{ outline: "0px solid transparent !important" }}
      _focusVisible={{ outline: "2px solid black !important" }}
      display="flex"
    >
      <FormControl display="flex" justifyContent="space-between" mx="v-8">
        <FormLabel>
          <Text>{t("noiseCancellation")}</Text>
        </FormLabel>
        <Switch
          isChecked={isNoiseCancelingPreferenceEnabled}
          onChange={() => toggleNoiseFilter(true)}
        />
      </FormControl>
    </MenuItem>
  );
};
