import { useRef } from "react";
import {
  Flex,
  Text,
  useDisclosure,
  useMediaQuery,
  useTheme,
} from "design-system";
import { SHModal } from "design-system/components";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { UpdateAddressForm } from "./UpdateAddressForm";
import { useTranslation } from "hooks/react-i18next";
import { Trans } from "react-i18next";

const AddressDisclaimer = () => {
  const { t } = useTranslation(["careProvider", "common"]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const closeModalFocusRef = useRef(null);
  const { breakpoints } = useTheme();
  const [isMobileSize] = useMediaQuery(`(max-width: ${breakpoints.md})`);

  return (
    <Flex
      mb={12}
      borderRadius={16}
      backgroundColor={"info-subtle"}
      p={16}
      align="center"
    >
      <InfoOutlineIcon boxSize={20} mr={8} color="info-on-subtle" />
      <Flex
        mx={4}
        direction={isMobileSize ? "column" : "row"}
        justify="space-between"
        textAlign="left"
        w="100%"
      >
        <Trans mx={4}>
          <Text>{t("browserPageWrapper.addressDisclaimer")}</Text>
        </Trans>
        <Text
          mt={isMobileSize ? 16 : 0}
          cursor="pointer"
          _hover={{ textDecoration: "underline", color: "info-bold" }}
          color="info-on-subtle"
          fontWeight={600}
          as="button"
          onClick={onOpen}
        >
          {t("updateAddress.link")}
        </Text>
      </Flex>

      <SHModal
        isOpen={isOpen}
        onClose={onClose}
        modalTitle={t("updateAddress.title")}
        finalFocusRef={closeModalFocusRef}
      >
        <UpdateAddressForm onClose={onClose} />
      </SHModal>
    </Flex>
  );
};

export default AddressDisclaimer;
