import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const useFirstLivekitSession = () => {
  const [isFirstSession, setIsFirstSession] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    const cookieName = "firstLivekitSession";
    const firstSession = cookies.get(cookieName);

    if (!firstSession) {
      // No cookie exists, mark as first session
      setIsFirstSession(true);
      cookies.set(cookieName, "true", { path: "/" }); // Session-wide cookie
    } else {
      // Cookie exists, not the first session
      setIsFirstSession(false);
    }
  }, []);

  return isFirstSession;
};

export default useFirstLivekitSession;
