const NO_EMAIL_REGISTER_URL_BASE =
  "https://care.springhealth.com/register/no-email/";
const BENEFITS_URL_BASE = "https://benefits.springhealth.com/customer/";

const routes = {
  // Route for vanity URL link to Highmark portal.
  MyHmk: {
    to: "/apps/Public/myhmk",
    as: "/myhmk",
    customerName: "HMK",
    dataCy: "myhmk-page",
    heading: "my highmark page",
    redirectUrl: "https://myhighmark.page.link/mentalwellbeing",
  },
  // Route for vanity URL link to Beneficity portal.
  Beneficity: {
    to: "/apps/Public/beneficity",
    as: "/beneficity",
    customerName: "Beneficity",
    dataCy: "beneficity-page",
    heading: "beneficity page",
    redirectUrl: "https://beneficity.page.link/mentalwellbeing",
  },
  // Route for vanity URL link to AllMyHealth (AMH) portal.
  Amh: {
    to: "/apps/Public/amh",
    as: "/amh",
    customerName: "AMH",
    dataCy: "amh-page",
    heading: "All My Health Page",
    redirectUrl: "https://allmyhealth.page.link/mentalwellbeing",
  },
  // Route for vanity URL link to Holidays page.
  Holidays: {
    to: "/apps/Public/holidays",
    as: "/holidays",
    customerName: "Holiday Member Push",
    dataCy: "holidays-page",
    heading: "holidays page",
    redirectUrl:
      "https://care.springhealth.com/members/home/?utm_medium=direct+mail&utm_source=postcard&utm_campaign=q42024+holiday+dm&utm_content=link&utm_term=enrolled",
  },
  // Route for vanity URL link to Smurfit-westrock Mailer page.
  SmurfitWestrockMailer: {
    to: "/apps/Public/smurfit-westrock/mailer",
    as: "/smurfit-westrock/mailer",
    customerName: "Smurfit Westrock",
    dataCy: "smurfit-westrock-mailer-page",
    heading: "smurfit westrock mailer page",
    redirectUrl:
      "https://care.springhealth.com/register/no-email?utm_medium=direct%20mail&utm_source=letter&utm_campaign=q1%202025%20letter&utm_term=cust%20smurfit%20westrock",
  },

  // Added as a part of MFOCL-335
  LennarMailer: {
    to: "/apps/Public/lennar/mailer",
    as: "/lennar/mailer",
    customerName: "Lennar",
    dataCy: "lennar-mailer-page",
    heading: "lennar mailer page",
    redirectUrl:
      BENEFITS_URL_BASE +
      "lennar?utm+medium=Direct+Mail&utm+source=Letter&utm_campaign=q1+25+launch&utm_term=cust+lennar",
  },

  LennarMagnet: {
    to: "/apps/Public/lennar/magnet",
    as: "/lennar/magnet",
    customerName: "Lennar",
    dataCy: "lennar-magnet-page",
    heading: "lennar magnet page",
    redirectUrl:
      "https://care.springhealth.com/register/no-email?utm+medium=Direct+Mail&utm+source=Magnet&utm_campaign=q1+25+launch&utm_term=cust+lennar",
  },

  DukeEnergyMailer: {
    to: "/apps/Public/duke-energy/mailer",
    as: "/duke-energy/mailer",
    customerName: "Duke Energy",
    dataCy: "duke-energy-mailer-page",
    heading: "duke energy mailer page",
    redirectUrl:
      BENEFITS_URL_BASE +
      "duke-energy?utm+medium=Direct+Mail&utm+source=Letter&utm_campaign=q1+25+launch&utm_term=cust+duke+energy",
  },

  SmurfitWestrockMagnet: {
    to: "/apps/Public/smurfit-westrock/magnet",
    as: "/smurfit-westrock/magnet",
    customerName: "Smurfit Westrock",
    dataCy: "smurfit-westrock-magnet-page",
    heading: "smurfit westrock magnet page",
    redirectUrl:
      "https://care.springhealth.com/register/no-email?utm_medium=direct%20mail&utm_source=letter&utm_campaign=q1%202025%20letter&utm_term=cust%20smurfit%20westrock",
  },

  DowChemicalMailer: {
    to: "/apps/Public/dow/mailer",
    as: "/dow/mailer",
    customerName: "Dow Chemical",
    dataCy: "dow-chemical-mailer-page",
    heading: "dow chemical mailer page",
    redirectUrl:
      BENEFITS_URL_BASE +
      "dowchemical?utm_medium=direct+mail&utm_source=letter&utm_campaign=q1+2025+letter&utm_term=cust+dow",
  },

  MortensonMailer: {
    to: "/apps/Public/mortenson/mailer",
    as: "/mortenson/mailer",
    customerName: "Mortenson",
    dataCy: "mortenson-mailer-page",
    heading: "mortenson mailer page",
    redirectUrl:
      NO_EMAIL_REGISTER_URL_BASE +
      "?utm_medium=direct+mail&utm_source=letter&utm_campaign=2025+letter&utm_term=cust+mortenson",
  },

  MortensonMagnet: {
    to: "/apps/Public/mortenson/magnet",
    as: "/mortenson/magnet",
    customerName: "Mortenson",
    dataCy: "mortenson-magnet-page",
    heading: "mortenson magnet page",
    redirectUrl:
      NO_EMAIL_REGISTER_URL_BASE +
      "?utm_medium=direct+mail&utm_source=magnet&utm_campaign=2025+magnet&utm_term=cust+mortenson",
  },

  BuildersFirstSourcePostcard: {
    to: "/apps/Public/builders-firstsource/postcard",
    as: "/builders-firstsource/postcard",
    customerName: "Builders FirstSource",
    dataCy: "builders-firstsource-postcard-page",
    heading: "builders firstsource postcard page",
    redirectUrl:
      BENEFITS_URL_BASE +
      "builders?utm+medium=Direct+Mail&utm+source=Postcard&utm_campaign=q1+25+launch&utm_term=cust+builders+firstsource",
  },

  FreeportMcMoRanPost: {
    to: "/apps/Public/freeport-mcmoran/post",
    as: "/freeport-mcmoran/post",
    customerName: "Freeport McMoRan",
    dataCy: "freeport-mcmoran-post-page",
    heading: "freeport mcmoran post page",
    redirectUrl:
      BENEFITS_URL_BASE +
      "freeport-mcmoran?utm+medium=Direct+Mail&utm+source=Postcard&utm_campaign=q1+25+launch&utm_term=cust+freeport+mcmoran",
  },

  TravelersPostcard: {
    to: "/apps/Public/travelers/postcard",
    as: "/travelers/postcard",
    customerName: "Travelers",
    dataCy: "travelers-postcard-page",
    heading: "travelers postcard page",
    redirectUrl:
      "https://care.springhealth.com/register/no-email?utm+medium=Direct+Mail&utm+source=Postcard&utm_campaign=q1+25+launch&utm_term=cust+travelers",
  },

  HaskellPostcard: {
    to: "/apps/Public/haskell/postcard",
    as: "/haskell/postcard",
    customerName: "Haskell",
    dataCy: "haskell-postcard-page",
    heading: "haskell postcard page",
    redirectUrl:
      "https://care.springhealth.com/register/no-email?utm+medium=Direct+Mail&utm+source=Postcard&utm_campaign=q1+25+launch&utm_term=cust+haskell",
  },

  LutronPostcard: {
    to: "/apps/Public/lutron/postcard",
    as: "/lutron/postcard",
    customerName: "Lutron",
    dataCy: "luton-postcard-page",
    heading: "luton postcard page",
    redirectUrl:
      BENEFITS_URL_BASE +
      "lutron?utm+medium=Direct+Mail&utm+source=Postcard&utm_campaign=q1+25+launch&utm_term=cust+lutron",
  },

  GuardianLetter: {
    to: "/apps/Public/guardianbenefits/letter",
    as: "/guardianbenefits/letter",
    customerName: "Guardian",
    dataCy: "guardian-letter-page",
    heading: "guardian letter page",
    redirectUrl:
      "https://guardianbenefits.springhealth.com?utm+medium=Direct+Mail&utm+source=Letter&utm_campaign=q1+25+launch&utm_term=cust+guardian+benefits",
  },

  FortuneBrandsMailer: {
    to: "/apps/Public/fortune-brands/mailer",
    as: "/fortune-brands/mailer",
    customerName: "Fortune Brands",
    dataCy: "fortune-brands-mailer-page",
    heading: "fortune brands mailer page",
    redirectUrl:
      "https://care.springhealth.com/register/no-email?utm+medium=Direct+Mail&utm+source=Letter&utm_campaign=q1+25+launch&utm_term=cust+fortune+brands",
  },

  RudolphMagnet: {
    to: "/apps/Public/rudolph/magnet",
    as: "/rudolph/magnet",
    customerName: "Rudolph Foods",
    dataCy: "rudolph-foods-magnet-page",
    heading: "rudolph foods magnet page",
    redirectUrl:
      "https://care.springhealth.com/register/no-email?utm+medium=Direct+Mail&utm+source=Letter&utm_campaign=q1+25+launch&utm_term=cust+rudolph+brands ",
  },

  RudolphMailer: {
    to: "/apps/Public/rudolph/mailer",
    as: "/rudolph/mailer",
    customerName: "Rudolph Foods",
    dataCy: "rudoph-foods-mailer-page",
    heading: "rudolph foods mailer page",
    redirectUrl:
      "https://care.springhealth.com/register/no-email?utm+medium=Direct+Mail&utm+source=Letter&utm_campaign=q1+25+launch&utm_term=cust+rudolph+brands ",
  },
};

export default routes;
