import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Avatar, DedicatedIcon } from "@spring/smeargle";
import {
  Box,
  Flex,
  Text,
  VMappedProviderIcon,
  SHTooltip as Tooltip,
  useDisclosure,
} from "design-system/components";
import { VWorkplaceIcon } from "design-system/assets";
import { getNormalizedProviderRoleByKind } from "utils/providers";

import { curriedGetKind } from "components/templates/CareVisitDetails/utils";

const PlaceHolderImage =
  "https://res.cloudinary.com/spring-health/image/upload/c_scale,w_300/v1520544987/placeholder_rkonj3.png";

export const AppointmentDetailsCard = ({
  provider,
  time,
  kind,
  isCancelled,
  customerId,
  isMultiRole,
}) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const { t } = useTranslation("careVisits");
  enum AssociateProviderStatus {
    Active = "active",
    InActive = "inactive",
  }
  const isAssociateProvider =
    provider.associate_status === AssociateProviderStatus.Active;
  const licenses = isAssociateProvider
    ? provider?.licenses?.slice(0, 2)?.join(", ")
    : provider?.licenses?.join(", ");
  const getKind = curriedGetKind(t, customerId);

  const ProviderRole = getNormalizedProviderRoleByKind(kind);
  // TODO: see if this can be removed
  const computeProviderNameColor = (flagVal, isCancelled) => {
    if (flagVal && isCancelled) {
      return "content-secondary";
    } else if (flagVal && !isCancelled) {
      return "content-primary";
    }
  };

  const outerPadding = () => {
    if (isAssociateProvider) {
      return "16px";
    } else if (provider.dedicated_customer?.name) {
      return "26px 50px";
    } else {
      return "16px 50px";
    }
  };

  return (
    <Box
      color="#6a6a6a"
      borderColor={"border-subtle"}
      borderWidth="v-width-thin"
      borderRadius={12}
      boxShadow="xs"
      padding={outerPadding()}
      maxWidth={["100%", "100%", "388px"]}
      height="100%"
    >
      <Flex
        height="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
      >
        {provider.dedicated_customer?.name && (
          <Box mb="21px">
            <DedicatedIcon />
          </Box>
        )}
        <Avatar
          round
          src={provider.avatar || PlaceHolderImage}
          imgProps={{ alt: provider.name, "aria-hidden": "true" }}
          height={provider.dedicated_customer?.name ? 120 : 160}
          width={provider.dedicated_customer?.name ? 120 : 160}
        />
        <Box>
          <Text
            width="100%"
            textAlign="center"
            color={computeProviderNameColor(true, isCancelled)}
            size="body-medium-strong"
            mb={4}
            data-cy="provider-name-label"
          >
            {provider.name}
          </Text>
          <Text width="100%" textAlign="center">
            <Box as="span" verticalAlign="middle" mr={4}>
              {isMultiRole ? (
                <VWorkplaceIcon />
              ) : (
                <VMappedProviderIcon careProviderType={ProviderRole} />
              )}
            </Box>
            {isAssociateProvider && (
              <Box pb={16} width="100%" as="span">
                <Tooltip
                  label={
                    <Text size="body-small-regular">
                      {t("careProvider.associateProviderTooltip")}
                    </Text>
                  }
                  placement="top"
                  role="tooltip"
                  borderRadius={8}
                  p={8}
                  maxWidth="202px"
                  isOpen={isOpen}
                >
                  <Text
                    borderBottom="1px dashed #00131738"
                    display="inline"
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    onClick={onToggle}
                  >{`${t("careProvider.associateProvider")}`}</Text>
                </Tooltip>
                {licenses && <Text display="inline">{` • ${licenses}`}</Text>}
              </Box>
            )}
            <Text
              as={isAssociateProvider ? "div" : "span"}
              pt={isAssociateProvider && 16}
              width="100%"
            >
              {getKind(kind)}
            </Text>
          </Text>
        </Box>
        <Box width="100%">
          <Text width="100%" textAlign="center">
            <Text
              as="span"
              width={"100%"}
              display="inline-block"
              decoration={isCancelled && "line-through"}
              data-cy="appointment-details-card-date"
            >
              {DateTime.fromISO(time).toLocaleString(DateTime.DATE_FULL)}
            </Text>
            <Text
              as="span"
              width="100%"
              display="inline-block"
              decoration={isCancelled && "line-through"}
              data-cy="appointment-details-card-time"
            >
              {DateTime.fromISO(time).toFormat("t ZZZZ")}
            </Text>
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
