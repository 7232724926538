import { Card } from "@spring/smeargle";
import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
} from "design-system/components";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { Link } from "components/atoms";
import {
  LanguageIcon,
  Phone,
  SendMessage,
  SupportCenter,
} from "components/templates/MemberDashboard/Icons";
import { clickableDivProps } from "lib/accessibility_helpers";
import { HelpLanguageMenu } from "./HelpLanguageMenu";

import styles from "./styles.module.scss";

import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import { trackSupportPhoneNumberClicked } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { Email } from "components/templates/MemberDashboard/Icons";
import { SmartPhone } from "design-system/assets";
import { useHelpInfo } from "hooks/useHelpInfo";
import { getMemberCohort } from "operations/queries/member";
import { getPhoneStyleForRTL } from "shared/utils/getPhoneStyleForRTL";
import envUtils from "utils/environment";
import { formatPhoneForDisplay } from "utils/global";
import {
  getDirectDialForT2,
  getFreePhoneForT2,
  isT2Member as isAT2Member,
} from "utils/memberHelpers";
import { track, TRACK_EVENT } from "utils/mixpanel";
import useChatBotHandler from "utils/chatbot/useChatBotHandler";

import { openSupport } from "utils/zendeskHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import { footerLinks } from "./footerLinks";
import { SupportPageSubmitRequest } from "constants/links";

const trackHelpClick = (channel) => {
  track(`Help And Support -- ${channel} Clicked`);
};

const FooterHeader = ({
  countryList,
  supportedLanguages,
  selectedCountry,
  setSelectedCountry,
}) => {
  return (
    <Box width="100%" m={{ base: 0, md: 12 }}>
      <Flex direction="column" mb={8} width="100%">
        <HelpLanguageMenu
          countryList={countryList}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
        <AvailableLanguages supportedLanguages={supportedLanguages} />
      </Flex>
    </Box>
  );
};

FooterHeader.propTypes = {
  setSelectedCountry: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  countryList: PropTypes.array.isRequired,
  supportedLanguages: PropTypes.string.isRequired,
};

const AvailableLanguages = ({ supportedLanguages }) => {
  return (
    <div className={styles.languageSubHeading} data-cy="help-language">
      <Flex direction="row" align="center">
        <Flex direction="column">
          <LanguageIcon />
        </Flex>
        <Flex direction="column">
          <div className={styles.FooterHeaderSubHeading}>
            {supportedLanguages}
          </div>
        </Flex>
      </Flex>
    </div>
  );
};

AvailableLanguages.propTypes = {
  supportedLanguages: PropTypes.string.isRequired,
};

const HelpCardFooter = ({ children }) => (
  <footer className={styles.footerContentContainer}>
    <div className={styles.footerContent} data-cy="footer-data">
      {children}
    </div>
  </footer>
);

HelpCardFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const SupportCenterCard = () => {
  const { t: translatedString } = useTranslation([
    "memberHelpCard",
    "limitedLangMemberHelpCard",
  ]);
  const shouldAuthHelpCenter = useFeatureFlag(FLAGS.HELP_CENTER_REDIRECT);
  const handleClick = () => {
    trackHelpClick("Support Center Clicked");
    openSupport(shouldAuthHelpCenter);
  };

  return (
    <div className={styles.contactCard}>
      <Card
        white={false}
        gray={true}
        bordered={false}
        padding="xsm"
        dataCy={"help-support-center"}
      >
        <Flex direction="row" align="center">
          <Flex direction="column">
            <SupportCenter />
          </Flex>
          <Flex direction="column">
            <div
              data-testid="support-center-card-button"
              className={`${styles.contactCardHeading} ${styles.linkUnderline}`}
              onClick={handleClick}
              {...clickableDivProps({ onClick: handleClick })}
            >
              {translatedString("memberHelpCard:supportCenter")}
            </div>

            <div className={styles.contactCardSubHeading}>
              {translatedString("Available 24/7", {
                ns: "limitedLangMemberHelpCard",
              })}
            </div>
          </Flex>
        </Flex>
      </Card>
    </div>
  );
};

const PhoneCard = ({
  phoneNumber,
  phoneAvailability,
  isGeneralSupport,
  isT2Member,
  cohort,
}) => {
  const { t: translatedString } = useTranslation([
    "memberHelpCard",
    "t2MemberHelpCard",
  ]);
  if (isT2Member) {
    phoneNumber = isGeneralSupport
      ? getFreePhoneForT2(cohort)
      : getDirectDialForT2(cohort);
  }
  const PhoneSupportText = ({ isGeneralSupport, isT2Member }) => {
    let supportText;
    if (isT2Member) {
      if (isGeneralSupport) {
        supportText = translatedString("t2MemberHelpCard:t2FreePhone", {
          number: getFreePhoneForT2(cohort),
        });
      } else {
        supportText = translatedString("t2MemberHelpCard:t2MemberSupportText", {
          number: getDirectDialForT2(cohort),
        });
      }
    } else {
      if (isGeneralSupport) {
        supportText = translatedString("memberHelpCard:generalSupportText", {
          number: formatPhoneForDisplay(phoneNumber),
        });
      } else {
        supportText = translatedString("memberHelpCard:crisisSupportText", {
          number: formatPhoneForDisplay(phoneNumber),
        });
      }
    }
    return <Box style={getPhoneStyleForRTL()}>{supportText}</Box>;
  };

  const phoneAvailabilityText = translatedString(phoneAvailability, {
    ns: phoneAvailability?.startsWith("Available 24/7")
      ? "limitedLangMemberHelpCard"
      : "memberHelpCard",
  });

  return (
    <div data-testid="member-help-phone-card" className={styles.contactCard}>
      <Card
        white={false}
        gray={true}
        bordered={false}
        padding="xsm"
        dataCy={"help-phone-number"}
      >
        <Flex direction="row" align="center">
          <Flex direction="column">
            {isT2Member && isGeneralSupport ? (
              <SmartPhone />
            ) : (
              <Phone className={styles.phoneIcon} />
            )}
          </Flex>
          <Flex direction="column">
            <Box
              onClick={() => {
                trackSupportPhoneNumberClicked({
                  phoneNumber,
                  isGeneralSupport,
                  isT2Member,
                });
              }}
            >
              <Link
                inlineTextLink
                to={`tel:${phoneNumber}`}
                className={styles.contactCardHeading}
                aria-label={translatedString(
                  "memberHelpCard:generalSupportText",
                  {
                    number: formatPhoneForDisplay(phoneNumber),
                  },
                )}
              >
                <PhoneSupportText
                  isGeneralSupport={isGeneralSupport}
                  isT2Member={isT2Member}
                ></PhoneSupportText>
              </Link>
            </Box>
            <div className={styles.contactCardSubHeading}>
              {phoneAvailabilityText}
            </div>
          </Flex>
        </Flex>
      </Card>
    </div>
  );
};

PhoneCard.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  phoneAvailability: PropTypes.string.isRequired,
  isGeneralSupport: PropTypes.bool.isRequired,
  isT2Member: PropTypes.bool,
};

const ContactCard = ({ emailAvailability, closeFlyout, isT2Member }) => {
  const { t: translatedString } = useTranslation([
    "limitedLangHelp",
    "t2MemberHelpCard",
    "limitedLangMemberHelpCard",
  ]);

  const { handleOpenChat } = useChatBotHandler();

  const handleClick = () => {
    if (isT2Member) {
      TRACK_EVENT.LINK_CLICKED(
        window.location.pathname,
        SupportPageSubmitRequest,
        "Submit a Request",
        {
          location: "Help flyout",
        },
      );
      window.open(SupportPageSubmitRequest, "_blank");
    } else {
      closeFlyout();

      handleOpenChat();

      trackHelpClick("Zendesk Contact");
    }
  };

  return (
    <div className={styles.contactCard}>
      <Card
        white={false}
        gray={true}
        bordered={false}
        padding="xsm"
        dataCy={"help-send-message"}
      >
        <Flex direction="row" align="center">
          <Flex direction="column">
            {isT2Member ? <Email /> : <SendMessage />}
          </Flex>
          <Flex direction="column">
            <div
              data-testid="contact-card-button"
              className={`${styles.contactCardHeading} ${styles.linkUnderline}`}
              onClick={handleClick}
              {...clickableDivProps({ onClick: handleClick })}
            >
              {isT2Member
                ? translatedString("t2MemberHelpCard:t2SendMessage")
                : translatedString(
                    "limitedLangHelp:helpCenter.contactButtonText",
                  )}
            </div>
            <div className={styles.contactCardSubHeading}>
              {translatedString(emailAvailability, {
                ns: "limitedLangMemberHelpCard",
              })}
            </div>
          </Flex>
        </Flex>
      </Card>
    </div>
  );
};

ContactCard.propTypes = {
  emailAvailability: PropTypes.string.isRequired,
  closeFlyout: PropTypes.func,
};

const GeneralContactDetails = ({
  phoneNumber,
  phoneAvailability,
  emailAvailability,
  closeFlyout,
  isT2Member,
  cohort,
}) => {
  const { t: translatedString } = useTranslation([
    "memberHelpCard",
    "t2MemberHelpCard",
  ]);
  phoneAvailability = isT2Member
    ? translatedString("t2MemberHelpCard:t2InternetCallWarning")
    : phoneAvailability;
  return (
    <Box m={{ base: 0, md: 12 }} pb="50px">
      <Heading as={"h4"} fontSize={16} fontWeight={"bold"}>
        {translatedString(
          "limitedLangMemberHelpCard:generalContactDetailsHeading",
        )}
      </Heading>
      {!isT2Member && <SupportCenterCard />}
      <ContactCard
        emailAvailability={emailAvailability}
        closeFlyout={closeFlyout}
        isT2Member={isT2Member}
      />
      <PhoneCard
        phoneNumber={phoneNumber}
        phoneAvailability={phoneAvailability}
        isGeneralSupport={true}
        isT2Member={isT2Member}
        cohort={cohort}
      />
    </Box>
  );
};

GeneralContactDetails.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  phoneAvailability: PropTypes.string.isRequired,
  emailAvailability: PropTypes.string.isRequired,
  closeFlyout: PropTypes.func,
};

const CrisisContactDetails = ({
  phoneNumber,
  phoneAvailability,
  isT2Member,
  cohort,
}) => {
  const { t: translatedString } = useTranslation("limitedLangMemberHelpCard");
  return (
    <Box m={{ base: 0, md: 12 }} data-cy="help-crisis">
      <Heading as="h3" fontSize={16} fontWeight={"bold"}>
        {translatedString("crisisContactDetailsHeading")}
      </Heading>
      <PhoneCard
        phoneNumber={phoneNumber}
        phoneAvailability={phoneAvailability}
        isGeneralSupport={false}
        isT2Member={isT2Member}
        cohort={cohort}
      />
    </Box>
  );
};

CrisisContactDetails.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  phoneAvailability: PropTypes.string.isRequired,
  isT2Member: PropTypes.bool,
};

const NonLocalPhoneNumberVerbiage = () => {
  const { careTeamEmail } = envUtils;
  return (
    <div className={styles.nonLocalPhoneVerbiage}>
      <Heading as={"h4"} fontSize={16} fontWeight={"bold"}>
        <Trans
          ns={"memberHelpCard"}
          i18nKey={"nonLocalPhoneNumber"}
          components={[<Link key={"0"} to={`mailto:${careTeamEmail}`} />]}
        />
      </Heading>
    </div>
  );
};

const FooterLinks = () => {
  const { t: translatedString } = useTranslation("limitedLangRegister");
  return (
    <Flex
      position={{ base: "fixed", md: "absolute" }}
      width={{ base: "100%", md: "auto" }}
      bottom={{ base: 0, md: 32 }}
      pb={{ base: 16, md: 0 }}
      flexWrap="wrap"
      alignItems="center"
      backgroundColor="background-base"
      backdropFilter="blur(24px)"
    >
      <Link
        inlineTextLink
        to={footerLinks.privacyPolicy}
        className={`${styles.noUnderline}`}
      >
        {translatedString("createAccount.form.consents.privacyPolicy")}
      </Link>
      <span className={`${styles.interpunct}`}></span>
      <Link
        inlineTextLink
        to={footerLinks.tos}
        className={`${styles.noUnderline}`}
      >
        {translatedString("createAccount.form.consents.tos")}
      </Link>
      <span className={`${styles.interpunct}`}></span>
      <Link
        inlineTextLink
        to={footerLinks.memberRightsAndResponsibilitiesPDF}
        className={`${styles.noUnderline}`}
      >
        {translatedString("createAccount.form.consents.patientRights")}
      </Link>
    </Flex>
  );
};

const getSupportedLanguages = (availableLanguages, translatedString) => {
  if (availableLanguages?.length === 1) {
    return availableLanguages[0];
  }
  const lastLang = availableLanguages?.pop();
  return `${availableLanguages?.join(", ")} ${translatedString("common:grammar.and")} ${lastLang}`;
};

const MemberHelpCardSkeleton = () => {
  return (
    <Box p={20}>
      <SimpleGrid overflowX="clip" h="100%">
        <Stack gap={12} mb={16}>
          <SkeletonText noOfLines={1} spacing="4" skeletonHeight="2" mb="8px" />
          <Skeleton h="64px" />
          <SkeletonText noOfLines={1} spacing="4" skeletonHeight="2" mb="8px" />

          <Skeleton h="64px" />
          <Skeleton h="64px" />
          <Skeleton h="64px" />
        </Stack>
      </SimpleGrid>
    </Box>
  );
};
const MemberHelpCard = ({ closeFlyout }) => {
  const { t: translatedString } = useTranslation("memberHelpCard");
  const { helpInfo, loading, selectedCountry, setSelectedCountry } =
    useHelpInfo();
  const nonLocalPhoneNumberCountries = [
    "JP",
    "NO",
    "EG",
    "MY",
    "PT",
    "TR",
    "GT",
    "PA",
  ];
  const helpInfoCountry = helpInfo?.selected_country || selectedCountry;
  const isNonLocalPhoneNumberCountry =
    nonLocalPhoneNumberCountries.includes(helpInfoCountry);

  const { data: userData, loading: userLoading } = useQuery(getMemberCohort, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });
  const isT2Member = isAT2Member(userData?.user?.member);
  if (!helpInfo || loading || userLoading) {
    return <MemberHelpCardSkeleton />;
  }

  return (
    <HelpCardFooter>
      {loading || userLoading ? (
        <MemberHelpCardSkeleton />
      ) : (
        <>
          {!isT2Member && (
            <FooterHeader
              countryList={helpInfo?.available_countries}
              selectedCountry={helpInfoCountry}
              setSelectedCountry={setSelectedCountry}
              supportedLanguages={translatedString("supportedLanguages", {
                languages: getSupportedLanguages(
                  helpInfo?.region?.languages,
                  translatedString,
                ),
              })}
            />
          )}
          <CrisisContactDetails
            phoneNumber={helpInfo?.region?.crisis_support_number}
            phoneAvailability={helpInfo?.region?.crisis_support_availability}
            isT2Member={isT2Member}
            cohort={userData?.user?.member?.cohort}
          />
          <GeneralContactDetails
            closeFlyout={closeFlyout}
            phoneNumber={helpInfo?.region?.general_support_number}
            phoneAvailability={helpInfo?.region?.general_support_availability}
            emailAvailability={helpInfo?.region?.email_availability}
            isT2Member={isT2Member}
            cohort={userData?.user?.member?.cohort}
          />
          {isNonLocalPhoneNumberCountry && <NonLocalPhoneNumberVerbiage />}
          <FooterLinks />
        </>
      )}
    </HelpCardFooter>
  );
};

MemberHelpCard.propTypes = {
  closeFlyout: PropTypes.func,
};

MemberHelpCard.defaultProps = {
  closeFlyout: () => {},
};

export default MemberHelpCard;
