import {
  Box,
  Heading,
  SimpleGrid,
  GridItem,
  useBreakpointValue,
} from "design-system/components";
import { useEffect, useState, useRef } from "react";
import { useInViewportOnce } from "hooks";
import { ActiveCareSection, BenefitsSummarySection } from "components";
import { useTranslation } from "react-i18next";
import { useFeatureFlag, FLAGS } from "utils/launchdarkly";
import {
  hasCoveredCoachingRemaining,
  hasCoveredTherapyRemaining,
} from "modules/shared/utils";
import { TRACK_EVENT } from "utils/mixpanel";

export const YourCareSection = ({
  careTeam,
  momentsAreRecommended,
  hasEverCompletedARecommendedCareItem,
  memberHasInPersonSupport,
  isDirectScheduling,
  isGlobalWithNoCoachingSessions,
  memberCountry,
  member,
  memberAssessments,
  columns,
  spacing,
  hasManagedDependents,
  isSpecialtyCareNavigatorSupported,
  openSpecializedCNScheduleModal,
  showNewPreAssessedExperience,
}) => {
  const memberCanBookTherapyAppointments = hasCoveredTherapyRemaining(member);
  const memberCanBookCoachingAppointments = hasCoveredCoachingRemaining(member);
  const yourCareHeadingVariant = useBreakpointValue([
    "md_v1",
    "md_v1",
    "lg_v1",
    "lg_v1",
  ]);
  const activeCareHeadingVariant = useBreakpointValue([
    "sm_v1",
    "sm_v1",
    "md_v1",
    "md_v1",
  ]);
  const benefitsSummaryHeadingVariant = useBreakpointValue([
    "sm_v1",
    "sm_v1",
    "md_v1",
    "md_v1",
  ]);
  const { t } = useTranslation(["homepage"]);
  const [trackedOnce, setTrackedOnce] = useState(false);
  const enableNewNoProviderInterface = useFeatureFlag(
    FLAGS.ENABLE_NEW_NO_PROVIDER_INTERFACE,
  );

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");

  const careTeamDetails = careTeam?.user?.member?.care_team || {};

  const hasNoAssignedCareProviders = Object?.keys(careTeamDetails).every(
    (key) => {
      const value = careTeamDetails[key];
      return key === "id" || key === "__typename" || value === null;
    },
  );

  const shouldDisplayNoCareTeamInterface =
    hasNoAssignedCareProviders && enableNewNoProviderInterface;

  useEffect(() => {
    if (showNewPreAssessedExperience || !shouldDisplayNoCareTeamInterface) {
      if (inViewport && !trackedOnce) {
        TRACK_EVENT.COMPONENT_VIEWED(
          window.pathname,
          "Your care component viewed",
        );
        setTrackedOnce(true);
      }
    }
  }, [
    showNewPreAssessedExperience,
    shouldDisplayNoCareTeamInterface,
    trackedOnce,
    inViewport,
  ]);

  /** private practice members don't have benefits */
  const showBenefitsSummary = !member?.is_private_practice;

  const getSubHeadingText = () => {
    if (showNewPreAssessedExperience || !shouldDisplayNoCareTeamInterface) {
      return t("activeCare.activeCare");
    }
    return t("activeCare.scheduleFirstSession");
  };
  return (
    <section>
      <Box pt={[56, 68, 72, 78]}>
        <Heading as="h2" variant={yourCareHeadingVariant} pb={24}>
          {t("yourCareHeading")}
        </Heading>
        <SimpleGrid columns={columns} spacing={spacing}>
          <GridItem
            colSpan={showBenefitsSummary ? [4, 4, 8, 8] : [4, 4, 12, 12]}
          >
            <Heading
              as="h3"
              variant={activeCareHeadingVariant}
              pb="24"
              ref={ref}
            >
              {getSubHeadingText()}
            </Heading>

            <ActiveCareSection
              memberHasInPersonSupport={memberHasInPersonSupport}
              memberCountry={memberCountry}
              memberNextUpcomingAppointments={
                member?.first_upcoming_appointments
              }
              careTeam={careTeam}
              includeMoments={!momentsAreRecommended}
              hasEverCompletedARecommendedCareItem={
                hasEverCompletedARecommendedCareItem
              }
              isDirectScheduling={isDirectScheduling}
              isGlobalWithNoCoachingSessions={isGlobalWithNoCoachingSessions}
              hasManagedDependents={hasManagedDependents}
              memberCanBookCoachingAppointments={
                memberCanBookCoachingAppointments
              }
              memberCanBookTherapyAppointments={
                memberCanBookTherapyAppointments
              }
              isSpecialtyCareNavigatorSupported={
                isSpecialtyCareNavigatorSupported
              }
              openSpecializedCNScheduleModal={openSpecializedCNScheduleModal}
              showNewPreAssessedExperience={showNewPreAssessedExperience}
            />
          </GridItem>
          {showBenefitsSummary && (
            <GridItem colSpan={[4, 4, 8, 4]} w="100%">
              <Box>
                <BenefitsSummarySection
                  member={member}
                  memberAssessments={memberAssessments}
                  showNewPreAssessedExperience={showNewPreAssessedExperience}
                >
                  <Heading
                    as="h3"
                    fontSize={[19, 24]}
                    fontWeight="600"
                    variant={benefitsSummaryHeadingVariant}
                    pb="24"
                  >
                    {t("benefitsSummaryHeading")}
                  </Heading>
                </BenefitsSummarySection>
              </Box>
            </GridItem>
          )}
        </SimpleGrid>
      </Box>
    </section>
  );
};

export default YourCareSection;
