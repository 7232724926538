import { gql } from "@apollo/client";

const getMemberSUDSupported = gql`
  query getMemberSUDSupported($id: ID!) {
    user(id: $id) {
      member {
        sud_benefit {
          enabled
        }
      }
    }
  }
`;

export default getMemberSUDSupported;
