import React from 'react';
import { modalIds } from '@spring/constants';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';

import { Headline, Subtitle } from '../../typography';
import { FlexCol, Section, Modal } from '../../layout';

import styles from './styles.module.scss';

import { Content, LinkButton, UnevenBubble } from '../../../components';

const TryMomentsModal = (props) => (
  <Modal id={modalIds.tryMomentsModal} size="lg">
    <div>
      <Headline center noMargin>
        {props.title || 'Start self-guided care'}
      </Headline>
      <div className={styles.subHeader}>
        <Subtitle noMargin>
          {props.subtitle || "Point your phone’s camera at the QR code below to download the Spring Health app and access personalized therapy techniques."}
        </Subtitle>
      </div>
      <FlexCol alignment="center">
        <Section alignment="center">
          <div className={styles.imageWrapper}>
            <UnevenBubble />
            <div className={styles.QRCodeWrapper}>
              <QRCode className={styles.QRCode} value={props.url} size={'175'} renderAs={'svg'} />
            </div>
          </div>
        </Section>
        <Content center>
          <LinkButton bold text={props.linkText || 'Send an email with a download link instead'} onClick={props.sendEmail} />
          {props.userEmail && (
            <div style={{ marginTop: '5px' }}>
              {props.userEmail}
            </div>
          )}
        </Content>
      </FlexCol>
    </div>
  </Modal>
);

TryMomentsModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  linkText: PropTypes.string,
  userEmail: PropTypes.string,
  sendEmail: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export { TryMomentsModal };

export default connect()(TryMomentsModal);
