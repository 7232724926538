import { useEffect, useRef } from "react";
import { isSafari } from "react-device-detect";
import {
  useLocalParticipant,
  useConnectionState,
  useLocalParticipantPermissions,
} from "@livekit/components-react";
import { ConnectionState, LocalAudioTrack } from "livekit-client";
import { BackgroundBlur } from "@livekit/track-processors";

import { Flex, Box, Heading } from "@chakra-ui/react";

import SessionRoomGrid from "components/templates/SHSessionRoom/components/SessionRoomGrid";
import SessionRoomDrawer from "components/templates/SHSessionRoom/components/SessionRoomDrawer/SessionRoomDrawer";
import { VideoCallControls } from "components/templates/SHSessionRoom/components/VideoCallControls";

import { ModalSheet } from "@springcare/sh-component-library";

import {
  useSessionDevices,
  useSessionRoomDrawer,
  useUpdateChatMessages,
  useParticipantMetadata,
  useSessionRoomConsentDisclosure,
} from "context/SessionRoomContext";

import {
  logSessionFirstConnectDeviceEnablingError,
  logSessionFirstConnectEnablingDevices,
  logSessionInitialDeviceSelection,
  logSessionLocalAudioTrackConstraints,
  logBlurBackgroundError,
} from "components/templates/SHSessionRoom/telemetry/datadog";

import { ConsentFaqModalContent } from "components/templates/SHSessionRoom/components/modals/ConsentFaqModalContent";

import {
  trackPageVersionViewed,
  trackSessionDrawerToggle,
} from "components/templates/SHSessionRoom/telemetry/analytics";
import { useTranslation } from "hooks/react-i18next";
import { Track } from "livekit-client";
import { toggleKrispNoiseFiltering } from "components/templates/SHSessionRoom/utils/KrispNoiseFilterUtils";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { SessionVerificationModal } from "components/templates/SHSessionRoom/components/modals/SessionVerificationModal";
import { RecordingConsentRequestedModal } from "./modals/RecordingConsentRequestedModal";

const SessionRoomStage = ({ isMobile }) => {
  const {
    isNoiseCancelingPreferenceEnabled,
    isBlurBackgroundPreferenceEnabled,
    appointmentId,
    participantId,
  } = useParticipantMetadata();

  const enableSessionRecordings = useFeatureFlag(
    FLAGS.ENABLE_CONSENTS_WAITING_ROOM_LIVEKIT,
  );
  const { sessionDevices, setSessionDevices } = useSessionDevices();

  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "sessionRoomStage",
  });
  const yourCareSessionLabel = t("yourCareSession");

  const {
    isSessionRoomDrawerOpen,
    onSessionRoomDrawerClose,
    activeDrawerTab,
    drawerWidth,
  } = useSessionRoomDrawer();

  const sessionConsentDisclosure = useSessionRoomConsentDisclosure();

  const { localParticipant, cameraTrack } = useLocalParticipant();
  const localAudioTrack = localParticipant.getTrackPublication(
    Track.Source.Microphone,
  )?.track as LocalAudioTrack;

  const connectionState = useConnectionState();
  const { canPublish } = useLocalParticipantPermissions();
  const isFirstConnect = useRef(true);

  // update chat messages and forward the send, chatMessages, and isSending functions / objects
  // to decrease an extra hook call here to useChat()
  const { send, chatMessages, isSending } = useUpdateChatMessages();
  const handleEscapeKeyPress = (e) => {
    if (e.key === "Escape" && isSessionRoomDrawerOpen) {
      onSessionRoomDrawerClose();
      trackSessionDrawerToggle(activeDrawerTab, false, false);
    }
  };

  useEffect(() => {
    // Wait until we have been admitted to the room for the first time to enable devices
    if (
      (canPublish || connectionState === ConnectionState.Connected) &&
      isFirstConnect.current
    ) {
      const context = {
        appointmentId,
        participantId,
        selectedAudioDevice: sessionDevices?.selectedAudioInputDevice?.deviceId,
        selectedVideoDevice: sessionDevices?.selectedVideoDevice?.deviceId,
      };

      logSessionFirstConnectEnablingDevices({ context });
      isFirstConnect.current = false;

      const enableDevices = async () => {
        try {
          await localParticipant.setCameraEnabled(
            sessionDevices.isCameraEnabled,
          );
        } catch (error) {
          logSessionFirstConnectDeviceEnablingError({
            context,
            deviceKind: "camera",
            error,
          });
        }
        try {
          await localParticipant.setMicrophoneEnabled(
            sessionDevices.isMicrophoneEnabled,
          );
        } catch (error) {
          logSessionFirstConnectDeviceEnablingError({
            context,
            deviceKind: "microphone",
            error,
          });
        }
      };

      enableDevices();
    }
  }, [canPublish, connectionState]);

  useEffect(() => {
    logSessionInitialDeviceSelection(
      appointmentId,
      participantId,
      sessionDevices.selectedAudioInputDevice.deviceId,
      sessionDevices.selectedVideoDevice.deviceId,
      isSafari
        ? "system - (Safari N/A)"
        : sessionDevices.selectedAudioOutputDevice.deviceId,
      sessionDevices.devices,
    );
  }, []);

  useEffect(() => {
    setSessionDevices((prevState) => ({
      ...prevState,
      isCameraEnabled: localParticipant.isCameraEnabled,
      isMicrophoneEnabled: localParticipant.isMicrophoneEnabled,
    }));
  }, [localParticipant.isCameraEnabled, localParticipant.isMicrophoneEnabled]);

  useEffect(() => {
    trackPageVersionViewed("meeting_room");
  }, []);

  useEffect(() => {
    // There's extra height on mobile which we haven't yet found the cause of. This makes sure
    // that Mobile users see all content
    const bottom = document.documentElement.scrollHeight - window.innerHeight;
    window.scrollTo(0, bottom);
  }, []);

  useEffect(() => {
    // There's extra height on mobile which we haven't yet found the cause of.
    // When the Drawer is open it needs to be scrolled to the top. When closed,
    // we return.
    window.addEventListener("keydown", handleEscapeKeyPress);
    if (isSessionRoomDrawerOpen) {
      window.scrollTo(0, 0);
    } else {
      const bottom = document.documentElement.scrollHeight - window.innerHeight;
      window.scrollTo(0, bottom);
    }

    return () => {
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [isSessionRoomDrawerOpen]);

  useEffect(() => {
    if (localAudioTrack) {
      toggleKrispNoiseFiltering(
        isNoiseCancelingPreferenceEnabled,
        localAudioTrack,
        participantId,
        appointmentId,
      );
      logSessionLocalAudioTrackConstraints(
        appointmentId,
        participantId,
        localAudioTrack.constraints,
      );
    }
  }, [localAudioTrack, isNoiseCancelingPreferenceEnabled]);

  useEffect(() => {
    const setBackgroundBlur = async (isBlurEnabled: boolean) => {
      const DEFAULT_BLUR_LEVEL = 10;
      try {
        if (isBlurEnabled) {
          await localParticipant
            .getTrackPublication(Track.Source.Camera)
            ?.track.setProcessor(BackgroundBlur(DEFAULT_BLUR_LEVEL));
        } else {
          await localParticipant
            .getTrackPublication(Track.Source.Camera)
            ?.track.stopProcessor();
        }
      } catch (e) {
        logBlurBackgroundError(e, appointmentId, participantId);
      }
    };

    if (cameraTrack) {
      setBackgroundBlur(isBlurBackgroundPreferenceEnabled);
    }
  }, [cameraTrack, isBlurBackgroundPreferenceEnabled]);

  return (
    <Flex direction="column" h="100vh" w="100vw" overflow="hidden">
      <Heading
        as="h1"
        position="absolute"
        aria-hidden="true"
        aria-label={yourCareSessionLabel}
        width="1px"
        height="1px"
        overflow="hidden"
      >
        {yourCareSessionLabel}
      </Heading>
      <Flex flex={1} overflow="hidden" position="relative">
        <Box
          flex={1}
          width={
            isSessionRoomDrawerOpen && !isMobile
              ? `calc(100% - ${drawerWidth}px)`
              : "100%"
          }
          overflow="hidden"
        >
          <SessionRoomGrid
            isMobile={isMobile}
            drawerWidth={isSessionRoomDrawerOpen && !isMobile ? drawerWidth : 0}
          />
          <ModalSheet
            isOpen={sessionConsentDisclosure.isOpen}
            onClose={sessionConsentDisclosure.onClose}
            size="sm"
            placement="right"
            title="Consent FAQs"
          >
            <ConsentFaqModalContent />
          </ModalSheet>
        </Box>
        {isSessionRoomDrawerOpen && (
          <SessionRoomDrawer
            send={send}
            chatMessages={chatMessages}
            isSending={isSending}
            isMobile={isMobile}
          />
        )}
      </Flex>

      <Flex
        justify={isSessionRoomDrawerOpen ? "start" : "center"}
        pt="v-4"
        pb="v-8"
        bg={isMobile ? "transparent" : "black"}
      >
        <VideoCallControls />
      </Flex>

      {enableSessionRecordings && (
        <>
          <SessionVerificationModal />
          <RecordingConsentRequestedModal />
        </>
      )}
    </Flex>
  );
};

export default SessionRoomStage;
