// react-hook-form general schema's are comprised of a mix of plain objects,
// custom rhf-syntax (i.e pattern: {...} or minLength: {...} ), all wrapped
// in a function

// Not to do with validation but using this in a few forms so may as well
// keep it here for re-use.

import { Trans, useTranslation } from "react-i18next";
import { DIAL_CODES } from "design-system/components";

export const MONTHS = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const getRelationships = () => {
  const { t } = useTranslation("limitedLangRegister");
  return [
    { label: t("relationships.mother"), value: "Mother" },
    { label: t("relationships.father"), value: "Father" },
    { label: t("relationships.parent"), value: "Parent" },
    { label: t("relationships.brother"), value: "Brother" },
    { label: t("relationships.sister"), value: "Sister" },
    { label: t("relationships.familyMember"), value: "Family member" },
    { label: t("relationships.guardian"), value: "Guardian" },
    { label: t("relationships.friend"), value: "Friend" },
    { label: t("relationships.partner"), value: "Partner" },
    { label: t("relationships.roommate"), value: "Roommate" },
    { label: t("relationships.socailWorker"), value: "Social worker" },
    { label: t("relationships.other"), value: "Other" },
  ];
};

// validation for a simple 'required' field
export function requiredValidation(field) {
  // const fieldName = field;
  return {
    required: (
      <Trans i18nKey="required" ns="limitedLangValidations">
        {{ fieldName: field }}
      </Trans>
    ),
  };
}

// validates phone # length for all phone numbers
export function phoneValidation(dialCode) {
  const { t } = useTranslation("limitedLangValidations");
  const selectedCode = DIAL_CODES.find((country) =>
    [country.value, country.code].includes(dialCode),
  );

  const cleanPhoneNumber = (value) => {
    // Remove all non-numeric characters
    return value.replace(/[^\d]/g, "");
  };
  const validatePhoneNumberLength = (value, minLength, maxLength) => {
    const cleanedValue = cleanPhoneNumber(value);
    if (cleanedValue.length < minLength) {
      return t("min", {
        fieldName: "Phone number",
        min: minLength,
        type: "characters",
      });
    }
    if (cleanedValue.length > maxLength) {
      return t("max", {
        fieldName: "Phone number",
        max: maxLength,
        type: "characters",
      });
    }
    return true;
  };
  const validatePattern = (value) => {
    const cleanedValue = cleanPhoneNumber(value);
    const regex = /^[0-9]+$/;
    if (!regex.test(cleanedValue)) {
      return t("numbersOnly");
    }
    return true;
  };
  if (selectedCode) {
    return {
      required: t("required", { fieldName: "Phone number" }),
      validate: {
        cleanNumber: (value) =>
          validatePhoneNumberLength(
            value,
            selectedCode.min_length,
            selectedCode.max_length,
          ),
        pattern: validatePattern,
      },
    };
  }
}
// validates email via regex pattern
export const emailValidation = {
  required: (
    <Trans ns="limitedLangValidations" i18nKey="required">
      {{ fieldName: "Email" }}
    </Trans>
  ),
  pattern: {
    value: /\S+@\S+\.\S+/,
    message: (
      <Trans ns="limitedLangValidations" i18nKey="invalidFormat.email"></Trans>
    ),
  },
};

// validates password length
export const passwordValidation = {
  required: (
    <Trans ns="limitedLangValidations" i18nKey="required">
      {{ fieldName: "Password" }}
    </Trans>
  ),
  minLength: {
    value: 8,
    message: (
      <Trans
        ns="limitedLangValidations"
        i18nKey="invalidFormat.password"
      ></Trans>
    ),
  },
};

// used specifically to prevent <18yo signups
export function ageValidation(
  date,
  field,
  needBdayCheck = true,
  allowedAge = 18,
) {
  if (date === undefined) {
    return true;
  }

  const [birthMonth, birthDay, birthYear] = date.map((item) => Number(item));

  const maxDay = new Date().getDate();
  const maxMonth = new Date().getMonth() + 1;
  const maxYear = new Date().getFullYear() - allowedAge;
  const minYear = 1900;
  const maxDaysAllowed = maxDaysInMonth(birthMonth, birthYear);
  const errorMessage = (
    <Trans ns="limitedLangValidations" i18nKey="invalidDate">
      {{ allowedAge }}
    </Trans>
  );

  function birthdayCheck() {
    if (birthYear > maxYear || birthYear < minYear) {
      return false;
    }
    if (birthYear === maxYear && birthMonth > maxMonth) {
      return false;
    }

    if (birthYear === maxYear && birthMonth < maxMonth) {
      return true;
    }

    if (birthYear === maxYear && birthMonth === maxMonth && birthDay > maxDay) {
      return false;
    }

    if (
      birthYear === maxYear &&
      birthMonth === maxMonth &&
      birthDay <= maxDay
    ) {
      return true;
    }
    if (needBdayCheck) {
      const maxDateAndYear = new Date();
      maxDateAndYear.setFullYear(maxDateAndYear.getFullYear() - allowedAge);
      if (
        Date.parse(new Date(`${date[0]}/${date[1]}/${date[2]}`)) >
        Date.parse(maxDateAndYear)
      ) {
        return false;
      }
    }
    return true;
  }

  function ageCheck() {
    const birthday = new Date(`${date[0]}/${date[1]}/${date[2]}`);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    const epochYear = 1970;
    return Math.abs(ageDate.getUTCFullYear() - epochYear);
  }

  function monthValidation() {
    return {
      required: (
        <Trans ns="limitedLangValidations" i18nKey="required">
          {{ fieldName: "Month" }}
        </Trans>
      ),
      min: {
        value: 1,
        message: (
          <Trans ns="limitedLangValidations" i18nKey="range">
            {{ max: 12 }}
          </Trans>
        ),
      },
      max: {
        value: 12,
        message: (
          <Trans ns="limitedLangValidations" i18nKey="range">
            {{ max: 12 }}
          </Trans>
        ),
      },
    };
  }

  function dayValidation() {
    return {
      required: (
        <Trans ns="limitedLangValidations" i18nKey="required">
          {{ fieldName: "Day" }}
        </Trans>
      ),
      min: {
        value: 1,
        message: (
          <Trans ns="limitedLangValidations" i18nKey="range">
            {{ max: maxDaysAllowed }}
          </Trans>
        ),
      },
      max: {
        value: maxDaysAllowed,
        message: (
          <Trans ns="limitedLangValidations" i18nKey="range">
            {{ max: maxDaysAllowed }}
          </Trans>
        ),
      },
      pattern: {
        value: /^[0-9]+$/,
        message: (
          <Trans ns="limitedLangValidations" i18nKey="numbersOnly"></Trans>
        ),
      },
    };
  }

  function yearValidation() {
    return {
      required: (
        <Trans ns="limitedLangValidations" i18nKey="required">
          {{ fieldName: "Year" }}
        </Trans>
      ),
      min: {
        value: 1900,
        message: (
          <Trans ns="limitedLangValidations" i18nKey="after">
            {{ year: 1900 }}
          </Trans>
        ),
      },
      pattern: {
        value: /^[0-9]+$/,
        message: <Trans ns="limitedLangValidations" i18nKey="numbersOnly" />,
      },
    };
  }

  function minorYearValidation() {
    return {
      required: (
        <Trans ns="limitedLangValidations" i18nKey="required">
          {{ fieldName: "Year" }}
        </Trans>
      ),
      pattern: {
        value: /^[0-9]+$/,
        message: <Trans ns="limitedLangValidations" i18nKey="numbersOnly" />,
      },
    };
  }

  if (field === "month") {
    return monthValidation();
  }

  if (field === "day") {
    return dayValidation();
  }

  if (field === "minorYear") {
    return minorYearValidation();
  }

  if (field === "year") {
    return yearValidation();
  }

  if (field === "birthdate") {
    return birthdayCheck();
  }

  if (field === "age") {
    return ageCheck();
  }

  if (!needBdayCheck) {
    return true;
  }

  return {
    validate: () => birthdayCheck() || errorMessage,
  };
}

export const dayValidation = {
  required: (
    <Trans ns="limitedLangValidations" i18nKey="required">
      {{ fieldName: "Day" }}
    </Trans>
  ),
  min: {
    value: 1,
    message: <Trans ns="limitedLangValidations" i18nKey="dayDefaultRange" />,
  },
  max: {
    value: 31,
    message: <Trans ns="limitedLangValidations" i18nKey="dayDefaultRange" />,
  },
  pattern: {
    value: /^[0-9]+$/,
    message: <Trans ns="limitedLangValidations" i18nKey="numbersOnly" />,
  },
};

export const yearValidation = {
  required: (
    <Trans ns="limitedLangValidations" i18nKey="required">
      {{ fieldName: "Year" }}
    </Trans>
  ),
  min: {
    value: 1900,
    message: (
      <Trans ns="limitedLangValidations" i18nKey="yearRange">
        {{ currentYear: new Date().getFullYear() }}
      </Trans>
    ),
  },
  max: {
    value: new Date().getFullYear(),
    message: (
      <Trans ns="limitedLangValidations" i18nKey="yearRange">
        {{ currentYear: new Date().getFullYear() }}
      </Trans>
    ),
  },
  pattern: {
    value: /^[0-9]+$/,
    message: <Trans ns="limitedLangValidations" i18nKey="numbersOnly" />,
  },
};

function leapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export const maxDaysInMonth = (month, year) => {
  let day = 31;
  if ([4, 6, 9, 11].indexOf(month) > -1) {
    day = 30;
  } else if (month === 2) {
    day = leapYear(year) ? 29 : 28;
  }
  return day;
};

export const checkValidDate = (day, month, year, setError, clearErrors) => {
  const daysInAMonth = maxDaysInMonth(parseInt(month), year);
  const selectedDate = new Date(year, month, day);

  if (day && month && year) {
    if (day > daysInAMonth) {
      setError("birth_day", {
        type: "invalidRange",
        message: (
          <Trans ns="limitedLangValidations" i18nKey="range">
            {{ max: daysInAMonth }}
          </Trans>
        ),
      });
      return false;
    } else if (selectedDate > new Date()) {
      setError("birth_day", {
        type: "invalidDate",
        message: <Trans ns="limitedLangValidations" i18nKey="past" />,
      });
      setError("birth_year", {
        type: "invalidDate",
        message: <Trans ns="limitedLangValidations" i18nKey="past" />,
      });
      return false;
    }

    clearErrors("birth_day");
    clearErrors("birth_year");
    return true;
  }
};

export const checkValidDateLegacy = (
  dateOfBirthValue,
  setError,
  clearErrors,
) => {
  const [monthValue, dayValue, yearValue] = dateOfBirthValue;
  const daysInAMonth = maxDaysInMonth(parseInt(monthValue), yearValue);

  if (dayValue) {
    if (dayValue > daysInAMonth) {
      setError("day", {
        type: "focus",
        message: (
          <Trans ns="limitedLangValidations" i18nKey="range">
            {{ max: daysInAMonth }}
          </Trans>
        ),
      });
      return false;
    }

    clearErrors("day");
    return true;
  }
};

export function dateInputYearValidation() {
  return {
    validate: {
      validYearFormat: (value) => {
        const year = parseInt(value.split("-")[0], 10);
        if (isNaN(year) || value.length !== 10 || year > 9999) {
          return (
            <Trans
              ns="limitedLangValidations"
              i18nKey="invalidFormat.yearFormat"
            ></Trans>
          );
        }
        return true;
      },
      validYear: (value) => {
        const year = parseInt(value.split("-")[0], 10);
        const currentYear = new Date().getFullYear();
        if (year < 1900 || year > currentYear) {
          return (
            <Trans
              ns="limitedLangValidations"
              i18nKey="invalidFormat.year"
            ></Trans>
          );
        }
        return true;
      },
    },
  };
}

export function booleanValidation(field, value) {
  return {
    validate: () => {
      if (value === "true" || value === "false") {
        return true;
      }
      return (
        <Trans i18nKey="required" ns="limitedLangValidations">
          {{ fieldName: field }}
        </Trans>
      );
    },
  };
}
