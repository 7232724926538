import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get, getOr } from "lodash/fp";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Stack,
  Text,
} from "design-system/components";
import { Switch } from "@chakra-ui/react";
import { RightArrow } from "design-system/assets";
import { trackFormSubmitted } from "./analytics";
import { addNotification } from "@spring/smeargle/actions";
import { useDispatch } from "react-redux";

import { manageUserSubscription } from "operations/mutations/user";
import { getUserSubscriptions } from "operations/queries/user";
import { isT2Member } from "utils/memberHelpers";
import { useMutation, useQuery } from "@apollo/client";

interface Props {
  data: {
    user: {
      id: string;
      member: {
        id: string;
      };
    };
  };
}

const MemberEmailNotifications = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("limitedLangSettings");

  const { data: userSubscriptions, loading: userSubscriptionsLoading } =
    useQuery(getUserSubscriptions, {
      variables: { id: props.data.user.id },
    });

  interface ToggleIds {
    appointmentEmailIds: number[];
    checkInEmailIds: number[];
    newsAndAnnouncementsEmailIds: number[];
  }

  const toggles: ToggleIds = {
    appointmentEmailIds: [
      +get(
        "user.subscriptions.appointment_reminders_email.id",
        userSubscriptions,
      ),
    ],
    checkInEmailIds: [
      +get("user.subscriptions.progress_checkins_email.id", userSubscriptions),
    ],
    newsAndAnnouncementsEmailIds: [
      +get(
        "user.subscriptions.news_and_announcements_email.id",
        userSubscriptions,
      ),
    ],
  };

  const appointmentSubscription = get(
    "user.subscriptions.appointment_reminders_email.subscribed",
    userSubscriptions,
  );
  const checkInsSubscription = get(
    "user.subscriptions.progress_checkins_email.subscribed",
    userSubscriptions,
  );
  const newsAndAnnouncmentsSubscription = get(
    "user.subscriptions.news_and_announcements_email.subscribed",
    userSubscriptions,
  );

  useEffect(() => {
    setAppointmentToggle(appointmentSubscription);
    setCheckInToggle(checkInsSubscription);
    setNewsAndAnnouncementsToggle(newsAndAnnouncmentsSubscription);
    setAppointmentSubscribedValue(appointmentSubscription);
    setCheckInSubscribedValue(checkInsSubscription);
    setNewsAndAnnouncementsSubscribedValue(newsAndAnnouncmentsSubscription);
  }, [userSubscriptionsLoading]);

  // current value of the toggle
  const [appointmentToggle, setAppointmentToggle] = useState(
    appointmentSubscription,
  );
  const [checkInToggle, setCheckInToggle] = useState(checkInsSubscription);
  const [newsAndAnnouncementsToggle, setNewsAndAnnouncementsToggle] = useState(
    newsAndAnnouncmentsSubscription,
  );

  // current state of the actual subscribed value in iterable
  const [appointmentSubscribedValue, setAppointmentSubscribedValue] = useState(
    appointmentSubscription,
  );
  const [checkInSubscribedValue, setCheckInSubscribedValue] =
    useState(checkInsSubscription);
  const [
    newsAndAnnouncementsSubscribedValue,
    setNewsAndAnnouncementsSubscribedValue,
  ] = useState(newsAndAnnouncmentsSubscription);

  const isMemberT2 = isT2Member(getOr({}, "data.user.member", props));

  const appointmentReminders =
    userSubscriptions?.user?.subscriptions?.appointment_reminders_email;
  const progressCheckIns =
    userSubscriptions?.user?.subscriptions?.progress_checkins_email;
  const newsAndAnnouncements =
    userSubscriptions?.user?.subscriptions?.news_and_announcements_email;

  const [submitSubscriptionChange] = useMutation(manageUserSubscription, {
    refetchQueries: ["getUserSubscriptions"],
  });

  const submitEmailNotifications = async () => {
    try {
      const promises = [];
      let mutationSuccessful = true;

      //if toggle subscription value hasn't changed, do not make graphql request
      if (appointmentToggle !== appointmentSubscribedValue) {
        toggles.appointmentEmailIds.map((id) => {
          const payload = {
            group_id: id,
            group_type: "messageType",
            user_id: props.data.user.member.id,
            entity_type: "MEMBER",
            subscribed: !appointmentToggle,
          };

          trackFormSubmitted(
            "Update Appointment Reminders Notifications",
            "Email Notifications",
            appointmentToggle.toString(),
            "settings01",
          );
          promises.push(
            submitSubscriptionChange({ variables: { input: payload } }),
          );
          setAppointmentSubscribedValue(appointmentToggle);
        });
      }

      if (checkInToggle !== checkInSubscribedValue) {
        toggles.checkInEmailIds.map((id) => {
          const payload = {
            group_id: id,
            group_type: "messageType",
            user_id: props.data.user.member.id,
            entity_type: "MEMBER",
            subscribed: !checkInToggle,
          };

          trackFormSubmitted(
            "Update Progress Check-Ins Notifications",
            "Email Notifications",
            checkInToggle.toString(),
            "settings02",
          );
          promises.push(
            submitSubscriptionChange({ variables: { input: payload } }),
          );
          setCheckInSubscribedValue(checkInToggle);
        });
      }

      if (newsAndAnnouncementsToggle !== newsAndAnnouncementsSubscribedValue) {
        toggles.newsAndAnnouncementsEmailIds.map((id) => {
          const payload = {
            group_id: id,
            group_type: "messageType",
            user_id: props.data.user.member.id,
            entity_type: "MEMBER",
            subscribed: !newsAndAnnouncementsToggle,
          };

          trackFormSubmitted(
            "Update News and Announcements Notifications",
            "Email Notifications",
            newsAndAnnouncementsToggle.toString(),
            "settings03",
          );
          promises.push(
            submitSubscriptionChange({ variables: { input: payload } }),
          );
          setNewsAndAnnouncementsSubscribedValue(newsAndAnnouncementsToggle);
        });
      }

      // confirm if mutation is successful before showing success or error
      await Promise.all(promises).then((results) => {
        results.map((res) => {
          if (!res.data.manageUserSubscription.success) {
            mutationSuccessful = false;
            return;
          }
          return;
        });

        if (!mutationSuccessful) {
          return dispatch(
            addNotification(t("notifications.genericError"), "error"),
          );
        } else if (mutationSuccessful) {
          return dispatch(
            addNotification(t("notifications.changesSaved"), "success"),
          );
        }
        return;
      });
    } catch (_error) {
      dispatch(addNotification(t("notifications.genericError"), "error"));
    }
  };

  return !!appointmentReminders ||
    !!progressCheckIns ||
    !!newsAndAnnouncements ? (
    <>
      <Heading fontSize={20} fontWeight={"bold"} mb={14} color="#4b4b4b">
        {t("emailNotifications.title")}
      </Heading>
      <FormControl as="fieldset">
        <legend hidden>{t("emailNotifications.title")}</legend>
        <Stack>
          <Flex direction="row" align="start" pt={3}>
            <Switch
              id="appointmentToggle"
              aria-labelledby="appointmentToggleLabel"
              isChecked={appointmentToggle}
              colorScheme="primary"
              onChange={() => setAppointmentToggle(!appointmentToggle)}
              pr={3.5}
              pt={0.5}
              sx={{
                ".chakra-switch__track": {
                  _focus: { boxShadow: "0 0 0 3px black" },
                },
              }}
              _focusVisible={{ boxShadow: "0 0 0 3px black" }}
            />
            <Flex direction="column" align="start">
              <FormLabel id="appointmentToggleLabel" fontWeight="bold">
                {appointmentReminders?.title}
              </FormLabel>
              <FormHelperText
                id="appointmentToggleHelperText"
                color="platform.600"
                fontSize="sm"
              >
                {appointmentReminders?.description}
              </FormHelperText>
            </Flex>
          </Flex>

          {!isMemberT2 && (
            <Flex direction="row" align="start" pt={3}>
              <Switch
                id="checkInToggle"
                aria-labelledby="checkInToggleLabel"
                isChecked={checkInToggle}
                colorScheme="primary"
                onChange={() => setCheckInToggle(!checkInToggle)}
                pr={3.5}
                pt={0.5}
                sx={{
                  ".chakra-switch__track": {
                    _focus: { boxShadow: "0 0 0 3px black" },
                  },
                }}
                _focusVisible={{ boxShadow: "0 0 0 3px black" }}
              />
              <Flex direction="column" align="start">
                <FormLabel id="checkInToggleLabel" fontWeight="bold">
                  {progressCheckIns?.title}
                </FormLabel>
                <FormHelperText
                  id="checkInToggleHelperText"
                  color="platform.600"
                  fontSize="sm"
                >
                  {progressCheckIns?.description}
                </FormHelperText>
              </Flex>
            </Flex>
          )}

          <Flex direction="row" align="start" pt={3}>
            <Switch
              id="newsAndAnnouncementsToggle"
              aria-labelledby="newsAndAnnouncementsToggleLabel"
              isChecked={newsAndAnnouncementsToggle}
              colorScheme="primary"
              onChange={() =>
                setNewsAndAnnouncementsToggle(!newsAndAnnouncementsToggle)
              }
              pr={3.5}
              pt={0.5}
              sx={{
                ".chakra-switch__track": {
                  _focus: { boxShadow: "0 0 0 3px black" },
                },
              }}
              _focusVisible={{ boxShadow: "0 0 0 3px black" }}
            />
            <Flex direction="column" align="start" mb={6}>
              <FormLabel id="newsAndAnnouncementsToggleLabel" fontWeight="bold">
                {newsAndAnnouncements?.title}
              </FormLabel>
              <FormHelperText
                id="newsAndAnnouncementsToggleHelperText"
                color="platform.600"
                fontSize="sm"
              >
                {newsAndAnnouncements?.description}
              </FormHelperText>
            </Flex>
          </Flex>
          <Flex justify="end">
            <Button
              h={58}
              w={150}
              variant="solid"
              colorScheme="primary"
              background="#007e5e"
              borderRadius={3}
              isDisabled={userSubscriptionsLoading || false}
              onClick={() => submitEmailNotifications()}
            >
              <Flex justify="end">
                <Text fontWeight="bold" mr={16}>
                  {t("common:form.submitText")}
                </Text>
                <RightArrow />
              </Flex>
            </Button>
          </Flex>
        </Stack>
      </FormControl>
    </>
  ) : null;
};

export default MemberEmailNotifications;
