import React from "react";
import styles from "./styles.module.scss";
import {
  CareNavigatorIcon,
  CoachIcon,
  MedicationManagerIcon,
  TherapistIcon,
  Avatar,
} from "@spring/smeargle";
import { Flex, useMediaQuery, Box } from "design-system/components";
import { ProviderRole } from "@spring/constants";
import ModalHeading from "./ModalHeading";
import { CareProviderSubhead } from "components/molecules";
import { CareProvider } from "modules/shared/graphql-codegen/graphql";
import { useTranslation } from "hooks/react-i18next";
import { Button } from "@springcare/sh-component-library";
import { Text, Heading } from "@chakra-ui/react";
import { VWorkplaceIcon } from "@springcare/verdant-icons-react";

import { Consultations } from "components/templates/Browse/ProviderBrowsePage/components/ProviderCard/Consultations";
import ProfileVideoPlayButton from "components/molecules/ProfileVideoPlayer/ProfileVideoPlayButton";
import useProfileVideos from "hooks/useProfileVideos";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { CalendarPrimary } from "design-system/index";
import { TRACK_EVENT } from "utils/mixpanel";

const ICON_COLOR = "rgba(6, 130, 98, 1)";
const ICON_SIZE = "18px";

interface CareProviderOverviewProps {
  isBrowseOrRecs: boolean;
  shouldShowNoCostBadge: boolean;
  careProvider: CareProvider;
  therapyTopSpecialtiesFlag: boolean;
  providerRole: string;
  supportsProviderConsultation: boolean;
  schedulingRef?: React.RefObject<HTMLDivElement>;
  memberName: string;
  customRoleName?: string;
  isMultiRole?: boolean;
}

const getIcon = (providerRole, isMultiRole) => {
  if (!providerRole) {
    return (
      <MedicationManagerIcon
        width={ICON_SIZE}
        height={ICON_SIZE}
        color={ICON_COLOR}
      />
    );
  }

  if (isMultiRole) {
    return (
      <VWorkplaceIcon width={ICON_SIZE} height={ICON_SIZE} color={ICON_COLOR} />
    );
  }

  providerRole = providerRole.replace(" ", "_");

  switch (providerRole.toLowerCase()) {
    case ProviderRole.CareNavigator:
    case ProviderRole.CareAdvocate:
    case ProviderRole.CareConsultant:
      return (
        <CareNavigatorIcon
          svgProps={{ width: ICON_SIZE, height: ICON_SIZE }}
          color={ICON_COLOR}
        />
      );
    case ProviderRole.Therapist:
    case ProviderRole.AssociateProvider:
      return (
        <TherapistIcon
          svgProps={{ width: ICON_SIZE, height: ICON_SIZE }}
          color={ICON_COLOR}
        />
      );
    case ProviderRole.Coach:
      return (
        <CoachIcon height={ICON_SIZE} width={ICON_SIZE} color={ICON_COLOR} />
      );
    case ProviderRole.MedicationManager:
      return (
        <MedicationManagerIcon
          width={ICON_SIZE}
          height={ICON_SIZE}
          color={ICON_COLOR}
        />
      );
    default:
      return (
        <MedicationManagerIcon
          width={ICON_SIZE}
          height={ICON_SIZE}
          color={ICON_COLOR}
        />
      );
  }
};

const CareProviderOverview = ({
  isBrowseOrRecs,
  shouldShowNoCostBadge,
  careProvider,
  therapyTopSpecialtiesFlag,
  providerRole,
  supportsProviderConsultation,
  schedulingRef,
  memberName,
  customRoleName,
  isMultiRole,
}: CareProviderOverviewProps) => {
  const { captionsUrl, showVideoProfile, videoUrl } = useProfileVideos(
    careProvider.id,
  );

  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [isTablet] = useMediaQuery("(max-width: 960px)");
  const { t } = useTranslation("careProvider");

  const showBookNowButton = useFeatureFlag(
    FLAGS.PROVIDER_PROFILE_BOOK_NOW_BUTTON,
  );

  const handleBookButton = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Member clicked book now button",
    );
    schedulingRef?.current?.scrollIntoView();
  };

  return (
    <Box mb={supportsProviderConsultation && -8}>
      <Flex
        mt={isMobile && isBrowseOrRecs && shouldShowNoCostBadge ? "-35px" : ""}
        alignItems="start"
        flexDirection="row"
        flexWrap="wrap"
        className={styles.providerContainer}
      >
        <Box h="116px" pr={16} position="relative">
          <div className={styles.avatar}>
            <Box position="relative">
              <Avatar
                src={careProvider.avatar}
                imgProps={{ alt: "" }}
                height={104}
                width={104}
                round
              />
              {showVideoProfile && (
                <ProfileVideoPlayButton
                  captionsUrl={captionsUrl}
                  name={careProvider.name}
                  videoUrl={videoUrl}
                />
              )}
            </Box>
          </div>
          {!showVideoProfile && ( //Hide the provider role badge if video profile is enabled
            <Flex
              w={40}
              h={40}
              bg="background-base"
              borderColor="border-subtle"
              borderWidth={1}
              borderRadius="50%"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              bottom={0}
            >
              {getIcon(providerRole, isMultiRole)}
            </Flex>
          )}
        </Box>
        <Box
          marginTop={"auto"}
          marginBottom={"auto"}
          style={{ marginLeft: 0, alignItems: "start" }}
          className={styles.providerInfo}
          flexBasis={"50%"}
        >
          <div className={styles.providerName}>
            <ModalHeading
              providerName={careProvider.name}
              careProvider={careProvider}
              size={isMobile ? "heading-small" : "heading-medium"}
            />
          </div>
          <div
            style={{ paddingTop: "unset", marginTop: "-8px" }}
            className={styles.providerRoles}
          >
            <Text
              size={isMobile && "body-small-regular"}
              color="#content-secondary"
              width="100%"
            >
              <CareProviderSubhead
                roles={careProvider.roles}
                licenses={careProvider.licenses}
                categories={careProvider.categories}
                associateStatus={careProvider.associate_status}
                showNewProfilePage
                isMobile={isMobile}
                customRoleName={customRoleName}
              />
            </Text>
            {therapyTopSpecialtiesFlag &&
              !isBrowseOrRecs &&
              careProvider?.top_tags?.length > 0 && (
                <Flex
                  direction={["column", "row"]}
                  align={["center", "left"]}
                  alignItems={"baseline"}
                >
                  <Text
                    whiteSpace={"nowrap"}
                    variant="bodyBold_v1"
                    width={["100%", "auto"]}
                    textAlign={["center", "left"]}
                    mr={4}
                  >
                    {t("topSpecialties")}
                  </Text>
                  <Text
                    w="100%"
                    variant="body_v1"
                    color="#737778"
                    textAlign={["center", "left"]}
                  >
                    {[...careProvider.top_tags]?.sort()?.join(", ")}
                  </Text>
                </Flex>
              )}
          </div>
        </Box>

        {showBookNowButton && (
          <Box flex="1" flexBasis={isTablet ? "100%" : "auto"}>
            <Flex justifyContent="flex-end">
              <Button
                onClick={handleBookButton}
                icon={CalendarPrimary}
                colorScheme="primary"
                _focus={{ textColor: "#F1FCF9" }}
                w={isTablet ? "100%" : "180px"}
                mt={isTablet ? "16px" : "0"}
                h="48px"
              >
                {t("bookNow")}
              </Button>
            </Flex>
          </Box>
        )}
      </Flex>
      {supportsProviderConsultation && (
        <Box pt={32}>
          <Heading size="heading-small" pb={16}>
            {t("consultation.heading")}
          </Heading>
          <Consultations memberName={memberName} provider={careProvider} />
          <Box pt={16} borderBottom="1px solid #EFEDE8"></Box>
        </Box>
      )}
    </Box>
  );
};

export default CareProviderOverview;
