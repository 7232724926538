import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import {
  PROVIDER_PROFILE_VIDEOS_BASE_URL_MAP,
  PROVIDER_PROFILE_VIDEOS_MAP,
} from "constants/providerProfileVideosMap.js";

type UseProfileVideosReturn = {
  captionsUrl?: string;
  showVideoProfile: boolean;
  videoUrl?: string;
};

const useProfileVideos = (providerId: string): UseProfileVideosReturn => {
  const appEnv = process.env.APP_ENV;
  const videoProfileEnabled = useFeatureFlag(FLAGS.PROVIDER_PROFILE_VIDEOS);

  const videoIds = PROVIDER_PROFILE_VIDEOS_MAP[appEnv] || [];
  const baseUrl =
    PROVIDER_PROFILE_VIDEOS_BASE_URL_MAP[appEnv] ||
    "https://provider-profile-videos.dev.springtest.us/";

  const videoUrl = videoIds.includes(providerId)
    ? `${baseUrl}${providerId}.mp4`
    : undefined;

  // Only English captions are available for now
  const captionsUrl = videoIds.includes(providerId)
    ? `${baseUrl}subtitles/en/${providerId}.vtt`
    : undefined;

  return {
    captionsUrl: captionsUrl,
    showVideoProfile: videoProfileEnabled && !!videoUrl && !!captionsUrl,
    videoUrl: videoUrl,
  };
};

export default useProfileVideos;
