import React, { useRef, useState } from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { InsuranceForm } from "./InsuranceForm";
import { TRACK_EVENT } from "utils/mixpanel";
import { useInViewportOnce } from "hooks";
import { returnTherapyCoveredAndRemaining } from "modules/shared/utils";
import { InsuranceValidationForm } from "components/templates/CostEstimation/components/shared/InsuranceValidationForm";
import { useCustomToast } from "@springcare/sh-component-library";
import Router from "next/router";
import routes from "routes/index";
import { useInsuranceValidationExperience } from "components/templates/CostEstimation/hooks/useInsuranceValidationExperience";
import { CostEstimateInsuranceTypeEnum } from "constants/insurance";
import { useMutation } from "@apollo/client";
import { getMemberInsurance } from "operations/queries/member";
import updateMemberPaymentPreference from "operations/mutations/member/updateMemberPaymentPreference";

export const InsuranceFormWrapper = ({
  memberInsuranceInfo,
  changeDisplay,
}) => {
  const { t } = useTranslation(["costEstimate", "insurance"]);
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);
  const { remainingTherapy } =
    returnTherapyCoveredAndRemaining(memberInsuranceInfo);

  const showInsuranceValidationForm = useInsuranceValidationExperience();

  const successToast = useCustomToast({
    type: "success",
    message: t("modal.form.success.message"),
    buttonText: t("modal.form.success.actionButtonText"),
    buttonAction: () =>
      Router.push(routes.MemberSettings.to, routes.MemberSettings.as),
    layout: "fit-content",
    action: "on",
  });

  const successToastSelfPay = useCustomToast({
    type: "success",
    message: t("modal.form.selfPaySuccess.message"),
    buttonText: t("modal.form.selfPaySuccess.actionButtonText"),
    buttonAction: () =>
      Router.push(routes.MemberSettings.to, routes.MemberSettings.as),
    layout: "fit-content",
    action: "on",
  });

  const showToastAndTransition = () => {
    successToast();
    changeDisplay();
  };

  const [updateInsurance] = useMutation(updateMemberPaymentPreference, {
    refetchQueries: [getMemberInsurance],
    onCompleted: (data) => {
      if (data?.updateMemberInsurancePolicy?.success) {
        successToastSelfPay();
        changeDisplay();
      }
    },
  });

  const handleSelfPayRequestClick = async () => {
    await updateInsurance({
      variables: {
        member_id: memberInsuranceInfo?.user?.member?.id,
        insurance_type: CostEstimateInsuranceTypeEnum.noInsurance,
        trigger_cost_estimate_zendesk_ticket: false,
      },
    });
  };

  if (inViewport && !trackedOnce) {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      window.location.pathname,
      "Add insurance info",
      {
        in_network: String(memberInsuranceInfo?.user?.member.cohort.in_network),
        available_sessions: String(remainingTherapy),
      },
    );
    setTrackedOnce(true);
  }

  if (showInsuranceValidationForm) {
    return (
      <InsuranceValidationForm
        memberInsuranceInfo={memberInsuranceInfo}
        renderInputProps={{
          border: "hidden",
          variant: "medium-emphasis",
        }}
        submitButtonProps={{
          width: "fit-content",
          float: "right",
        }}
        submitButtonText={t("insurance:form.saveAndContinue")}
        onValidatedInsuranceInfoSave={() => showToastAndTransition()}
        onNonValidatedInsuranceInfoSave={() => showToastAndTransition()}
        onSelfPayInfoSave={() => handleSelfPayRequestClick()}
      />
    );
  }

  return (
    <Box width="100%" maxWidth="728px" paddingX={[0, 8, 8]} mx="auto" ref={ref}>
      <Text>{t("modal.insuranceSubheading")}</Text>

      <Heading size="heading-small" as="h3" fontSize={16} marginTop={20}>
        {t("modal.form.heading")}
      </Heading>
      <Text marginBottom={16}>{t("modal.form.subHeading")}</Text>

      <InsuranceForm
        memberInsuranceInfo={memberInsuranceInfo}
        changeDisplay={changeDisplay}
      />
    </Box>
  );
};
