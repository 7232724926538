import {
  useParticipantMetadata,
  useSessionRecording,
} from "context/SessionRoomContext";
import { useEffect } from "react";
import useUpdateSessionConsents from "components/templates/SHSessionRoom/hooks/useUpdateSessionConsents";

export const useOnConsentResponse = () => {
  const { appointmentId } = useParticipantMetadata();
  const {
    setOnConsentResponse,
    sessionConsents,
    setRequestingConsent,
    setSessionConsents,
  } = useSessionRecording();
  const updateConsents = useUpdateSessionConsents();
  useEffect(() => {
    if (sessionConsents?.care_provider_consent) {
      setOnConsentResponse(() => (consent) => {
        if (consent) {
          // Action can only be recorded by a care provider requesting consent from a member
          updateConsents({ appointmentId, memberConsent: true }).then(() => {
            setSessionConsents({
              ...sessionConsents,
              member_consent: true,
            });
          });
        } else {
          setRequestingConsent(false);
        }
      });
    } else {
      setOnConsentResponse(() => () => {
        setRequestingConsent(false);
      });
    }
  }, [sessionConsents, appointmentId]);
};
