import { gql } from "@apollo/client";

const updateMemberInsurance = gql`
  mutation UpdateMemberInsurancePolicy(
    $member_id: ID!
    $carrier_name: String
    $insurance_payer_id: ID
    $insurance_member_id: String
    $insurance_group_id: String
    $plan_name: String
    $insurance_card_front_id: ID
    $insurance_card_back_id: ID
    $insurance_type: String
    $trigger_cost_estimate_zendesk_ticket: Boolean
  ) {
    updateMemberInsurancePolicy(
      member_id: $member_id
      carrier_name: $carrier_name
      insurance_payer_id: $insurance_payer_id
      insurance_member_id: $insurance_member_id
      insurance_group_id: $insurance_group_id
      plan_name: $plan_name
      insurance_card_front_id: $insurance_card_front_id
      insurance_card_back_id: $insurance_card_back_id
      insurance_type: $insurance_type
      trigger_cost_estimate_zendesk_ticket: $trigger_cost_estimate_zendesk_ticket
    ) {
      member_insurance_policy {
        id
        carrier_name
        insurance_payer_id
        insurance_group_id
        insurance_member_id
        plan_name
        insurance_card_back_id
        insurance_card_front_id
        member {
          id
          previsit {
            id
            can_schedule
          }
        }
      }
      success
    }
  }
`;

export default updateMemberInsurance;
