import { useRef, useState } from "react";
import {
  Button,
  Input,
  Badge,
  Link,
  useCustomToast,
  VerdantVariantsType,
} from "@springcare/sh-component-library";
import {
  Box,
  Flex,
  Checkbox,
  Text,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  FormErrorMessage,
} from "@chakra-ui/react";
import { VArrowRightIcon } from "@springcare/verdant-icons-react";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { InfoIcon } from "design-system/assets";
import { getMemberInsurance, getMemberInfo } from "operations/queries/member";
import { DocumentUploadButton } from "./DocumentUploadButton";
import {
  COST_ESTIMATE_DISPLAY_STATES,
  ValueOfCostEstimateDisplayStates,
} from "components/templates/CostEstimation/constants";
import { CostEstimateInsuranceTypeEnum } from "constants/insurance";
import { MemberInsuranceData } from "components/templates/CostEstimation/types";
import { TRACK_EVENT } from "utils/mixpanel";
import { isEmpty } from "lodash";
import Router from "next/router";
import routes from "routes";
import {
  useAddS3DocumentToMember,
  useUpdateMemberInsurance,
} from "components/templates/CostEstimation/hooks";
import { useUpdateMember } from "modules/shared";

type RequestCostEstimateFormData = {
  primaryInsuranceCarrier: string;
  planName: string;
  groupId: string;
  insuranceMemberId: string;
  frontOfCard: File;
  backOfCard: File;
  finishLater?: boolean;
};

type DisplayStates = { [key: string]: ValueOfCostEstimateDisplayStates };

type InsuranceFormProps = {
  showUploadHelpText?: boolean;
  memberInsuranceInfo: MemberInsuranceData;
  changeDisplay?: (displayState: ValueOfCostEstimateDisplayStates) => void;
  displayState?: DisplayStates;
  isSaveInsuranceOperation?: boolean;
  showSelfPayCheckbox?: boolean;
  onSuccess?: () => void;
  primaryCTAVariant?: string;
  renderInputProps?: InputStylingProps;
};

type InputStylingProps = {
  borderRadius?: string;
  borderWidth?: string;
  border?: string;
  variant: VerdantVariantsType;
};

const getSubmitClickTelemetryType = (
  isSaveInsuranceOperation: boolean,
  finishLater: boolean,
) => {
  if (isSaveInsuranceOperation) {
    return "Save Insurance Info";
  } else {
    return !finishLater ? "Submit cost estimate" : "Save and finish later";
  }
};

const getSubmitClickTelemetryLocation = (
  isSaveInsuranceOperation: boolean,
  currentPath: string,
) => {
  if (!isSaveInsuranceOperation || currentPath === routes.MemberSettings.as) {
    return "Verify Insurance";
  }
  return "Appointment Booking Gating Flow";
};

export const InsuranceForm = ({
  memberInsuranceInfo,
  primaryCTAVariant = undefined,
  changeDisplay,
  isSaveInsuranceOperation = false,
  showSelfPayCheckbox = true,
  onSuccess,
  showUploadHelpText = true,
  renderInputProps,
}: InsuranceFormProps) => {
  const { t } = useTranslation("insurance");
  const [insurancePolicyInfo, setInsurancePolicyInfo] = useState(
    memberInsuranceInfo?.user?.member?.insurance_policy,
  );
  /*
    Need to add the isSaveInsurance otherwise the form is disabled if the member is actually self paying
    even if the member might want to add their insurance in
  */
  const isSelfPaying =
    showSelfPayCheckbox &&
    isSaveInsuranceOperation &&
    memberInsuranceInfo?.user?.member?.payment_preference === "OUT_OF_POCKET";
  const [frontOfCardUrlObjectKey, setFrontOfCardUrlObjectKey] = useState(null);
  const [frontOfCardFileIsLoading, setFrontOfCardFileIsLoading] =
    useState(false);
  const [backOfCardUrlObjectKey, setBackOfCardUrlObjectKey] = useState(null);
  const [backOfCardFileIsLoading, setBackOfCardFileIsLoading] = useState(false);
  const [showFileUploadDisclaimer, setShowFileUploadDisclaimer] =
    useState(false);
  const [isSelfPayingFormValue, setIsSelfPayingFormValue] = useState(
    isSelfPaying && isSaveInsuranceOperation,
  );
  const ref = useRef();

  const initialFormValues = {
    primaryInsuranceCarrier: insurancePolicyInfo?.carrier_name,
    planName: insurancePolicyInfo?.plan_name,
    groupId: insurancePolicyInfo?.insurance_group_id,
    insuranceMemberId: insurancePolicyInfo?.insurance_member_id,
  };
  const userId = memberInsuranceInfo?.user?.id;
  const memberId = memberInsuranceInfo?.user?.member?.id;

  const isDisabledButton = () => {
    if (isSelfPayingFormValue) {
      return !isSelfPayingFormValue || !userId || !memberId;
    }

    return (
      !isValid ||
      isSubmitting ||
      frontOfCardFileIsLoading ||
      backOfCardFileIsLoading ||
      !userId ||
      !memberId
    );
  };

  const successToast = useCustomToast({
    type: "success",
    message: t("form.insuranceUpdateSuccess"),
    layout: "fit-content",
    duration: 5000,
  });

  const errorToast = useCustomToast({
    type: "error",
    message: t("form.errors.somethingWentWrong"),
    layout: "fit-content",
    duration: 5000,
  });

  const [uploadDocumentMutation] = useAddS3DocumentToMember({
    onError: (err) => errorToast(),
  });

  const [updateMemberMutation] = useUpdateMember({
    onError: (err) => errorToast(),
  });

  const [updateInsurance] = useUpdateMemberInsurance({
    refetchQueries: [getMemberInsurance],
    onError: (err) => errorToast(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { isSubmitting, errors, isValid },
  } = useForm<RequestCostEstimateFormData>({
    defaultValues: initialFormValues,
    mode: "onBlur",
  });

  const getEmptyFields = (data: Record<string, any>): string[] => {
    return Object.entries(data).reduce((accumulator, [key, value]) => {
      if (["frontOfCard", "backOfCard"].includes(key)) {
        if (
          value instanceof File ||
          (value instanceof FileList && value.length > 0)
        ) {
          return accumulator;
        }

        const frontOfCardId =
          key === "frontOfCard" && value !== null
            ? insurancePolicyInfo?.insurance_card_front_id
            : null;
        if (!frontOfCardId && key === "frontOfCard") {
          return [...accumulator, key];
        }

        const backOfCardId =
          key === "backOfCard" && value !== null
            ? insurancePolicyInfo?.insurance_card_back_id
            : null;
        if (!backOfCardId && key === "backOfCard") {
          return [...accumulator, key];
        }
      } else if (!value) {
        return [...accumulator, key];
      }

      return accumulator;
    }, []);
  };

  const onError = (_errors) => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      !isSaveInsuranceOperation
        ? "Submit cost estimate"
        : "Save Insurance Info",
      {
        ...(isSaveInsuranceOperation && {
          location: "Appointment Booking Gating Flow",
        }),
        page_version: "Add insurance info",
        is_submitted: String(false),
        missing_fields: getEmptyFields(getValues()),
        is_self_pay: "false",
      },
    );
  };

  const onSubmit = async (data: RequestCostEstimateFormData) => {
    const {
      primaryInsuranceCarrier,
      planName,
      groupId,
      insuranceMemberId,
      frontOfCard,
      backOfCard,
      finishLater,
    } = data;
    let frontOfCardId =
      frontOfCard !== null
        ? insurancePolicyInfo?.insurance_card_front_id
        : null;
    let backOfCardId =
      backOfCard !== null ? insurancePolicyInfo?.insurance_card_back_id : null;

    try {
      const successCallback = onSuccess || successToast;

      const docUploadPromiseStack = await Promise.all([
        frontOfCard?.name &&
          frontOfCardUrlObjectKey &&
          uploadDocumentMutation({
            variables: {
              input: {
                id: memberInsuranceInfo.user.id,
                object_key: frontOfCardUrlObjectKey,
                file_name: frontOfCard.name,
                member_id: memberInsuranceInfo.user.member.id,
              },
            },
          }),
        backOfCard?.name &&
          backOfCardUrlObjectKey &&
          uploadDocumentMutation({
            variables: {
              input: {
                id: memberInsuranceInfo.user.id,
                object_key: backOfCardUrlObjectKey,
                file_name: backOfCard.name,
                member_id: memberInsuranceInfo.user.member.id,
              },
            },
          }),
      ]);

      const frontOfCardIndex =
        frontOfCard?.name && frontOfCardUrlObjectKey && 0;
      const backOfCardIndex =
        frontOfCardIndex === 0 && backOfCard?.name && backOfCardUrlObjectKey
          ? 1
          : backOfCard?.name && backOfCardUrlObjectKey && 0;

      frontOfCardId =
        docUploadPromiseStack[frontOfCardIndex]?.data.upload_document
          .document_id;
      backOfCardId =
        docUploadPromiseStack[backOfCardIndex]?.data.upload_document
          .document_id;

      const formDataObject = {
        member_id: memberId,
        carrier_name: primaryInsuranceCarrier,
        plan_name: planName,
        insurance_group_id: groupId,
        insurance_member_id: insuranceMemberId,
        insurance_card_front_id: frontOfCardId,
        insurance_card_back_id: backOfCardId,
        trigger_cost_estimate_zendesk_ticket: !finishLater,
        insurance_type: CostEstimateInsuranceTypeEnum.insurance,
      };

      TRACK_EVENT.BUTTON_CLICKED(
        window.location.pathname,
        getSubmitClickTelemetryType(isSaveInsuranceOperation, finishLater),
        {
          location: getSubmitClickTelemetryLocation(
            isSaveInsuranceOperation,
            window.location.pathname,
          ),
          page_version: "Add Insurance Info",
          is_submitted: String(!finishLater),
          missing_fields: isSelfPayingFormValue
            ? []
            : getEmptyFields(getValues()),
          ...(!isSaveInsuranceOperation
            ? { page_version: "Add Insurance Info" }
            : { is_self_pay: String(isSelfPayingFormValue) }),
        },
      );

      if (isSaveInsuranceOperation) {
        const insurancePolicyPayload = {
          member_id: memberId,
          carrier_name: primaryInsuranceCarrier,
          plan_name: planName,
          insurance_group_id: groupId,
          insurance_member_id: insuranceMemberId,
          insurance_card_front_id: frontOfCardId,
          insurance_card_back_id: backOfCardId,
          trigger_cost_estimate_zendesk_ticket: false,
          insurance_type: CostEstimateInsuranceTypeEnum.insurance,
        };

        /**
         * Lifting functionality from admin-portal. See https://github.com/SpringCare/admin-portal/blob/ede906cf43ce4ef81c8bb93c5a9e9cc2262558e1/src/components/form/EditMemberDetailForm/EditMemberDetailForm.tsx#L108
         * - TL; DR use Promise.all to bulk send the 2 update requests and wait for both to settle
         */
        const promiseStackResult = await Promise.all([
          updateMemberMutation({
            variables: {
              input: {
                id: memberInsuranceInfo?.user?.member?.id,
                patch: {
                  payment_preference: isSelfPayingFormValue
                    ? "OUT_OF_POCKET"
                    : "INSURANCE",
                },
              },
            },
          }),
          /**
           * If payment_preference = false, it means member's payment_preference
           * is Insurance. This line will only run if payment_preference = Insurance.
           * Otherwise, if payment_preference = true, member's payment_preference =
           * 'OUT_OF_POCKET'
           */
          isEmpty(errors) &&
            !isSelfPayingFormValue &&
            updateInsurance({
              variables: insurancePolicyPayload,
              refetchQueries: [getMemberInsurance],
            }),
        ]);

        if (promiseStackResult[1]?.data) {
          setInsurancePolicyInfo(
            promiseStackResult[1]?.data.updateMemberInsurancePolicy
              .member_insurance_policy,
          );
          setFrontOfCardUrlObjectKey(null);
          setBackOfCardUrlObjectKey(null);
        }
        successCallback();
        return;
      }
      // Remove below and add conditional logic for adding hte cost request stuff in
      await updateInsurance({
        variables: {
          ...formDataObject,
        },
        refetchQueries: !finishLater && [getMemberInsurance, getMemberInfo],
      });

      !finishLater
        ? changeDisplay(COST_ESTIMATE_DISPLAY_STATES.REQUEST_STATUS)
        : Router.replace(routes.MemberHome.to, routes.MemberHome.as);
    } catch (_err) {
      errorToast();
    }
  };

  const handleCheckboxChange = () => {
    clearErrors();
    setIsSelfPayingFormValue((i) => !i);
  };

  return (
    <form
      id="requestCostEstimateForm"
      aria-label={
        isSaveInsuranceOperation
          ? t("a11y.updateInsuranceFormLabel")
          : t("a11y.costEstimateFormLabel")
      }
      ref={ref}
    >
      <SimpleGrid columns={[1, 1, 2]} columnGap={3} rowGap={1}>
        <GridItem colSpan={1}>
          <FormControl
            isRequired
            isInvalid={Boolean(errors.primaryInsuranceCarrier)}
            isDisabled={isSelfPayingFormValue}
          >
            <FormLabel fontWeight="normal" htmlFor="primaryInsuranceCarrier">
              {t("form.primaryInsuranceCarrier")}
            </FormLabel>
            <Input
              paddingLeft={!isSelfPayingFormValue ? "16px" : "44px"}
              data-cy="primary-insurance-carrier"
              type="text"
              height={48}
              name="primaryInsuranceCarrier"
              id="primaryInsuranceCarrier"
              {...renderInputProps}
              {...register("primaryInsuranceCarrier", {
                required: t("form.errors.requiredField"),
              })}
              color={isSelfPayingFormValue && "content-disabled"}
              isDisabled={isSelfPayingFormValue}
            />
            <ErrorMessage
              errors={errors}
              name="primaryInsuranceCarrier"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl
            isRequired
            isInvalid={Boolean(errors.planName)}
            isDisabled={isSelfPayingFormValue}
          >
            <FormLabel fontWeight="normal" htmlFor="planName">
              {t("form.planName")}
            </FormLabel>
            <Input
              paddingLeft={!isSelfPayingFormValue ? "16px" : "46px"}
              data-cy="plan-name"
              type="text"
              height={48}
              name="planName"
              id="planName"
              {...renderInputProps}
              {...register("planName", {
                required: t("form.errors.requiredField"),
              })}
              color={isSelfPayingFormValue && "content-disabled"}
              isDisabled={isSelfPayingFormValue}
            />
            <ErrorMessage
              errors={errors}
              name="planName"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl
            isRequired
            isInvalid={Boolean(errors.groupId)}
            isDisabled={isSelfPayingFormValue}
          >
            <FormLabel fontWeight="normal" htmlFor="groupId">
              {t("form.groupId")}
            </FormLabel>
            <Input
              paddingLeft={!isSelfPayingFormValue ? "16px" : "44px"}
              data-cy="group-id"
              type="text"
              height={48}
              name="groupId"
              id="groupId"
              {...renderInputProps}
              {...register("groupId", {
                required: t("form.errors.requiredField"),
              })}
              color={isSelfPayingFormValue && "content-disabled"}
              isDisabled={isSelfPayingFormValue}
            />
            <ErrorMessage
              errors={errors}
              name="groupId"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl
            isRequired
            isInvalid={Boolean(errors.insuranceMemberId)}
            isDisabled={isSelfPayingFormValue}
          >
            <FormLabel fontWeight="normal" htmlFor="insuranceMemberId">
              {t("form.memberId")}
            </FormLabel>
            <Input
              paddingLeft={!isSelfPayingFormValue ? "16px" : "44px"}
              data-cy="insurance-member-id"
              type="text"
              height={48}
              name="insuranceMemberId"
              id="insuranceMemberId"
              {...renderInputProps}
              {...register("insuranceMemberId", {
                required: t("form.errors.requiredField"),
              })}
              color={isSelfPayingFormValue && "content-disabled"}
              isDisabled={isSelfPayingFormValue}
            />
            <ErrorMessage
              errors={errors}
              name="insuranceMemberId"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={[1, 1, 2]}>
          <Text
            size="heading-small"
            fontWeight={600}
            fontSize={16}
            marginTop={"1.5em"}
            marginBottom={!showUploadHelpText && 8}
          >
            {t("form.insuranceCardUploadHeading")}{" "}
            <Text as="span" style={{ fontWeight: 400 }}>
              {t("form.insuranceCardUploadHeadingFileTypes")}
            </Text>
          </Text>
          {showUploadHelpText && (
            <Text marginBottom={8}>
              {t("form.insuranceCardUploadSubHeading")}
            </Text>
          )}
        </GridItem>

        <GridItem colSpan={1}>
          <DocumentUploadButton
            userId={memberInsuranceInfo?.user.id}
            data-cy="front-of-card"
            label={t("form.frontOfCardLabel")}
            buttonId="frontOfCardButton"
            inputId="frontOfCard"
            register={register}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
            documentId={insurancePolicyInfo?.insurance_card_front_id}
            deactivateFormSubmitButton={setFrontOfCardFileIsLoading}
            setDocumentUrlObjectKey={setFrontOfCardUrlObjectKey}
            isDisabled={isSelfPayingFormValue}
            setShowFileUploadDisclaimer={setShowFileUploadDisclaimer}
            trigger={!backOfCardFileIsLoading && trigger}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <DocumentUploadButton
            userId={memberInsuranceInfo?.user.id}
            data-cy="back-of-card"
            label={t("form.backOfCardLabel")}
            buttonId="backOfCardButton"
            inputId="backOfCard"
            register={register}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
            documentId={insurancePolicyInfo?.insurance_card_back_id}
            deactivateFormSubmitButton={setBackOfCardFileIsLoading}
            setDocumentUrlObjectKey={setBackOfCardUrlObjectKey}
            isDisabled={isSelfPayingFormValue}
            setShowFileUploadDisclaimer={setShowFileUploadDisclaimer}
            trigger={!frontOfCardFileIsLoading && trigger}
          />
        </GridItem>

        {showFileUploadDisclaimer && (
          <GridItem colSpan={[1, 1, 2]} mt={2}>
            <Badge icon={InfoIcon} fontWeight={400} fontSize={16} pl={8} pr={8}>
              <Text py={2} whiteSpace="pre-wrap">
                {t("form.fileUploadDisclaimer")}
              </Text>
            </Badge>
          </GridItem>
        )}

        {showSelfPayCheckbox && isSaveInsuranceOperation && (
          <GridItem colSpan={[1, 1, 2]} mt="9px" mb="27px">
            <Box
              padding="24px 16px"
              background="background-subtle"
              borderRadius="v-lg"
            >
              <Checkbox
                id="selfPayCheckbox"
                onChange={handleCheckboxChange}
                isChecked={isSelfPayingFormValue}
              >
                <Text as="span" size="body-medium-strong">
                  {t("form.selfPayAcknowledgementText")}
                </Text>
              </Checkbox>
              <Text ml="30px">
                <Trans
                  ns={"insurance"}
                  i18nKey={"form.selfPayDescriptionText"}
                  components={[
                    <Link
                      colorScheme="base"
                      key={"0"}
                      href={routes["Billing--WalletTab"].as}
                    />,
                  ]}
                />
              </Text>
            </Box>
          </GridItem>
        )}

        <GridItem colSpan={[1, 1, 2]}>
          {isSaveInsuranceOperation ? (
            <Flex width="100%" justifyContent="flex-end" pt={32}>
              <Button
                id="submitSelfPayInsurance"
                type="submit"
                borderRadius="v-lg"
                size="lg"
                paddingX={showSelfPayCheckbox ? 32 : 16}
                onClick={(event) => {
                  event.preventDefault();

                  if (isSelfPayingFormValue) {
                    return onSubmit(getValues());
                  }

                  handleSubmit(
                    (data) => onSubmit(data),
                    (errors) => onError(errors),
                  )();
                }}
                variant={
                  primaryCTAVariant ? primaryCTAVariant : "high-emphasis"
                }
                colorScheme="base"
                disabled={isDisabledButton()}
              >
                <Flex alignItems="center" justifyContent="space-evenly">
                  {showSelfPayCheckbox ? (
                    <>
                      <Text size="body-medium-strong" mr={16} as="span">
                        {t("form.submit")}
                      </Text>
                      <VArrowRightIcon fontSize={18} aria-hidden={true} />
                    </>
                  ) : (
                    <Text size="body-medium-strong" as="span">
                      {t("form.saveAndContinue")}
                    </Text>
                  )}
                </Flex>
              </Button>
            </Flex>
          ) : (
            <Button
              width="100%"
              marginTop={48}
              type="button"
              id="requestCostEstimateSubmitButton"
              form="requestCostEstimateForm"
              onClick={handleSubmit(
                (data) => onSubmit(data),
                (errors) => onError(errors),
              )}
              disabled={
                isSubmitting ||
                frontOfCardFileIsLoading ||
                backOfCardFileIsLoading
              }
              mb={10}
            >
              {t("form.requestCostEstimateSubmitButton")}
            </Button>
          )}
        </GridItem>

        {/* Conditionally render secondary CTA only if we're not saving insurance info */}
        {!isSaveInsuranceOperation && (
          <GridItem colSpan={[1, 1, 2]}>
            <Button
              width="100%"
              variant="medium-emphasis"
              id="requestCostEstimateForm"
              type="button"
              onClick={() =>
                onSubmit({
                  finishLater: true,
                  ...getValues(),
                })
              }
              form="requestCostEstimateForm"
              disabled={
                isSubmitting ||
                frontOfCardFileIsLoading ||
                backOfCardFileIsLoading
              }
            >
              {t("form.saveAndFinishLaterButton")}
            </Button>
          </GridItem>
        )}
      </SimpleGrid>
    </form>
  );
};
