import React from "react";
import { Col, Form, Input, PhoneInput, Select } from "@spring/smeargle";
import { useTranslation } from "react-i18next";

import { updateMemberPrevisit } from "operations/mutations/member";
import { Heading, Text, Box } from "design-system/components";
import { getEmergencyContactSchema } from "schemas/emergencyContactForm";
import { addNotification } from "@spring/smeargle/actions";
import { getRelationships } from "constants/relations";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { useMemberInfo } from "hooks";

interface Props {
  member: {
    id: string;
    previsit: {
      status: string;
      data: string;
    };
  };
}

const MemberEmergencyContact = ({ member }: Props) => {
  const { data: memberInfo } = useMemberInfo();

  const memberPrevisitStatus = memberInfo?.user?.member?.previsit?.status;
  const { t } = useTranslation("limitedLangRegister");

  const schema = getEmergencyContactSchema(t);

  const dispatch = useDispatch();

  const [doUpdateMemberPrevisit] = useMutation(updateMemberPrevisit, {
    refetchQueries: ["getMemberInfo"],
    onCompleted: () => {
      dispatch(
        addNotification(
          "Thank you, your responses have been submitted.",
          "success",
        ),
      );
    },
  });

  const handleSubmit = (formData) => {
    const memberPrevisit = member?.previsit;

    let preVisitData = { emergencyContact: formData };
    if (memberPrevisitStatus === "Submitted") {
      const previsitInfo = JSON.parse(memberPrevisit?.data);
      preVisitData = { ...previsitInfo, ...preVisitData };
    }
    const payload = {
      member_id: member?.id,
      patch: {
        status: memberPrevisitStatus || "Pending",
        data: JSON.stringify(preVisitData),
      },
    };

    doUpdateMemberPrevisit({ variables: { input: payload } });
  };

  const getEmergencyContact = () => {
    const formData = member?.previsit?.data && JSON.parse(member.previsit.data);

    return formData?.emergencyContact?.name?.length > 0
      ? formData?.emergencyContact
      : {};
  };

  return (
    <Form
      dataCy="emergency_contact"
      initialData={getEmergencyContact()}
      formKey="member-emergency-contact"
      schema={schema}
      onSubmit={handleSubmit}
      submitIcon="arrow-right"
      submitText={t("common:form.submitText")}
      submitAriaLabel={t("a11y:submit.emergencyContactDetails")}
    >
      <Box>
        <Heading variant="sm_v1" color="#4b4b4b">
          {t("emergency_contact.title")}
        </Heading>
        <Text variant="body_v1" mt="2">
          {t("emergency_contact.subtitle")}
        </Text>
        <Col size={12}>
          <Input
            fieldKey="name"
            label={t("emergency_contact.form.emergency_contact_name.label")}
            dataCy="emergency_name"
            autoComplete="name"
            renderLabelFirst={true}
          />
        </Col>
        <Col size={12}>
          <Select
            placeholder={t("emergency_contact.form.relationship.label")}
            label={t("emergency_contact.form.relationship.label")}
            dataCy="emergency_relationship"
            fieldKey="relationship"
            options={getRelationships()}
            displayKey="label"
            searchKeys={["label", "value"]}
          />
        </Col>
        <Col size={12}>
          <Input
            fieldKey="email"
            label={t("emergency_contact.form.emergency_contact_email.label")}
            dataCy="emergency_email"
            autoComplete="name"
            renderLabelFirst={true}
          />
        </Col>
        <Col size={12}>
          <PhoneInput
            fieldKey="phoneNumber"
            dialCodeSelectId="emergency"
            label={t(
              "emergency_contact.form.emergency_contact_phone_number.label",
            )}
            dataCy="emergency_phone"
            theme="simple"
          />
        </Col>
      </Box>
    </Form>
  );
};

export default MemberEmergencyContact;
