import React, { useEffect, useRef, useState } from "react";
import { Badge } from "@springcare/sh-component-library";
import {
  Box,
  Heading,
  Image,
  Text,
  useBreakpointValue,
  Flex,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { VExternalLinkIcon } from "@springcare/verdant-icons-react";
import { useInViewportOnce } from "hooks/useInViewport";
import { TRACK_EVENT } from "utils/mixpanel";

type AlsoAvailableTileProps = {
  icon: React.ElementType;
  heading: string;
  description: string;
  onClickCallback: () => void;
  onClickAction?: string;
  dataCy?: string;
  width?: string;
  height?: string;
  badgeText?: string;
  trackingProps?: object;
  colorScheme?: string;
};

const ExternalLinkIcon = () => {
  return (
    <Flex
      position="absolute"
      top="-18px"
      right="-10px"
      width="36px"
      height="36px"
      justifyContent="center"
      alignItems="center"
      data-testid="external-link-icon"
    >
      <VExternalLinkIcon aria-hidden="true" />
    </Flex>
  );
};

const TopRightArea = ({ children }) => {
  return (
    <Flex justifyContent="center">
      <Box pt={10}>
        <Flex position="relative">{children}</Flex>
      </Box>
    </Flex>
  );
};

const AlsoAvailableTile = ({
  icon: Icon,
  heading,
  description,
  onClickCallback,
  onClickAction,
  dataCy,
  width,
  height,
  badgeText,
  trackingProps,
  colorScheme = "positive",
}: AlsoAvailableTileProps) => {
  const iconSize = useBreakpointValue([64, 108]);
  const [isMobileSize] = useMediaQuery("(min-width: 480px)");

  const ref = useRef();
  const inViewport = useInViewportOnce(ref);
  const [viewedOnce, setViewedOnce] = useState(false);
  const TRACKING_LOCATION = "Also available to you section";

  useEffect(() => {
    if (inViewport && !viewedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        `${dataCy.replace(/_/g, " ")} card`,
        {
          location: TRACKING_LOCATION,
          spring_doc_id: "also_available_tile_001",
          ...trackingProps,
        },
      );
      setViewedOnce(true);
    }
  }, [inViewport, dataCy, viewedOnce]);

  const showBadge = Boolean(badgeText);
  const showExternalLinkIcon = onClickAction === "External link";
  const showTopRightArea = showBadge || showExternalLinkIcon;

  return (
    <Box
      ref={ref}
      as="button"
      h={height || "100%"}
      p={24}
      borderRadius={8}
      bg="platform.on"
      boxShadow="0px 2px 8px rgba(106, 84, 77, 0.16)"
      data-cy={dataCy}
      onClick={() => {
        if (onClickAction) {
          TRACK_EVENT.CARD_CLICKED(
            window.location.pathname,
            onClickAction,
            heading,
            {
              spring_doc_id: "HPR_022",
              location: TRACKING_LOCATION,
              ...trackingProps,
            },
          );
        }
        onClickCallback();
      }}
      cursor="pointer"
      textAlign="start"
      w={width || "100%"}
      _hover={{
        transform: "scale(1.025)",
        boxShadow: "0px 4px 16px rgba(106, 84, 77, 0.2)",
      }}
    >
      <VStack as="span" alignItems="start" h={"100%"} w="100%">
        <Flex justifyContent="space-between" mb={5} width="100%">
          <Box data-testid="also-available-tile-icon">
            <Flex whiteSpace="nowrap">
              {Icon && typeof Icon !== "string" && (
                <Icon width={iconSize} height={iconSize} aria-hidden="true" />
              )}
              {Icon && typeof Icon === "string" && (
                <Flex width={"90%"} height={iconSize} justifyContent="start">
                  <Image
                    objectFit="contain"
                    src={Icon}
                    aria-hidden="true"
                    alt="Benefit logo"
                  />
                </Flex>
              )}
            </Flex>
          </Box>
          {showTopRightArea && (
            <TopRightArea>
              {showBadge && (
                <Badge colorScheme={colorScheme} data-testid="top-right-badge">
                  {badgeText}
                </Badge>
              )}
              {showExternalLinkIcon && <ExternalLinkIcon />}
            </TopRightArea>
          )}
        </Flex>
        {isMobileSize ? (
          <Heading as="h3" size="heading-small">
            {heading}
          </Heading>
        ) : (
          <Text size="body-medium-strong">{heading}</Text>
        )}
        <Text as="span" overflowY="hidden">
          {description}
        </Text>
      </VStack>
    </Box>
  );
};

export default AlsoAvailableTile;
