import PropTypes from "prop-types";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { get, getOr } from "lodash/fp";
import uuid from "react-uuid";
import {
  modalIds,
  getAppointmentMediumForTracking,
  getAppointmentKindForTracking,
  ProviderRole,
} from "@spring/constants";
import { Modal } from "@spring/smeargle";
import CareProviderScheduleContent from "components/molecules/CareProviderScheduleContent/CareProviderScheduleContent";
import { graphql } from "@apollo/client/react/hoc";
import { getMemberInfo } from "operations/queries/member";
import Meowth from "@spring/meowth";
import { getAppointments } from "operations/queries/appointment";
import {
  trackProviderProfileModalOpened,
  trackProviderProfileModalClosed,
} from "components/templates/Browse/ProviderBrowsePage/analytics";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import routes from "routes";

// CareProviderScheduleModalV2 is Verdant Clone - MEI-107 will replace
// remaining uses of this with this V2 after GA of MXENG-3682
const CareProviderScheduleModal = ({ modalOpen, providerType, ...props }) => {
  const therapyTopSpecialtiesFlag =
    useFeatureFlag(FLAGS.THERAPY_TOP_SPECIALTIES) &&
    providerType === ProviderRole.Therapist;
  const router = useRouter();
  const currentRoute = router?.asPath;
  const isBrowseOrRecs =
    currentRoute === routes.TherapistsBrowse.as ||
    currentRoute === routes.RecommendedTherapists.as;
  let [modalUuid, setModalId] = useState();
  const modalData = props.modalData;
  const selectedMedium =
    props.formData?.data?.initialStartTime?.selectedMedium ||
    props.modalData?.medium;

  const cnAppointments = getOr([], "cn_appointments.appointments.data", props);
  const mmAppointments = getOr([], "mm_appointments.appointments.data", props);
  const isBenefitsPage = window.location.pathname.includes("benefits");
  const isHomePage = window.location.pathname.includes("home");
  const isCareVisitsPage = window.location.pathname.includes("care_visits");

  let topTags = "";
  if (therapyTopSpecialtiesFlag && props.modalData?.top_tags?.length > 0) {
    topTags = props.modalData?.top_tags?.sort()?.join(", ");
  }

  const getModalLocation = (eventProps) => {
    if (isBrowseOrRecs) {
      return "Provider Card";
    }
    if (isBenefitsPage) {
      return "Benefit Cards";
    }
    if (isHomePage) {
      return props.modalData?.mpLocation;
    }
    if (isCareVisitsPage && eventProps.appointment_type === "Care Navigation") {
      return "Your Care Navigator";
    }
    return props.modalData?.mpLocation || "Appointment Confirmation";
  };

  useEffect(() => {
    setModalId(uuid());
  }, []);

  useEffect(() => {
    const eventProps = {
      provider_id: getOr("", "modalData.id", props),
      appointment_type: getAppointmentKindForTracking(props.modalData.kind),
      appointment_medium: getAppointmentMediumForTracking(selectedMedium),
      top_specialties: topTags,
    };
    if (modalOpen) {
      modalData.mpLocation = getModalLocation(eventProps);
      trackProviderProfileModalOpened({ modalData, modalUuid, eventProps });
    } else if (modalOpen === false) {
      trackProviderProfileModalClosed({ modalUuid, eventProps });
      setModalId(uuid());
    }
  }, [modalOpen]);

  if (get("cn_appointments.loading", props)) {
    return null;
  }

  return (
    <Modal
      size="lg"
      id={modalIds.careProviderScheduleModal}
      ariaLabeledBy={props.modalData?.name?.replaceAll(" ", "")}
    >
      <CareProviderScheduleContent
        modalUUID={modalUuid}
        careProvider={props.modalData}
        memberInfo={props.memberInfo}
        providerRole={props.modalData?.providerRole}
        appointmentKind={props.modalData?.kind}
        medium={selectedMedium}
        cnAppointments={cnAppointments}
        mmAppointments={mmAppointments}
        action={props.modalData?.action}
        initialStartTime={props.modalData?.initialStartTime}
        buttonText={props.modalData?.buttonText}
        dataCy={props.modalData?.dataCy}
        appointmentId={props.modalData?.appointmentId}
        appointmentLocation={props.modalData?.appointmentLocation}
        providerOrder={props.modalData?.providerOrder}
        queryRequestId={props.modalData?.queryRequestId}
        pageNumber={props?.modalData?.pageNumber}
        previousReasonForScheduling={props.modalData?.reasonForScheduling}
        isCoveredForMember={props.modalData?.isCoveredForMember}
        isReschedule={props.modalData?.isReschedule}
        customRoleName={props.modalData?.customRoleName}
        isMultiRole={props.modalData?.isMultiRole}
        showMultiRoleTypeSelector={props.modalData?.showMultiRoleTypeSelector}
      />
    </Modal>
  );
};

CareProviderScheduleModal.propTypes = {
  modalData: PropTypes.shape({
    mpLocation: PropTypes.string,
    avatar: PropTypes.any,
    bio: PropTypes.any,
    buttonText: PropTypes.any,
    careTeamRole: PropTypes.any,
    care_provider_tags: PropTypes.array,
    dataCy: PropTypes.string,
    distance_miles: PropTypes.any,
    first_name: PropTypes.any,
    id: PropTypes.any,
    initialStartTime: PropTypes.any,
    kind: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
    dedicated_customer: PropTypes.shape({
      name: PropTypes.string,
    }),
    on_site: PropTypes.any,
    licenses: PropTypes.any,
    categories: PropTypes.any,
    medium: PropTypes.any,
    name: PropTypes.any,
    providerRole: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    states: PropTypes.arrayOf(PropTypes.string),
    user_id: PropTypes.any,
  }),
  modalOpen: PropTypes.bool,
  providerType: PropTypes.string,
};

export { CareProviderScheduleModal, isMomentsOnlyUser };

const mapStateToProps = (state) => ({
  modalOpen: getOr(
    null,
    `modal.${modalIds.careProviderScheduleModal}.open`,
    state,
  ),
  modalData: getOr(
    {
      licenses: [],
      roles: [],
      states: [],
      tags: [],
      care_provider_tags: [],
      supportsInPerson: false,
    },
    `modal.${modalIds.careProviderScheduleModal}.data`,
    state,
  ),
  formData: getOr(
    {},
    `care-provider-schedule-${get(`modal.${modalIds.careProviderScheduleModal}.data.id`, state)}`,
    state.form,
  ),
});

const isMomentsOnlyUser = () => {
  const scopes = Meowth.getUserScopes();
  return scopes.includes("group:moments_only");
};

export default compose(
  connect(mapStateToProps),
  graphql(getMemberInfo, {
    options: Meowth.apolloOptionsUserId,
    name: "memberInfo",
  }),
  graphql(getAppointments, {
    name: "cn_appointments",
    skip: isMomentsOnlyUser,
    options: {
      variables: {
        limit: 1,
        attended: "KEPT",
        kind: [
          // Check if they have talked to a CN before
          "Initial Care Navigator Call",
          "Follow Up With Care Navigator",
        ],
      },
    },
  }),
  graphql(getAppointments, {
    name: "mm_appointments",
    skip: isMomentsOnlyUser,
    options: {
      variables: {
        limit: 1,
        attended: "KEPT",
        booking_user_id: Meowth.getUserId(),
        kind: [
          "Initial Physician Appointment",
          "Follow Up Physician Appointment",
        ],
      },
    },
  }),
)(CareProviderScheduleModal);
