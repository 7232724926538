import { useTranslation } from "react-i18next";
import { Heading, Text, Box } from "@chakra-ui/react";
import { useMemberInsurance } from "hooks";
import { InsuranceForm } from "components/templates/CostEstimation/components/shared";
import { InsuranceValidationForm } from "components/templates/CostEstimation/components/shared/InsuranceValidationForm";
import { Link, useCustomToast } from "@springcare/sh-component-library";
import { links } from "@spring/constants";
import { Trans } from "react-i18next";
import { useInsuranceValidationExperience } from "components/templates/CostEstimation/hooks/useInsuranceValidationExperience";
import updateMemberPaymentPreference from "operations/mutations/member/updateMemberPaymentPreference";
import { useMutation } from "@apollo/client";
import { getMemberInsurance } from "operations/queries/member";
import { CostEstimateInsuranceTypeEnum } from "constants/insurance";

export const SettingsInsuranceForm = () => {
  const { t } = useTranslation([
    "limitedLangSettings",
    "insurance",
    "costEstimate",
  ]);
  const { data: insuranceData, loading: insuranceDataLoading } =
    useMemberInsurance();

  const successToast = useCustomToast({
    type: "success",
    message: t("insurance:form.insuranceUpdateSuccess"),
    layout: "fit-content",
    duration: 5000,
  });

  const showInsuranceValidationForm = useInsuranceValidationExperience();

  const insuranceValidationFormSubHeadingReadOnly = (
    <Trans
      i18nKey="limitedLangSettings:newInsuranceForm.subtitleReadonly"
      components={{
        CostEstimateSupportLink: (
          <Link
            href={links.CostEstimateSupportLink}
            rel="noopener noreferrer"
            isExternal
          />
        ),
      }}
    />
  );

  const insuranceValidationFormSubHeading = insuranceData?.user?.member
    ?.validated_insurance_policy
    ? insuranceValidationFormSubHeadingReadOnly
    : t("limitedLangSettings:newInsuranceForm.subtitle");

  const [updateInsurance] = useMutation(updateMemberPaymentPreference, {
    refetchQueries: [getMemberInsurance],
    onCompleted: (data) => {
      if (data?.updateMemberInsurancePolicy?.success) {
        successToast();
      }
    },
  });

  const handleSelfPayRequestClick = async () => {
    await updateInsurance({
      variables: {
        member_id: insuranceData?.user?.member?.id,
        insurance_type: CostEstimateInsuranceTypeEnum.noInsurance,
        trigger_cost_estimate_zendesk_ticket: false,
      },
    });
  };

  if (insuranceDataLoading) return null;
  return (
    <Box width="100%" padding={[8, 8, "initial"]} mb="40px">
      {showInsuranceValidationForm && (
        <div>
          <Heading size="heading-medium" as="h2" marginBottom="v-8">
            {t("limitedLangSettings:newInsuranceForm.primaryHeading")}
          </Heading>
          <InsuranceValidationForm
            memberInsuranceInfo={insuranceData}
            renderInputProps={{
              border: "hidden",
              variant: "medium-emphasis",
            }}
            disableHeading={true}
            subHeading={insuranceValidationFormSubHeading}
            submitButtonProps={{
              width: "fit-content",
              float: "right",
              paddingX: "v-32",
              paddingY: "v-16",
            }}
            submitButtonText={t("insurance:form.submit")}
            onValidatedInsuranceInfoSave={() => successToast()}
            onNonValidatedInsuranceInfoSave={() => successToast()}
            onSelfPayInfoSave={() => handleSelfPayRequestClick()}
          />
        </div>
      )}
      {!showInsuranceValidationForm && (
        <div>
          <Heading size="heading-medium" as="h2" marginBottom="v-8">
            {t("newInsuranceForm.primaryHeading")}
          </Heading>
          <Text mb="v-24">{t("newInsuranceForm.subtitle")}</Text>

          <InsuranceForm
            isSaveInsuranceOperation={true}
            memberInsuranceInfo={insuranceData}
            renderInputProps={{
              borderRadius: "v-sm",
              borderWidth: "1px",
              variant: "low-emphasis",
            }}
          />
        </div>
      )}
    </Box>
  );
};
