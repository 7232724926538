import { gql } from "@apollo/client";

const getRecommendedTherapistsAvatar = gql`
  query getRecommendedTherapists(
    $id: ID!
    $current_path: String
    $version: String
    $filters: RecommendedTherapistsFiltersInputType
  ) {
    user(id: $id) {
      member {
        recommended_therapists(
          current_path: $current_path
          version: $version
          filters: $filters
        ) {
          therapist {
            id
            name
            avatar
            roles
            bio
            licenses
          }
        }
      }
    }
  }
`;

export default getRecommendedTherapistsAvatar;
