import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import {
  ChannelPartnerData,
  getChannelPartner,
} from "operations/queries/customer";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { getAppointments } from "operations/queries/appointment";
import { getMemberInsurance } from "operations/queries/member";

export const HIGHMARK = "highmark";
export const HIGHMARK_HEALTH = "highmark health";
const highmarkPartnerNames = [HIGHMARK, HIGHMARK_HEALTH];

const useHighmarkExperience = () => {
  const showBanners = useFeatureFlag(FLAGS.HIGHMARK_COST_BANNERS);
  const showInsuranceForm = useFeatureFlag(FLAGS.HIGHMARK_INSURANCE_FORM);
  const {
    data: channelPartnerData,
    loading: channelPartnerLoading,
  }: { data: ChannelPartnerData; loading: boolean } = useQuery(
    getChannelPartner,
    {
      ...Meowth.apolloOptionsUserId(),
      skip: Meowth.apolloSkipUserId(),
    },
  );

  const channelPartnerName =
    channelPartnerData?.user?.customer?.channel_partner?.name;

  const isHighmark =
    !channelPartnerLoading &&
    channelPartnerName &&
    highmarkPartnerNames.includes(channelPartnerName.toLowerCase());

  const { data: appointmentData, loading: appointmentsLoading } = useQuery(
    getAppointments,
    {
      ...Meowth.apolloOptionsUserId(),
      skip: Meowth.apolloSkipUserId(),
    },
  );

  const { data: insuranceData, loading: insuranceLoading } = useQuery(
    getMemberInsurance,
    {
      ...Meowth.apolloOptionsUserId(),
      skip: Meowth.apolloSkipUserId(),
    },
  );

  const isWithinYear = (date) => {
    if (!date) return null;
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(new Date().getFullYear() - 1);
    const dateToTest = new Date(date);
    return dateToTest > oneYearAgo;
  };

  const hasHadAppointmentWithinYear = () => {
    const latestAppointment = appointmentData?.appointments?.data[0]?.start_at;
    return isWithinYear(latestAppointment);
  };

  const hasRequestedCostEstimateWithinYear = () => {
    const estimateRequestDate =
      insuranceData?.user?.member?.insurance_policy
        ?.cost_estimate_requested_date;
    return isWithinYear(estimateRequestDate);
  };

  const showMainHighmarkBanner =
    isHighmark &&
    showBanners &&
    !insuranceLoading &&
    !appointmentsLoading &&
    !hasHadAppointmentWithinYear() &&
    !hasRequestedCostEstimateWithinYear();

  const showHighmarkBanners = isHighmark && showBanners;

  const showHighmarkInsuranceForm = isHighmark && showInsuranceForm;

  const isLoadingExperienceData =
    channelPartnerLoading || appointmentsLoading || insuranceLoading;

  return {
    showHighmarkBanners,
    showMainHighmarkBanner,
    showHighmarkInsuranceForm,
    isHighmark,
    isLoadingExperienceData,
  };
};

export default useHighmarkExperience;
