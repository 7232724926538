import { useRef } from "react";
import { VStack, Text, useDisclosure } from "@chakra-ui/react";
import { Button } from "@springcare/sh-component-library";
import { VEmptyStateDuotoneSpot } from "@springcare/verdant-icons-react";
import { SHModal } from "design-system/components";
import { UpdateAddressForm } from "components/templates/Browse/ProviderBrowsePage/components/HeaderSection/UpdateAddressForm";

import { useTranslation } from "react-i18next";

const MultiRoleProviderEmptyState = ({ marginBottom = 0, marginTop = 0 }) => {
  const { t } = useTranslation(["careProvider", "common"]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const closeModalFocusRef = useRef(null);

  const styles = {
    align: "center",
    mt: marginTop,
    mb: marginBottom,
  };

  return (
    <>
      <VStack {...styles}>
        <VEmptyStateDuotoneSpot width={80} height={80} />
        <Text textAlign="center" size="body-medium-strong">
          {t("newBrowseProviderPage.noMultiroleProviders.title")}
        </Text>
        <Text>{t("newBrowseProviderPage.noMultiroleProviders.subtitle")}</Text>
        <Button onClick={onOpen}>
          {t("newBrowseProviderPage.noMultiroleProviders.updateAddressButton")}
        </Button>
      </VStack>

      <SHModal
        isOpen={isOpen}
        onClose={onClose}
        modalTitle={t("updateAddress.title")}
        finalFocusRef={closeModalFocusRef}
      >
        <UpdateAddressForm onClose={onClose} />
      </SHModal>
    </>
  );
};

export default MultiRoleProviderEmptyState;
