// NOTE: this is WIP and gets more accurate as we test IRL
import {
  browserName,
  isMobile,
  isIOS,
  isAndroid,
  isWindows,
  isMacOs,
  isEdge,
  isChrome,
  isFirefox,
  isSafari,
} from "react-device-detect";

import {
  logPrejoinInitDevicesNotConnected,
  logPrejoinInitDevicePermissionsError,
} from "components/templates/SHSessionRoom/telemetry/datadog";

export const initiateDevicePermissionsAndConnection = async (
  setPermissionsModalOpen,
  setViewStatusByPermissionsDenied,
  setDevicePermissionsDenied,
  SessionViewStatus,
  setSessionViewStatus,
  sessionDevices,
  startMedia,
) => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoInputDevices = devices.filter(
      (device) => device.kind === "videoinput",
    );
    const audioInputDevices = devices.filter(
      (device) => device.kind === "audioinput",
    );

    const hasConnectedDevices = checkConnectedDevices(
      videoInputDevices,
      audioInputDevices,
    );
    // this COULD happen if peripherals are not detected, or due to ad-blockers or device security settings
    // in this case, we show the ambiguous error message
    if (!hasConnectedDevices) {
      setViewStatusByPermissionsDenied(
        MediaPermissionsErrorType.AmbiguousPermissionError,
        setSessionViewStatus,
        SessionViewStatus,
      );
      setDevicePermissionsDenied(true);
      setPermissionsModalOpen(false);
      return;
    }
    // check device permissions - empty means no permission granted
    const hasConnectedDevicePermissions = checkConnectedDevicesPermissions(
      videoInputDevices,
      audioInputDevices,
    );
    if (!hasConnectedDevicePermissions) {
      // open the (native) browser permissions modal/popup from WelcomeModal
      setPermissionsModalOpen(true);
    }
    //
    if (hasConnectedDevicePermissions && !sessionDevices.stream) {
      await startMedia();
    }
  } catch (error) {
    logPrejoinInitDevicePermissionsError(error);
  }
};

const checkConnectedDevices = (videoInputDevices, audioInputDevices) => {
  // Check that cam/mic peripherals are detected on the user device, unlikely
  // more likely to happen due to ad-blockers or device security/firewall
  const hasVideoInputDevice = videoInputDevices.length > 0;
  const hasAudioInputDevice = audioInputDevices.length > 0;

  if (hasVideoInputDevice && hasAudioInputDevice) {
    return true;
  }
  logPrejoinInitDevicesNotConnected();
  return false;
};

const checkConnectedDevicesPermissions = (
  videoInputDevices,
  audioInputDevices,
) => {
  // check device permissions - empty "" means permissions aren't granted
  const hasVideoInputPermissions = videoInputDevices.some(
    (device) => device.label !== "",
  );
  const hasAudioInputPermissions = audioInputDevices.some(
    (device) => device.label !== "",
  );

  if (hasVideoInputPermissions && hasAudioInputPermissions) {
    return true;
  }
  return false;
};

// Enum for media permission error types returned by the handler
export enum MediaPermissionsErrorType {
  SystemPermissionDenied = "SystemPermissionDenied",
  BrowserPermissionDenied = "BrowserPermissionDenied",
  CouldNotStartVideoSource = "CouldNotStartVideoSource",
  AmbiguousPermissionError = "AmbiguousPermissionError",
}

// Interface for the media permission error, returned by the handleMediaPermissionError function
interface MediaPermissionError {
  type: MediaPermissionsErrorType;
  name: string;
  message: string;
  os: string;
  browser: string;
}

// Primary error names returned from browsers, used as reference for handlers
const NOT_ALLOWED_ERROR = "NotAllowedError";
const NOT_READABLE_ERROR = "NotReadableError";
const NOT_FOUND_ERROR = "NotFoundError";
const ABORT_ERROR = "AbortError";

// Type definition for error handlers
type ErrorHandler = (
  errName: string,
  errMessage?: string,
) => MediaPermissionsErrorType;

// Normalizes browser names to handle inconsistencies.
const normalizeBrowserName = (name: string): string => {
  if (isEdge) return "Edge";
  if (isChrome) return "Chrome";
  if (isFirefox) return "Firefox";
  if (isSafari) return "Safari";
  return name;
};

// Default error handler for browsers without a specific handler
const defaultErrorHandler: ErrorHandler = (errName) => {
  switch (errName) {
    case NOT_ALLOWED_ERROR:
      return MediaPermissionsErrorType.BrowserPermissionDenied;
    case NOT_READABLE_ERROR:
      return MediaPermissionsErrorType.CouldNotStartVideoSource;
    case NOT_FOUND_ERROR:
      return MediaPermissionsErrorType.SystemPermissionDenied;
    case ABORT_ERROR:
      return MediaPermissionsErrorType.CouldNotStartVideoSource;
    default:
      return MediaPermissionsErrorType.AmbiguousPermissionError;
  }
};

// Specific error handler for Chrome
const chromeHandler: ErrorHandler = (errName, errMessage) => {
  // iOS browsers all run on webkit, but error messages appear to be different for each browser
  // this is a catch-all for iOS browsers. Webkit requirement has changed as of iOS 17.4 but the
  // check is still here for backwards compatibility and because we don't know
  // if/when Browser vendors will update their Browser Engines off of Webkit.
  if (isIOS) {
    return MediaPermissionsErrorType.AmbiguousPermissionError;
  }
  // if system permission is false, the query still asks for devices - could cause user confusion
  // the copy returned from this will specify this, and point to correct instructions
  const isSystemPermissionError = /Permission denied by system/i.test(
    errMessage,
  );

  const isBrowserPermissionError = /\bPermission denied\b(?! by system)/i.test(
    errMessage,
  );

  if (isBrowserPermissionError) {
    return MediaPermissionsErrorType.BrowserPermissionDenied;
  }

  if (isSystemPermissionError) {
    return MediaPermissionsErrorType.SystemPermissionDenied;
  }

  if (errName === NOT_READABLE_ERROR) {
    return isWindows
      ? MediaPermissionsErrorType.AmbiguousPermissionError
      : MediaPermissionsErrorType.CouldNotStartVideoSource;
  }
  return MediaPermissionsErrorType.AmbiguousPermissionError;
};

// Specific error handler for Safari
const safariHandler: ErrorHandler = (errName, errMessage) => {
  // iOS browsers all run on webkit, but error messages appear to be different for each browser
  // this is a catch-all for iOS browsers. Webkit requirement has changed as of iOS 17.4 but the
  // check is still here for backwards compatibility and because we don't know
  // if/when Browser vendors will update their Browser Engines off of Webkit.
  if (isIOS) {
    return MediaPermissionsErrorType.AmbiguousPermissionError;
  }
  const isBrowserPermissionError =
    /The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission./i.test(
      errMessage,
    );

  if (isBrowserPermissionError) {
    return MediaPermissionsErrorType.BrowserPermissionDenied;
  }
  // use errName as a fallback
  if (errName === NOT_ALLOWED_ERROR) {
    if (isMobile) {
      // On iOS devices, NotAllowedError typically means system permission denial
      return MediaPermissionsErrorType.SystemPermissionDenied;
    } else {
      return MediaPermissionsErrorType.BrowserPermissionDenied;
    }
  } else if (errName === NOT_FOUND_ERROR) {
    return MediaPermissionsErrorType.BrowserPermissionDenied;
  }
  return MediaPermissionsErrorType.AmbiguousPermissionError;
};

// Specific error handler for Edge browsers
const edgeHandler: ErrorHandler = (errName, errMessage) => {
  // iOS browsers all run on webkit, but error messages appear to be different for each browser
  // this is a catch-all for iOS browsers. Webkit requirement has changed as of iOS 17.4 but the
  // check is still here for backwards compatibility and because we don't know
  // if/when Browser vendors will update their Browser Engines off of Webkit.
  if (isIOS) {
    return MediaPermissionsErrorType.AmbiguousPermissionError;
  }
  // the errMessage is the same as Chrome (bc Edge is based on Chromium)
  const isSystemPermissionError = /Permission denied by system/i.test(
    errMessage,
  );
  const isBrowserPermissionError = /\bPermission denied\b(?! by system)/i.test(
    errMessage,
  );

  if (isBrowserPermissionError) {
    return MediaPermissionsErrorType.BrowserPermissionDenied;
  }

  if (isSystemPermissionError) {
    return MediaPermissionsErrorType.SystemPermissionDenied;
  }
  if (errName === NOT_ALLOWED_ERROR) {
    if (isMacOs || isMobile) {
      return MediaPermissionsErrorType.SystemPermissionDenied;
    } else {
      return MediaPermissionsErrorType.BrowserPermissionDenied;
    }
  } else if (errName === NOT_READABLE_ERROR) {
    return MediaPermissionsErrorType.CouldNotStartVideoSource;
  }
  return MediaPermissionsErrorType.AmbiguousPermissionError;
};

// Specific error handler for Firefox
const firefoxHandler: ErrorHandler = (errName, errMessage) => {
  // iOS browsers all run on webkit, but error messages appear to be different for each browser
  // this is a catch-all for iOS browsers. Webkit requirement has changed as of iOS 17.4 but the
  // check is still here for backwards compatibility and because we don't know
  // if/when Browser vendors will update their Browser Engines off of Webkit.
  if (isIOS) {
    return MediaPermissionsErrorType.AmbiguousPermissionError;
  }
  const isSystemPermissionError = /The object can not be found here./i.test(
    errMessage,
  );
  const isBrowserPermissionError =
    /The request is not allowed by the user agent or the platform in the current context./i.test(
      errMessage,
    );

  if (isSystemPermissionError) {
    return MediaPermissionsErrorType.SystemPermissionDenied;
  }

  if (isBrowserPermissionError) {
    return MediaPermissionsErrorType.BrowserPermissionDenied;
  }
  // fallback if regex does not match relies on errName
  if ([NOT_FOUND_ERROR, NOT_READABLE_ERROR].includes(errName)) {
    return MediaPermissionsErrorType.SystemPermissionDenied;
  } else if (errName === NOT_ALLOWED_ERROR) {
    return MediaPermissionsErrorType.BrowserPermissionDenied;
  } else if (errName === ABORT_ERROR) {
    return MediaPermissionsErrorType.CouldNotStartVideoSource;
  }
  return MediaPermissionsErrorType.AmbiguousPermissionError;
};

// Mapping of browsers to their specific error handlers
const browserMediaErrorHandlers: Record<string, ErrorHandler> = {
  Chrome: chromeHandler,
  Edge: edgeHandler,
  Firefox: firefoxHandler,
  Safari: safariHandler,
  // Add more browsers and their handlers as needed
};

// Handles media permission errors by mapping them to custom error types, returns to FE.
export const handleMediaPermissionError = (
  err: DOMException,
): MediaPermissionError => {
  const errName = err.name;
  const errMessage = err.message;
  const normalizedBrowserName = normalizeBrowserName(browserName);

  const handler =
    browserMediaErrorHandlers[normalizedBrowserName] || defaultErrorHandler;

  if (!browserMediaErrorHandlers[normalizedBrowserName]) {
    return {
      type: MediaPermissionsErrorType.AmbiguousPermissionError,
      name: errName,
      message: errMessage,
      os: browserName,
      browser: normalizedBrowserName,
    };
  }

  const errorType = handler(errName, errMessage);

  // Construct the OS name for use in mapping to UI
  const os =
    (isWindows && "Windows") ||
    (isMacOs && "Mac OS") ||
    (isIOS && "iOS") ||
    (isAndroid && "Android") ||
    "Unknown OS";

  return {
    type: errorType,
    name: errName,
    message: errMessage,
    os: os,
    browser: normalizedBrowserName,
  };
};

export const setViewStatusByPermissionsDenied = (
  errorType,
  setSessionViewStatus,
  SessionViewStatus,
) => {
  if (errorType === MediaPermissionsErrorType.BrowserPermissionDenied) {
    setSessionViewStatus(SessionViewStatus.BrowserPermissionsDenied);
  } else if (errorType === MediaPermissionsErrorType.SystemPermissionDenied) {
    setSessionViewStatus(SessionViewStatus.SystemPermissionsDenied);
  } else if (errorType === MediaPermissionsErrorType.CouldNotStartVideoSource) {
    setSessionViewStatus(SessionViewStatus.CameraInUsePermissionsDenied);
  } else {
    setSessionViewStatus(SessionViewStatus.AmbiguousPermissionsDenied);
  }
};
