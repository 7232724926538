import { useState, useEffect } from "react";
import { Flex, Text, Box, AspectRatio, useDisclosure } from "@chakra-ui/react";
import CameraPlaceholder from "components/templates/SHSessionRoom/components/ParticipantTile/CameraPlaceholder";
import DeviceMenuSection from "components/templates/SHSessionRoom/components/DeviceMenuSection";
import PreJoinVideoCallControls from "components/templates/SHSessionRoom/components/PreJoinVideoCallControls";
import MicrophoneIndicator from "components/templates/SHSessionRoom/components/ParticipantTile/MicrophoneIndicator";
import ParticipantNameTag from "components/templates/SHSessionRoom/components/ParticipantTile/ParticipantNameTag";
import WelcomeModal from "components/templates/SHSessionRoom/components/modals/WelcomeModal";

import {
  useParticipantMetadata,
  useSessionDevices,
  useSessionConnectStatus,
  useSessionViewStatus,
} from "context/SessionRoomContext";

import {
  trackDeviceToggleState,
  trackBrowserPermissionsModalOpened,
  trackBrowserPermissionsSet,
} from "components/templates/SHSessionRoom/telemetry/analytics";

import { SessionViewStatus } from "components/templates/SHSessionRoom/types";
import { ToggleButtonTypes } from "components/templates/SHSessionRoom/types";

import { useTranslation } from "hooks/react-i18next";

import { logSessionStreamStartError } from "components/templates/SHSessionRoom/telemetry/datadog";

import {
  initiateDevicePermissionsAndConnection,
  handleMediaPermissionError,
  setViewStatusByPermissionsDenied,
} from "components/templates/SHSessionRoom/utils/browserMediaPermissions";

const VideoPreviewSection = ({ videoRef, isMobile }) => {
  const [permissionsModalOpen, setPermissionsModalOpen] = useState(false);

  const { onClose } = useDisclosure();
  const {
    sessionDevices,
    setSessionDevices,
    devicePermissionsDenied,
    setDevicePermissionsDenied,
  } = useSessionDevices();
  const { likelyFirewallError } = useSessionConnectStatus();
  const { selectedVideoDevice } = sessionDevices;
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoPreviewSection",
  });
  const { setSessionViewStatus } = useSessionViewStatus();
  const { localParticipantName, appointmentId, participantId } =
    useParticipantMetadata();
  const maxHeight = "90vh";
  const SMALL_THRESHHOLD = "20vh"; // Height threshold for small styling
  const isHeightLessThan20Vh = maxHeight < SMALL_THRESHHOLD;

  const startMedia = async () => {
    // NOTE: this is implicit, as Safari/Firefox do not allow use of Permissions API
    // This event should only be used for analysis at the beginning of a user flow
    // as past the first acceptance/denial, this event will not be accurate
    trackBrowserPermissionsModalOpened();
    const constraints = {
      video: {
        deviceId: selectedVideoDevice.deviceId,
        width: isMobile ? { ideal: 405 } : { ideal: 1920 },
        height: isMobile ? { ideal: 720 } : { ideal: 1080 },
        aspectRatio: { exact: 1.777 },
      },
      audio: {
        deviceId: selectedVideoDevice.deviceId,
      },
    };
    // requests access to camera and microphone
    // if given access, sets the stream to the video element and starts audio detection
    try {
      if (videoRef.current && videoRef.current.srcObject) {
        const oldStream = videoRef.current.srcObject as MediaStream;
        oldStream.getTracks().forEach((track) => track.stop());
      }
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;

      setSessionDevices((prevState) => ({
        ...prevState,
        stream,
        isCameraEnabled: constraints.video ? true : prevState.isCameraEnabled,
        isMicrophoneEnabled: constraints.audio
          ? true
          : prevState.isMicrophoneEnabled,
      }));
      setPermissionsModalOpen(false);
      // NOTE: This event is implicit, as Safari/Firefox do not allow use of Permissions API
      // This event should only be used for analysis at the beginning of a user flow
      // as past the first acceptance/denial, this event will not be accurate
      trackBrowserPermissionsSet(true, ["camera", "microphone"]);
    } catch (error) {
      // handle the error and set the view status based on the error name/message
      const err = handleMediaPermissionError(error);
      setViewStatusByPermissionsDenied(
        err.type,
        setSessionViewStatus,
        SessionViewStatus,
      );
      // set the device permissions denied to true (updates UI -- x-feature)
      setDevicePermissionsDenied(true);
      // NOTE: this mixpanel event is implicit, as Safari/Firefox do not allow use of Permissions API (query)
      trackBrowserPermissionsSet(false, ["camera", "microphone"]);
      // log the error to datadog
      logSessionStreamStartError(error, appointmentId, participantId);
      return;
    }
  };

  const toggleVideo = () => {
    if (sessionDevices.stream) {
      const newCameraState = !sessionDevices.isCameraEnabled;
      trackDeviceToggleState(
        ToggleButtonTypes.Camera,
        newCameraState,
        "pre_join",
      );
      setSessionDevices((prevState) => ({
        ...prevState,
        isCameraEnabled: !prevState.isCameraEnabled,
      }));
    }
  };

  const toggleAudio = async () => {
    if (sessionDevices.stream) {
      const newMicrophoneState = !sessionDevices.isMicrophoneEnabled;
      trackDeviceToggleState(
        ToggleButtonTypes.Microphone,
        newMicrophoneState,
        "pre_join",
      );
      setSessionDevices((prevState) => ({
        ...prevState,
        isMicrophoneEnabled: !prevState.isMicrophoneEnabled,
      }));
    }
  };

  useEffect(() => {
    initiateDevicePermissionsAndConnection(
      setPermissionsModalOpen,
      setViewStatusByPermissionsDenied,
      setDevicePermissionsDenied,
      SessionViewStatus,
      setSessionViewStatus,
      sessionDevices,
      startMedia,
    );
  }, []);

  useEffect(() => {
    if (selectedVideoDevice.deviceId) {
      startMedia();
    }
  }, [selectedVideoDevice.deviceId]);

  return (
    <>
      <Flex flex={1} mr={[0, 0, 40, 40]} direction="column" alignItems="center">
        <Box
          w={["100%", "100%", 688, 688]}
          mb="v-8"
          pt={isMobile ? "v-none" : "v-64"}
        >
          <Box pos="relative">
            {devicePermissionsDenied || likelyFirewallError ? (
              <Flex
                borderRadius="v-xl"
                justify="center"
                align="center"
                bg="black"
                overflow="hidden"
                sx={{ aspectRatio: "16 / 9" }}
              >
                <Text color="white">{t("noPreview")}</Text>
              </Flex>
            ) : (
              <>
                <Box
                  display={sessionDevices.isCameraEnabled ? "none" : "block"}
                  borderTopRightRadius="v-xl"
                  borderTopLeftRadius="v-xl"
                  sx={{ aspectRatio: "16 / 9" }}
                  overflow="hidden"
                  w={isMobile && "calc(100vw - 48px)"}
                >
                  <CameraPlaceholder borderRadius="v-none" />
                </Box>

                <AspectRatio
                  display={sessionDevices.isCameraEnabled ? "block" : "none"}
                  ratio={16 / 9}
                  borderTopRightRadius="v-xl"
                  borderTopLeftRadius="v-xl"
                  bg="black"
                  w={isMobile && "calc(100vw - 48px)"}
                >
                  <video
                    ref={videoRef}
                    autoPlay
                    muted
                    playsInline
                    controls={false}
                    aria-label="Video preview"
                    style={{
                      borderTopRightRadius: "16px",
                      borderTopLeftRadius: "16px",
                      transform: "scaleX(-1)",
                      objectFit: "contain",
                    }}
                  />
                </AspectRatio>

                <Flex
                  position="absolute"
                  zIndex={1}
                  bottom="v-64"
                  justifyContent={
                    localParticipantName ? "space-between" : "end"
                  }
                  alignItems="end"
                  width="full"
                  p="v-8"
                >
                  {localParticipantName && (
                    <ParticipantNameTag
                      isSmall={isHeightLessThan20Vh}
                      text={localParticipantName}
                    />
                  )}
                  <MicrophoneIndicator
                    isSmall={isHeightLessThan20Vh}
                    isMicrophoneEnabled={sessionDevices.isMicrophoneEnabled}
                  />
                </Flex>
                <PreJoinVideoCallControls
                  isMobile={isMobile}
                  isCameraEnabled={sessionDevices.isCameraEnabled}
                  isMicrophoneEnabled={sessionDevices.isMicrophoneEnabled}
                  toggleVideo={toggleVideo}
                  toggleAudio={toggleAudio}
                />
              </>
            )}
          </Box>
        </Box>
        {!likelyFirewallError && (
          <Flex
            direction="row"
            w="100%"
            p="v-2"
            justify="space-evenly"
            overflowX="visible"
            hidden={isMobile}
          >
            <DeviceMenuSection />
          </Flex>
        )}
      </Flex>
      <WelcomeModal
        isOpen={permissionsModalOpen && !devicePermissionsDenied}
        onClose={onClose}
        startMedia={startMedia}
      />
    </>
  );
};

export default VideoPreviewSection;
