import { useState, useRef, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "hooks/react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { cloneDeep } from "lodash/fp";
import Meowth from "@spring/meowth";
import { Button, FilterChip } from "@springcare/sh-component-library";
import {
  Box,
  Flex,
  HStack,
  Text,
  useMediaQuery,
  useDisclosure,
} from "@chakra-ui/react";
import {
  VFilterIcon,
  VLocalServicesIcon,
} from "@springcare/verdant-icons-react";
import { SHModal } from "design-system/components";

import CompanyExclusiveSection from "components/templates/Browse/ProviderBrowsePage/components/BRECS/CompanyExclusiveSection";
import { UpdateAddressForm } from "components/templates/Browse/ProviderBrowsePage/components//HeaderSection/UpdateAddressForm";
import ProviderFilterFlyout from "components/templates/Browse/ProviderBrowsePage/components/Modals/ProviderFilterFlyout";

import {
  searchCareProviders,
  getRecommendedTherapists,
} from "operations/queries/careProvider";

import { useProviderBrowseContext } from "context/ProviderBrowseContext";

import { useCountryStates } from "hooks";

import { trackAddPreferencesClicked } from "components/templates/RecommendationsPage/analytics";
import {
  trackEditAddressClicked,
  trackFilterChangeApplied,
} from "components/templates/Browse/ProviderBrowsePage/analytics";

import {
  getQueriedFilters,
  prepareQueryFilters,
  mapCategoryToSingular,
  dedupeProviders,
  BrecsVariants,
} from "components/templates/Browse/ProviderBrowsePage/utils";
import { useBrecsAlgoVersion } from "hooks/useBrecsAlgoVersion";

const FilterSection = ({ providerType }) => {
  const brecsVariantFlag = useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT);
  const sagemakerRanking = useFeatureFlag(FLAGS.BROWSE_SAGEMAKER_RANK);
  const isCombinedList = brecsVariantFlag === BrecsVariants.CombinedList;
  const { t } = useTranslation(["careProvider", "common"]);
  const [stateFull, setStateFull] = useState(null);
  const [stateAbbrv, setStateAbbrv] = useState(null);
  const [overflowActive, setOverflowActive] = useState(false);
  const closeModalFocusRef = useRef(null);
  const { data: countryStates } = useCountryStates();
  const {
    isOpen: isFilterFlyoutOpen,
    onOpen: onOpenFilterFlyout,
    onClose: closeFilterFlyout,
  } = useDisclosure();
  const {
    isOpen: isAddressModalOpen,
    onOpen: onOpenAddressModal,
    onClose: closeAddressModal,
  } = useDisclosure();
  const [isSm] = useMediaQuery("(max-width: 539px)");
  const [isMd] = useMediaQuery("(min-width: 540px) and (max-width: 960px)");
  const [isLg] = useMediaQuery("(min-width: 961px) and (max-width: 1139px)");
  const [isXlg] = useMediaQuery("(min-width: 1140px)");
  const timezoneIndicator = useMediaQuery("(min-width: 960px)");
  const {
    memberAddressCopy,
    isCompanyExclusive,
    possibleFilters,
    queriedFilters,
    setQueriedFilters,
    providerList,
    brecsProviderList,
    setBrecsProviderList,
    setSelectedFilters,
    setProviderListLoading,
    setProviderList,
    setPaging,
    setBrecsProviderListLoading,
    setQueryRequestId,
    setPossibleFilters,
  } = useProviderBrowseContext();
  const filterRowRef = useRef(null);
  const [doSearchCareProviders] = useLazyQuery(searchCareProviders, {
    fetchPolicy: "network-only",
  });
  const [doGetRecommendedTherapists] = useLazyQuery(getRecommendedTherapists, {
    fetchPolicy: "no-cache",
  });

  const showDedicatedProviderToggle = possibleFilters?.allow_company_exclusive;

  const timeZoneName = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  useEffect(() => {
    if (!memberAddressCopy || !countryStates?.states) return;
    const addressArr = memberAddressCopy.split(", ");
    const stateObj = countryStates?.states?.find(
      (state) => state.value === addressArr[2],
    );

    if (stateObj) {
      setStateFull(stateObj.label);
      setStateAbbrv(stateObj.value);
    }
  }, [memberAddressCopy, countryStates?.states]);

  useEffect(() => {
    const selectedFiltersContainer =
      document.getElementById("scrollable-chips");
    const selectedFiltersScroll = (event) => {
      const isInteger = event.deltaY % 1 === 0;
      if (isInteger) return; // for trackpad
      event.preventDefault();
      selectedFiltersContainer.scrollBy({
        // @ts-ignore
        left: event?.deltaY < 0 ? -175 : 175,
        behavior: "smooth",
      });
    };
    selectedFiltersContainer?.addEventListener("wheel", selectedFiltersScroll);

    return () => {
      selectedFiltersContainer?.removeEventListener(
        "wheel",
        selectedFiltersScroll,
      );
    };
  }, []);

  const QUERY_VERSION = useBrecsAlgoVersion(sagemakerRanking);

  async function updateFilteredProviders() {
    const preparedFilters = prepareQueryFilters(queriedFilters, providerType);

    if (preparedFilters) {
      await getFilteredProviders(preparedFilters);
    }
  }

  async function appendBrecsProviders(
    filteredSearchProvidersObj,
    preparedFilters,
  ) {
    const recommendedData = await getFilteredBrecsProviders(preparedFilters);
    const filteredBrecsProviders =
      recommendedData && recommendedData?.recommendedTherapists;
    const filteredSearchProviders = filteredSearchProvidersObj?.providers;

    // if there's no recs, set the search providers
    if (!filteredBrecsProviders) {
      setProviderList(filteredSearchProviders);
      setProviderListLoading(false);
      setPaging(filteredSearchProvidersObj?.paging);
      setQueryRequestId(filteredSearchProviders?.query_request_id);
      return;
    }

    setBrecsProviderList(filteredBrecsProviders);
    const duplicatesRemoved = dedupeProviders(
      filteredSearchProviders,
      recommendedData?.data,
      providerList,
      setProviderList,
      isCombinedList,
      false, // isInitialLoad
    );

    const totalProviderCount =
      filteredBrecsProviders?.length +
      filteredSearchProvidersObj?.paging?.total -
      duplicatesRemoved;

    setProviderListLoading(false);
    setPaging({
      limit: filteredSearchProvidersObj?.paging?.limit,
      page: filteredSearchProvidersObj?.paging?.page,
      pages: filteredSearchProvidersObj?.paging?.pages,
      total: totalProviderCount,
    });

    setQueryRequestId(filteredSearchProvidersObj?.query_request_id);
  }

  async function getFilteredProviders(preparedFilters) {
    const conditionsInFilters = preparedFilters?.filters?.conditions?.length;

    preparedFilters["version"] = QUERY_VERSION;
    setProviderListLoading(true);
    const response = await doSearchCareProviders({
      variables: preparedFilters,
    });

    if (response) {
      setPossibleFilters(
        response?.data?.care_providers_search?.possible_filters,
      );
    }

    if (response && conditionsInFilters) {
      setProviderList(response?.data.care_providers_search?.providers);
      setBrecsProviderList([]);
      setProviderListLoading(false);
      setPaging(response?.data.care_providers_search?.paging);
      setQueryRequestId(response?.data.care_providers_search?.query_request_id);
    } else if (response && !conditionsInFilters) {
      await appendBrecsProviders(
        response?.data.care_providers_search,
        preparedFilters,
      );
    }
  }

  async function getFilteredBrecsProviders(preparedFilters) {
    const apolloOptionsUserId = Meowth.apolloOptionsUserId();
    const sagemakerRanking = useFeatureFlag(FLAGS.RECS_SAGEMAKER_RANK);
    apolloOptionsUserId.variables.version =
      useBrecsAlgoVersion(sagemakerRanking);
    apolloOptionsUserId.variables.filters = preparedFilters.filters;
    setBrecsProviderListLoading(true);

    const { data } = await doGetRecommendedTherapists({
      ...apolloOptionsUserId,
    });
    if (data?.user?.member?.recommended_therapists) {
      setBrecsProviderListLoading(false);
      // Return the therapist key with group_name added within the therapist object
      const recommendedTherapists = data.user.member.recommended_therapists.map(
        (item) => ({
          ...item.therapist,
          group_name: item.group_name,
          isBrecsRecommended: true,
        }),
      );
      return {
        data: data.user.member.recommended_therapists,
        recommendedTherapists: recommendedTherapists,
      };
    } else {
      return false;
    }
  }

  function unselectFilter(filterObj: { type: string; filterLabel: string }) {
    const { type, filterLabel } = filterObj;
    const queriedFiltersCopy = { ...queriedFilters };
    // Removes deselected filter
    queriedFiltersCopy.filters[type] = queriedFiltersCopy.filters[type].filter(
      (item) => {
        return item !== filterLabel;
      },
    );

    // Track tag removed for Mixpanel
    trackFilterChangeApplied(
      filterLabel,
      mapCategoryToSingular(type, t),
      false,
      "BRECS Preferences Section",
    );
    // Updates state of `searchProvider` payload with removed filters
    setQueriedFilters(cloneDeep(queriedFiltersCopy));
    // Updates flyout "selected" filters to match updated "queried" filters
    setSelectedFilters(cloneDeep(queriedFiltersCopy));
    // Triggers + updates `searchProvider` query with new results
    updateFilteredProviders();
  }

  const queriedFilterObjs = getQueriedFilters(queriedFilters.filters);

  const preferencesButtonText = (btnText) => {
    if (isXlg || isLg) {
      return queriedFilterObjs.length > 0
        ? `${btnText} (${queriedFilterObjs.length})`
        : t("providerFilterFlyout.prefsTitle");
    } else if (isMd || isSm) {
      return queriedFilterObjs.length > 0
        ? `(${queriedFilterObjs.length})`
        : btnText;
    }
  };

  useEffect(() => {
    // Function to check if the filter row is overflowing
    const checkOverflow = () => {
      const filterContainer = filterRowRef.current;
      if (filterContainer) {
        const isOverflowing =
          filterContainer.offsetWidth < filterContainer.scrollWidth;
        setOverflowActive(isOverflowing);
      }
    };
    // // Initial render check, in case user has pre-populated filters
    checkOverflow();
    // Set up ResizeObserver to monitor for changes in the filter rows size
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(() => {
        checkOverflow();
      });
    });
    if (filterRowRef.current) {
      resizeObserver.observe(filterRowRef.current);
    }
    // Clean up
    return () => {
      if (filterRowRef.current) {
        resizeObserver.unobserve(filterRowRef.current);
      }
    };
  }, [queriedFilterObjs?.length]);

  return (
    <>
      <Box
        pos={isSm ? "sticky" : "fixed"}
        top={78}
        width="100%"
        mt={1}
        zIndex={1}
        borderBottomColor="border-subtle"
        borderBottomWidth={1}
      >
        <Box h="72px" bg="accent-on-base" justifyContent="space-between">
          <Box w={isXlg ? "1140px" : "100%"} margin="0 auto">
            <Flex
              w="100%"
              h="72px"
              align="center"
              justifyContent="space-between"
              pr={isMd ? 48 : 16}
              pl={isMd ? 132 : 16}
            >
              {showDedicatedProviderToggle && (isLg || isXlg) && (
                <CompanyExclusiveSection
                  showDedicatedProviderToggle={showDedicatedProviderToggle}
                  isSm={isSm}
                  isMd={isMd}
                  isLg={isLg}
                  isXlg={isXlg}
                  t={t}
                />
              )}

              <Flex
                h="100%"
                align="center"
                boxShadow={
                  overflowActive && "5px 0 5px -5px rgba(45, 40, 31, 0.282);"
                }
              >
                <FilterChip
                  onClick={() => {
                    trackAddPreferencesClicked("BRECS Preferences Flyout");
                    onOpenFilterFlyout();
                  }}
                  variant="medium-emphasis"
                  size="sm"
                  icon={VFilterIcon}
                  ml={showDedicatedProviderToggle && 4}
                  mr={16}
                  px={[8, 8, 20, 20]}
                  py={8}
                  borderRadius={30}
                  disabled={isCompanyExclusive}
                  data-cy="moreFilters"
                  selected={queriedFilterObjs.length > 0}
                  _focus={{
                    backgroundColor:
                      queriedFilterObjs.length > 0
                        ? "accent-base"
                        : "hover-base-medium-emphasis",
                  }}
                >
                  {preferencesButtonText(t("providerFilterFlyout.prefsTitle"))}
                </FilterChip>
              </Flex>

              {queriedFilterObjs && (
                <HStack
                  id="scrollable-chips"
                  w="100%"
                  gap={0}
                  overflowX="scroll"
                  ref={filterRowRef}
                  pl={overflowActive ? 8 : 0}
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "0",
                      display: "none",
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#FF0000",
                    },
                    scrollbarWidth: "none" /* Firefox */,
                    "-ms-overflow-style": "none" /* IE, Edge */,
                  }}
                >
                  {queriedFilterObjs.map((queriedFilter) => {
                    return (
                      <FilterChip
                        onClick={() => unselectFilter(queriedFilter)}
                        type="removable"
                        key={queriedFilter.filterLabel}
                        disabled={isCompanyExclusive}
                        data-cy={`${queriedFilter.filterLabel}-tag`}
                      >
                        {queriedFilter.filterLabel}
                      </FilterChip>
                    );
                  })}
                </HStack>
              )}

              <HStack
                h="72px"
                gap={16}
                align="center"
                boxShadow={
                  overflowActive && "-5px 0 5px -5px rgba(45, 40, 31, 0.282);"
                }
              >
                <Flex ml={16}>
                  <Button
                    onClick={() => {
                      trackEditAddressClicked();
                      onOpenAddressModal();
                    }}
                    variant="no-emphasis"
                    icon={VLocalServicesIcon}
                    p={0}
                    cursor={isCompanyExclusive ? "not-allowed" : "pointer"}
                    disabled={isCompanyExclusive}
                  >
                    {isSm ? stateAbbrv : stateFull}
                  </Button>
                </Flex>

                {timezoneIndicator[0] && (
                  <Box marginInlineStart="0px !important">
                    <Text size="body-medium" whiteSpace="nowrap">
                      {t("timeZone")} {timeZoneName}
                    </Text>
                  </Box>
                )}
              </HStack>
            </Flex>
          </Box>
        </Box>
        {showDedicatedProviderToggle && (isSm || isMd) && (
          <CompanyExclusiveSection
            showDedicatedProviderToggle={showDedicatedProviderToggle}
            isSm={isSm}
            isMd={isMd}
            isLg={isLg}
            isXlg={isXlg}
            t={t}
          />
        )}
      </Box>

      <ProviderFilterFlyout
        onOpen={onOpenFilterFlyout}
        isOpen={isFilterFlyoutOpen}
        onClose={closeFilterFlyout}
      />

      <SHModal
        isOpen={isAddressModalOpen}
        onClose={closeAddressModal}
        modalTitle={t("updateAddress.title")}
        finalFocusRef={closeModalFocusRef}
      >
        <UpdateAddressForm onClose={closeAddressModal} />
      </SHModal>
    </>
  );
};

export default FilterSection;
