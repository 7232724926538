/* NOTE: This file will be deprecated in Q2, 2023 -- No changes made in this file in the last few months
are permanent and this should NOT be used in the new design system. We moved this from smeargle to
cherrim solely because we needed to use it with design system components,
so it could not stay in smeargle without becoming a circular dependency
*/

import { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { SpringLogoIcon, SpringTextLogo } from "design-system/assets";

import styles from "./styles.module.scss";
import { SHLanguageSelect, Box, Flex } from "design-system/components";
import { useTranslation } from "react-i18next";
import { Show, useMediaQuery } from "@chakra-ui/react";
import { useRouter } from "next/router";
import routes from "routes";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

interface NavigationBar {
  onScroll: boolean;
  initialState: boolean;
  left: React.ReactNode;
  right: React.ReactNode;
  logoWrapper: Function;
  enableLanguageToggle: boolean;
  handleLanguageChange: () => void;
  defaultLanguage: object;
  backgroundColor: string;
  languageSelectAriaLabel: string;
  languageIconAriaLabel: string;
  isYourAssessmentResultsPage: boolean;
  isScrollableNav: boolean;
}

export const Navigation = (props: NavigationBar) => {
  const [lang, setLang] = useState("en");
  const isOldHeader = true;
  const { t } = useTranslation("a11y");
  const router = useRouter();
  const redirectToHome = () => {
    router.replace(routes.MemberHome.to, routes.MemberHome.as);
  };
  const [isLgScreen] = useMediaQuery(
    `(min-width: 768px) and (max-width: 1250px)`,
  );
  const [isXLgScreen] = useMediaQuery(
    `(min-width: 1250px) and (max-width: 1400px)`,
  );

  const { isScrollableNav = false } = props;
  const whiteNavBar =
    props.isYourAssessmentResultsPage &&
    isScrollableNav &&
    useFeatureFlag(FLAGS.SHOW_NEW_ASSESSMENT_RESULTS_PAGE);

  return (
    <nav
      aria-label={t("main")}
      className={classnames({
        [styles.fixed_navigation]: !isScrollableNav,
        [styles.scrollable_navigation]: isScrollableNav,
        [styles.onScroll]: props.onScroll,
        [styles.initialState]: props.initialState,
      })}
    >
      {props.isYourAssessmentResultsPage ? (
        <Box
          w={"100%"}
          background={whiteNavBar ? "background-base" : "background-subtle"}
          display={isXLgScreen && "flex"}
          justifyContent={"center"}
        >
          <Flex
            direction={"row"}
            justifyContent={"space-between"}
            py={15}
            ms={isXLgScreen ? "24" : isLgScreen ? 30 : 110}
            me={{ lg: 80, md: 80 }}
            w={isXLgScreen && "1150px"}
          >
            <Box
              as="button"
              aria-label="SpringLogoIcon"
              onClick={redirectToHome}
            >
              <Show above="767px">
                <div>
                  <SpringLogoIcon />
                </div>
              </Show>
            </Box>
            <Flex direction={"row"}>{props.right}</Flex>
          </Flex>
        </Box>
      ) : (
        <Flex
          direction={"row"}
          justifyContent={"space-around"}
          py={15}
          mx={{ sm: 24 }}
        >
          <Box>
            <div className={styles.leftWithLogo}>
              {props.logoWrapper && (
                <props.logoWrapper>
                  <div className={styles.logo}>
                    <SpringTextLogo />
                  </div>
                  <div className={styles.logoWithoutText}>
                    <SpringLogoIcon />
                  </div>
                </props.logoWrapper>
              )}
              {!props.logoWrapper && (
                <>
                  <div className={styles.logo}>
                    <SpringTextLogo />
                  </div>
                  <div className={styles.logoWithoutText}>
                    <SpringLogoIcon />
                  </div>
                </>
              )}
              {props.left && (
                <div className={styles.linkSectionLeft}>{props.left}</div>
              )}
            </div>
          </Box>
          <Box>
            <Flex direction={"row"} justifyContent={"center"}>
              {props.enableLanguageToggle && (
                <nav aria-label={props.languageSelectAriaLabel}>
                  <SHLanguageSelect
                    lang={lang}
                    setLang={setLang}
                    isOldHeader={isOldHeader}
                  />
                </nav>
              )}
              <Flex direction={"row"}>{props.right}</Flex>
            </Flex>
          </Box>
        </Flex>
      )}
    </nav>
  );
};

Navigation.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  enableLanguageToggle: PropTypes.bool,
  handleLanguageChange: PropTypes.func,
  defaultLanguage: PropTypes.object,
  backgroundColor: PropTypes.string,
  languageSelectAriaLabel: PropTypes.string,
  languageIconAriaLabel: PropTypes.string,
};

Navigation.defaultProps = {
  languageSelectAriaLabel: "",
  languageIconAriaLabel: "",
  isYourAssessmentResultsPage: false,
};
