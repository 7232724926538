import { useQuery } from "@apollo/client";
import { getCustomerSite } from "operations/queries/customerSite";
import { getMemberInfo } from "operations/queries/member";
import Meowth from "@spring/meowth";

export const useCustomerCustomRoleName = () => {
  const { data: memberInfo, loading: memberInfoLoading } = useQuery(
    getMemberInfo,
    Meowth.apolloOptionsUserId(),
  );

  const customerSiteID = memberInfo?.user?.member?.customer_site_id;

  const { data } = useQuery(getCustomerSite, {
    variables: {
      id: customerSiteID,
    },
    skip: !customerSiteID || memberInfoLoading,
  });
  // TODO: The BE should really just have a field for this
  const customRoleName =
    data?.customer_site?.care_providers?.[0]?.customer_role_name || "";
  return customRoleName;
};
