import { Switch } from "@springcare/sh-component-library";
import { MenuItem, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { isChrome, isEdge } from "react-device-detect";

import { useParticipantMetadata } from "context/SessionRoomContext";

import { trackFilterToggleState } from "components/templates/SHSessionRoom/telemetry/analytics";

import { useTranslation } from "hooks/react-i18next";

export const BlurBackgroundMenuItem = () => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "blurBackgroundMenuItem",
  });

  const {
    isBlurBackgroundPreferenceEnabled,
    setIsBlurBackgroundPreferenceEnabled,
  } = useParticipantMetadata();

  const canUseBlur = isChrome || isEdge;

  const toggleBlurFilter = (track: boolean) => {
    if (!canUseBlur) return;

    if (track) {
      trackFilterToggleState(
        "Blur Background",
        !isBlurBackgroundPreferenceEnabled,
      );
    }
    setIsBlurBackgroundPreferenceEnabled((prevState) => !prevState);
  };

  return (
    <MenuItem
      onClick={() => toggleBlurFilter(false)}
      _focus={{ outline: "0px solid transparent !important" }}
      _focusVisible={{ outline: "2px solid black !important" }}
      display="flex"
    >
      <FormControl display="flex" justifyContent="space-between" mx="v-8">
        <FormLabel>
          <Text>{t("header")}</Text>
          <Text size="body-small-strong" color="content-secondary">
            {canUseBlur ? t("subHeader") : t("alternateSubHeader")}
          </Text>
        </FormLabel>
        <Switch
          isChecked={isBlurBackgroundPreferenceEnabled}
          onChange={() => toggleBlurFilter(true)}
          disabled={!canUseBlur}
        />
      </FormControl>
    </MenuItem>
  );
};
