import React, { createContext, useContext, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import { useQuery } from "@apollo/client";
import type { ApolloError } from "@apollo/client";
import { getCoveredLifeInfo } from "modules/shared/graphql/queries/getCoveredLifeInfo";
import { useMemberInfo } from "hooks";
/**
 * The goal is this provider will eventually support a debug panel so that we can
 * edit values on the fly and will be a primary source of truth for member data.
 *
 * getCoveredLifeInfo:
 *
 * isCareEnabled: false if the member is a CHIP guardian, meaning that they can
 *   manage their dependents' accounts, but do not have care enabled for themselves.
 *   They will not be able to access parts of the app - specifically, views that use
 *   <MemberDashboardPageWrapper /> - see /hooks/useGuardianRedirect.ts for more info.
 *
 * isChip: a flag on the covered life that indicates if the member is part of the CHIP program.
 */

interface MemberContextProps {
  isCareEnabled: boolean;
  isChip: boolean;
  setCoveredLifeId: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  error?: ApolloError;
}

const MemberContext = createContext<MemberContextProps | undefined>(undefined);

export const MemberProvider = ({ children }) => {
  const isChipGuardianEnabled = useFeatureFlag(FLAGS.CHIP_GUARDIAN_RELEASE);
  // coveredLifeId is set by useLazyFindCoveredLife
  const [coveredLifeId, setCoveredLifeId] = useState(undefined);

  // fetch our own member info if the coveredLifeId is not available
  const { data: memberData, loading: isMemberDataLoading } = useMemberInfo({
    skip: Boolean(coveredLifeId),
  });

  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(getCoveredLifeInfo, {
    variables: {
      id: coveredLifeId || memberData?.user?.member?.covered_life?.id,
    },
    skip:
      !isChipGuardianEnabled ||
      (!coveredLifeId && !memberData?.user?.member?.covered_life?.id),
  });
  // Careful changing this as useGuardianRedirect depends on this value
  const isCareEnabled = isChipGuardianEnabled
    ? data?.get_covered_life_info?.is_care_enabled ?? true // there are members that do not have a CL
    : true;
  const isChip = isChipGuardianEnabled && data?.get_covered_life_info?.is_chip;

  return (
    <MemberContext.Provider
      value={{
        isCareEnabled,
        isChip,
        setCoveredLifeId,
        isLoading: isLoading || isMemberDataLoading,
        error,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export const useMember = (): MemberContextProps => {
  const context = useContext(MemberContext);
  if (!context) {
    throw new Error("useMember must be used within a MemberProvider");
  }
  return context;
};
