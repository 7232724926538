import { AssessmentRisk, ProviderRole } from "@spring/constants";
import { formatMemberExactAge } from "utils/displayHelpers";
import { formatPhoneForDisplay } from "utils/global";
import { paramsToObject } from "utils/routes";
import { useRouter } from "next/router";
import {
  msftAllowedCountries,
  msftCustomerId,
  testCustomerId,
} from "constants/t2CustomersJulyRelease";
import { IntentOptions } from "components/organisms/MemberIntentQuestion/IntentOptionsEnum";
import { isEmpty } from "lodash";

export const isHighRisk = (assessments = []) => {
  return assessments.some((item) => item?.risk === AssessmentRisk.High);
};

export const isMinor = (member) => {
  return member?.minor;
};

export const isTeen = (memberDateOfBirth) => {
  const age = formatMemberExactAge(memberDateOfBirth);
  return age > 12 && age < 18;
};

export const isMinorNonTeen = (memberDateOfBirth) => {
  const age = formatMemberExactAge(memberDateOfBirth);
  return age < 13;
};

export const memberAgeRange = (memberDateOfBirth) => {
  const age = formatMemberExactAge(memberDateOfBirth);
  switch (true) {
    case 0 <= age && age <= 12:
      return "0-12";
    case 13 <= age && age <= 17:
      return "13-17";
    case 18 <= age && age <= 24:
      return "18-24";
    case 25 <= age && age <= 34:
      return "25-34";
    case 35 <= age && age <= 44:
      return "35-44";
    case 45 <= age && age <= 54:
      return "45-54";
    case 55 <= age && age <= 64:
      return "55-64";
    case 65 <= age:
      return "65+";
    default:
      return;
  }
};

export const isLegacyMinor = (member) => {
  return !member?.manager && member?.minor;
};

export const isT2Member = (member) => {
  return member?.is_global_t2;
};

export const getDirectDialForT2 = (cohort) => {
  return formatPhoneForDisplay(cohort?.eap_direct_dial_number);
};

export const getFreePhoneForT2 = (cohort) => {
  return formatPhoneForDisplay(cohort?.eap_freephone_number);
};

export const hasRequiredT2Params = (queryParams) => {
  return Boolean(
    queryParams?.customer_id &&
      queryParams?.country &&
      queryParams?.is_t2 === "true",
  );
};

export const isEligibleForColdSignup = (country) => {
  return msftAllowedCountries.includes(country);
};

export const isT2MemberPreSignup = (queryParams = null) => {
  queryParams =
    queryParams ||
    paramsToObject(new URLSearchParams(window.location.search).entries());
  if (isMSFTorTestCustomer(queryParams?.customer_id)) {
    return (
      isEligibleForColdSignup(queryParams?.country) &&
      hasRequiredT2Params(queryParams)
    );
  }
  return hasRequiredT2Params(queryParams);
};

export const isT2MemberPreSignupUsingRouter = () => {
  const router = useRouter();
  const queryParams = router?.query;

  if (isMSFTorTestCustomer(queryParams?.customer_id)) {
    return (
      isEligibleForColdSignup(queryParams?.country) &&
      hasRequiredT2Params(queryParams)
    );
  }
  return hasRequiredT2Params(queryParams);
};

export const isMSFTorTestCustomer = (customerId) => {
  return customerId === msftCustomerId || customerId === testCustomerId;
};

export const hasManagedDependents = (member) =>
  member?.managed_dependents.length > 0;

export const parseBirthday = (dob) => {
  const [month, day, year] = dob.split("/");

  return {
    month: Number(month),
    day,
    year,
  };
};

export const parseSSOBirthday = (dob) => {
  const [year, month, day] = dob.split("-");

  const converted_month = Number(month);

  return {
    month: isNaN(converted_month) ? "" : converted_month,
    day,
    year,
  };
};

export const isLowRisk = (assessments) => {
  if (assessments.length === 0) {
    return false;
  }
  return assessments.some((item) =>
    item?.risk
      ? [AssessmentRisk.Low, AssessmentRisk.LowMedium].includes(item.risk)
      : false,
  );
};

export const isMediumRisk = (assessments = []) => {
  return assessments.some((item) =>
    item?.risk ? [AssessmentRisk.Medium].includes(item.risk) : false,
  );
};

export const isMediumOrHighRisk = (assessments) => {
  if (!assessments || assessments?.length === 0) {
    return false;
  }
  return assessments.some((item) =>
    item?.risk
      ? [AssessmentRisk.Medium, AssessmentRisk.High].includes(item.risk)
      : false,
  );
};

export const isLowRiskSUD = (assessments) => {
  let isLowRiskSUD = true;

  assessments?.questionnaires.forEach((questionnaire) => {
    if (
      ["DAST10", "AUDIT"].includes(questionnaire.kind) &&
      [
        AssessmentRisk.LowMedium,
        AssessmentRisk.Medium,
        AssessmentRisk.High,
      ].includes(JSON.parse(questionnaire?.results_sanitized).risk)
    ) {
      isLowRiskSUD = false;
    }
  });

  return isLowRiskSUD;
};

export const isDemoEnvironment = () => {
  return process.env.APP_ENV === "demo";
};

export const getProviderRole = (type) => {
  const rolesMap = [
    {
      role: ProviderRole.CareNavigator,
      providerType: ["Care Navigator"],
    },
    {
      role: ProviderRole.Therapist,
      providerType: ["Therapist"],
    },
    {
      role: ProviderRole.MedicationManager,
      providerType: [
        "Internist",
        "Psychiatrist",
        "Family Practitioner",
        "Psychiatric Nurse Practitioner",
        "Physician",
      ],
    },
    {
      role: ProviderRole.Coach,
      providerType: ["Coach"],
    },
    {
      role: ProviderRole.CareAdvocate,
      providerType: ["Care Advocate"],
    },
    {
      role: ProviderRole.CareConsultant,
      providerType: ["Care Consultant"],
    },
    {
      role: ProviderRole.AssociateProvider,
      providerType: [ProviderRole.AssociateProvider], // not using the roles field but associate_status
    },
    {
      role: ProviderRole.PeerRecoverySpecialist,
      providerType: ["Peer Recovery Specialist"],
    },
    {
      role: ProviderRole.CareNavigator,
      providerType: ["Specialty Care Navigator"],
    },
  ];

  const providerRole = rolesMap.find((el) => el.providerType.includes(type));
  return providerRole?.role;
};

export const hasPreAssessedExperience = (intent_option, assessment = {}) => {
  if (!isEmpty(assessment)) {
    return false;
  }
  if (
    [IntentOptions.HouseholdCare, IntentOptions.NotSure].includes(intent_option)
  ) {
    return true;
  }
  return (
    intent_option === IntentOptions.SelfGuidedActivity && isEmpty(assessment)
  );
};
