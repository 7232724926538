import { API_DOMAIN } from "constants/api";

export type UpdateSessionConsentsVariables = {
  appointmentId: string;
  memberConsent?: boolean;
  careProviderConsent?: boolean;
};

export const useUpdateSessionConsents = () => {
  const rotomUrl = API_DOMAIN;

  return async ({
    appointmentId,
    memberConsent,
    careProviderConsent,
  }: UpdateSessionConsentsVariables) => {
    const res = await fetch(
      `${rotomUrl}/api/ehr/session_recording_consents/${appointmentId}`,
      {
        method: "put",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("compass_redirect_access_token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          member_consent: memberConsent,
          care_provider_consent: careProviderConsent,
        }),
      },
    );
    return await res.json();
  };
};
export default useUpdateSessionConsents;
